<template>
    <div class="searchable-field" :class="customerProperty" ref="parent">
        <TextField
            :label="label"
            v-model:text="cartStore.customer.address[customerProperty]"
            autocomplete="no"
            ref="inputField"
            :error="error"
            :message="message"
            :name="name"
            :loading="isLoading"
        />
        <SearchResults
            v-if="showResults"
            :class="upOrDown"
            :property="customerProperty"
            :loading="loading"
            @select="focussed = false"
        />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from "vue"
import TextField from "@/ui-elements/input/text-field/TextField.vue"
import SearchResults from "@/pages/pos/modal/customer/search/SearchResults.vue"
import { useCustomerModalStore } from "@/store/CustomerModal"
import { CustomerAddress } from "@/interface/Customers/CustomerAddress"
import {
    calculateDirection,
    calculateDisplay,
    useFocus,
} from "@/pages/pos/modal/customer/data/useSearchResults"
import { useCartStore } from "@/store/cart/Cart"
import { useDebounce } from "@/utils/useDebounce"

export default defineComponent({
    name: "SearchableTextField",
    components: { SearchResults, TextField },
    props: {
        label: String,
        customerProperty: {
            type: String as PropType<keyof CustomerAddress>,
            required: true,
        },
        boundingElement: {
            type: HTMLElement,
        },
        error: Boolean,
        message: String,
        name: {
            type: [Array, String] as PropType<string | string[]>,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    setup: function (props) {
        const customerStore = useCustomerModalStore()
        const cartStore = useCartStore()
        const focussed = ref(false)
        const showResults = computed(() =>
            calculateDisplay(
                focussed.value,
                cartStore.customer.address,
                props.customerProperty,
                customerStore.results
            )
        )
        const loading = ref(false)
        watch(
            [
                () => cartStore.customer.address[props.customerProperty],
                focussed,
            ],
            useDebounce(() => {
                if (
                    focussed.value &&
                    cartStore.customer.address[props.customerProperty]
                ) {
                    loading.value = true
                    customerStore
                        .fetchResults(
                            cartStore.customer.address[
                                props.customerProperty
                            ] || "",
                            props.customerProperty
                        )
                        .finally(() => (loading.value = false))
                }
            }, 300)
        )
        const inputField = ref()
        const upOrDown = computed(() =>
            calculateDirection(props.boundingElement, inputField.value?.$el)
        )
        const parent = useFocus(focussed)

        return {
            cartStore,
            focussed,
            showResults,
            inputField,
            upOrDown,
            parent,
            loading,
        }
    },
})
</script>

<style lang="scss">
.searchable-field {
    position: relative;
    width: 100%;
    height: fit-content;
}
</style>
