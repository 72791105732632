import { lsPersistentObject } from "@/utils/external-APIs/sot/helper"
import Queue from "@/utils/external-APIs/sot/queue"
import { useSettingsStore } from "@/store/Settings"
import MicroApi from "@/utils/external-APIs/sot/micro/microApi"

export default class Micro extends MicroApi {
    microsQueueSeen_date: Date
    microsQueueSeen: any
    #cash_payment_id: number
    #pin_payment_id: number
    #employeeObjectNum: number
    #revenueCenter: number
    #lastResult: any
    #microsQueue: any

    constructor() {
        super()
        this.microsQueueSeen_date = new Date()
        this.microsQueueSeen = lsPersistentObject(
            "microQueueSeen",
            Number(
                (this.microsQueueSeen_date.getHours() >= 5 &&
                    this.microsQueueSeen_date.setDate(
                        this.microsQueueSeen_date.getDate() + 1
                    ),
                this.microsQueueSeen_date.setHours(6),
                this.microsQueueSeen_date.setMinutes(0)) / 1000
            ) - Math.round(Date.now() / 1000)
        )
        const settingsStore = useSettingsStore()
        this.#cash_payment_id =
            settingsStore.settings.micros_paymentid_cash ?? 100
        this.#pin_payment_id = settingsStore.settings.micros_paymentid_pin ?? 10
        this.#employeeObjectNum =
            settingsStore.settings.micros_employeeObjectNum ?? 4
        this.#revenueCenter = settingsStore.settings.micros_revenueCenter ?? 11
        this.#lastResult = null
        this.#microsQueue = new Queue()
        this.getTenderMedia()
    }

    setHostPort = (h: string, p: number) => {
        Micro.host = h || Micro.host
        Micro.port = p || Micro.port
        Micro.setBaseUrls()
    }

    setPaymentIds = (cash: number, pin: number) => {
        this.#cash_payment_id = cash || this.#cash_payment_id
        this.#pin_payment_id = pin || this.#pin_payment_id
    }

    setEmployeeRevenueCenter = (
        employeeObjectNum: number,
        revenueCenter: number
    ) => {
        this.#employeeObjectNum = employeeObjectNum || this.#employeeObjectNum
        this.#revenueCenter = revenueCenter || this.#revenueCenter
    }

    getLastResult = () => {
        return this.#lastResult
    }

    setResultStatus = (result: {
        data: any
        status: number
        message: string
    }) => {
        const result_dom = this.xmlToDom(result.data)
        if (
            result_dom.querySelector("OperationalResult Success")
                ?.textContent != "true"
        ) {
            result.status = 500
            result.message =
                result_dom.querySelector("OperationalResult ErrorCode")
                    ?.textContent +
                ": " +
                result_dom.querySelector("OperationalResult ErrorMessage")
                    ?.textContent
        }
        return result
    }

    xmlToDom = (xml: any) => {
        const parser = new DOMParser()
        const dom = parser.parseFromString(xml, "application/xml")
        return dom
    }

    domToXml = (dom: any) => {
        const serializer = new XMLSerializer()
        const xml = serializer.serializeToString(dom)
        return xml
    }

    getConfigurationInfo = async (configurationInfoType: number) => {
        const config_dom = this._getConfigDom() ?? null

        const employeeObjectNum = config_dom.querySelector(
            "employeeObjectNum"
        ) as HTMLElement
        employeeObjectNum.textContent = this.#employeeObjectNum.toString()

        const configurationInfoTypeInit = config_dom.querySelector(
            "configurationInfoType int"
        ) as HTMLElement
        configurationInfoTypeInit.textContent = configurationInfoType.toString()

        const revenueCenter = config_dom.querySelector(
            "revenueCenter"
        ) as HTMLElement
        revenueCenter.textContent = this.#revenueCenter.toString()

        this.#lastResult = await Micro.micros_fetch(
            "POST",
            Micro.baseurl,
            "GetConfigurationInfo",
            this.domToXml(config_dom)
        )
        this.setResultStatus(this.#lastResult)

        if (this.#lastResult.status == 200) {
            // we expect to have element with data always after ConfigInfoType element
            return this.xmlToDom(
                this.xmlToDom(this.#lastResult.data).querySelector(
                    "ConfigInfoType"
                )?.nextElementSibling?.textContent
            )
        }
    }

    getTenderMedia = async () => {
        const result = await this.getConfigurationInfo(6)
        return result // return dom! (not Promise)
    }

    postTransaction = async (xml: any) => {
        this.#lastResult = await Micro.micros_fetch(
            "POST",
            Micro.baseurl,
            "PostTransactionEx",
            xml
        )
        this.setResultStatus(this.#lastResult)
        return this.#lastResult
    }

    pushOrder = async (
        order: {
            id: { toString: () => string | number }
            refnr: any
            nr: any
            source_info: any
            payment: string
            products: any
        },
        cb: (arg0: { status: string }) => any
    ) => {
        console.log("micros.pushOrder(): id ", order.id)

        if (this.microsQueueSeen[order.id.toString()]) {
            console.log("micros.pushOrder(): already processed this session")
            const result = { status: "200" } // return succesful return value
            cb && cb(result)
            return result
        }
        this.microsQueueSeen[order.id.toString()] = 1 // mark busy/done

        const order_dom = this._getOrderDom()

        //order_dom.querySelector('CheckTableObjectNum').textContent = order.source_info;
        const checkId = order_dom.querySelector("CheckID") as HTMLElement
        checkId.textContent = order.refnr || order.nr || order.id

        const checkEmployeeObjectNum = order_dom.querySelector(
            "CheckEmployeeObjectNum"
        ) as HTMLElement
        checkEmployeeObjectNum.textContent =
            order.source_info || this.#employeeObjectNum

        const checkRevenueCenterID = order_dom.querySelector(
            "CheckRevenueCenterID"
        ) as HTMLElement
        checkRevenueCenterID.textContent = this.#revenueCenter.toString()

        const tmedObjectNum = order_dom.querySelector(
            "TmedObjectNum"
        ) as HTMLElement
        tmedObjectNum.textContent =
            order.payment == "cash"
                ? this.#cash_payment_id.toString()
                : this.#pin_payment_id.toString()

        const order_line_dom = order_dom
            .querySelector("SimphonyPosApi_MenuItem")
            ?.cloneNode(true) as HTMLElement
        const order_extra_dom = order_line_dom
            .querySelector("SimphonyPosApi_MenuItemDefinition")
            ?.cloneNode(true)

        const condiments = order_line_dom.querySelector(
            "Condiments"
        ) as HTMLElement
        condiments.textContent = ""

        order_dom.querySelector("SimphonyPosApi_MenuItem")?.remove()

        order.products.forEach((i: any, product: any) => {
            if (product.external_id != "-1") {
                const line_dom = order_line_dom.cloneNode(true) as HTMLElement

                const miObjectNum = line_dom.querySelector(
                    "MiObjectNum"
                ) as HTMLElement
                miObjectNum.textContent = product.external_id

                if (product.external_opt == "~container") {
                    // ~container = box, set total price box (extras can not have price)
                    const miOverridePrice = line_dom.querySelector(
                        "MiOverridePrice"
                    ) as HTMLElement
                    miOverridePrice.textContent = product.price
                        .toString()
                        .replace(/\./, ",")
                } else {
                    const miOverridePrice = line_dom.querySelector(
                        "MiOverridePrice"
                    ) as HTMLElement
                    miOverridePrice.textContent = product.prod_price
                        .toString()
                        .replace(/\./, ",")
                }
                const miReference = line_dom.querySelector(
                    "MiReference"
                ) as HTMLElement
                miReference.textContent = `<ExtraData><MiQuantity>${product.n}</MiQuantity></ExtraData>`
                if (product.external_opt == "~ar") {
                    // ~ar = add remarks
                    const miReference = line_dom.querySelector(
                        "MiReference"
                    ) as HTMLElement
                    miReference.textContent += product.title
                }

                if (product.external_opt == "~container") {
                    // ~container = box, add extras/products as remarks
                    const miReference = line_dom.querySelector(
                        "MiReference"
                    ) as HTMLElement
                    miReference.textContent += product.extras_array
                        .map((extra: { title: string; n: number }) => {
                            return (
                                extra.title +
                                (extra.n > 1 ? " " + extra.n + "x" : "")
                            )
                        })
                        .join(", ")
                } else {
                    product.extras_array.forEach((ii: any, extra: any) => {
                        if (extra.external_id != "-1") {
                            if (/~(\d+)/.test(extra.external_id)) {
                                // size/id modifier

                                //TODO: REGEX
                                const offset = parseInt(RegExp.$1) - 1
                                if (/,/.test(product.external_id)) {
                                    const list = product.external_id.split(",")
                                    const miObjectNum = line_dom.querySelector(
                                        "MiObjectNum"
                                    ) as HTMLElement
                                    miObjectNum.textContent =
                                        list[offset] || list[0]
                                } else {
                                    // product does not have multiple ids... try extras
                                    const menuItemArray =
                                        line_dom.querySelectorAll(
                                            "SimphonyPosApi_MenuItemDefinition"
                                        )
                                    menuItemArray.forEach((i: any, e: any) => {
                                        const num =
                                            e.querySelector(
                                                "MiObjectNum"
                                            ).textContent
                                        if (/,/.test(num)) {
                                            const list = num.split(",")
                                            e.querySelector(
                                                "MiObjectNum"
                                            ).textContent =
                                                list[offset] || list[0]
                                        }
                                    })
                                }
                            } else {
                                const extra_dom: any =
                                    order_extra_dom?.cloneNode(true)
                                extra_dom.querySelector(
                                    "MiObjectNum"
                                ).textContent = extra.external_id
                                extra_dom.querySelector(
                                    "MiOverridePrice"
                                ).textContent = extra.price
                                    .toString()
                                    .replace(/\./, ",")
                                extra_dom.querySelector(
                                    "MiReference"
                                ).textContent = `<ExtraData><MiQuantity>${extra.n}</MiQuantity></ExtraData>`
                                if (extra.external_opt == "~ar") {
                                    // ~ar = add remarks
                                    extra_dom.querySelector(
                                        "MiReference"
                                    ).textContent += extra.title
                                }
                                line_dom
                                    .querySelector("Condiments")
                                    ?.appendChild(extra_dom)
                            }
                        }
                    })
                }

                const ppMenuItems = order_dom.querySelector(
                    "ppMenuItems"
                ) as HTMLElement
                ppMenuItems.appendChild(line_dom)
            }
        })

        const result = await this.postTransaction(this.domToXml(order_dom))

        if (result && /^2/.test(result.status)) {
            //microsQueueSeen[order.id.toString()] = 1;
        } else {
            delete this.microsQueueSeen[order.id.toString()] // unmark busy/done
        }

        cb && cb(result)
        return result
    }

    microsPushOrder = (order: any, cb: any) => {
        const microsQueue = new Queue()
        const _order = order // closure
        const _cb = cb
        microsQueue.addTask(() => {
            new Micro().pushOrder(_order, (result: any) => {
                _cb && _cb(result)
                microsQueue.next()
            })
            return false // return false since cb will call next
        })
    }

    private _getConfigDom = () => {
        return this.xmlToDom(`<?xml version="1.0" encoding="utf-8"?>
			<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">
			<soap:Body>
				<GetConfigurationInfo xmlns="http://micros-hosting.com/EGateway/">
					<vendorCode></vendorCode>
					<employeeObjectNum>4</employeeObjectNum>
					<configurationInfoType>
						<int>6</int>
					</configurationInfoType>
					<revenueCenter>11</revenueCenter>
				</GetConfigurationInfo>
			</soap:Body>
			</soap:Envelope>`)
    }

    private _getOrderDom = () => {
        return this.xmlToDom(`<?xml version="1.0" encoding="utf-8"?>
<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">
  <soap:Body>
    <PostTransactionEx xmlns="http://micros-hosting.com/EGateway/">
      <vendorCode />
      <pGuestCheck>
        <CheckDateToFire>2022-04-14T14:56:21</CheckDateToFire>
        <CheckEmployeeObjectNum>2308</CheckEmployeeObjectNum>
        <CheckGuestCount>0</CheckGuestCount>
        <CheckID />
        <CheckNum>0</CheckNum>
        <CheckOrderType>1</CheckOrderType>
        <CheckRevenueCenterID>1</CheckRevenueCenterID>
        <CheckSeq>0</CheckSeq>
        <CheckStatusBits>0</CheckStatusBits>
        <CheckTableObjectNum></CheckTableObjectNum>
        <PCheckInfoLines>
          <string />
          <string />
        </PCheckInfoLines>
        <EventObjectNum>0</EventObjectNum>
      </pGuestCheck>
      <ppMenuItems>
        <SimphonyPosApi_MenuItem>
          <Condiments>
            <SimphonyPosApi_MenuItemDefinition>
              <ItemDiscount>
                <DiscObjectNum>0</DiscObjectNum>
              </ItemDiscount>
              <MiObjectNum>300109010</MiObjectNum>
              <MiOverridePrice />
              <MiReference />
              <MiWeight />
              <MiMenuLevel>1</MiMenuLevel>
              <MiSubLevel>1</MiSubLevel>
              <MiPriveLevel>0</MiPriveLevel>
            </SimphonyPosApi_MenuItemDefinition>
          </Condiments>
          <MenuItem>
            <ItemDiscount>
              <DiscObjectNum>0</DiscObjectNum>
            </ItemDiscount>
            <MiObjectNum>300101502</MiObjectNum>
            <MiOverridePrice />
            <MiReference />
            <MiWeight />
            <MiMenuLevel>1</MiMenuLevel>
            <MiSubLevel>1</MiSubLevel>
            <MiPriveLevel>0</MiPriveLevel>
          </MenuItem>
        </SimphonyPosApi_MenuItem>
      </ppMenuItems>
      <ppComboMeals />
      <pServiceChg>
        <SvcChgObjectNum>0</SvcChgObjectNum>
      </pServiceChg>
      <pSubTotalDiscount>
        <DiscObjectNum>0</DiscObjectNum>
      </pSubTotalDiscount>
      <pTmedDetail>
        <TmedEPayment>
          <AccountDataSource>SOURCE_UNDEFINED</AccountDataSource>
          <AccountType>ACCOUNT_TYPE_UNDEFINED</AccountType>
          <ExpirationDate>0001-01-01T00:00:00</ExpirationDate>
          <IssueNumber>0</IssueNumber>
          <PaymentCommand>NO_E_PAYMENT</PaymentCommand>
          <StartDate>0001-01-01T00:00:00</StartDate>
        </TmedEPayment>
        <TmedObjectNum>100</TmedObjectNum>
        <TmedPartialPayment />
        <TmedReference />
      </pTmedDetail>
      <pTotalsResponse>
        <OperationalResult>
          <Success>true</Success>
          <ErrorCode>Success</ErrorCode>
          <ErrorMessage>Success</ErrorMessage>
        </OperationalResult>
        <TotalsAutoSvcChgTotals>0</TotalsAutoSvcChgTotals>
        <TotalsOtherTotals>0</TotalsOtherTotals>
        <TotalsSubTotal>5,2900</TotalsSubTotal>
        <TotalsTaxTotals>0</TotalsTaxTotals>
        <TotalsTotalDue>5,2900</TotalsTotalDue>
      </pTotalsResponse>
      <ppCheckPrintLines>
        <string />
      </ppCheckPrintLines>
      <ppVoucherOutput>
        <string />
      </ppVoucherOutput>
    </PostTransactionEx>
  </soap:Body>
</soap:Envelope>`)
    }
}
