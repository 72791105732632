import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "image-wrap" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "file-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["image-component", { selected: _ctx.selected }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select')))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", { src: _ctx.src }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.fileName), 1)
  ], 2))
}