import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d0cf2462"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-options" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (o) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["option", { selected: o.value === _ctx.value, disabled: _ctx.disabled }]),
        key: o.value,
        onClick: ($event: any) => (_ctx.$emit('update:value', _ctx.updateValue(o)))
      }, [
        (o.icon)
          ? (_openBlock(), _createBlock(_component_IconBase, {
              key: 0,
              icon: o.icon
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(o.label), 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}