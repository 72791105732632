import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrdersMap = _resolveComponent("OrdersMap")!
  const _component_MapSideBar = _resolveComponent("MapSideBar")!
  const _component_ModalWindow = _resolveComponent("ModalWindow")!

  return (_openBlock(), _createBlock(_component_ModalWindow, {
    show: _ctx.show,
    name: "map-modal",
    class: "route-map-modal"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_OrdersMap, {
        deliverers: _ctx.deliverers,
        "orders-coordinates": _ctx.ordersCoordinates,
        ordersGeoJSON: _ctx.ordersGeoJSON,
        onHover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hoveredInMap = $event)),
        highlight: _ctx.hoveredInSidebar
      }, null, 8, ["deliverers", "orders-coordinates", "ordersGeoJSON", "highlight"]),
      _createVNode(_component_MapSideBar, {
        deliverers: _ctx.deliverers,
        ready: _ctx.ordersStore.dataReady,
        statusFilter: _ctx.orderModalStore.MapModalFilters,
        "onUpdate:statusFilter": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.orderModalStore.MapModalFilters) = $event)),
        orders: _ctx.filteredOrders,
        onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close'))),
        onHover: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hoveredInSidebar = $event)),
        highlight: _ctx.hoveredInMap
      }, null, 8, ["deliverers", "ready", "statusFilter", "orders", "highlight"])
    ]),
    _: 1
  }, 8, ["show"]))
}