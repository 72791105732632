import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "order-table" }
const _hoisted_2 = { class: "row header" }
const _hoisted_3 = { class: "cell quantity text-right" }
const _hoisted_4 = { class: "cell product" }
const _hoisted_5 = {
  key: 0,
  class: "cell extras"
}
const _hoisted_6 = { class: "cell remarks" }
const _hoisted_7 = { class: "cell total text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductRow = _resolveComponent("ProductRow")!

  return (_openBlock(), _createElementBlock("div", {
    class: "tab-content mr-3",
    ref: _ctx.scrollAreaRef()
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("quantity")), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.translate("product")), 1),
        (!Number(_ctx.settings.orderpopup_extras_array))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.translate("extras")), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.translate("remarks")), 1),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.translate("total_price")), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order.products, (product) => {
        return (_openBlock(), _createBlock(_component_ProductRow, {
          key: product.id,
          product: product
        }, null, 8, ["product"]))
      }), 128))
    ])
  ], 512))
}