import { RouteRecordRaw } from "vue-router"
import {
    salesName,
    salesStructure,
} from "@/pageStructure/SalesStatisticsStructure"

export const SalesRoutes: RouteRecordRaw = {
    path: "/sales-statistics",
    meta: { name: salesName },
    component: () =>
        import(
            /* webpackPrefetch: true */ "@/pages/sales-statistics/SalesStatisticsLayout.vue"
        ),
    children: [
        {
            path: "",
            name: salesName,
            redirect: { name: salesStructure.orders.name },
        },
        {
            path: salesStructure.orders.path,
            meta: { subName: salesStructure.orders.name },
            component: () =>
                import("@/pages/sales-statistics/turnover/TurnoverLayout.vue"),
            children: [
                {
                    path: "",
                    name: salesStructure.orders.name,
                    redirect: {
                        name: salesStructure.orders.subMenu.conversion.name,
                    },
                },
                {
                    path: salesStructure.orders.subMenu.conversion.path,
                    name: salesStructure.orders.subMenu.conversion.name,
                    meta: {
                        subSubName:
                            salesStructure.orders.subMenu.conversion.name,
                    },
                    component: () =>
                        import(
                            "@/pages/sales-statistics/order-graphs/Conversion.vue"
                        ),
                },
            ],
        },
        {
            path: salesStructure.areas.path,
            name: salesStructure.areas.name,
            meta: { subName: salesStructure.areas.name },
            component: () =>
                import(
                    "@/pages/sales-statistics/delivery-areas/DeliveryAreas.vue"
                ),
        },
        {
            path: salesStructure.turnover.path,
            meta: { subName: salesStructure.turnover.name },
            component: () =>
                import("@/pages/sales-statistics/turnover/TurnoverLayout.vue"),
            children: [
                {
                    path: "",
                    name: salesStructure.turnover.name,
                    redirect: {
                        name: salesStructure.turnover.subMenu.hourly.name,
                    },
                },
                {
                    path: salesStructure.turnover.subMenu.hourly.path,
                    name: salesStructure.turnover.subMenu.hourly.name,
                    meta: {
                        subSubName: salesStructure.turnover.subMenu.hourly.name,
                    },
                    component: () =>
                        import(
                            "@/pages/sales-statistics/turnover/hourly/components/TurnoverHourly.vue"
                        ),
                },
            ],
        },
        {
            path: salesStructure.products.path,
            name: salesStructure.products.name,
            meta: { subName: salesStructure.products.name },
            component: () =>
                import("@/pages/sales-statistics/products/ProductsIndex.vue"),
        },
    ],
}
