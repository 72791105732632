import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7075014c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_ButtonBase = _resolveComponent("ButtonBase")!
  const _component_PopUpModal = _resolveComponent("PopUpModal")!

  return (_openBlock(), _createBlock(_component_PopUpModal, {
    show: _ctx.show,
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('cancel'))),
    onSave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('confirm')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_IconBase, {
          icon: "warning",
          class: "icon"
        }),
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.translate(_ctx.title)), 1),
        _createElementVNode("span", {
          innerHTML: _ctx.translate(_ctx.description),
          class: _normalizeClass(_ctx.descriptionClass)
        }, null, 10, _hoisted_3)
      ])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_ButtonBase, {
        variant: "text",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate("cancel")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ButtonBase, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('confirm'))),
        type: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate(_ctx.action)), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}