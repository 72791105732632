import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "details" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  class: "reference-number"
}
const _hoisted_4 = { class: "order-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeliveryDetails = _resolveComponent("DeliveryDetails")!
  const _component_OrderPrice = _resolveComponent("OrderPrice")!
  const _component_ButtonBase = _resolveComponent("ButtonBase")!

  return (_ctx.showButtons)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["kitchen-details", { 'divider-margin': _ctx.showRemarks || _ctx.showDeliverer }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1),
          _createVNode(_component_DeliveryDetails, { "delivery-type": _ctx.deliveryType }, null, 8, ["delivery-type"])
        ]),
        (_ctx.showRefNr && _ctx.order.refnr)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.order.refnr), 1))
          : _createCommentVNode("", true),
        _createVNode(_component_OrderPrice, { order: _ctx.order }, null, 8, ["order"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ButtonBase, {
            icon: "receipt",
            class: "order-receipt",
            onMousedown: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.clickHandler('enroute')), ["stop"])),
            type: 
                    _ctx.order.status === 'enroute' || _ctx.order.status === 'delivered'
                        ? 'primary'
                        : 'regular'
                
          }, null, 8, ["type"]),
          _createVNode(_component_ButtonBase, {
            icon: "check",
            class: "order-check",
            onMousedown: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.clickHandler('delivered')), ["stop"])),
            type: _ctx.order.status === 'delivered' ? 'primary' : 'regular'
          }, null, 8, ["type"])
        ])
      ], 2))
    : _createCommentVNode("", true)
}