import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-371e7f5f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message-date" }
const _hoisted_2 = { class: "dot" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "date" }
const _hoisted_5 = { class: "day" }
const _hoisted_6 = { class: "month" }
const _hoisted_7 = { class: "message-subject" }
const _hoisted_8 = { class: "subject" }
const _hoisted_9 = { class: "msgbox-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["message-item", { selected: _ctx.selected, 'is-new': _ctx.isNew }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.selected)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.date[0]), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.date[1]), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.message.subject), 1),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.stripHtmlTags(_ctx.message.message)), 1)
    ])
  ], 2))
}