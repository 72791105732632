<template>
    <CheckBox
        :label="translate('time')"
        v-model:checked="store.currentCart.cart.schedule.asap"
        :description="checkBoxDescription"
        :disabled="asapDisabled"
    ></CheckBox>
    <SelectComponent
        v-if="!store.currentCart.cart.schedule.asap"
        :value="store.currentCart.cart.schedule.time || null"
        @update:value="onTimeUpdate"
        :disabled="disabled"
        :options="timeIntervals"
        name="time"
    />
</template>

<script lang="ts">
import { computed, defineComponent, Ref, ref, watch } from "vue"
import { useI18n } from "vue-i18n"
import { useCartStore } from "@/store/cart/Cart"
import { DispatchType } from "@/interface/Cart"
import CheckBox from "@/ui-elements/input/check-box/CheckBox.vue"
import SelectComponent, {
    SelectOption,
} from "@/ui-elements/input/select/SelectComponent.vue"
import { useTimeIntervals } from "@/pages/pos/modal/customer/data/useTimeIntervals"
import { dateAfterToday } from "../data/useUtils"
import { fromUTC, toUTC } from "@/utils/useTimeShift"
import { AdditionalDeliveryCosts } from "@/pages/pos/modal/customer/data/customTimeSlots"
export default defineComponent({
    name: "TimeField",
    components: { CheckBox, SelectComponent },
    props: {
        isLoadedFromOrder: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        const { t: translate } = useI18n()
        const store = useCartStore()
        const disabled = computed(
            () => store.currentCart.cart.type === DispatchType.None
        )
        const timeIntervals: Ref<SelectOption[]> = ref([])
        const additionalDeliveryCosts: Ref<AdditionalDeliveryCosts> = ref({})

        const { getTimeIntervals } = useTimeIntervals()

        watch(
            [
                () => store.currentCart.cart.schedule.date,
                () => store.currentCart.cart.type,
            ],
            () => {
                store.currentCart.cart.schedule.time = ""
                store.currentCart.cart.additional_delivery_cost = 0
            }
        )

        const selectedDate = computed(() => {
            return store.currentCart.cart.schedule.date || null
        })

        const cartDispatchType = computed(() => {
            return store.currentCart.cart.type
        })

        watch(
            [selectedDate, cartDispatchType],
            () => {
                const { timeSlots, additionalCosts } = getTimeIntervals(
                    store.currentCart.cart.type,
                    selectedDate.value,
                    true
                )
                timeIntervals.value = timeSlots
                additionalDeliveryCosts.value = additionalCosts
            },
            { immediate: true }
        )

        const onTimeUpdate = (time: string) => {
            store.currentCart.cart.schedule.time = time
            store.currentCart.cart.additional_delivery_cost =
                additionalDeliveryCosts.value[time] || 0
        }

        // If it's an existing order and a time was set earlier we don't show the asap option
        const asapDisabled = computed(
            () =>
                dateAfterToday(store.currentCart.cart.schedule.date) ||
                (store.currentCart.is_loaded_from_order &&
                    store.currentCart.cart.schedule.time)
        )
        watch(asapDisabled, () => {
            if (asapDisabled.value) {
                store.currentCart.cart.schedule.asap = false
            }
        })

        const checkBoxDescription = computed(() => {
            return translate("direct")
        })

        return {
            translate,
            disabled,
            store,
            timeIntervals,
            asapDisabled,
            fromUTC,
            toUTC,
            checkBoxDescription,
            onTimeUpdate,
        }
    },
})
</script>
