<template>
    <ButtonBase
        @click="minimize"
        variant="outlined"
        icon="minus"
        class="minimize"
    />
    <ButtonBase
        @click="maximize"
        variant="outlined"
        icon="arrow-expand"
        class="maximize"
    />
    <ButtonBase
        @click="onClick"
        :disabled="isOffline || splitPaymentStore.isPartiallyPaid"
        icon="delete"
        class="close"
    />
    <CloseConfirmationModal
        v-if="openModal"
        @close="openModal = false"
        @confirm="close"
    />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue"
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue"
import CloseConfirmationModal from "@/ui-elements/page-header/CloseConfirmationModal.vue"
import { useSplitPaymentStore } from "@/store/SplitPayment"
import { RiceCooker } from "@/riceCooker/riceCooker"
import { offlineModeStore } from "@/store/offlineMode"
export default defineComponent({
    name: "ControlButtons",
    components: { ButtonBase, CloseConfirmationModal },
    setup() {
        const minimize = () => window.riceCooker.minWindow()
        const maximize = () => window.riceCooker.fullWindow()
        const close = () => window.riceCooker.closeWindow()
        const openModal = ref(false)
        const splitPaymentStore = useSplitPaymentStore()
        const onClick = () => (openModal.value = true)

        return {
            isOffline: offlineModeStore().isOffline,
            minimize,
            maximize,
            close,
            openModal,
            onClick,
            splitPaymentStore,
        }
    },
})
</script>
<style lang="scss" scoped>
.button {
    height: 2rem;
    padding: 0 $padding-s;
    margin-right: $margin-m;
    -webkit-app-region: no-drag;
    &.close {
        margin: 0;
    }

    &.minimize {
        margin-left: calc(2 * #{$margin-l});
        position: relative;
        &::after {
            position: absolute;
            content: "";
            background: $light-gray;
            width: 1px;
            height: 140%;
            top: 50%;
            left: -$margin-l;
            transform: translateY(-50%);
        }
    }
}
</style>
