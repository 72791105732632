import { MenuStructure } from "@/interface/MenuStructure"
import axios from "axios"

export const generateToken = async (): Promise<String> => {
    return (await axios.post("/client/createSsoToken")).data.data.token
}

export const openPromotionMaterialShop = async () => {
    const token = await generateToken()
    window.open(
        `https://www.orderbuddy.nl/foodticket/cgi/admin.cgi?action=shop-promo&v3_client_sso_token=${token}`,
        "_blank"
    )
}

const openPromotionMaterialShopOrderOverview = async () => {
    const token = await generateToken()
    window.open(
        `https://www.orderbuddy.nl/foodticket/cgi/admin.cgi?action=shop-promo-overview&v3_client_sso_token=${token}`,
        "_blank"
    )
}

export const shopName = "Marketing"
export const shopMenu: MenuStructure = {
    promotionMaterials: {
        text: "promotion_materials_title",
        name: "promotional-materials",
        path: "promotional-materials",
        subMenu: {},
    },
    promotionMaterialsOrders: {
        text: "promotion_materials_orders_title",
        name: "orders", //dirty fix
        path: "orders", //dirty fix
        subMenu: {},
        callback: openPromotionMaterialShopOrderOverview,
    },
    prizeWheel: {
        text: "prize_wheel_auto",
        name: "prizeWheel",
        path: "prizeWheel",
        subMenu: {},
    },
    resources: {
        text: "resources",
        name: "resources",
        path: "resources",
        subMenu: {},
    },
    email: {
        text: "e_mail",
        name: "email",
        path: "email",
        subMenu: {},
    },
    sms: {
        text: "sms",
        name: "sms",
        path: "sms",
        subMenu: {},
    },
    push: {
        text: "push",
        name: "push",
        path: "push",
        subMenu: {},
    },
}
