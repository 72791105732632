import { MenuStructure } from "@/interface/MenuStructure"

export const customersName = "customers"
export const customersMenu: MenuStructure = {
    customers: {
        text: "customers",
        name: "index",
        path: "index",
        subMenu: {},
    },
    invoices: {
        text: "invoices",
        name: "invoices",
        path: "invoices",
        subMenu: {},
    },
    reviews: {
        text: "reviews",
        name: "reviews",
        path: "reviews",
        subMenu: {},
    },
}
