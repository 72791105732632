import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DotsLoader = _resolveComponent("DotsLoader")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["loading", { cover: _ctx.cover }])
  }, [
    _createVNode(_component_DotsLoader, { variant: "primary" })
  ], 2))
}