<template>
    <InputField
        class="option-list-wrapper"
        :name="name"
        :label-icon="labelIcon"
        :label-icon-class="labelIconClass"
        :label="label"
        :class="{ disabled }"
    >
        <template v-slot:input>
            <div class="option-list" :class="contentClass">
                <div
                    class="option"
                    v-for="option in options"
                    :key="option.value"
                    :class="{ selected: isChecked(option.value) }"
                >
                    <component
                        :disabled="disabled"
                        :is="component"
                        :checked="isChecked(option.value)"
                        @update:checked="onSelect(option.value, $event)"
                        :text="
                            type === 'radio' && option.label ? option.label : ''
                        "
                        :description="
                            type === 'check' && option.label ? option.label : ''
                        "
                    ></component>
                    <!--                    todo: transfer label to switch like it's done for radio and checkbox-->
                    <span class="text" v-if="type === 'switch'">{{
                        option.label
                    }}</span>
                </div>
            </div>
        </template>
    </InputField>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue"
import { CheckBox, CheckSwitch, CheckRadio } from "@/ui-elements"
import { OptionType } from "@/interface/components/OptionList"
import InputField from "@/ui-elements/input/InputField.vue"

export type OptionListOption = {
    value: string
    label: string
}

export default defineComponent({
    name: "OptionList",
    components: { CheckBox, CheckSwitch, CheckRadio, InputField },
    props: {
        options: {
            type: Array as PropType<OptionListOption[]>,
            required: true,
        },
        type: {
            type: String as PropType<OptionType>,
            default: "check",
        },
        name: {
            type: String,
            default: "",
        },
        label: {
            type: String,
        },
        labelIcon: {
            type: String,
        },
        labelIconClass: {
            type: String,
        },
        value: {
            type: [Array, String, Number] as PropType<
                number | number[] | string | string[] | null | undefined
            >,
        },
        contentClass: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, context) {
        const components = {
            check: "CheckBox",
            switch: "CheckSwitch",
            radio: "CheckRadio",
        } as Record<OptionType, string>

        const component = computed(() => components[props.type])

        const updateOne = (optionKey: string | number, selected: boolean) => {
            const oldValue =
                props.value && typeof props.value === "object"
                    ? props.value
                    : []
            if (selected) {
                return [...oldValue, optionKey]
            } else {
                return (oldValue as Array<string | number>).filter(
                    (value: string | number) => value !== optionKey
                )
            }
        }

        const onlyOne = (optionValue: string) => {
            return optionValue
        }

        const functions = {
            check: () => updateOne,
            switch: () => updateOne,
            radio: () => onlyOne,
        } as Record<OptionType, Function>

        const dynamicUpdate = computed(() => functions[props.type])

        const onSelect = (optionKey: string, value: boolean) => {
            context.emit(
                "update:value",
                dynamicUpdate.value()(optionKey, value)
            )
        }

        const isChecked = (optionValue: string | number) => {
            if (props.value === null || props.value === undefined) {
                return false
            }
            if (props.type === "radio") {
                return props.value === optionValue
            }
            return (props.value as Array<string | number>).includes(optionValue)
        }

        return {
            component,
            onSelect,
            isChecked,
        }
    },
})
</script>

<style lang="scss">
.option-list-wrapper {
    .border {
        position: relative;
        border-width: 0 !important;
        background: unset;
        height: unset;
    }

    .option-list {
        height: 100%;
        display: grid;

        .option {
            display: flex;
            user-select: none;
            height: 2.75rem;
            align-items: center;

            .text {
                margin-left: $margin;
            }
        }
    }
    &.disabled {
        .option .text {
            color: $dark-gray;
            opacity: 0.9;
        }
    }
}
</style>
