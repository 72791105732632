import axios from "axios"

const SUDO_API_URL = "token/sudo"

export const disableSudoMode = async () => {
    return await axios.delete(SUDO_API_URL)
}

export const enableSudoMode = async (
    password: string,
    validateOnly: boolean = false
) => {
    return await axios.post(SUDO_API_URL, {
        password,
        validate_only: validateOnly,
    })
}
