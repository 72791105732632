import { useSettingsStore } from "@/store/Settings"
import { computed, Ref } from "vue"
import type { Order } from "@/interface/orders/Order"
import { useRouter } from "vue-router"
import { useAPIStore } from "@/store/API"
import { useUserStore } from "@/store/User"
import { RiceCooker } from "@/riceCooker/riceCooker"
import { offlineModeStore } from "@/store/offlineMode"

export const usePosOpenButton = (order: Ref<Order | undefined>) => {
    const settingsStore = useSettingsStore()
    const userStore = useUserStore()
    const paymentMethods = ["cash", "account", "pin"]

    const showPosOpenButton = computed(() => {
        // If a user is read only, POS is not available.
        if (userStore.user.is_read_only) {
            return false
        }

        if (!order.value) {
            return false
        }

        if (RiceCooker.isPresent() && offlineModeStore().isOffline) {
            return false
        }

        const acceptablePaymentMethod = paymentMethods.includes(
            order.value.payment.method
        )

        const notPaidWithAdyen = !/Adyen/.test(order.value?.payment.result)

        return (
            Number(settingsStore.settings.pos_active) &&
            acceptablePaymentMethod &&
            !order.value.is_pin_final &&
            notPaidWithAdyen
        )
    })

    const router = useRouter()
    const routeToOrder = () =>
        router.push({ path: "/pos/order/" + order.value!.id })
    const goToOrder = () => {
        if (!order) {
            return
        }

        if (!Number(settingsStore.settings.pin_req_changeorder)) {
            routeToOrder()
            return
        }
        const apiStore = useAPIStore()
        apiStore
            .setRequirePincode(true, true)
            .then(() => {
                routeToOrder()
            })
            .catch(() => {})
    }

    return {
        showPosOpenButton,
        goToOrder,
    }
}
