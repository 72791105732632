<template>
    <component
        :is="url && isExternalUrl ? 'a' : 'button'"
        :href="url ? url : undefined"
        class="button"
        :target="inNewTab ? '_blank' : ''"
        :class="{
            disabled: disabled || loading,
            [variant]: variant,
            [type]: type,
            'icon-content': icon && !slotHasContent,
        }"
        @mousedown="$emit('mousedown', $event)"
        @mouseup="$emit('mouseup', $event)"
        @click.stop="onClick"
    >
        <DotsLoader v-if="loading"></DotsLoader>
        <IconBase
            v-else-if="icon !== ''"
            :icon="icon"
            :class="iconClass"
        ></IconBase>
        <span
            class="button-text"
            :class="icon !== '' || loading ? 'button-spacing' : ''"
            v-if="slotHasContent"
        >
            <slot></slot>
        </span>
    </component>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue"
import IconBase from "@/ui-elements/IconBase.vue"
import { useRouter } from "vue-router"
import DotsLoader from "@/ui-elements/loaders/DotsLoader.vue"

export const buttonVariants = ["text", "outlined", "dashed", "filled"] as const
export const buttonTypes = [
    "primary",
    "primary-gradient",
    "regular",
    "secondary",
    "success",
    "warning",
] as const

type ButtonVariant = typeof buttonVariants[number]
type ButtonType = typeof buttonTypes[number]

export default defineComponent({
    name: "ButtonBase",
    components: {
        IconBase,
        DotsLoader,
    },
    props: {
        icon: {
            type: String,
            default: "",
        },
        iconClass: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String as PropType<ButtonVariant>,
            default: "filled",
        },
        type: {
            type: String as PropType<ButtonType>,
            default: "regular",
        },
        url: {
            type: String,
            default: "",
        },
        loading: Boolean,
        isExternalUrl: {
            type: Boolean,
            default: false,
        },
        inNewTab: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, context) {
        const slot = context.slots.default
        const slotHasContent = computed(() => !!slot)
        const router = useRouter()

        const onClick = (e: any) => {
            if (props.disabled) {
                return
            }
            if (props.isExternalUrl) {
                // Making sure that the current window is not also changing page.
                // When opening a external url
                e.preventDefault()
            }
            if (props.url) {
                openLink()
            }
            context.emit("click", e)
        }

        const openLink = () => {
            if (props.isExternalUrl) {
                // Window open will work on electron by opening a popup.
                // In the browser it wil function as a target blank.
                window.open(props.url)
            } else {
                router.push(props.url)
            }
        }

        return {
            slotHasContent,
            onClick,
        }
    },
    emits: ["click", "mousedown", "mouseup"],
})
</script>

<style lang="scss">
.button {
    display: flex;
    height: 2.75rem;
    border-radius: $radius;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: $padding $padding-l;
    width: max-content;
    user-select: none;

    .button-text {
        color: inherit;
        font-weight: $font-weight-medium;
    }

    .button-spacing {
        padding-left: $padding-s;
    }

    &.filled {
        span {
            color: $white;
        }

        &.primary {
            background: $primary-color;
        }

        &.warning {
            background: $warning-color;
        }

        &.primary-gradient {
            background: $primary-gradient;
        }

        &.regular {
            background: $secondary-color;
        }

        &.secondary {
            background: $light-gray;

            span {
                color: $secondary-color;
            }
        }

        &.success {
            background: $success-color;
        }

        &.disabled {
            background: $light-gray;
        }
    }

    &.outlined {
        &.primary {
            background: $lighter-gray;
            border: $thick-primary-border;

            span {
                color: $primary-color;
            }
        }

        &.regular {
            background: $white;
            border: $button-border;

            span {
                color: $secondary-color;
            }
        }

        &.disabled {
            background: $white !important;
            border-color: $dark-gray;
        }
    }

    &.text {
        padding: 0 $padding-m;

        &.primary span {
            color: $error-color;
        }

        &.regular span {
            color: $secondary-color;
        }

        &.secondary span {
            color: $darker-gray;
        }
    }
    &.dashed {
        background: $lightest-gray;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: $radius;
            background-image: $svg-border;
        }
        &.primary span {
            color: $error-color;
        }

        &.regular span {
            color: $secondary-color;
        }
    }

    &.icon-content {
        padding: 0 $padding;
    }

    &.disabled {
        cursor: unset;
        pointer-events: none;

        span {
            color: $dark-gray !important;
        }
    }
    .dots-loader {
        .dot {
            background: $dark-gray;
        }
    }
}
</style>
