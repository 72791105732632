<template>
    <div class="tab-content remarks" :ref="scrollAreaRef()">
        <div class="field">{{ remarksContent }}</div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue"
import { useI18n } from "vue-i18n"
import { Order } from "@/interface/orders/Order"

export default defineComponent({
    name: "RemarksTab",
    props: {
        order: {
            type: Object as PropType<Order>,
            required: true,
        },
        scrollAreaRef: Function,
    },
    setup(props) {
        const { t: translate } = useI18n()

        const remarksContent = computed(() =>
            props.order.remarks
                ? props.order.remarks
                : translate("there_are_no_remarks")
        )

        return {
            remarksContent,
        }
    },
})
</script>

<style lang="scss" scoped>
.remarks {
    width: 100%;
    padding-right: $padding-m;
    height: 100%;

    .field {
        width: 100%;
        border: $important-border;
        border-radius: $radius-s;
        padding: $padding-m;
        height: 100%;
    }
}
</style>
