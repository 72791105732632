<template>
    <div class="loading" :class="{ cover }">
        <DotsLoader variant="primary" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import DotsLoader from "@/ui-elements/loaders/DotsLoader.vue"

export default defineComponent({
    name: "LoadingIndicator",
    components: { DotsLoader },
    props: {
        cover: {
            type: Boolean,
            default: false,
        },
    },
})
</script>

<style lang="scss" scoped>
.loading {
    height: 100%;
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
        animation: $rotation;
        font-size: 2rem;
    }

    &.cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        background: $transparent-white;
    }
}
</style>
