import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonBase = _resolveComponent("ButtonBase")!

  return (_ctx.buttonActive)
    ? (_openBlock(), _createBlock(_component_ButtonBase, {
        key: 0,
        variant: _ctx.brakeActive ? 'filled' : 'outlined',
        type: _ctx.brakeActive ? 'warning' : 'regular',
        name: "brake",
        onClick: _ctx.action,
        icon: "warning",
        class: _normalizeClass({ 'animate-pulse-object': _ctx.brakeActive }),
        "data-testid": "break-modal",
        disabled: _ctx.disabled
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate("brake")), 1)
        ]),
        _: 1
      }, 8, ["variant", "type", "onClick", "class", "disabled"]))
    : _createCommentVNode("", true)
}