import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "panel",
  ref: "scrollAreaRef"
}
const _hoisted_2 = { class: "panel-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingIndicator = _resolveComponent("LoadingIndicator")!
  const _component_PageFooter = _resolveComponent("PageFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "header"),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_LoadingIndicator, {
            key: 0,
            cover: true
          }))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.footer)
      ? (_openBlock(), _createBlock(_component_PageFooter, {
          key: 0,
          scrollArea: _ctx.scrollArea,
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:pagination', $event))),
          "show-pagination": _ctx.showPagination,
          "show-total-count": _ctx.showTotalCount,
          ready: _ctx.ready
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "footer")
          ]),
          _: 3
        }, 8, ["scrollArea", "pagination", "show-pagination", "show-total-count", "ready"]))
      : _createCommentVNode("", true)
  ], 512))
}