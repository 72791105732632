import { Order, OrderStatusType } from "@/interface/orders/Order"
import { DateTime } from "luxon"
import { useOrderWarningStore } from "@/store/OrderWarning"
import i18n from "@/i18n"
import { useSettingsStore } from "@/store/Settings"
import { printOrder } from "@/services/printer/PrinterService"

export const updateOrderStatus = async (
    status: OrderStatusType,
    order: Order,
    saveOrder: (newOrder: any, triggerLoading?: boolean) => Promise<boolean>
) => {
    const warningStore = useOrderWarningStore()
    const settingsStore = useSettingsStore()
    const { t: translate } = i18n.global

    if (Number(settingsStore.settings.order_del_man)) {
        if (
            order.delivery === "deliver" &&
            status === "enroute" &&
            !order.deliverer_id
        ) {
            warningStore.setWarning(
                translate("no_deliverer"),
                translate("need_to_set")
            )
            return
        }
    }

    showTooEarlyWarning(order)

    const result = await saveOrder({ status }, true)
    if (!result) {
        return
    }
    // Print, if setting enabled
    if (
        settingsStore.settings.print_auto !== "0" &&
        order.status === "init" &&
        status === "pending" &&
        settingsStore.settings.print_when === "init-pending"
    ) {
        await printOrder(order)
    }
}

const showTooEarlyWarning = (order: Order) => {
    const warningStore = useOrderWarningStore()
    const settingsStore = useSettingsStore()
    const { t: translate } = i18n.global

    if (
        order.status === "init" ||
        !order.time ||
        order.time === "asap" ||
        warningStore.earlyWarningDone
    ) {
        return
    }

    const requestedTime = order.time.replace(/-(.*)/, "")

    const minutesTo = DateTime.fromFormat(requestedTime, "HH:mm").diffNow(
        "minutes"
    ).minutes
    const minTime = parseInt(
        order.delivery === "deliver"
            ? settingsStore.settings.delivery_min_time
            : settingsStore.settings.pickup_min_time
    )

    if (minutesTo <= minTime) {
        // it's not too early to complete the order
        return
    }

    warningStore.setWarning(
        translate("too_early"),
        translate("has_to_be_delivered", {
            time: order.time,
        })
    )
    warningStore.earlyWarningDone = true
}
