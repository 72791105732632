import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex items-center switch-wrapper" }
const _hoisted_2 = { class: "mark un-checkmark" }
const _hoisted_3 = { class: "mark checkmark" }
const _hoisted_4 = {
  key: 2,
  class: "wrapper-description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_InputField = _resolveComponent("InputField")!

  return (_openBlock(), _createBlock(_component_InputField, {
    class: _normalizeClass(["switch-input-wrapper", { disabled: _ctx.disabled }]),
    error: _ctx.error,
    success: _ctx.success,
    label: _ctx.label,
    name: _ctx.name,
    "label-icon": _ctx.labelIcon,
    "label-icon-class": _ctx.labelIconClass
  }, {
    input: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.uncheckedLabel)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["wrapper-label", { gray: _ctx.checked }])
            }, _toDisplayString(_ctx.uncheckedLabel), 3))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["check-switch", { checked: _ctx.checked }]),
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('update:checked', !_ctx.checked)), ["stop"]))
        }, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.uncheckIcon)
              ? (_openBlock(), _createBlock(_component_IconBase, {
                  key: 0,
                  icon: _ctx.uncheckIcon
                }, null, 8, ["icon"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.checkIcon)
              ? (_openBlock(), _createBlock(_component_IconBase, {
                  key: 0,
                  icon: _ctx.checkIcon
                }, null, 8, ["icon"]))
              : _createCommentVNode("", true)
          ])
        ], 2),
        (_ctx.checkedLabel)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass(["wrapper-label", { gray: !_ctx.checked }])
            }, _toDisplayString(_ctx.checkedLabel), 3))
          : _createCommentVNode("", true),
        (_ctx.description)
          ? (_openBlock(), _createElementBlock("h5", _hoisted_4, _toDisplayString(_ctx.description), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["error", "success", "label", "name", "label-icon", "label-icon-class", "class"]))
}