<template>
    <SelectComponent
        class="w-full"
        name="lang"
        @update:value="updateLocale($event)"
        :value="locale"
        :options="localeOptions"
        :transparent="transparent"
        :can-search="false"
    >
        <template #icon>
            <IconBase :icon="icon"></IconBase>
        </template>
    </SelectComponent>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue"
import SelectComponent from "@/ui-elements/input/select/SelectComponent.vue"
import {
    updateLocaleSettings,
    useLocalLocales,
} from "@/pages/auth/useLocalLocales"
import IconBase from "@/ui-elements/IconBase.vue"
import { dataHydration } from "@/services/DataHydrationService"
import { usePosMenusStore } from "@/store/PosMenus"
export default defineComponent({
    name: "LanguagePicker",
    components: {
        IconBase,
        SelectComponent,
    },
    props: {
        icon: String,
        transparent: {
            type: Boolean,
            default: true,
        },
        hideSelected: Boolean,
    },
    setup() {
        const { getDefaultLocale, getLocalLocales } = useLocalLocales()

        const locale = ref(getDefaultLocale())
        const localeOptions = computed(getLocalLocales)
        const updateLocale = async (selectedLocale: string) => {
            locale.value = selectedLocale
            if (selectedLocale === "default") {
                selectedLocale = getDefaultLocale()
            }

            updateLocaleSettings(selectedLocale)

            if (dataHydration.isActive()) {
                const posMenuStore = usePosMenusStore()
                posMenuStore.isLoading = true
                await dataHydration
                    .hydrateModule("posMenu", true)
                    .finally(() => {
                        posMenuStore.isLoading = false
                    })
            }
        }

        return {
            updateLocale,
            localeOptions,
            locale,
        }
    },
})
</script>
<style lang="scss" scoped>
:deep(.selected) {
    width: unset;
    margin: 0 $margin-m;
    gap: $margin-s;
}
</style>
