import { RiceCooker } from "@/riceCooker/riceCooker"
const settingsStore = require("@/store/Settings").useSettingsStore

export default class MicroApi {
    static baseurl: string
    static host: string
    static port: number

    static setBaseUrls = (): void => {
        this.host = settingsStore.settings.micros_apiIpAddress ?? "localhost"
        this.port = settingsStore.settings.micros_apiIpPort ?? 8080

        this.baseurl =
            "http://" +
            this.host +
            ":" +
            this.port +
            "/EGateway/SimphonyPosAPIWeb.asmx"
    }

    static micros_fetch = async (
        method: string,
        url: any,
        soap_action: string,
        xml: any
    ) => {
        console.log("--------\n_fetch", method, url, xml)

        const options = {
            method: method,
            cache: "no-cache",
            headers: {
                //'Accept': '*/*',
                "Content-Type": "text/xml;charset=UTF-8",
                SOAPAction: "http://micros-hosting.com/EGateway/" + soap_action,
                "Content-Length": "",
            },
            body: {},
        }

        if (method == "POST" && xml) {
            options.body = xml
            options.headers["Content-Length"] = Object.keys(
                options.body
            ).length.toString()
        }

        console.log("_fetch url", url)
        console.log("_fetch options", options)

        if (RiceCooker.isPresent()) {
            const response = await RiceCooker.fetch(
                url,
                JSON.stringify(options)
            )
            console.log("_fetch", method, url, "response", response)
            return response
        } else {
            console.log("_fetch requires appApi!")
            return {}
        }
    }
}
