export const sum = (numbers: number[]): number =>
    numbers.reduce((a, b) => a + b, 0)

export const roundToNearestK = (
    n: number,
    k: number,
    digits: number
): number => {
    const precision = Math.pow(10, Math.trunc(digits))

    return (
        Math.round(Math.round(((n + Number.EPSILON) * precision) / k) * k) /
        precision
    )
}

export const cents = (value: number): number => roundToNearestK(value, 1, 2)

export const percent = (value: number, percentage: number): number =>
    cents((value * percentage) / 100)

const removeZeroAtEnd = (string: string): string => {
    if (string[string.length - 1] === "0") {
        return removeZeroAtEnd(string.slice(0, string.length - 1))
    } else {
        return string
    }
}

export const getNumberOfDecimals = (number: number): number => {
    const string = String(number)
    if (string.includes(".")) {
        const decimals = string.split(".")[1]
        const withoutZero = removeZeroAtEnd(decimals)
        return withoutZero.length
    }
    return 0
}
