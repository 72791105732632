<template>
    <ModalWindow
        class="file-browser"
        :show="fileBrowserStore.openModal"
        @close="fileBrowserStore.close"
    >
        <PanelComponent :ready="dataReady">
            <template #default v-if="loading">
                <LoadingIndicator></LoadingIndicator>
            </template>
            <template #default v-else>
                <BrowserHeader
                    v-model:search="searchText"
                    :number="filteredFiles.length"
                    :disk-options="diskOptions"
                    v-model:disk="selectedDisk"
                ></BrowserHeader>
                <div class="images">
                    <ImageComponent
                        v-for="file in filteredFiles"
                        :key="file.path"
                        :src="makeSrc(disks, file)"
                        :selected="
                            file.path === fileBrowserStore.selectedFile.path
                        "
                        @select="fileBrowserStore.selectedFile = file"
                        @dblclick="copyFileUrlToClipboard(file)"
                    ></ImageComponent>
                </div>
            </template>
            <template #footer>
                <div class="left">
                    <ButtonBase
                        v-if="fileBrowserStore.selectedFile.path !== ''"
                        type="primary-gradient"
                        :icon="loadingDelete ? 'cog' : 'bin'"
                        @click="deleteFile"
                    >
                    </ButtonBase>
                    <div class="upload" @click="openFileDialog">
                        <LoadingIndicator
                            v-if="loadingUpload"
                        ></LoadingIndicator>
                        <IconBase icon="upload" v-else></IconBase>
                        <div class="text">
                            {{ translate("upload_your_image_here") }}
                        </div>
                    </div>
                    <div class="error">
                        {{ errorMessage }}
                    </div>
                </div>
                <div class="right">
                    <ButtonBase type="primary" variant="text" @click="cancel">
                        {{ translate("cancel") }}
                    </ButtonBase>
                    <ButtonBase @click="save">{{
                        translate("save")
                    }}</ButtonBase>
                </div>
            </template>
        </PanelComponent>
    </ModalWindow>
</template>

<script lang="ts">
import { capitalize, computed, defineComponent, ref } from "vue"
import ModalWindow from "@/ui-elements/layout/modals/ModalWindow.vue"
import PanelComponent from "@/ui-elements/layout/PanelComponent.vue"
import ImageComponent from "@/ui-elements/file-browser/ImageComponent.vue"
import BrowserHeader from "@/ui-elements/file-browser/BrowserHeader.vue"
import {
    getFileNameFormPath,
    makeSrc,
} from "@/ui-elements/file-browser/useFileBrowser"
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue"
import { useI18n } from "vue-i18n"
import {
    getFiles,
    useDeleteFile,
    useUploadFiles,
} from "@/services/FilesService"
import { useFileBrowserStore } from "@/store/fileBrowserStore"
import IconBase from "@/ui-elements/IconBase.vue"
import LoadingIndicator from "@/ui-elements/loaders/LoadingIndicator.vue"
import { ServerFile } from "@/interface/components/FileBrowser"
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification"
export default defineComponent({
    name: "FileBrowser",
    components: {
        LoadingIndicator,
        ImageComponent,
        PanelComponent,
        ModalWindow,
        BrowserHeader,
        ButtonBase,
        IconBase,
    },
    setup() {
        const { t: translate } = useI18n()
        const fileBrowserStore = useFileBrowserStore()

        const {
            loading,
            dataReady,
            selectedDisk,
            disks,
            files,
            handleRequest: handleFilesRequest,
        } = getFiles()
        const {
            loading: loadingUpload,
            errorMessage,
            handleRequest: handleUploadRequest,
        } = useUploadFiles(handleFilesRequest)
        const { loading: loadingDelete, handleRequest: handleDeleteRequest } =
            useDeleteFile(handleFilesRequest)

        const diskOptions = computed(() =>
            Object.keys(disks.value).map((disk) => ({
                value: disk,
                label: capitalize(disk),
            }))
        )
        const searchText = ref("")
        const filteredFiles = computed(() => {
            const filteredByDisk = files.value.filter(
                (file) => file.disk === selectedDisk.value
            )

            return searchText.value === ""
                ? filteredByDisk
                : filteredByDisk.filter(
                      (file) =>
                          getFileNameFormPath(file.path).indexOf(
                              searchText.value
                          ) > -1
                  )
        })
        const resetAndClose = () => {
            fileBrowserStore.close()
            fileBrowserStore.resetState()
            errorMessage.value = ""
        }
        const save = () => {
            fileBrowserStore.saving = true
            setTimeout(() => resetAndClose(), 100)
        }

        const selectAndSave = (file: ServerFile) => {
            fileBrowserStore.selectedFile = file
            save()
        }

        const deleteFile = async () => {
            errorMessage.value = ""
            await handleDeleteRequest(fileBrowserStore.selectedFile)
        }

        const openFileDialog = () => {
            errorMessage.value = ""
            const input = document.createElement("input")
            input.type = "file"
            input.multiple = true
            input.onchange = async () => {
                const files = input.files ? Array.from(input.files) : []
                await handleUploadRequest(files)
            }
            input.click()
        }

        const copyFileUrlToClipboard = (file: ServerFile) => {
            navigator.clipboard.writeText(makeSrc(disks.value, file))
            setToastNotification(
                translate("success"),
                translate("image_url_copied_to_clipboard"),
                "success"
            )
        }

        return {
            loading,
            dataReady,
            disks,
            files,
            makeSrc,
            diskOptions,
            selectedDisk,
            filteredFiles,
            searchText,
            translate,
            save,
            selectAndSave,
            cancel: resetAndClose,
            fileBrowserStore,
            openFileDialog,
            loadingUpload,
            loadingDelete,
            errorMessage,
            deleteFile,
            copyFileUrlToClipboard,
        }
    },
    emits: ["update:selected", "close"],
})
</script>

<style lang="scss">
.file-browser {
    .modal-window {
        width: 66.1rem;
        height: 45rem;
    }
    .panel {
        height: 100%;
        display: grid;
        grid-template-rows: minmax(0, 1fr) 4.75rem;
    }
    .panel-content {
        grid-template-rows: 4.75rem minmax(0, 1fr);
    }
    .images {
        padding: $padding;
        overflow-y: scroll;
        display: flex;
        flex-flow: row wrap;
        gap: $padding-m;
    }
    .actions {
        flex: 1 1;
        margin-left: 0;
        justify-content: space-between;
        .left {
            display: flex;
            align-items: center;
        }
        .error {
            margin-left: $margin;
            color: $error-color;
        }
        .button .icon-cog {
            animation: $rotation;
        }
    }
    .upload {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $padding-m;
        border-radius: $radius;
        background: $lightest-gray;
        cursor: pointer;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: $radius;
            background-image: $svg-border;
        }

        .icon-cog {
            font-size: 0.9rem;
            margin-right: $margin-s;
        }

        .icon-base {
            margin-right: $margin-s;
        }

        .text {
            color: $darker-gray;
            font-size: $font-size-nav;
        }
    }
}
</style>
