import { Order } from "@/interface/orders/Order"

/**
 * @param order
 */
export const isActuallyPaid = (order: Order | undefined) => {
    if (!order || !order.payment) {
        return false
    }
    return Boolean(
        order.payment?.method?.match(
            /^online|ideal|mistercash|creditcard|maestro|visa|mastercard|interac|\w\w_online|paypal|banktransfer/s
        )
    )
}

/**
 * @param order
 */
export const isPinFinal = (order: Order | undefined) => {
    if (!order || !order.payment || !order.payment2) {
        return false
    }

    if (order.payment?.method === "pin" || order.payment2?.method === "pin") {
        return Boolean(
            order.payment?.result?.match(
                /^(CCV:.*OverallResult="Success"|PayPlaza:.*"transactionSucceeded":"true"|Adyen: (OK \S+|.*"success":"true"))/s
            )
        )
    }

    return false
}
