import { defineStore } from "pinia"
import { merge } from "lodash"
import { RoutesConfig } from "@/pages/settings/utils/RoutesConfig"
import { SETTINGS_ROUTE_OVERRIDES } from "@/pages/settings/utils/routeOverrides"
import { MenuStructure } from "@/interface/MenuStructure"
import { buildMenu } from "@/pages/settings/utils/menuGenerator"
import axios from "axios"
import { toRaw } from "vue"
import { updateLocaleSettings } from "@/pages/auth/useLocalLocales"
import { SettingsFormData } from "@/interface/pos/modal/SettingsModal"
import { SettingsOverviewSearchResult } from "@/pages/settings/overview/SettingsOverviewSearchResultType"
import { UploadedAsset } from "@/interface/menu/Menu"
import * as Sentry from "@sentry/vue"

export type Settings = {
    upsellbuddy_ad: string | null
    admin_pack_costs_own: string
    admin_pack_costs_def: string
    admin_pack_costs_dinein: string
    css_logo: UploadedAsset | null
    vat_rates: Array<number | undefined>
    vat_default_rate: number
    ob_timezone: string
    ob_currency: string
    ob_language: string
    google_directions_mode: string
    qr_qrcode: boolean
    pos_def_payment: string | null
    pos_def_delivery: string | null
    pos_noextras: string | null
    pos_extras_after: string | null
    pos_delivery_min: string | null
    pos_customer: string | null
    pos_refnr: string | null
    pos_show_images: string | null
    pos_show_prices: string | null
    pos_edit_buttons_auto: string | null
    pos_keepinput: string | null
    counter_keep_price: string | null
    payment_vat: string | null
    pos_closing: string | null
    orders_show_remarks: string | null
    ob_products_show_allergy: string
    ob_show_name: string
    pos_active: string | null
    pos_extras_nodropdown: string | null
    show_ext_id: string | null
    admin_stock: string
    products_hidden_view: string | null
    products_simple_view: string | null
    dunkin: string | null
    products_simple_view_categories: string | null
    payment_cash: string | null
    payment_cash_round5: string
    payment_pin: string | null
    payment_invoice: string | null
    payment_online: string | null
    pickup: boolean
    delivery_pickup_menu: string
    delivery: boolean
    dinein: string
    dinein_menu: string | null
    payment_account: string | null
    mail_discount: number
    lang_show: boolean
    additional_languages: string[]
    shop_language: string
    data_takeout: string
    menus: Record<number, string>
    sms_send: string | null
    products_custom_cols: string | null
    admin_products_custom_cols: string | null
    pos_show_cats_always: string | null
    pos_v3_cols: string
    pos_v3_cols_extras: string
    sms_send_prohibited: string | null
    skip_payouts_by_request: string | null
    adyen_payout_hold: string | null
    order_del_man: string | null

    pickup_min_time: string
    delivery_min_time: string
    pickup_init_time: string | null
    delivery_init_time: string | null

    points_active: string | null
    points_per: string
    points_per_x: string
    points_per_amount: string
    points_max: string
    points_value: string

    tb_api_version: string
    tb_api_active: string
    tb_api_restaurant_id: string
    tb_pos_api_active: string
    tb_pos_api_restaurant_id: string
    tb_api_username: string
    tb_api_password: string
    tb_api_key: string
    tb_orders_color: string
    tb_import_use_nr: "1" | "0"
    tb_match_by_name: "1" | "0"

    deliveroo_match_by_name: "1" | "0"
    deliveroo_skip_errors: "1" | "0"
    de_orders_color: string

    uber_api_active: "1" | "0"
    uber_api_store_id: string
    ue_orders_color: string
    ubereats_match_by_name: "1" | "0"
    ubereats_skip_errors: "1" | "0"
    uber_api_store_status: "ONLINE" | "PAUSED"

    /** emergency break */
    delivery_min_time_tmp: number
    delivery_closed_tmp: number
    pickup_min_time_tmp: number
    pickup_closed_tmp: string | null
    closed_tmp: boolean
    tmp_set: boolean
    zipcodewithname: string
    zip_codes_not_reset: string

    adyen_pin_active: string | null
    adyen_pin_poiid: string
    payment_adyen_test: number
    payment_adyen_live: number

    /** sound notifications **/
    sound_play: string
    sound_file: string
    sound_loop: string

    show_popup: "order" | "time" | "" // when new order, show popup? order=order popup, time=time popup, none

    /** printing **/
    counter_customer_print: string // printerid:qty[], for POS orders customer receipts
    counter_print: string // printerid:qty[], for POS orders
    default_printer: number // printerid
    order_print: string // printerid:qty[], for newly incoming orders
    pos_pin_drawer: string
    pos_printer_drawer: string // printerid
    pos_printer_drawer_no_open_future: string | null
    print_auto: "1" | "0" | "accept"
    print_customer: "ask" | "auto" | ""
    print_customer_pin: "no" | "ask" | ""
    print_customer_table: "auto" | "ask" | "no" | ""
    print_labels_separate: "1" | "0"
    print_when: "" | "accept" | "direct" | "init-pending" // accept=when order accepted, direct=immediately,
    // no popup, init-pending=status from new to kitchen
    table_customer_print: string // printerid:qty[], for customer table order receipts
    table_print: string // printerid:qty[], for table orders
    admin_multi_custom_print: string // In multi location use the printer settings of the location

    delivery_min: string
    delivery_free: string
    delivery_costs: string

    /** click 2 dial **/
    kwebble_click2dail: boolean
    voip_provider: string

    masterslave: "1" | "0"
    master_ob_id: string
    pos_cat_after_add: string | null

    /** time intervals **/
    pos_pickup_time_steps: string
    pos_delivery_time_steps: string
    pos_time_steps: string
    webshop_pickup_time_steps: string
    webshop_delivery_time_steps: string
    webshop_time_steps: string
    dunkin_time_ranges: string | null
    dunkin_time_ranges_delivery: string | null
    dunkin_time_ranges_pickup: string | null

    pos_check_account_payment: string | null

    /** orders page */
    orders_turnover: string
    orders_map: string
    orders_map_style: string
    pickup_orders_color: string
    delivery_orders_color: string
    order_show_disdur: string
    alert_minutes: string
    alert_sound_play: string
    alert_sound_fil: string
    ob_order_click: string
    ob_order_clickhold: string
    ob_order_dblclick: string

    admin: string
    admin_blocked: string
    admin_blocked_beslag: string
    delete_orders: string
    order_ahead: string
    ob_timezone_offset: string

    pos_show_timestamps: string
    pos_closing_turnover: string
    pos_no_kitchen_dropdown: string
    counter_print_existing: string
    pos_onload_products: string
    print_set_status_after: string
    pos_show_cust_delivery_buttons: string
    pos_no_orderbutton: string
    hide_popup: string

    pin_req_changeorder: string

    ob_show_buttons: string
    ob_show_deliverer: string
    ob_show_refnr: string
    ob_show_always: string | null
    pin_products_no: string
    orderpopup_extras_array: string
    orderpopup_products_kitchentitle: string

    kitchenscreen_category: string
    kitchenscreen_hilight_categories: string
    kitchenscreen_hilight_categories_extras: string
    kitchenscreen_hilight_categories_list: string
    kitchenscreen_hilight_categories_skip: string
    kitchenscreen_hilight_categories_skip_not: string
    kitchenscreen_show_kitchentitle: string
    kitchenscreen_send_when: string
    kitchenscreen_status_ready: string
    kitchenscreen_print: string
    kitchenscreen_sortby: string
    kitchenscreen_send_when_direct: string
    kitchenscreen_order_colors: string
    kitchenscreen_order_address: string

    /** number buddy **/
    numberbuddy_color: string | null
    numberbuddy_leftright: string | null
    numberbuddy_bg: string | null
    numberbuddy_bg_pos: string | null
    numberbuddy_show_pos_orders: string | null
    sdateper_week_us: string

    /** customer screen **/
    pos_2ndscreen: string | null
    pos_2ndscreen_background_image: string | null

    counter_menu: string
    pin_req_auto_off: string

    /** External APIs **/
    /** novacom **/
    novacom_active: 0 | 1
    novacom_apiIpAddress: string
    novacom_apiIpPort: number
    novacom_apiClient: string
    novacom_apiWaiterId: number
    novacom_paymentid_cash: number
    novacom_paymentid_pin: number
    show_novacom_pos: 1 | 0

    /** micro **/
    micros_active: 1 | 0
    micros_apiIpAddress: string
    micros_apiIpPort: number
    micros_employeeObjectNum: number
    micros_revenueCenter: number
    micros_paymentid_cash: number
    micros_paymentid_pin: number
    show_micros_pos: 1 | 0

    print_table_order_date_update: string

    /** master menu **/
    master_menu: string | null
    master_menu_price: string | null

    shop_flyer_reminder_needed: boolean
}
const defaultTimeZone = "Europe/Amsterdam"
const defaultSettings = {
    upsellbuddy_ad: null,
    admin_pack_costs_own: "0",
    admin_pack_costs_def: "",
    admin_pack_costs_dinein: "0",
    vat_default_rate: 2,
    vat_rates: [],
    ob_timezone: defaultTimeZone,
    ob_currency: "EUR",
    ob_language: "nl",
    google_directions_mode: "driving",
    css_logo: null,
    qr_qrcode: false,
    pos_def_payment: "pin",
    pos_def_delivery: "",
    pos_noextras: null,
    pos_extras_after: null,
    pos_delivery_min: "1",
    pos_customer: "1",
    pos_refnr: "1",
    pos_v3_cols: "0",
    pos_v3_cols_extras: "0",
    pos_keepinput: null,
    counter_keep_price: "0",
    pos_closing_turnover: "0",
    ob_products_show_allergy: "1",
    ob_show_name: "0",
    pos_active: "1",
    pos_extras_nodropdown: "0",
    pos_edit_buttons_auto: "0",
    show_ext_id: null,
    admin_stock: "0",
    products_hidden_view: "1",
    pos_show_images: "1",
    pos_show_prices: "1",
    pos_closing: "06",
    orders_show_remarks: null,
    payment_cash: "1",
    payment_cash_round5: "1",
    payment_pin: "1",
    payment_vat: "0",
    payment_invoice: "1",
    payment_online: "1",
    pickup: true,
    delivery_pickup_menu: "1",
    delivery: true,
    dinein: "1",
    dinein_menu: "1",
    payment_account: null,
    mail_discount: 0,
    lang_show: false,
    additional_languages: [] as string[],
    shop_language: "",
    data_takeout: "0",
    menus: { 1: "default" } as Record<number, string>,
    sms_send: "0",
    products_custom_cols: "",
    admin_products_custom_cols: "",
    pos_show_cats_always: "0",
    sms_send_prohibited: "0",

    pickup_min_time: "15",
    delivery_min_time: "15",

    points_active: null,
    points_per: "",
    points_per_x: "",
    points_per_amount: "",
    points_max: "",
    points_value: "",

    tb_api_active: "0",
    tb_api_restaurant_id: "",
    tb_pos_api_active: "0",
    tb_pos_api_restaurant_id: "",
    tb_api_username: "",
    tb_api_password: "",
    tb_api_key: "",

    /** emergency break */
    delivery_min_time_tmp: 0,
    delivery_closed_tmp: 0,
    pickup_min_time_tmp: 0,
    pickup_closed_tmp: null,
    closed_tmp: false,
    tmp_set: false,

    adyen_pin_active: "0",
    adyen_pin_poiid: "",
    payment_adyen_test: 0,
    payment_adyen_live: 1,

    /** sound notifications **/
    sound_play: "0",
    sound_file: "Beep.mp3",
    sound_loop: "0",

    show_popup: "order", // when new order, show popup? order=order popup, time=time popup, none

    /** printing **/
    counter_customer_print: "", // printerid:qty[], for POS orders customer receipts
    counter_print: "", // printerid:qty[], for POS orders
    default_printer: 0, // printerid
    order_print: "", // printerid:qty[], for newly incoming orders
    pos_pin_drawer: "0", // require pin for opendrawer
    pos_printer_drawer: "", // printerid
    pos_printer_drawer_no_open_future: null,
    print_auto: "1", // 1, 0, accept
    print_customer: "auto", // ask, auto, ""
    print_customer_pin: "", // no, ask, ""
    print_customer_table: "", // auto, ask, no, ""
    print_labels_separate: "0", // 1, 0
    print_when: "accept", // accept=when order accepted, direct=immediately, no popup, init-pending=status from new to kitchen
    table_customer_print: "", // printerid:qty[], for customer table order receipts
    table_print: "", // printerid:qty[], for table orders
    admin_multi_custom_print: "0",

    zipcodewithname: "0",
    zip_codes_not_reset: "0",

    delivery_min: "0",
    delivery_free: "0",
    delivery_costs: "0",

    /** click 2 dial **/
    kwebble_click2dail: true,
    voip_provider: "",

    masterslave: "0",
    master_ob_id: "",
    pos_cat_after_add: null,

    pos_check_account_payment: "1",

    /** orders page */
    orders_turnover: "0",
    orders_map: "0",
    orders_map_style:
        '{"labels":"cla9eo17t004w15n74kp1wx3f","tiles":"cli30dsur02l401pgggfa9x3l"}', //'{"labels":"clia0krq1004n01pge1ev9kyx","tiles":"cli31hfhh02j001pn5jz3aheq"}',
    pickup_orders_color: "",
    delivery_orders_color: "",
    order_show_disdur: "0",
    alert_minutes: "0",
    alert_sound_play: "0",
    alert_sound_fil: "0",
    ob_order_click: "order",
    ob_order_clickhold: "status_next",
    ob_order_dblclick: "status_next",
    order_del_man: "0",

    admin: "0",
    admin_blocked: "0",
    admin_blocked_beslag: "0",
    delete_orders: "0",
    order_ahead: "1",
    ob_timezone_offset: "+00:00",
    print_set_status_after: "",

    pos_show_timestamps: "0",
    pos_no_kitchen_dropdown: "0",
    counter_print_existing: "0",
    pos_show_cust_delivery_buttons: "0",
    pos_onload_products: "0",
    pos_no_orderbutton: "0",

    pin_req_changeorder: "0",

    ob_show_buttons: "0",
    ob_show_deliverer: "0",
    ob_show_refnr: "0",
    pin_products_no: "0",

    orderpopup_extras_array: "0",
    orderpopup_products_kitchentitle: "0",

    sdateper_week_us: "0",

    kitchenscreen_category: "0",
    kitchenscreen_hilight_categories: "1",
    kitchenscreen_hilight_categories_extras: "1",
    kitchenscreen_hilight_categories_list: "",
    kitchenscreen_hilight_categories_skip: "0",
    kitchenscreen_hilight_categories_skip_not: "0",
    kitchenscreen_show_kitchentitle: "0",
    kitchenscreen_send_when: "",
    kitchenscreen_status_ready: "",
    kitchenscreen_print: "",
    kitchenscreen_sortby: "",
    kitchenscreen_send_when_direct: "",
    kitchenscreen_order_colors: "1",
    kitchenscreen_order_address: "0",

    /* number buddy */
    numberbuddy_color: "",
    numberbuddy_leftright: "",
    numberbuddy_bg: "",
    numberbuddy_bg_pos: "",
    numberbuddy_show_pos_orders: "",

    pos_2ndscreen: null,
    pos_2ndscreen_background_image: null,

    counter_menu: "1",
    pin_req_auto_off: "0",

    /* External APIs */
    /* novacom */
    novacom_active: 0,
    novacom_apiIpAddress: null,
    novacom_apiIpPort: null,
    novacom_apiClient: null,
    novacom_apiWaiterId: null,
    novacom_paymentid_cash: 0,
    novacom_paymentid_pin: 0,
    show_novacom_pos: 0,

    /* micro */
    micros_active: 0,
    micros_apiIpAddress: null,
    micros_apiIpPort: null,
    micros_employeeObjectNum: null,
    micros_revenueCenter: null,
    micros_paymentid_cash: null,
    micros_paymentid_pin: null,
    show_micros_pos: 0,
    print_table_order_date_update: "0",

    /** master menu **/
    master_menu: "",
    master_menu_price: "",

    shop_flyer_reminder_needed: false,
}

export const useSettingsStore = defineStore({
    id: "settings",
    state: () => ({
        settings: defaultSettings as unknown as Settings,
        routesConfig: {} as RoutesConfig,
        routesConfigLanguage: "" as string,
        settingsMenu: {} as MenuStructure,
        selectedMenuType: "1",
        search: {
            query: "" as string,
            selectedResult: {} as SettingsOverviewSearchResult | null,
        },
        terminals: {
            terminal1: {
                environment: "test",
                ip: "",
            },
        },
    }),
    getters: {
        getTimeZone(state) {
            return state?.settings?.ob_timezone !== ""
                ? state.settings.ob_timezone
                : defaultTimeZone
        },
    },
    actions: {
        async fetchRoutesConfig() {
            let config = {}
            try {
                const response = await axios.get("settings/routing-config")
                config = response.data.data || {}
                this.routesConfigLanguage = response.data.meta.language
            } catch (e) {
                Sentry.captureException(e)
            }
            this.routesConfig = merge(config, SETTINGS_ROUTE_OVERRIDES)
            this.settingsMenu = buildMenu(this.routesConfig)
        },

        async fetchSettings(): Promise<boolean> {
            try {
                const response = await axios.get("client/settings")
                this.settings = {
                    ...toRaw(this.settings),
                    ...response.data.data,
                }
                if (this.settings.ob_language) {
                    const locale =
                        localStorage.getItem("localLocale") === "default"
                            ? this.settings.ob_language
                            : localStorage.getItem("localLocale") || "nl"
                    updateLocaleSettings(locale)
                }

                return true
            } catch (e) {
                return false
            }
        },

        updatePOSSettings(settingsData: SettingsFormData) {
            this.settings.pos_def_payment = settingsData.pos_def_payment
            this.settings.pos_def_delivery = settingsData.pos_def_delivery
            this.settings.pos_noextras = String(
                Number(settingsData.pos_noextras)
            )
            this.settings.pos_delivery_min = String(
                Number(settingsData.pos_delivery_min)
            )
            this.settings.pos_keepinput = String(
                Number(settingsData.pos_keepinput)
            )
            this.settings.pos_customer = String(
                Number(settingsData.pos_customer)
            )
            this.settings.pos_refnr = String(Number(settingsData.pos_refnr))
            this.settings.pos_show_images = String(
                Number(settingsData.pos_show_images)
            )
            this.settings.pos_show_prices = String(
                Number(settingsData.pos_show_prices)
            )
            this.settings.pos_check_account_payment = String(
                Number(settingsData.pos_check_account_payment)
            )
            this.settings.pos_v3_cols = settingsData.pos_v3_cols
            this.settings.pos_v3_cols_extras = settingsData.pos_v3_cols_extras
            this.settings.pos_edit_buttons_auto = String(
                settingsData.pos_edit_buttons_auto
            )
            this.settings.pos_onload_products = String(
                Number(settingsData.pos_onload_products)
            )
            this.settings.counter_menu = String(
                Number(settingsData.counter_menu)
            )
            this.settings.pos_extras_nodropdown = String(
                Number(settingsData.pos_extras_nodropdown)
            )
        },

        removeSetting(setting?: keyof Settings) {
            if (!setting) {
                return
            }

            delete this.settings[setting]
        },
    },
})
