<template>
    <BaseTable :selectable="false" :deletable="false" class="mb-4">
        <template #list-header>
            <TableCell> {{ translate("number") }} </TableCell>
            <TableCell> {{ translate("status") }} </TableCell>
            <TableCell> {{ translate("total") }} </TableCell>
            <TableCell>
                {{ translate("customers_invoices_days") }}
            </TableCell>
            <TableCell> {{ translate("actions") }} </TableCell>
        </template>
        <template #list>
            <template v-for="invoice in invoices" :key="invoice.id">
                <TableRow
                    :selectable="false"
                    :deletable="false"
                    @click="openInvoiceInOrderBuddy(invoice.id)"
                >
                    <TableCell>{{ invoice.nr }}</TableCell>
                    <TableCell>{{ invoice.status_description }} </TableCell>
                    <TableCell>{{ invoice.total }}</TableCell>
                    <TableCell
                        :class="[
                            invoice.is_long_overdue
                                ? 'longOverdue'
                                : invoice.is_overdue
                                ? 'overdue'
                                : '',
                        ]"
                    >
                        {{ invoice.days_open }}
                    </TableCell>
                    <TableCell><IconBase icon="link" /></TableCell>
                </TableRow>
                <TableRow
                    v-for="(line, index) in invoice.lines"
                    :key="index"
                    :selectable="false"
                    :deletable="false"
                    @click="openInvoiceInOrderBuddy(invoice.id)"
                >
                    <TableCell />
                    <TableCell :colspan="4">
                        {{ line }}
                    </TableCell>
                </TableRow>
            </template>
        </template>
    </BaseTable>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import { useI18n } from "vue-i18n"
import BaseTable from "@/ui-elements/layout/tables/BaseTable.vue"
import TableCell from "@/ui-elements/layout/tables/TableCell.vue"
import TableRow from "@/ui-elements/layout/tables/TableRow.vue"
import IconBase from "@/ui-elements/IconBase.vue"
import { useRouter } from "vue-router"
import { AdminInfoInvoice } from "@/interface/user/User"

export default defineComponent({
    name: "AdminInvoicesTable",
    props: {
        invoices: Array as PropType<AdminInfoInvoice[]>,
    },
    components: {
        BaseTable,
        TableCell,
        TableRow,
        IconBase,
    },
    setup() {
        const { t: translate } = useI18n()
        const router = useRouter()

        const openInvoiceInOrderBuddy = (invoiceId: number) =>
            router.push({
                name: "financialInvoiceEdit",
                params: {
                    id: invoiceId,
                },
            })

        return {
            translate,
            openInvoiceInOrderBuddy,
        }
    },
})
</script>
