<template>
    <div class="base-table">
        <table class="table-list">
            <thead>
                <TableRow
                    @update:selected="selectionObject.setAllItems($event)"
                    :isLink="false"
                    :head="true"
                    :selectable="selectable"
                    :deletable="deletable"
                    :selected="selectionObject?.allChecked || false"
                    :indeterminate="selectionObject?.indeterminate"
                    :isDraggable="isDraggable"
                >
                    <slot name="list-header"></slot>
                </TableRow>
            </thead>
            <template v-if="isDraggable">
                <template v-if="!loading && !empty">
                    <slot name="list"></slot>
                </template>
            </template>
            <template v-else>
                <tbody class="list" v-if="!loading && !empty">
                    <slot name="list"></slot>
                </tbody>
            </template>
        </table>
        <div class="no-rows" v-if="empty || loading">
            <LoadingIndicator v-if="loading"></LoadingIndicator>
            <slot name="no-rows" v-else></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import { useI18n } from "vue-i18n"
import TableRow from "@/ui-elements/layout/tables/TableRow.vue"
import LoadingIndicator from "@/ui-elements/loaders/LoadingIndicator.vue"
import { SelectionObject } from "@/utils/useSelectedItems"

export default defineComponent({
    name: "BaseTable",
    components: {
        TableRow,
        LoadingIndicator,
    },
    props: {
        selectable: {
            type: Boolean,
            default: true,
        },
        loading: Boolean,
        deletable: {
            type: Boolean,
            default: true,
        },
        selectionObject: {
            type: Object as PropType<SelectionObject>,
        },
        empty: {
            type: Boolean,
            default: false,
        },
        isDraggable: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { t: translate } = useI18n()
        return {
            translate,
        }
    },
})
</script>
<style lang="scss" scoped>
.base-table {
    flex: 1 1;
    display: flex;
    flex-flow: column nowrap;
    overflow-x: scroll;
}
.list {
    height: 100%;
}

.table-list {
    width: 100%;
    empty-cells: show;
}

.no-rows {
    width: 100%;
    padding: $padding;
    text-align: center;
    flex: 1 1;
}
</style>
