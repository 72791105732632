export const useDoubleClick = (
    single_callback: () => any,
    double_callback: () => any,
    timeout = 500
) => {
    let timer = 0
    return () => {
        if (!timer) {
            timer = window.setTimeout(() => {
                single_callback()
                timer = 0
            }, timeout)
        } else {
            clearTimeout(timer)
            double_callback()
            timer = 0
        }
    }
}
