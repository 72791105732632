import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobilePageHeader = _resolveComponent("MobilePageHeader")!
  const _component_DesktopPageHeader = _resolveComponent("DesktopPageHeader")!
  const _component_OrderAudioNotification = _resolveComponent("OrderAudioNotification")!
  const _component_DeviceCheck = _resolveComponent("DeviceCheck")!

  return (_openBlock(), _createBlock(_component_DeviceCheck, null, {
    default: _withCtx(({ isMobile, isTablet, isDesktop }) => [
      isMobile
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_MobilePageHeader)
          ]))
        : isTablet
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_DesktopPageHeader)
            ]))
          : isDesktop
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_DesktopPageHeader)
              ]))
            : _createCommentVNode("", true),
      (
                    _ctx.ordersStore.playSoundOnce ||
                    _ctx.ordersStore.playSound ||
                    (_ctx.ordersStore.autoOpened &&
                        _ctx.ordersStore.showOrderId &&
                        !_ctx.ordersStore.orderData(_ctx.ordersStore.showOrderId)
                            ?.has_seen)
                )
        ? (_openBlock(), _createBlock(_component_OrderAudioNotification, {
            key: 3,
            once: _ctx.ordersStore.playSoundOnce,
            autoPrint: true,
            class: "hidden"
          }, null, 8, ["once"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}