import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f7bb5244"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonBase = _resolveComponent("ButtonBase")!
  const _component_PanelComponent = _resolveComponent("PanelComponent")!

  return (_ctx.show)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "#modals"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["modal", _ctx.$attrs.class])
        }, [
          _createVNode(_component_PanelComponent, {
            class: "floating-modal",
            "scroll-area-el": _ctx.scrollArea
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _renderSlot(_ctx.$slots, "title", {}, () => [
                  _createElementVNode("h3", _hoisted_2, [
                    _renderSlot(_ctx.$slots, "icon", {}, undefined, true),
                    _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
                  ])
                ], true)
              ]),
              _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ])
            ]),
            footer: _withCtx(() => [
              _renderSlot(_ctx.$slots, "actions", {}, () => [
                _createVNode(_component_ButtonBase, {
                  type: "regular",
                  variant: "filled",
                  class: "save",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('submit')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.submitButtonText || _ctx.translate("save")), 1)
                  ]),
                  _: 1
                })
              ], true)
            ]),
            _: 3
          }, 8, ["scroll-area-el"])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}