import { CustomerAddress } from "@/interface/Customers/CustomerAddress"
import { Client } from "@/interface/Client"
import { Customer } from "@/interface/Customers/Customer"
import { Invoice, Line } from "@/interface/Customers/invoices/Invoice"
import { CustomerReview } from "@/interface/Customers/CustomerReviews/CustomerReview"
import { Fax } from "@/interface/orders/Fax"
import { Push } from "@/interface/orders/Push"
import { Deliverer } from "@/interface/deliverer/Deliverer"
import { OrderProduct } from "@/interface/orders/OrderProduct"
import { OrderPayment } from "@/interface/orders/Payment"
import GeoJSON from "geojson"
import { SplitPaymentState } from "@/store/SplitPayment"

export interface Order {
    id: number
    client_id: number
    customer_id: number
    created_at: string
    label: string
    company: string | null
    first_name: string
    last_name: string
    printer_address: string
    address: CustomerAddress
    billing_address: CustomerAddress
    language: string
    coordinates: GeoJSON.Point
    distance: number | null
    email: string
    phone: string
    payment: OrderPayment
    payment2: {
        amount?: number
        method?: string
        method_name?: string
    }
    delivery: OrderDeliveryType
    delivery_costs: number
    deliverer_id: number | null
    is_in_future: boolean
    time: string
    remarks: string | null
    total: number
    total_rounded: number
    status: OrderStatusType
    is_delivery_asap: boolean
    time_set: string
    deleted: number
    mailing: number
    time_offset: number
    ip: string
    ua: string
    discount: OrderDiscount
    discounts?: Array<{
        id: number // id of dbDiscount
        label: string
        code: string // used for both type=code en code=wigroup
        type: "custom" | "code" | "wigroup"
        amount_type: "absolute" | "percentage"
        amount?: number // only present when type=custom
        amount_type_value?: number
    }>
    paid_at: string
    init_at: string
    pending_at: string
    en_route_at: string
    source: OrderSource
    nr: number
    children_ids: number[]
    subtotal: number
    parent_id: number
    updated_at: string
    has_seen: boolean
    reason: string
    tip: number
    deposit: number
    refnr: number
    via_client_id: number
    payout_id: number
    joeyco: string
    set_at: string
    table_id: number
    points: OrderPoints
    table_name: string
    delivered_at: string
    aggregator_data: null | string
    client: Client
    customer: Customer
    customer_invoice_lines: Line[]
    customer_invoice?: Invoice
    deliverer: Deliverer
    faxes: Fax[]
    products: OrderProduct[]
    pushes: Push[]
    smses: Smses[]
    mailing_email_stats: MailingEmailStats[]
    reviews: CustomerReview[]
    // table: Table
    // winwheel_results: WinWheelResult[]
    vat_rates: VatRate[]
    duration: number
    total_netto: number
    is_pin_final: boolean
    products_can_be_updated: boolean
    inv_company?: string
    has_split_payment: boolean
    split_payment_meta: {
        state: Partial<SplitPaymentState>
    }
    is_new_customer: boolean
    is_complete?: boolean
    refunded_at: string | null
    packaging_costs?: number
    has_custom_packaging_costs: boolean
}

interface Smses {
    id: number
    client_id: number
    order_id: number | null
    date: string | null
    to: string
    message: string
    mailing_id: number
    sent: string | null
    received: string | null
    status: number | string
    errorcode: number | string
    reference: string | null
    errorstr: string | null
    sms_count: number
    price: number
    customer_id: number | null
    gateway: string
}

interface MailingEmailStats {
    id: number
    mailing_id: number | null
    order_id: number | null
    email: string
    customer_id: string | null
    status: string
    discountcode_id: number | null
    tries: number
    has_opened: boolean
    hash: string
    created_at: string | null
    updated_at: string | null
}

export interface OrderDiscount {
    amount: number
    code: string
    amount_original?: number // This is only relevant for orders with products from different locations (multi location)
}

export interface OrderSource {
    id: number
    type: OrderSourceType
    info: string
    url: string
    name: string
}

export interface VatRate {
    amount: number
    total: number
    rate: string
}

export interface OrderPoints {
    paid: number
    earned: number
}

export const ORDER_STATUSES = [
    "init",
    "pending",
    "pending-ready",
    "enroute",
    "delivered",
] as const

export type OrderStatusType = typeof ORDER_STATUSES[number]

export const ORDER_STATUSES_FOR_LABELS = [
    "init",
    "pending",
    "pending-ready",
    "enroute",
    "delivered",
    "pickedUp",
    "served",
] as const

export const ORDER_SOURCE_TYPES = [
    "app",
    "bt",
    "counter",
    "de",
    "dt",
    "fb",
    "hb",
    "hu",
    "je",
    "kiosk",
    "lh",
    "lo",
    "ls",
    "online",
    "pi",
    "qr",
    "qr_table",
    "se",
    "ta",
    "tb",
    "th",
    "ue",
] as const

export type OrderSourceType = typeof ORDER_SOURCE_TYPES[number]

export const ORDER_DELIVERIES = ["deliver", "dinein", "pickup"] as const

export type OrderDeliveryType = typeof ORDER_DELIVERIES[number]
