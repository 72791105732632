import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a5d0e152"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "tab-content remarks",
    ref: _ctx.scrollAreaRef()
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.remarksContent), 1)
  ], 512))
}