import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c199f7cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["colspan", "rowspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("td", {
    class: _normalizeClass(["table-cell", { [_ctx.align]: _ctx.align, [_ctx.verticalAlign]: _ctx.verticalAlign }]),
    colspan: _ctx.colspan,
    rowspan: _ctx.rowspan
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 10, _hoisted_1))
}