<template>
    <td
        class="table-cell"
        :colspan="colspan"
        :rowspan="rowspan"
        :class="{ [align]: align, [verticalAlign]: verticalAlign }"
    >
        <slot />
    </td>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"

export type TableCellAlignment = "right" | "center" | "left"

export type TableCellVerticalAlignment = "top" | "middle" | "bottom"

export default defineComponent({
    name: "TableCell",
    props: {
        colspan: [Number, String],
        rowspan: Number,
        align: {
            type: String as PropType<TableCellAlignment>,
            default: "left",
        },
        verticalAlign: {
            type: String as PropType<TableCellVerticalAlignment>,
            default: "middle",
        },
    },
})
</script>

<style lang="scss" scoped>
.table-cell {
    color: $secondary-color;
    padding: $padding-s $padding;

    &.right {
        text-align: right;
    }

    &.center {
        text-align: center;
    }

    &.top {
        vertical-align: top;
    }

    &.bottom {
        vertical-align: bottom;
    }
}
</style>
