import { MenuStructure } from "@/interface/MenuStructure"

export const debugStructure: MenuStructure = {
    general: {
        text: "General",
        name: "debug/general",
        path: "general",
        subMenu: {},
    },
    sysinfo: {
        text: "System information",
        name: "debug/sysinfo",
        path: "sysinfo",
        subMenu: {},
    },
    printers: {
        text: "Printers",
        name: "debug/printers",
        path: "printers",
        subMenu: {},
    },
    dataHydration: {
        text: "Data hydration",
        name: "debug/data-hydration",
        path: "data-hydration",
        subMenu: {},
    },
}
