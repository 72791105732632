import { vModelText as _vModelText, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputField = _resolveComponent("InputField")!

  return (_openBlock(), _createBlock(_component_InputField, {
    class: _normalizeClass(["text-area", _ctx.name]),
    disabled: _ctx.disabled,
    focus: _ctx.focus,
    error: _ctx.error,
    success: _ctx.success,
    label: _ctx.label,
    name: _ctx.name,
    "label-icon": _ctx.labelIcon,
    "label-icon-class": _ctx.labelIconClass
  }, {
    input: _withCtx(() => [
      _withDirectives(_createElementVNode("textarea", _mergeProps({
        ref: "textarea",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.content) = $event))
      }, { ..._ctx.bind, ..._ctx.$attrs }, {
        onFocusin: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focus = true)),
        onFocusout: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focus = false)),
        onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.run())),
        class: { resizable: _ctx.resizable }
      }), null, 16), [
        [_vModelText, _ctx.content]
      ])
    ]),
    _: 1
  }, 8, ["disabled", "focus", "error", "success", "label", "name", "class", "label-icon", "label-icon-class"]))
}