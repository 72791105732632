import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70fc30dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-wrapper" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonBase = _resolveComponent("ButtonBase")!
  const _component_PanelComponent = _resolveComponent("PanelComponent")!
  const _component_ModalWindow = _resolveComponent("ModalWindow")!

  return (_openBlock(), _createBlock(_component_ModalWindow, {
    show: _ctx.show,
    title: _ctx.title,
    class: _normalizeClass(_ctx.$attrs.class),
    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PanelComponent, {
        class: "pop-up",
        loading: _ctx.loading,
        "scroll-area-el": _ctx.scrollArea
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "header", {}, () => [
              _createElementVNode("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "title", {}, () => [
                  _createElementVNode("h3", _hoisted_3, [
                    _renderSlot(_ctx.$slots, "icon", {}, undefined, true),
                    _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
                  ])
                ], true),
                (_ctx.cancellable)
                  ? (_openBlock(), _createBlock(_component_ButtonBase, {
                      key: 0,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
                      icon: "delete",
                      type: "regular",
                      variant: "filled",
                      class: "close"
                    }))
                  : _createCommentVNode("", true)
              ])
            ], true),
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ])
          ])
        ]),
        footer: _withCtx(() => [
          _renderSlot(_ctx.$slots, "actions", {}, () => [
            (_ctx.cancellable)
              ? (_openBlock(), _createBlock(_component_ButtonBase, {
                  key: 0,
                  type: "secondary",
                  variant: "outlined",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.closeButtonText || _ctx.translate("cancel")), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_ButtonBase, {
              type: "regular",
              variant: "filled",
              class: "save",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('submit'))),
              "data-testid": "save"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.submitButtonText || _ctx.translate("save")), 1)
              ]),
              _: 1
            })
          ], true)
        ]),
        _: 3
      }, 8, ["loading", "scroll-area-el"])
    ]),
    _: 3
  }, 8, ["show", "title", "class"]))
}