<template>
    <div
        class="image-component"
        :class="{ selected: selected }"
        @click="$emit('select')"
    >
        <div class="image-wrap">
            <img :src="src" />
        </div>
        <div class="file-name">
            {{ fileName }}
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue"
import { getFileNameFormPath } from "@/ui-elements/file-browser/useFileBrowser"

export default defineComponent({
    name: "ImageComponent",
    props: {
        src: {
            type: String,
            default: "",
        },
        selected: Boolean,
    },
    setup(props) {
        const fileName = computed(() => getFileNameFormPath(props.src))
        return {
            fileName,
        }
    },
    emit: ["select"],
})
</script>

<style lang="scss">
.image-component {
    width: 10rem;
    height: 8.75rem;
    display: grid;
    grid-template-rows: minmax(0, 1fr) 1.5rem;
    grid-template-columns: 100%;
    background: $lightest-gray;
    border-radius: $radius;
    padding: $padding-s $padding-s 0 $padding-s;
    position: relative;
    cursor: pointer;

    &.selected::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: $radius;
        background-image: $orange-svg-border;
    }

    .image-wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-height: 100%;
        }
    }
    .file-name {
        max-width: 100%;
        text-align: center;
        align-items: center;
        font-size: $font-size-s;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: auto 0;
    }
}
</style>
