import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:checked', !_ctx.checked))),
    class: _normalizeClass(["check-radio-wrapper", { hasText: !!_ctx.text }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["check-radio", { check: _ctx.checked, disabled: _ctx.disabled }])
    }, null, 2),
    _createElementVNode("div", null, _toDisplayString(_ctx.text), 1)
  ], 2))
}