const escapeRegExp = (input: string): string =>
    input.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&")

export const getParameter = (
    parameterName: string,
    case_insensitive: boolean,
    data: any
) => {
    if (data) {
        if (typeof data === "string") {
            data = JSON.parse(
                '{"' +
                    decodeURI(data)
                        .replace(/"/g, '\\"')
                        .replace(/&/g, '","')
                        .replace(/=/g, '":"') +
                    '"}'
            )
        }

        if (case_insensitive) {
            const lc = parameterName.toLowerCase()
            if (typeof data[lc] != "undefined") return data[lc]
        }
        let result =
            typeof data[parameterName] == "undefined" ? "" : data[parameterName]

        try {
            result = decodeURIComponent(result).replace(/\+/g, " ")
        } catch (e) {
            result = unescape(result).replace(/\+/g, " ")
        }

        return result
    } else {
        parameterName = escapeRegExp(parameterName)
        const re = new RegExp(
            "[?&;]" + escape(parameterName) + "=([^&;]*)",
            case_insensitive ? "i" : ""
        )
        const re2 = new RegExp(
            "[?&;]" + parameterName + "=([^&;]*)",
            case_insensitive ? "i" : ""
        )
        const r: RegExpExecArray | null =
            re.exec(location.search) || re2.exec(location.search)
        if (r) {
            let result: string = ""
            try {
                result = decodeURIComponent(r[1]).replace(/\+/g, " ")
            } catch (e) {
                result = unescape(r[1]).replace(/\+/g, " ")
            }
            return result
        } else {
            return ""
        }
    }
}
