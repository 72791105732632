import { MenuStructure } from "@/interface/MenuStructure"

export const authName = "auth"
export const authStructure: MenuStructure = {
    login: {
        text: "Login",
        name: "login",
        path: "login",
        subMenu: {},
    },
    logout: {
        text: "Logout",
        name: "logout",
        path: "logout",
        subMenu: {},
    },
    request: {
        text: "Request",
        name: "request",
        path: "request-new",
        subMenu: {},
    },
    reset: {
        text: "Reset",
        name: "reset",
        path: "reset-password",
        subMenu: {},
    },
}
