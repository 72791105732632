<template>
    <TextArea
        :label="translate('remarks')"
        v-model:text="store.currentCart.cart.remarks"
    ></TextArea>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import TextArea from "@/ui-elements/input/text-area/TextArea.vue"
import { useI18n } from "vue-i18n"
import { useCartStore } from "@/store/cart/Cart"
export default defineComponent({
    name: "RemarksField",
    components: {
        TextArea,
    },
    setup() {
        const { t: translate } = useI18n()
        const store = useCartStore()
        return {
            translate,
            store,
        }
    },
})
</script>
