import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_SelectComponent = _resolveComponent("SelectComponent")!

  return (_openBlock(), _createBlock(_component_SelectComponent, {
    class: "w-full",
    name: "lang",
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateLocale($event))),
    value: _ctx.locale,
    options: _ctx.localeOptions,
    transparent: _ctx.transparent,
    "can-search": false
  }, {
    icon: _withCtx(() => [
      _createVNode(_component_IconBase, { icon: _ctx.icon }, null, 8, ["icon"])
    ]),
    _: 1
  }, 8, ["value", "options", "transparent"]))
}