import { FlatPickr } from "@/interface/components/FlatPickr"
import { computed, getCurrentInstance, ref, Ref, watch } from "vue"
import { DateTime } from "luxon"
import { EnabledTime } from "@/ui-elements/input/date-time-select/DateTimeSelect.vue"
import { useI18n } from "vue-i18n"
import { Arabic } from "flatpickr/dist/l10n/ar.js"
import { Dutch } from "flatpickr/dist/l10n/nl.js"
import English from "flatpickr/dist/l10n/default.js"
import { French } from "flatpickr/dist/l10n/fr.js"
import { German } from "flatpickr/dist/l10n/de.js"
import { Italian } from "flatpickr/dist/l10n/it.js"
import { Mandarin } from "flatpickr/dist/l10n/zh.js"
import flatpickr from "flatpickr"
import { toTimeFormat } from "@/utils/useRefactorDate"

export const useDateTimeSelect = (
    range: Ref<boolean>,
    enableTime: Ref<boolean>,
    enableDate: Ref<boolean>,
    withSeconds: Ref<boolean>,
    enabledTime: Ref<EnabledTime>,
    enabledDates: Ref<EnabledTime>,
    placeholder: Ref<string>,
    useLast10Years: Ref<boolean>
) => {
    const { locale } = useI18n()
    const flatPickr = ref({}) as Ref<FlatPickr>
    const focused = ref(false)
    const instance = getCurrentInstance()
    const localesMap: Record<string, flatpickr.CustomLocale> = {
        ar: Arabic,
        nl: Dutch,
        en: English,
        fr: French,
        de: German,
        it: Italian,
        "zh-CN": Mandarin,
    }

    const ready = (...args: Array<any>) => {
        flatPickr.value = args[2]
    }

    const dateFormat = computed(() => {
        let timeFormat = "H:i"
        if (withSeconds.value) {
            timeFormat += ":S"
        }

        if (enableDate.value) {
            return enableTime.value ? "Y-m-d " + timeFormat : "Y-m-d"
        }
        return timeFormat
    })

    const getDefaultHour = () => {
        if (enabledTime.value.start) {
            return DateTime.fromISO(enabledTime.value.start).hour
        }
        return 12
    }

    const getDefaultMinute = () => {
        if (enabledTime.value.start) {
            return DateTime.fromISO(enabledTime.value.start).minute
        }
        return 0
    }

    const onOpen = () => {
        focused.value = true
    }

    const onClose = (date: Array<string | Date>) => {
        focused.value = false
        if (!instance) {
            return
        }

        const rangeReturnValue = date.map((d: any) =>
            enableTime.value && !enableDate.value
                ? toTimeFormat(d, withSeconds.value)
                : d
        )
        let returnDateValue = null
        if (date[0]) {
            returnDateValue = enableTime.value
                ? date[0]
                : DateTime.fromJSDate(date[0] as Date).toISODate()
        }
        const returnTimeValue = date[0]
            ? toTimeFormat(date[0], withSeconds.value)
            : ""
        const returnValue = range.value
            ? rangeReturnValue
            : enableTime.value && !enableDate.value
            ? returnTimeValue
            : returnDateValue
        instance.emit("update:date", returnValue)
    }

    const flatPickrLocale = computed(() => localesMap[locale.value] || English)

    const flatpickrConfig = computed(() => ({
        dateFormat: dateFormat.value,
        allowInput: true,
        altInput: true,
        altFormat: dateFormat.value,
        position: "[auto][center]",
        onReady: ready,
        onOpen: onOpen,
        onClose: onClose,
        mode: range.value ? "range" : "single",
        locale: flatPickrLocale.value,
        enableTime: enableTime.value,
        enableSeconds: withSeconds.value,
        noCalendar: !enableDate.value,
        time_24hr: true,
        minTime: enabledTime.value.start,
        maxTime: enabledTime.value.end,
        minDate: enabledDates.value.start,
        maxDate: enabledDates.value.end,
        defaultHour: getDefaultHour(),
        defaultMinute: getDefaultMinute(),
    }))

    const addYearSelect = () => {
        const monthSelector = flatPickr.value.monthNav
        if (!monthSelector) {
            // if month selector isn't there the calendar is time only
            return
        }

        const thisYear = new Date().getFullYear() + 1
        const startYear = useLast10Years.value ? thisYear - 10 : thisYear - 100
        const yearOptions = Array.from(
            Array(thisYear - startYear + 1).keys()
        ).map((year) => year + startYear)

        const yearSelect = document.createElement("select")
        yearSelect.classList.add("yearSelect")
        yearSelect.onchange = (event: Event) => {
            flatPickr.value.currentYear = parseInt(
                (event.target as HTMLInputElement).value
            )
            flatPickr.value.redraw()
        }
        yearOptions.forEach((year) => {
            const option = new Option(
                year + "",
                year + "",
                flatPickr.value.currentYear === year,
                flatPickr.value.currentYear === year
            )
            yearSelect.appendChild(option)
        })
        const oldYear =
            monthSelector.getElementsByClassName("numInputWrapper")[0]
        oldYear.replaceWith(yearSelect)
    }

    watch(flatPickr, () => {
        addYearSelect()
    })

    // trick to update placeholder, it's a known issue that it's not reactive
    // https://github.com/ankurk91/vue-flatpickr-component/issues/148
    watch(placeholder, () => {
        const inputElement = flatPickr.value?.input?.nextSibling
        if (inputElement) {
            inputElement.placeholder = placeholder.value
        }
    })

    return {
        flatpickrConfig,
        focused,
    }
}
