import { computed, Ref, ref, watch } from "vue"
import {
    ObjectWithId,
    SelectedItem,
} from "@/interface/components/SelectedItems"
export type SelectionObject = {
    indeterminate: boolean
    allChecked: boolean
    setAllItems: (value: boolean) => void
}

export function useSelectedItems<Type extends ObjectWithId>(
    items: Ref<Array<Type>>
) {
    const selectedItems: Ref<SelectedItem[]> = ref([])
    const setAll = (value: boolean) => {
        selectedItems.value = selectedItems.value.map((_) => ({
            ..._,
            selected: value,
        }))
    }
    const selection = computed(() => ({
        indeterminate: selectedItems.value
            .map((_) => _.selected)
            .some((_, i, arr) => _ !== arr[0]),
        allChecked:
            !!selectedItems.value.length &&
            selectedItems.value.map((_) => _.selected).every((_) => _),
        setAllItems: (value: boolean) => setAll(value),
    }))
    watch(
        items,
        () => {
            selectedItems.value = items.value.map((_) => ({
                id: _.id,
                selected: false,
            }))
        },
        { immediate: true }
    )
    return {
        selectedItems,
        selection,
    }
}

export const getSelectedIds = (selectedItems: SelectedItem[]) => {
    return selectedItems.reduce<number[]>((ids, selectedItem) => {
        if (selectedItem.selected) {
            return [...ids, Number(selectedItem.id)]
        }
        return ids
    }, [])
}
