import { RouteRecordRaw } from "vue-router"
import { posMenu } from "@/pageStructure/POSStructure"

export const POSRoutes: RouteRecordRaw = {
    path: "/" + posMenu.pos.path,
    meta: { name: posMenu.pos.name },
    component: () => import("@/pages/pos/POSIndex.vue"),
    props: true,
    children: [
        {
            path: "",
            name: posMenu.pos.name,
            meta: { name: posMenu.pos.name },
            component: () => import("@/pages/pos/POSIndex.vue"),
        },
        {
            path: "order/:id",
            meta: { name: posMenu.pos.name },
            component: () => import("@/pages/pos/POSIndex.vue"),
        },
    ],
}
