<template>
    <div class="page-nav" aria-label="Page navigation">
        <SelectComponent
            v-if="!hidePerPage"
            class="per-page-select"
            :row-height="smallerHeight"
            :options="perPageOptions"
            :value="pagination.per_page"
            :can-search="false"
            @update:value="
                $emit('update:pagination', { ...pagination, per_page: $event })
            "
            name="perpage"
            :text="translate('per_page')"
        >
        </SelectComponent>
        <div class="pagination">
            <div
                class="page-navigation page-item"
                :class="{ disabled: pagination.current_page === 1 }"
                @click="changePage(pagination.current_page - 1)"
            >
                <IconBase icon="double-arrow-left"></IconBase>
            </div>
            <div
                class="page-item"
                :class="{ active: pagination.current_page === p, dots: p < 0 }"
                v-for="p in pageList"
                @click="changePage(p)"
                :key="p"
            >
                <template v-if="p > 0">{{ p }}</template>
                <template v-else>...</template>
            </div>
            <div
                class="page-navigation page-item"
                :class="{
                    disabled: pagination.current_page === pagination.last_page,
                }"
                @click="changePage(pagination.current_page + 1)"
            >
                <IconBase icon="double-arrow-right"></IconBase>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import IconBase from "@/ui-elements/IconBase.vue"
import { computed, ref, defineComponent, PropType } from "vue"
import { useI18n } from "vue-i18n"
import { Pagination } from "@/interface/components/Pagination"
import SelectComponent, {
    SelectOption,
} from "@/ui-elements/input/select/SelectComponent.vue"
import { getPerPageOptions } from "@/ui-elements/layout/footer/utils/utils"

export default defineComponent({
    name: "PaginationComponent",
    components: { IconBase, SelectComponent },
    props: {
        pagination: {
            type: Object as PropType<Pagination>,
            required: true,
            validator: (value: any) => {
                const keys = Object.keys(value)
                return (
                    keys.includes("current_page") &&
                    keys.includes("last_page") &&
                    keys.includes("total") &&
                    keys.includes("per_page")
                )
            },
        },
        hidePerPage: Boolean,
        perPageCustomOptions: {
            type: Array as PropType<SelectOption[]>,
        },
    },
    setup(props, context) {
        const { t: translate } = useI18n()
        const pageList = computed(() => {
            const current = props.pagination.current_page
            const last = props.pagination.last_page
            if (last < 5) {
                return Array.from(Array(last), (x, index) => index + 1)
            } else if (current < 3) {
                const start = Array.from(Array(2), (x, index) => index + 1)
                return start.concat([-1, last])
            } else if (current > last - 2) {
                const end = Array.from(Array(2), (x, index) => last - 1 + index)
                return [1, -1].concat(end)
            } else {
                return [1, -1].concat(current).concat([-1, last])
            }
        })
        const perPageOptions: any = ref(
            props.perPageCustomOptions || getPerPageOptions()
        )
        const changePage = (page: number) => {
            if (page > 0 && page <= props.pagination.last_page) {
                context.emit("update:pagination", {
                    ...props.pagination,
                    current_page: page,
                })
            }
        }
        const smallerHeight = 30
        return {
            pageList,
            changePage,
            perPageOptions,
            translate,
            smallerHeight,
        }
    },
    emits: ["update:pagination"],
})
</script>

<style lang="scss">
.page-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: $margin;

    .per-page-select {
        margin-left: $margin;
        height: 2.6rem;
        min-width: unset;

        .input-field {
            width: 9rem;
            margin: 0;
        }
    }

    .pagination {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        column-gap: $margin-s;
    }

    .page-item {
        width: 2rem;
        height: 2rem;
        border-radius: $radius;
        background: $light-gray;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: $quick-transition;

        &:hover {
            transform: scale(1.1);
            background: $dark-gray;
        }
    }

    .page-item.active {
        pointer-events: none;
        cursor: default;
        background: $secondary-color;
        color: $white;
    }

    .page-item.dots {
        width: unset;
        background: none;
    }

    .page-item.dots:hover {
        transform: none;
        cursor: default;
        //background: $light-gray;
    }

    .page-navigation {
        .icon-base {
            margin: 0 $margin-s;
        }

        &.disabled {
            cursor: default;
            pointer-events: none;
            color: $darker-gray;

            .icon-base {
                color: $dark-gray;
            }
        }
    }
}
</style>
