import { ServerDisks, ServerFile } from "@/interface/components/FileBrowser"
import { capitalize } from "vue"

export const getFileNameFormPath = (path: string) => {
    const array = path.split("/")
    return array[array.length - 1]
}
export const makeSrc = (disks: ServerDisks, file: ServerFile) => {
    return disks[file.disk] + "/" + file.path
}

export const createDiskOptions = (disks: ServerDisks) =>
    Object.keys(disks).map((disk) => ({
        value: disk,
        label: capitalize(disk),
    }))
