<template>
    <div class="minus tooltip-button" @click.stop="minus" @mousedown.stop>
        –
    </div>
    <div class="value">
        <div class="val">+ {{ value }}</div>
        <div class="minutes">
            {{ translate("components_brake_delay_minutes") }}
        </div>
    </div>
    <div class="plus tooltip-button" @click.stop="plus" @mousedown.stop>+</div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { useI18n } from "vue-i18n"

export default defineComponent({
    name: "SliderTooltip",
    props: {
        value: Number,
        disabled: Boolean,
    },
    setup(props, context) {
        const { t: translate } = useI18n()
        const minus = () => {
            if (!props.disabled) {
                context.emit("minus")
            }
        }
        const plus = () => {
            if (!props.disabled) {
                context.emit("plus")
            }
        }
        return {
            translate,
            minus,
            plus,
        }
    },
    emits: ["minus", "plus"],
})
</script>

<style lang="scss">
.vue-slider-dot-tooltip {
    display: flex;
    bottom: -0.75rem;
    align-items: center;

    .tooltip-button {
        color: $white;
        background: $secondary-color;
        border-radius: $radius;
        width: 2rem;
        height: 2rem;
        font-size: $font-size-h2;
        margin: 0 $margin-xs;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .value {
        border-radius: $radius;
        padding: 0 $padding-s;
        height: 3rem;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        background: $light-gray;
        cursor: grab;
    }

    .val {
        font-size: $font-size-h4;
        line-height: 1.15rem;
    }

    .minutes {
        font-size: $font-size-nav;
        line-height: 0.85rem;
    }
}
.vue-slider-dot-disabled {
    .tooltip-button,
    .value {
        cursor: not-allowed;
    }
}
</style>
