import { RoutesConfig, RouteType } from "@/pages/settings/utils/RoutesConfig"
import { RouteRecordName, RouteRecordRaw } from "vue-router"
import { flatten } from "lodash"

const MAX_DEPTH = 2

export const buildRoutes = (config: RoutesConfig) => {
    const routes: RouteRecordRaw[] = Object.keys(config).map((sectionId) => {
        const groups = config[sectionId].children || {}

        const sectionGroupRoutes = Object.keys(groups).map((groupId) => {
            const subGroups: any = groups[groupId].children || {}
            return Object.keys(subGroups).map((subGroupId) => {
                return buildRoute(
                    subGroups[subGroupId],
                    subGroupId,
                    sectionId,
                    sectionId
                )
            })
        })

        const sectionRoutes = flatten(sectionGroupRoutes)

        return {
            ...buildRoute(
                { ...config[sectionId], children: undefined },
                sectionId,
                sectionId
            ),
            component: () =>
                import("@/pages/settings/layout/SettingsLayout.vue"),
            children: sectionRoutes,
            redirect: getRedirect(sectionRoutes),
        }
    })

    return flatten(routes)
}

const buildRoute = (
    item: RouteType,
    itemId: string,
    sectionName: string,
    parentName: RouteRecordName = "",
    withComponent = false,
    depth = 1
) => {
    const route: RouteRecordRaw = {
        path: item.path || "",
        name: itemId,
        meta: {
            name: sectionName,
            subName: parentName,
            subSubName: "parent" in item && item.parent ? item.parent : itemId,
            ...item.meta,
            id: itemId,
            title: item.title,
        },
        children: [],
        component: () => {
            return withComponent
                ? item.component || import("@/pages/settings/form/Form.vue")
                : import(
                      /* webpackPrefetch: true */ "@/pages/settings/layout/BasicSettingsLayout.vue"
                  )
        },
    }

    if (depth >= MAX_DEPTH) {
        return route
    }

    if ("children" in item && item.children) {
        route.children = Object.keys(item.children).map((pageId: any) => {
            return buildRoute(
                item.children![pageId],
                pageId,
                sectionName,
                itemId,
                true,
                depth++
            )
        })
        route.redirect = getRedirect(route.children)
    }
    return route
}

const getRedirect = (children: RouteRecordRaw[]) => {
    const grandChild = children[0].children?.length
        ? children[0].children[0]
        : null

    return grandChild && grandChild.redirect
        ? grandChild.redirect
        : {
              name: children[0].name,
          }
}
