<template>
    <PopUpModal
        show
        :title="translate('menu_close_modal_title')"
        @close="$emit('close')"
        @submit="$emit('confirm')"
        class="confirm-close"
        :submitButtonText="translate('close')"
    >
        {{ translate("menu_close_modal_content") }}
    </PopUpModal>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import PopUpModal from "@/ui-elements/layout/modals/PopUpModal.vue"
import { useI18n } from "vue-i18n"
export default defineComponent({
    name: "CloseConfirmationModal",
    components: {
        PopUpModal,
    },
    setup() {
        const { t: translate } = useI18n()
        return {
            translate,
        }
    },

    emits: ["close", "confirm"],
})
</script>

<style lang="scss"></style>
