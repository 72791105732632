<template>
    <TextField
        :label="translate('discount_code')"
        v-model:text="discount"
        :disabled="disabled"
        :error="!!errorMessage"
        :message="errorMessage"
        :icon="discountValid ? 'check' : ''"
    ></TextField>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue"
import { useI18n } from "vue-i18n"
import TextField from "@/ui-elements/input/text-field/TextField.vue"
import { useCartStore } from "@/store/cart/Cart"
import { CartDiscount } from "@/interface/Cart"
import { discountIsAvailable } from "orderbuddy-calculations/src/helpers/discountIsAvailable"
import { useCartDiscountsStore } from "@/store/CartDiscount"
export default defineComponent({
    name: "DiscountCode",
    components: {
        TextField,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { t: translate } = useI18n()
        const store = useCartStore()
        const discountsStore = useCartDiscountsStore()
        const errorMessage = ref("")

        const getCode = (discounts: CartDiscount[]) => {
            if (discounts.length) {
                const discountsWithCode = discounts.filter(
                    (d) => d.type === "code"
                )
                if (discountsWithCode.length && discountsWithCode[0].code) {
                    return discountsWithCode[0].code
                }
            }
            return ""
        }

        const discount = ref(
            "discounts" in store.currentCart.cart &&
                store.currentCart.cart.discounts &&
                store.currentCart.cart.discounts.length
                ? getCode(store.currentCart.cart.discounts)
                : ""
        )

        watch(discount, () => saveCode(discount.value))

        const saveCode = (code: string) => {
            let discountCode = code

            if (!validateDiscount()) {
                // empty discount, so we don't have the previous one here
                discountCode = ""
            }

            if (store.currentCart.cart.discounts.length) {
                store.currentCart.cart.discounts.forEach((d, i) => {
                    if (d.type === "code") {
                        if (discountCode === "") {
                            store.currentCart.cart.discounts.splice(i, 1)
                        } else {
                            d.code = discountCode
                        }
                    }
                })
            } else {
                if (discountCode !== "") {
                    store.currentCart.cart.discounts.push({
                        type: "code",
                        code: discountCode,
                    })
                }
            }
            store.updateCart()
        }

        const validateDiscount = () => {
            if (!discount.value) {
                errorMessage.value = ""
                return true
            }
            const existingDiscount =
                discountsStore.discounts.simple.find((compareDiscount) =>
                    compareDiscount.codes.includes(discount.value)
                ) ||
                discountsStore.discounts.free_product.find((compareDiscount) =>
                    compareDiscount.codes.includes(discount.value)
                ) ||
                discountsStore.discounts.discounted_product.find(
                    (compareDiscount) =>
                        compareDiscount.codes.includes(discount.value)
                )

            if (!existingDiscount) {
                errorMessage.value = translate(
                    "pos_modal_customer_data_discount_notFound"
                )
                return
            }
            if (!discountIsAvailable(existingDiscount)) {
                errorMessage.value = translate(
                    "pos_modal_customer_data_discount_notValid"
                )
                return
            }
            errorMessage.value = ""
            return true
        }
        const discountValid = computed(
            () => errorMessage.value === "" && discount.value !== ""
        )

        return {
            translate,
            store,
            discount,
            errorMessage,
            saveCode,
            discountValid,
        }
    },
})
</script>
