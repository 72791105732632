<template>
    <div class="message-item" :class="{ selected: selected, 'is-new': isNew }">
        <div class="message-date">
            <div class="dot">
                <div v-if="selected"></div>
            </div>
            <div class="date">
                <div class="day">{{ date[0] }}</div>
                <div class="month">{{ date[1] }}</div>
            </div>
        </div>
        <div class="message-subject">
            <div class="subject">{{ message.subject }}</div>
            <div class="msgbox-message">
                {{ stripHtmlTags(message.message) }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue"
import { MessageObject } from "@/interface/message-box/MessageObject"
import { toDateMonthFormat } from "@/utils/useRefactorDate"

export default defineComponent({
    name: "MessageSingle",
    props: {
        message: {
            type: Object as PropType<MessageObject>,
            default: () => {},
        },
        selected: Boolean,
        isNew: Boolean,
    },
    setup(props) {
        const stripHtmlTags = (text: string) =>
            text.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp;/g, " ")

        const date = computed(() => {
            const array = toDateMonthFormat(props.message.created_at).split(" ")
            if (array.length !== 2) {
                return ["", ""]
            } else {
                return array
            }
        })

        return {
            toDateMonthFormat,
            stripHtmlTags,
            date,
        }
    },
})
</script>

<style scoped lang="scss">
.message-item {
    padding: $padding-m $padding-m $padding-m $padding-s;
    display: flex;
    min-width: 0;
    column-gap: $padding-m;
    align-items: center;
    cursor: pointer;

    .message-date {
        display: flex;
        justify-content: center;
        position: relative;
        padding: 0 $padding-m;
        .dot {
            position: absolute;
            display: flex;
            align-items: center;
            height: 100%;
            left: 0;
            div {
                width: 0.4rem;
                height: 0.4rem;
                flex-shrink: 0;
                border-radius: 0.2rem;
                background: $primary-color;
            }
        }
        .date {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
        }
    }

    .message-subject {
        min-width: 0;
        border-left: $message-right-border;
        padding-left: $padding-m;

        .subject {
            max-width: 100%;
            padding-bottom: $padding-s;
        }

        .msgbox-message {
            font-size: $font-size-nav;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &.selected {
        background: $lightest-gray;
        border-radius: $radius;
        cursor: auto;
    }

    &.is-new {
        .subject {
            font-weight: $font-weight-medium;
        }
    }
}
</style>
