import { DispatchType } from "@/interface/Cart"
import { useSettingsStore } from "@/store/Settings"

/** Used to set dispatch type initially, so before opening the customer's modal **/
export const getDefaultDispatchType = (): DispatchType => {
    const settingsStore = useSettingsStore()
    const setting = settingsStore.settings.pos_def_delivery as DispatchType
    let dispatchType = DispatchType.PickUp

    if (setting === DispatchType.DeliverOnly) {
        dispatchType = DispatchType.Deliver
    }
    return dispatchType
}

/** This function is used for when the customer modal is opened **/
export const getInitialDispatchType = (): DispatchType => {
    const settingsStore = useSettingsStore()
    const setting = (settingsStore.settings.pos_def_delivery ??
        DispatchType.Deliver) as DispatchType
    let dispatchType

    switch (setting) {
        case DispatchType.DeliverOnly:
            dispatchType = DispatchType.Deliver
            break

        case DispatchType.PickUpOnly:
        case DispatchType.None:
            dispatchType = DispatchType.PickUp
            break

        default:
            dispatchType = setting
            break
    }

    return dispatchType
}
