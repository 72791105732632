import { settingsName } from "@/pageStructure/SettingsStructure"
import { RouteRecordRaw } from "vue-router"

export const SettingsRoutes: RouteRecordRaw = {
    path: "/settings/",
    name: settingsName,
    component: () =>
        import(
            /* webpackPrefetch: true */
            "@/pages/settings/layout/BasicSettingsLayout.vue"
        ),
    redirect: {
        name: "settings/overview",
    },
    children: [
        {
            path: "overview",
            name: "settings/overview",
            meta: { name: "settings/overview" },
            component: () =>
                import("@/pages/settings/overview/SettingsOverview.vue"),
        },
    ],
}
