import { defineStore } from "pinia"
import { Customer } from "@/interface/Customers/Customer"
import axios, { Canceler } from "axios"
import qs from "qs"
import { CustomerAddress } from "@/interface/Customers/CustomerAddress"
import { useCartStore } from "@/store/cart/Cart"
import { DispatchType } from "@/interface/Cart"
import { requiredFields } from "@/pages/pos/modal/customer/data/useCustomerValidation"
import { multiLocationStore } from "@/store/MultiLocation"

type CustomerModalState = {
    search: string
    results: Array<Customer>
    showModal: boolean
    customerSet: boolean
    isCallerId: boolean
    cancelRequest: Canceler | undefined
    dispatchTypeSet: boolean
    doValidation: boolean
}

export const useCustomerModalStore = defineStore({
    id: "customer-modal",
    state: () =>
        ({
            showModal: false,
            search: "",
            results: [],
            customerSet: false,
            isCallerId: false,
            cancelRequest: undefined,
            // if false - we need to change dispatch type to
            // settings.pos_def_delivery value when open modal
            dispatchTypeSet: false,
            doValidation: false,
        } as CustomerModalState),
    actions: {
        async fetchResults(search: string, field = "") {
            if (this.cancelRequest) {
                this.cancelRequest()
            }
            return axios
                .get(
                    `/client/customers${
                        search !== null && field !== "" ? "/selection" : ""
                    }`,
                    {
                        params: {
                            per_page: 5,
                            filter: {
                                [field]: search,
                            },
                            main_location_id: multiLocationStore().isActive
                                ? multiLocationStore().main_location_id
                                : 0,
                        },
                        paramsSerializer: (params) => {
                            return qs.stringify(params, { skipNulls: true })
                        },
                        cancelToken: new axios.CancelToken((c) => {
                            this.cancelRequest = c
                        }),
                    }
                )
                .then((response) => (this.results = response.data.data))
                .catch((error) => {
                    if (!axios.isCancel(error)) {
                        console.warn("Error while searching customers.")
                    }
                })
        },
    },
    getters: {
        hasValidAddress() {
            const cartStore = useCartStore()
            if (cartStore.currentCart.cart.type !== DispatchType.Deliver) {
                return true
            } else {
                return requiredFields.every(
                    (key) => !!cartStore.customer.address[key]
                )
            }
        },
    },
})
