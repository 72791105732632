<template>
    <div class="wrapper" :class="appearance">
        <div class="accordion" @click="onClick" :class="{ open: showContent }">
            <div class="title" :class="{ 'selected-title': showContent }">
                <slot name="title"></slot>
            </div>
            <IconBase
                class="chevron-icon"
                :icon="showContent ? 'chevron-up' : 'chevron-down'"
            ></IconBase>
        </div>
        <div v-show="showContent" class="content">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue"
import IconBase from "@/ui-elements/IconBase.vue"

export default defineComponent({
    name: "AccordionComponent",
    components: {
        IconBase,
    },
    props: {
        isOpened: {
            type: Boolean,
            default: undefined,
        },
        appearance: {
            type: String as PropType<"minimal" | "default">,
            default: "default",
        },
    },
    setup(props, context) {
        const isInnerOpened = ref(false)

        const showContent = computed(() => {
            return props.isOpened !== undefined
                ? props.isOpened
                : isInnerOpened.value
        })

        const onClick = (e: Event) => {
            context.emit("click", e)

            if (e.defaultPrevented) {
                return
            }

            if (props.isOpened === undefined) {
                isInnerOpened.value = !isInnerOpened.value
            }
        }

        return {
            showContent,
            onClick,
        }
    },
    emits: ["click"],
})
</script>

<style scoped lang="scss">
.wrapper {
    &.default {
        margin-top: $margin-s;
        border-radius: $radius;

        .accordion {
            padding: $padding-s $padding;
            background: $lightest-gray;
        }

        .content {
            padding: $padding-s $padding;
            background: $lightest-gray;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                border-top: $normal-border;
                width: calc(100% - 2 * #{$margin});
                top: 0;
                padding: 0 $margin;
            }
        }
    }

    &.minimal {
        .content {
            padding: 0 0 $padding-s 0;
        }
    }

    .accordion {
        cursor: pointer;
        display: flex;
        align-items: center;
        line-height: 1rem;
        border-radius: $radius;

        &.open {
            border-radius: $radius $radius 0 0;
        }

        .chevron-icon {
            font-size: $font-size-nav;
        }
    }

    .title {
        margin: 0;
        width: 100%;

        &.selected-title {
            font-weight: $font-weight-medium;
        }
    }

    .content {
        border-radius: 0 0 $radius $radius;
        align-items: center;
    }
}

@media only screen and (max-width: $screen-mobile) {
    .menu-list {
        .wrapper {
            .accordion {
                gap: 1rem !important;
                padding: 0.5rem 1rem;
                background: transparent !important;
                width: 97vw;
            }

            .content {
                padding: 1rem 1rem;
                background: transparent;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 1rem;

                .accordion {
                    width: 91vw;
                }
            }

            .title {
                font-size: 1.5rem;
            }

            .icon-base {
                font-size: 1.2rem !important;
            }
        }
    }

    :deep(.content) {
        overflow-y: hidden;

        .columns {
            grid-template-columns: repeat(8, 3fr) !important;
        }
    }
}
</style>
