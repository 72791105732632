<template>
    <div class="time-adjust">
        <div
            class="minus"
            @mousedown="changeTime(-1, 5)"
            ref="minusButton"
            @mouseup="longPressMinus = false"
        >
            -
        </div>
        <div class="hour">{{ formatLeadingZero(refactoredTime.hours) }}</div>
        <div class="separator">:</div>
        <div class="minutes">
            {{ formatLeadingZero(refactoredTime.minutes) }}
        </div>
        <div
            class="plus"
            @mousedown="changeTime(1, 5)"
            ref="plusButton"
            @mouseup="longPressPlus = false"
        >
            +
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, Ref, ref, watch } from "vue"
import { formatLeadingZero } from "@/utils/useLeadingZero"
import { onLongPress } from "@vueuse/core"
import { fromUTC, toUTC } from "@/utils/useTimeShift"
export default defineComponent({
    name: "TimeAdjust",
    props: {
        time: {
            type: String,
            required: true,
        },
    },
    setup(props, context) {
        const plusButton = ref()
        const minusButton = ref()
        const refactorTime = (time: string | undefined) => {
            if (time) {
                const split = time.split(":")
                if (split.length >= 2) {
                    return {
                        hours: parseInt(split[0]),
                        minutes: parseInt(split[1]),
                    }
                }
            }
            console.warn("TimeAdjust: Time prop has wrong form.")
            return {
                hours: 0,
                minutes: 0,
            }
        }
        const refactoredTime = computed(() => refactorTime(props.time))
        const update = (hours: number, minutes: number) => {
            context.emit(
                "update:time",
                formatLeadingZero(hours) + ":" + formatLeadingZero(minutes)
            )
        }
        const changeTime = (dir: -1 | 1, amount: number) => {
            const newMinutes = refactoredTime.value.minutes + dir * amount
            if (newMinutes >= 60) {
                update((refactoredTime.value.hours + 1) % 24, newMinutes % 60)
            } else if (newMinutes >= 0) {
                update(refactoredTime.value.hours, newMinutes)
            } else if (newMinutes < 0) {
                update((refactoredTime.value.hours - 1) % 24, 60 + newMinutes)
            }
        }
        const longPressMinus = ref(false)
        const longPressPlus = ref(false)
        onLongPress(minusButton, () => (longPressMinus.value = true), {
            delay: 200,
        })
        onLongPress(plusButton, () => (longPressPlus.value = true), {
            delay: 200,
        })
        const repeatChange = (
            longPressRef: Ref<boolean>,
            counterRef: Ref<number>,
            dir: -1 | 1
        ) => {
            if (counterRef.value < 600) {
                if (longPressRef.value && counterRef.value < 4) {
                    setTimeout(() => {
                        changeTime(dir, 5)
                        counter.value++
                        repeatChange(longPressRef, counterRef, dir)
                    }, 400)
                } else if (longPressRef.value) {
                    setTimeout(() => {
                        changeTime(dir, 5)
                        counter.value++
                        repeatChange(longPressRef, counterRef, dir)
                    }, 100)
                } else {
                    counterRef.value = 0
                }
            }
        }
        const counter = ref(0)
        watch(longPressMinus, () => repeatChange(longPressMinus, counter, -1))
        watch(longPressPlus, () => repeatChange(longPressPlus, counter, 1))

        return {
            refactoredTime,
            changeTime,
            formatLeadingZero,
            plusButton,
            minusButton,
            longPressMinus,
            longPressPlus,
        }
    },
})
</script>

<style lang="scss" scoped>
.time-adjust {
    display: flex;
    justify-content: center;
    gap: $margin-s;
    padding: $padding 0;

    & > div {
        font-size: 3rem;
        cursor: default;
        user-select: none;
    }
    .minus,
    .hour,
    .minutes,
    .plus {
        height: 6rem;
        width: 6rem;
        border: $important-border;
        border-radius: $radius;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .minus,
    .plus {
        background: $secondary-color;
        color: white;
        cursor: pointer;
    }
    .separator {
        height: 6rem;
        display: flex;
        align-items: center;
    }
}
</style>
