import { DateTime } from "luxon"
import { computed } from "vue"
import { useUserStore } from "@/store/User"

export const useWorkingDay = () => {
    const userStore = useUserStore()

    const startOfWorkingDay = computed(() => {
        return DateTime.fromISO(userStore.user.start_of_working_day)
    })

    const endOfWorkingDay = computed(() => {
        return DateTime.fromISO(userStore.user.end_of_working_day)
    })

    return {
        startOfWorkingDay,
        endOfWorkingDay,
    }
}
