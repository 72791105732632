import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "for-defs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVGDefs = _resolveComponent("SVGDefs")!
  const _component_MapControls = _resolveComponent("MapControls")!

  return (_openBlock(), _createElementBlock("div", {
    class: "route-map",
    id: _ctx.mapId
  }, [
    (_openBlock(), _createElementBlock("svg", _hoisted_2, [
      _createVNode(_component_SVGDefs)
    ])),
    _createVNode(_component_MapControls, {
      map: _ctx.mapRef,
      bounds: _ctx.coordinateBounds
    }, null, 8, ["map", "bounds"])
  ], 8, _hoisted_1))
}