<template>
    <div class="route-map" :id="mapId">
        <svg class="for-defs">
            <SVGDefs></SVGDefs>
        </svg>
        <MapControls :map="mapRef" :bounds="coordinateBounds"></MapControls>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, watch } from "vue"
import "leaflet/dist/leaflet.css"
import L from "leaflet"
import { MapControls, SVGDefs } from "@/ui-elements"
import { useMap } from "@/utils/Map/useMap"
import {
    drawCross,
    drawDelivererMarker,
    drawFoodTicketMarker,
} from "@/utils/Map/useMapMarkers"
import {
    boundsOfCoordinates,
    centerOfCoordinates,
    coordinateToLatLng,
} from "@/utils/useCoordinate"
import { useUserStore } from "@/store/User"
import {
    OrderFeature,
    OrderFeatureCollection,
} from "@/interface/orders/OrdersMap"
import { Deliverer } from "@/interface/deliverer/Deliverer"
import { useOrdersStore } from "@/store/Orders"
type EntityCoordinate = { id: number; coord: number[] }

export default defineComponent({
    name: "OrdersMap",
    components: {
        MapControls,
        SVGDefs,
    },
    props: {
        ordersGeoJSON: {
            type: Object as PropType<OrderFeatureCollection>,
        },
        ordersCoordinates: {
            type: Array as PropType<EntityCoordinate[]>,
            required: true,
        },
        deliverers: {
            type: Array as PropType<(Deliverer & { n: number })[]>,
            required: true,
        },
        highlight: {
            type: Number,
            required: true,
        },
    },
    setup(props, context) {
        const deliverersCoordinates = computed(() =>
            props.deliverers
                .filter((deliverer) => !!deliverer.coordinates)
                .map((deliverer) => ({
                    id: deliverer.id,
                    coord: deliverer.coordinates.coordinates,
                }))
        )
        const concatenatedCoordinates = computed(() =>
            deliverersCoordinates.value
                .concat(props.ordersCoordinates)
                .concat([
                    {
                        id: 0,
                        coord: useUserStore().user.coordinates.coordinates,
                    },
                ])
                .map((entity) => entity.coord)
        )
        const centerCoordinate = computed(() =>
            coordinateToLatLng(
                centerOfCoordinates(
                    concatenatedCoordinates.value,
                    useUserStore().user.coordinates.coordinates
                )
            )
        )
        const coordinateBounds = computed(
            () =>
                concatenatedCoordinates.value.length > 0
                    ? boundsOfCoordinates(concatenatedCoordinates.value)
                    : L.latLngBounds([centerCoordinate.value]) // Replace with center if bounds cannot be calculated
        )

        const mapId = "mapContainer"

        const { defaultZoom, map: mapRef, addTiles, setMap } = useMap(mapId)
        defaultZoom.value = 13

        const orderMarkers = {} as { [id: string]: L.Marker }
        const geoJSON = L.geoJSON(undefined, {
            pointToLayer(geoJsonPoint: OrderFeature, latlng: L.LatLng) {
                const orderIcon = L.divIcon({
                    iconSize: [32, 32],
                    className: "order",
                    html:
                        '<div class="inner">' +
                        (geoJsonPoint.properties.refnr ||
                            useOrdersStore().getOrderLetter(
                                geoJsonPoint.properties.id
                            )) +
                        "</div>",
                })
                const marker = L.marker(latlng, { icon: orderIcon })
                marker.on("mouseover", () => {
                    context.emit("hover", geoJsonPoint.properties.id)
                })
                marker.on("mouseout", () => {
                    context.emit("hover", 0)
                })
                orderMarkers[geoJsonPoint.properties.id] = marker
                return marker
            },
        })
        const addOrderMarkers = () => {
            Object.keys(orderMarkers).forEach((markerId) => {
                orderMarkers[markerId].remove()
                delete orderMarkers[markerId]
            })

            if (props.ordersGeoJSON && mapRef.value) {
                geoJSON.addData(props.ordersGeoJSON).addTo(mapRef.value)
            }
        }
        const delivererMarkers = {} as Record<string, L.Marker>
        const drawDeliverers = () => {
            Object.keys(delivererMarkers).forEach((markerId) => {
                delivererMarkers[markerId].remove()
                delete delivererMarkers[markerId]
            })
            deliverersCoordinates.value.forEach((deliverer) => {
                const marker = drawDelivererMarker(deliverer.coord)
                marker.on("mouseover", () => {
                    context.emit("hover", deliverer.id)
                })
                marker.on("mouseout", () => {
                    context.emit("hover", 0)
                })
                delivererMarkers[deliverer.id] = marker
            })
            if (mapRef.value) {
                L.layerGroup(Object.values(delivererMarkers)).addTo(
                    mapRef.value
                )
            }
        }

        const drawRestaurantMarker = () => {
            const marker = drawFoodTicketMarker(
                useUserStore().user.coordinates.coordinates
            )

            if (mapRef.value) {
                marker.addTo(mapRef.value)
            }
        }

        const setupMap = () => {
            setMap(centerCoordinate.value, coordinateBounds.value)
            if (mapRef.value) {
                addTiles()
                drawCross(
                    mapRef.value,
                    useUserStore().user.coordinates.coordinates //[52.35, 4.9]
                )
                addOrderMarkers()
                drawDeliverers()
                drawRestaurantMarker()
            }
        }
        const updateOrderMarkers = (selectedId: number) => {
            Object.keys(orderMarkers).forEach((markerId) => {
                const id = parseInt(markerId)
                orderMarkers[id].getElement()?.classList.remove("hover")
                if (selectedId === id) {
                    orderMarkers[id].getElement()?.classList.add("hover")
                }
            })
        }
        const updateDelivererMarkers = (selectedId: number) => {
            Object.keys(delivererMarkers).forEach((markerId) => {
                const id = parseInt(markerId)
                delivererMarkers[id].getElement()?.classList.remove("hover")
                if (selectedId === id) {
                    delivererMarkers[id].getElement()?.classList.add("hover")
                }
            })
        }

        watch(
            () => props.highlight,
            () => {
                updateOrderMarkers(props.highlight)
                updateDelivererMarkers(props.highlight)
            }
        )
        watch(
            () => props.ordersGeoJSON,
            () => {
                addOrderMarkers()
                drawDeliverers()
            }
        )
        watch(
            () => props.deliverers,
            () => {
                drawDeliverers()
            }
        )

        onMounted(() => {
            setupMap()
        })
        return {
            mapId,
            mapRef,
            coordinateBounds,
            defaultZoom,
            concatenatedCoordinates,
            centerCoordinate,
        }
    },
    emits: ["hover"],
})
</script>
