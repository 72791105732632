//error false //else true
//todos check if is kios and if has field inv_company empty
//if empty not sed else is already sent
//then send order to backend to be updated with inv_company field not empty

import { useOrdersStore } from "@/store/Orders"
import Micro from "@/utils/external-APIs/sot/micro/micro"
import { useSettingsStore } from "@/store/Settings"
import Novacom from "@/utils/external-APIs/sot/novacom/novacom"

export const sendKiosOrdersToExternalAPI = () => {
    return new Promise<boolean>((resolve) => {
        const ordersStore = useOrdersStore()
        const settingsStore = useSettingsStore()
        if (settingsStore.settings.micros_active) {
            ordersStore.orders.forEach((order) => {
                if (order.source.type === "kiosk" && order.inv_company) {
                    new Micro().microsPushOrder(
                        order,
                        (result: { status: string; message: any }) => {
                            if (
                                result &&
                                (result.status == "200" ||
                                    result.status == "204")
                            ) {
                                console.log(
                                    "",
                                    "Order " +
                                        order.id +
                                        " successfully pushed to Micros POS\n",
                                    "success"
                                )
                                ordersStore.updateOrder(order.id, {
                                    inv_company: "mirocPushOrder",
                                })
                                resolve(true)
                            } else {
                                console.log(
                                    "Oops...",
                                    "Could not push order " +
                                        order.id +
                                        " to Micros POS\n\n" +
                                        (result
                                            ? result.message
                                            : "Unknown error"),
                                    "error"
                                )
                                resolve(false)
                            }
                        }
                    )
                } else {
                    resolve(false)
                }
            })
        }
        if (settingsStore.settings.novacom_active) {
            ordersStore.orders.forEach((order) => {
                if (order.source.type === "kiosk" && order.inv_company) {
                    new Novacom().novacomPushOrder(order, (result) => {
                        if (
                            result &&
                            (result.status == "200" || result.status == "204")
                        ) {
                            console.log(
                                "",
                                "Order " +
                                    order.id +
                                    " successfully pushed to Novacom POS\n",
                                "success"
                            )
                            ordersStore.updateOrder(order.id, {
                                inv_company: "novacomPushOrder",
                            })
                            resolve(true)
                        } else {
                            console.log(
                                "Oops...",
                                "novacomPushOrder(): could not push order " +
                                    order.id +
                                    "\n\n" +
                                    (result ? result.message : "Unknown error"),
                                "error"
                            )
                            resolve(false)
                        }
                    })
                } else {
                    resolve(false)
                }
            })
        }
        resolve(false)
    })
}

export const novacomRetryPushOrder = (id: number) => {
    return new Promise<boolean>((resolve) => {
        const ordersStore = useOrdersStore()
        ordersStore.orders.forEach((order) => {
            if (order.source.type === "kiosk" && order.id === id) {
                new Novacom().novacomPushOrder(order, (result) => {
                    if (
                        result &&
                        (result.status == "200" || result.status == "204")
                    ) {
                        console.log(
                            "",
                            "Order " +
                                order.id +
                                " successfully retried and pushed to Novacom POS\n",
                            "success"
                        )
                        ordersStore.updateOrder(order.id, {
                            inv_company: "novacomPushOrder",
                        })
                        resolve(true)
                    } else {
                        console.log(
                            "Oops...",
                            "novacomPushOrder(): could not retry and push order " +
                                order.id +
                                "\n\n" +
                                (result ? result.message : "Unknown error"),
                            "error"
                        )
                        resolve(false)
                    }
                })
            }
        })
    })
}

export const microsRetryPushOrder = async (id: number) => {
    return new Promise<boolean>((resolve) => {
        const ordersStore = useOrdersStore()
        ordersStore.orders.forEach((order) => {
            if (order.source.type === "kiosk" && order.id === id) {
                new Micro().microsPushOrder(
                    order,
                    (result: { status: string; message: any }) => {
                        if (
                            result &&
                            (result.status == "200" || result.status == "204")
                        ) {
                            console.log(
                                "",
                                "Order " +
                                    id +
                                    " successfully pushed to Micros POS\n",
                                "success"
                            )
                            resolve(true)
                        } else {
                            console.log(
                                "Oops...",
                                "Could not push order " +
                                    id +
                                    " to Micros POS\n\n" +
                                    (result ? result.message : "Unknown error"),
                                "error"
                            ) // show msg...
                            resolve(false)
                        }
                    }
                )
            }
        })
    })
}
