import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b10593a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "announcement-text" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "link-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_ButtonBase = _resolveComponent("ButtonBase")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["announcement", _ctx.type])
  }, [
    _createVNode(_component_IconBase, {
      icon: _ctx.iconName,
      class: _normalizeClass(_ctx.type)
    }, null, 8, ["icon", "class"]),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.announcementData.text), 1),
    (_ctx.announcementData.link)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.announcementData.link), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showClose)
      ? (_openBlock(), _createBlock(_component_ButtonBase, {
          key: 1,
          class: "close",
          variant: "text",
          name: "announcement-close",
          icon: "delete"
        }))
      : _createCommentVNode("", true)
  ], 2))
}