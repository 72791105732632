<template>
    <component
        :is="errorComponent"
        v-bind="errorComponentConfig"
        class="input-field-wrapper"
        :class="{ disabled }"
    >
        <LabelComponent
            v-if="label || labelIcon"
            :label="label"
            :label-icon="labelIcon"
            :label-icon-class="labelIconClass"
        />
        <div
            class="input-field"
            :class="{
                disabled: disabled,
                error: error || !!errorMessage.length,
                success: success,
                transparent: transparent,
                focus: focus,
            }"
        >
            <div class="border">
                <div class="visual" v-if="$slots.visual">
                    <slot name="visual"></slot>
                </div>
                <slot name="input"></slot>
            </div>
            <template v-if="!tooltipError">
                <div
                    class="validation-message"
                    :class="{ error: error || !!errorMessage.length }"
                    v-if="!!$slots.message || !!errorMessage.length"
                >
                    <template v-if="errorMessage">
                        <p v-for="(message, i) in errorMessage" :key="i">
                            {{ message }}
                        </p>
                    </template>
                    <slot name="message"></slot>
                </div>
            </template>
        </div>
    </component>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue"
import { useFormErrors } from "@/utils/useFormErrors"
import LabelComponent from "@/ui-elements/LabelComponent.vue"
import Tooltip from "@/ui-elements/Tooltip.vue"

export default defineComponent({
    name: "InputField",
    components: {
        Tooltip,
        LabelComponent,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        success: {
            type: Boolean,
            default: false,
        },
        focus: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
        },
        name: {
            type: [Array, String] as PropType<string | string[]>,
            default: () => [],
        },
        labelIcon: {
            type: String,
        },
        labelIconClass: {
            type: String,
        },
        tooltipError: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { getErrorByName } = useFormErrors()

        const errorMessage = computed(() => {
            if (!props.name) {
                return []
            }

            let messages = []
            if (typeof props.name === "object") {
                messages = props.name
                    .map((name) => getErrorByName(name))
                    .flat(1)
            } else {
                messages = [getErrorByName(props.name)]
            }

            return messages.filter((message) => !!message)
        })

        const errorComponent = computed(() =>
            props.tooltipError && errorMessage.value.length ? "Tooltip" : "div"
        )

        const errorComponentConfig = computed(() => {
            if (props.tooltipError) {
                return {
                    hover: true,
                    arrow: true,
                    content: errorMessage.value.join(" "),
                    options: {
                        modifiers: {
                            preventOverflow: { escapeWithReference: true },
                        },
                    },
                }
            } else {
                return undefined
            }
        })

        return {
            errorComponentConfig,
            errorMessage,
            errorComponent,
        }
    },
})
</script>

<style lang="scss">
.input-field-wrapper.disabled {
    .label-component {
        color: $dark-gray;
    }
}
.input-field {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;

    & > .border {
        border: $normal-border;
        border-radius: $radius;
        background: $white;
        height: 2.75rem;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .visual {
        display: flex;
        align-items: center;
    }

    .validation-message {
        margin-top: $margin-s;
        &:empty {
            display: none;
        }
    }

    &.disabled {
        .border {
            pointer-events: none;
            cursor: default;
            background: $lighter-gray;
        }
    }

    &.focus .border {
        border: $important-border;
    }

    &.error {
        .border {
            border: $error-border;
        }

        .validation-message {
            color: $error-color;
        }
    }

    &.success .border {
        border: $success-border;
    }

    &.transparent {
        background: transparent;
        .border {
            background: transparent;
            border-color: $white;
        }

        div,
        span,
        .options-list .option span {
            color: $white;
        }
    }
}
</style>
