import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DotsLoader = _resolveComponent("DotsLoader")!
  const _component_IconBase = _resolveComponent("IconBase")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.url && _ctx.isExternalUrl ? 'a' : 'button'), {
    href: _ctx.url ? _ctx.url : undefined,
    class: _normalizeClass(["button", {
            disabled: _ctx.disabled || _ctx.loading,
            [_ctx.variant]: _ctx.variant,
            [_ctx.type]: _ctx.type,
            'icon-content': _ctx.icon && !_ctx.slotHasContent,
        }]),
    target: _ctx.inNewTab ? '_blank' : '',
    onMousedown: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('mousedown', $event))),
    onMouseup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('mouseup', $event))),
    onClick: _withModifiers(_ctx.onClick, ["stop"])
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_DotsLoader, { key: 0 }))
        : (_ctx.icon !== '')
          ? (_openBlock(), _createBlock(_component_IconBase, {
              key: 1,
              icon: _ctx.icon,
              class: _normalizeClass(_ctx.iconClass)
            }, null, 8, ["icon", "class"]))
          : _createCommentVNode("", true),
      (_ctx.slotHasContent)
        ? (_openBlock(), _createElementBlock("span", {
            key: 2,
            class: _normalizeClass(["button-text", _ctx.icon !== '' || _ctx.loading ? 'button-spacing' : ''])
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 40, ["href", "target", "class", "onClick"]))
}