import axios from "axios"
import { computed } from "vue"
import { useSettingsStore } from "@/store/Settings"
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification"
import { useI18n } from "vue-i18n"

export const useClickToDial = () => {
    const { settings } = useSettingsStore()
    const { t: translate } = useI18n()

    const callsEnabled = computed(() => {
        return (
            settings.voip_provider === "kwebble" && settings.kwebble_click2dail
        )
    })

    const dial = async (phone: string) => {
        const localExtension = localStorage.getItem("clickToDialLocalExtension")
        try {
            await axios.post("/voip/click2dial", {
                to: {
                    phone: phone,
                },
                from: {
                    extension: localExtension || "",
                },
            })
        } catch (e: any) {
            const message =
                e.response?.data?.message || translate("please_try_again")
            setToastNotification(
                translate("something_went_wrong"),
                message,
                "danger"
            )
        }
    }

    return {
        callsEnabled,
        dial,
    }
}
