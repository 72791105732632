<template>
    <div class="deliverer-tile" :class="{ selected: selected }">
        <div class="application">
            <div class="circle" :class="{ enabled: deliverer.has_app }">
                <IconBase
                    icon="scooter"
                    class="scooter-icon"
                    :class="{ enabled: deliverer.has_app }"
                />
            </div>
        </div>
        <div class="deliverer-content">
            <div class="name" :style="`color: ${deliverer.orders_color}`">
                {{ deliverer.first_name }} {{ deliverer.last_name }}
            </div>
            <div v-if="multiLocation.isActive">
                {{ multiLocation.location(deliverer.client_id)?.company }}
            </div>
            <div class="data" v-if="moreData">
                {{ date }} ({{ translate("orders") }}: {{ deliverer.n }})
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue"
import { IconBase } from "@/ui-elements"
import { Deliverer } from "@/interface/deliverer/Deliverer"
import { toLongDateFormat } from "@/utils/useRefactorDate"
import { useI18n } from "vue-i18n"
import { multiLocationStore } from "@/store/MultiLocation"
export default defineComponent({
    name: "DelivererTile",
    components: {
        IconBase,
    },
    props: {
        deliverer: {
            type: Object as PropType<Deliverer>,
            required: true,
        },
        selected: Boolean,
        moreData: Boolean,
    },
    setup(props) {
        const { t: translate } = useI18n()
        const date = computed(() =>
            toLongDateFormat(props.deliverer.coordinates_updated_at)
        )
        return { date, translate, multiLocation: multiLocationStore() }
    },
})
</script>

<style lang="scss" scoped>
.deliverer-tile {
    height: 3.5rem;
    width: 100%;
    background: $lightest-gray;
    border-radius: $radius;
    display: flex;
    align-items: center;
    column-gap: $margin-m;
    padding: $padding-s;

    .name {
        font-weight: $font-weight-medium;
    }

    &:hover {
        cursor: pointer;
    }
    &.highlight {
        box-shadow: 0 0 0 2px $primary-color;
    }

    .application {
        .circle {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 2.5rem;
            border: 2px solid $gray;
            display: flex;
            align-items: center;
            justify-content: center;

            &.enabled {
                border-color: $primary-color;
            }
        }

        .scooter-icon {
            color: $gray;
            font-size: $font-size-h4;

            &.enabled {
                color: $primary-color;
            }
        }
    }

    .deliverer-content {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.selected {
        border: $primary-border;
    }
}
</style>
