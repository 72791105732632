import axios, { AxiosResponse } from "axios"
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification"

import i18n from "@/i18n"
import { paramsSerializer } from "@/utils/api/paramsSerializer"

export const saveBlob = (blob: Blob, file_name: string) => {
    const url = window.URL.createObjectURL(new Blob([blob]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", file_name)
    document.body.appendChild(link)
    link.click()
    link.remove()
}
export const getFileName = (response: AxiosResponse) => {
    const strings = response.headers["content-disposition"].split("=")
    if (strings.length !== 2) {
        console.warn("Content-Disposition header is not correct.")
        throw Error("Content-Disposition header is not correct.")
    } else {
        return strings[1]
    }
}

export const downloadFile = (
    url: string,
    error_text: string,
    filterParameters: any = {}
) => {
    const { t: translate } = i18n.global
    return axios
        .get(url, {
            responseType: "blob",
            params: {
                filter: filterParameters,
            },
            paramsSerializer: paramsSerializer,
        })
        .then((response) => {
            const fileName = getFileName(response)
            saveBlob(response.data, fileName)
        })
        .catch(() =>
            setToastNotification(
                translate("something_went_wrong"),
                error_text,
                "danger"
            )
        )
}
