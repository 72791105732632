import { defineStore } from "pinia"
import axios from "axios"

export const useVoipStore = defineStore({
    id: "voip",
    state: () =>
        ({
            subscribed: false,
            callerIdActive: false,
            callerId: null,
        } as {
            subscribed: boolean
            callerIdActive: boolean
            callerId: string | null
        }),
})
