import { createToast, withProps } from "mosha-vue-toastify"
import ToastNotification, {
    ToastNotificationType,
} from "@/ui-elements/toast-notification/ToastNotification.vue"

export function setToastNotification(
    title: string,
    content: string,
    toastType: ToastNotificationType
): void {
    createToast(
        withProps(ToastNotification, {
            toastTitle: title,
            toastContent: content,
            toastType: toastType,
        }),
        {
            position: "bottom-center",
            type: toastType,
            showCloseButton: true,
        }
    )
}
