<template>
    <div class="phone-field" ref="parent">
        <PhoneInput
            :label="translate('phone')"
            :value="cartStore.customer.address.phone || ''"
            @update:value="cartStore.customer.address.phone = $event"
            ref="inputField"
            :error="error"
            name="phone"
            :validate="cartStore.customer.address.phone !== ''"
            @validation="$emit('validation', $event)"
        ></PhoneInput>
        <ButtonBase v-if="callsEnabled && hasPhone" @click="call">{{
            translate("call")
        }}</ButtonBase>
        <SearchResults
            v-if="display"
            :class="upOrDown"
            @select="focussed = false"
            property="phone"
        ></SearchResults>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue"
import { useCustomerModalStore } from "@/store/CustomerModal"
import { useI18n } from "vue-i18n"
import PhoneInput from "@/ui-elements/input/PhoneInput.vue"
import SearchResults from "@/pages/pos/modal/customer/search/SearchResults.vue"
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue"
import {
    calculateDirection,
    calculateDisplay,
    useFocus,
} from "@/pages/pos/modal/customer/data/useSearchResults"
import { useCartStore } from "@/store/cart/Cart"
import { useDebounce } from "@/utils/useDebounce"
import { useClickToDial } from "@/utils/clickToDial/useClickToDial"
export default defineComponent({
    name: "PhoneField",
    components: {
        PhoneInput,
        SearchResults,
        ButtonBase,
    },
    props: {
        boundingElement: {
            type: HTMLElement,
        },
        error: Boolean,
    },
    setup(props) {
        const { t: translate } = useI18n()
        const customerStore = useCustomerModalStore()
        const cartStore = useCartStore()
        const focussed = ref(false)
        const display = computed(() =>
            calculateDisplay(
                focussed.value,
                cartStore.customer.address,
                "phone",
                customerStore.results
            )
        )

        watch(
            [() => cartStore.customer.address.phone, focussed],
            useDebounce(() => {
                if (focussed.value && cartStore.customer.address.phone) {
                    customerStore.fetchResults(
                        cartStore.customer.address.phone,
                        "phone"
                    )
                }
            }, 300)
        )

        const inputField = ref()
        const upOrDown = computed(() =>
            calculateDirection(props.boundingElement, inputField.value?.$el)
        )
        const { callsEnabled, dial } = useClickToDial()
        const hasPhone = computed(() => cartStore.customer.address.phone)

        const call = async () => {
            dial(cartStore.customer.address.phone)
        }
        const parent = useFocus(focussed)
        return {
            translate,
            cartStore,
            focussed,
            display,
            parent,
            inputField,
            upOrDown,
            callsEnabled,
            hasPhone,
            call,
        }
    },
    emits: ["validation"],
})
</script>

<style lang="scss">
.phone-field {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: row wrap;

    .phone-input {
        flex: 1 1;
    }
    .button {
        align-self: flex-end;
        margin: 0 0 0 $margin;
    }
}
</style>
