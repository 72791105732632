import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_FlatPickr = _resolveComponent("FlatPickr")!
  const _component_InputField = _resolveComponent("InputField")!

  return (_openBlock(), _createBlock(_component_InputField, {
    class: _normalizeClass(["date-picker", { filter: _ctx.filterStyle, [_ctx.name]: true }]),
    ref: "DateTimePickerRef",
    disabled: _ctx.disabled,
    error: _ctx.error,
    success: _ctx.success,
    label: _ctx.label,
    "label-icon": _ctx.labelIcon,
    "label-icon-class": _ctx.labelIconClass,
    name: _ctx.name,
    focus: _ctx.focused
  }, {
    visual: _withCtx(() => [
      (!_ctx.enableDate)
        ? (_openBlock(), _createBlock(_component_IconBase, {
            key: 0,
            icon: "clock",
            class: "clock-icon"
          }))
        : (_openBlock(), _createBlock(_component_IconBase, {
            key: 1,
            class: "calendar-icon",
            icon: "calendar"
          }))
    ]),
    input: _withCtx(() => [
      _createVNode(_component_FlatPickr, {
        modelValue: _ctx.date,
        config: _ctx.flatpickrConfig,
        disabled: _ctx.disabled,
        placeholder: _ctx.placeholder
      }, null, 8, ["modelValue", "config", "disabled", "placeholder"]),
      (_ctx.clearable && !_ctx.cleared)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "clear",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClear && _ctx.onClear(...args)))
          }, [
            _createVNode(_component_IconBase, {
              icon: "delete",
              class: "clear-icon"
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class", "disabled", "error", "success", "label", "label-icon", "label-icon-class", "name", "focus"]))
}