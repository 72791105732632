<template>
    <InputField
        class="text-field"
        :disabled="loading || disabled"
        :focus="focussed"
        :error="error"
        :success="success"
        :label="label"
        :label-icon="labelIcon"
        :label-icon-class="labelIconClass"
        :name="name"
        :class="name"
    >
        <template v-slot:visual v-if="prepend || icon || currencyPrepend">
            {{ currencyPrepend || prepend }}
            <IconBase :icon="icon" v-if="icon" />
        </template>
        <template v-slot:input>
            <LoadingIndicator v-if="loading" />
            <input
                ref="input"
                :type="inputType"
                :inputmode="type === 'number' ? 'numeric' : 'text'"
                @focus="focussed = true"
                @blur="focussed = false"
                @focusin="focussed = true"
                @focusout="focussed = false"
                v-model="content"
                :disabled="loading || disabled"
                :placeholder="placeholder"
                :autocomplete="autocomplete"
                :autofocus="autofocus"
                :step="step"
                :min="min"
                :max="max"
                :data-testid="dataTestid"
            />
            <div
                class="toggle"
                :class="{ active: inputType === 'text' }"
                @click="toggleType"
                v-if="type === 'password'"
            />
            <div class="buttons" v-if="showButtons && type === 'number'">
                <IconBase icon="minus" @click="minus" />
                <IconBase icon="plus" @click="plus" />
            </div>
            <div class="append-at-last" v-if="append">
                {{ append }}
            </div>
            <div class="append-at-last cursor-pointer" v-if="appendIcon">
                <IconBase
                    :icon="appendIcon"
                    @click="$emit('appendIconClick')"
                />
            </div>
        </template>
        <template
            v-slot:message
            v-if="message || (type === 'password' && passwordStrength)"
        >
            {{ message }}
            <PasswordStrength
                :password="text"
                v-if="type === 'password' && passwordStrength"
            />
        </template>
    </InputField>
</template>

<script lang="ts">
import {
    ref,
    watch,
    defineComponent,
    Ref,
    computed,
    PropType,
    onMounted,
    getCurrentInstance,
    useAttrs,
} from "vue"
import InputField from "@/ui-elements/input/InputField.vue"
import PasswordStrength from "@/ui-elements/input/PasswordStrength.vue"
import IconBase from "@/ui-elements/IconBase.vue"

import { escape, format } from "@/utils/ui-elements/useTextField"
import { useCurrencySymbol } from "@/utils/useCurrencySymbol"
import LoadingIndicator from "@/ui-elements/loaders/LoadingIndicator.vue"
export default defineComponent({
    name: "TextField",
    components: { LoadingIndicator, IconBase, InputField, PasswordStrength },
    props: {
        type: {
            type: String,
            default: "text",
        },
        text: { type: [String, Number], default: "" },
        disabled: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        success: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "",
        },
        autocomplete: {
            type: String,
            default: "on",
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        step: {
            type: Number,
            default: 0.01,
        },
        min: {
            type: Number,
            default: -Infinity,
        },
        max: {
            type: Number,
            default: Infinity,
        },
        prepend: {
            type: String,
            default: "",
        },
        icon: {
            type: String,
            default: "",
        },
        passwordStrength: {
            type: Boolean,
            default: false,
        },
        message: {
            type: String,
        },
        label: {
            type: String,
        },
        append: {
            type: String,
            default: "",
        },
        appendIcon: {
            type: String,
            default: "",
        },
        name: {
            type: [Array, String] as PropType<string | string[]>,
            default: () => [],
        },
        labelIcon: {
            type: String,
        },
        labelIconClass: {
            type: String,
        },
        showButtons: {
            type: Boolean,
            default: false,
        },
        showCurrency: {
            type: Boolean,
            default: false,
        },
        dataTestid: String,
        loading: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, context) {
        const input = ref()

        const content = computed({
            get: () =>
                props.type === "number" && props.text !== null // If null, leave it null, do not transform it to 0
                    ? format(props.text, props.step)
                    : props.text,

            set: (text) =>
                context.emit(
                    "update:text",
                    text !== "" &&
                        props.type === "number" &&
                        typeof text === "string"
                        ? escape(text, props.step, props.min, props.max)
                        : text === ""
                        ? null
                        : text // We allow null, also for type = number
                ),
        })

        const currencyPrepend = ref(
            props.showCurrency ? useCurrencySymbol() : ""
        )

        const minus = () => {
            content.value = Math.max(
                parseFloat(props.text + "") - props.step,
                props.min
            )
        }

        const plus = () => {
            content.value = Math.min(
                parseFloat(props.text + "") + props.step,
                props.max
            )
        }

        const inputType = ref(props.type === "number" ? "text" : props.type)
        const focussed: Ref<boolean> = ref(false)

        function toggleType() {
            if (inputType.value === "text") {
                inputType.value = "password"
            } else if (inputType.value === "password") {
                inputType.value = "text"
            }
        }

        watch(focussed, (_) => {
            if (_) {
                context.emit("focus")
            } else {
                context.emit("blur")
            }
        })

        onMounted(() => {
            if (props.autofocus) {
                input.value.focus()
            }
        })

        return {
            inputType,
            toggleType,
            focussed,
            content,
            input,
            currencyPrepend,
            minus,
            plus,
        }
    },
    emits: ["update:text", "focus", "blur", "appendIconClick"],
})
</script>

<style lang="scss">
.text-field {
    input {
        border: none;
        outline: none;
        width: 100%;
        padding: $padding-s;
        height: 100%;
    }

    .loading {
        position: absolute;
        left: 45%;
    }

    .toggle {
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        margin-right: $margin-s;

        &::before {
            font-family: "icomoon";
            content: "\e982";
        }

        &.active::before {
            content: "\e951";
        }
    }

    .visual {
        margin: $margin-m $margin-xs $margin-m $margin-m;
        padding-right: $padding-m;

        .icon-base {
            width: 0.9rem;
            height: 0.8rem;
        }
    }

    &.disabled {
        input,
        .append-at-last {
            color: $dark-gray;
        }
    }

    .append-at-last {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        padding-right: 0.5rem;
    }

    .buttons {
        margin-right: $margin-s;
        height: 100%;
        display: flex;
        align-items: center;
        .icon-plus,
        .icon-minus {
            border-radius: $radius-s;
            background: $secondary-color;
            color: $white;
            margin-left: $margin-s;
            padding: $padding-s;
        }
    }
}
</style>
