<template>
    <PanelComponent class="side-menu">
        <div class="title">
            <slot name="icon"></slot>
            <h4 class="title-text">{{ title }}</h4>
        </div>
        <div class="page-links">
            <RouterLink
                v-for="page in pages"
                :key="page.name"
                :to="{ name: page.name }"
                custom
                v-slot="{ navigate }"
            >
                <div
                    class="page-link"
                    :class="{
                        current: currentPage === page.name,
                        open:
                            Object.keys(page.subMenu).length > 0 &&
                            currentPage === page.name,
                    }"
                    @click="!page.callback ? navigate() : page.callback()"
                >
                    <div class="link-text">
                        {{ translations ? translate(page.text) : page.text }}
                    </div>
                    <IconBase
                        icon="chevron-down"
                        class="arrow-icon"
                        v-if="
                            page.hasOwnProperty('subMenu') &&
                            Object.keys(page.subMenu).length
                        "
                        :class="{
                            open:
                                Object.keys(page.subMenu).length > 0 &&
                                currentPage === page.name,
                        }"
                    ></IconBase>
                </div>
                <transition name="open-submenu">
                    <SubMenu
                        v-if="
                            page.hasOwnProperty('subMenu') &&
                            currentPage === page.name
                        "
                        :menu-options="page.subMenu"
                        :class="{ open: currentPage === page.name }"
                        :translations="translations"
                    ></SubMenu>
                </transition>
            </RouterLink>
        </div>
    </PanelComponent>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue"
import { RouterLink, useRoute } from "vue-router"
import { IconBase } from "@/ui-elements"
import SubMenu from "@/ui-elements/menu/SubMenu.vue"
import { useI18n } from "vue-i18n"
import { MenuStructure } from "@/interface/MenuStructure"
import { useMounted } from "@vueuse/core"
import PanelComponent from "@/ui-elements/layout/PanelComponent.vue"
export default defineComponent({
    name: "SideMenu",
    computed: {
        RouterLink() {
            return RouterLink
        },
    },
    components: {
        IconBase,
        SubMenu,
        PanelComponent,
    },
    props: {
        pages: {
            type: Object as PropType<MenuStructure>,
            default: () => {},
        },
        title: String,
        translations: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        const { t: translate } = useI18n()
        const scrollArea = ref()
        const isMounted = useMounted()
        const currentPage = computed(() => useRoute().meta.subName)
        return {
            scrollArea,
            translate,
            currentPage,
            isMounted,
        }
    },
})
</script>

<style lang="scss">
.side-menu {
    margin-right: $margin-s;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow-y: auto;

    .title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: $padding-m $padding;
        border-bottom: $normal-border;
        position: sticky;
        top: 0;
        background: $white;
        z-index: 1;
    }

    .title-icon {
        margin-right: $padding-m;
        font-size: 1.1rem;
    }

    .title-text {
        padding-right: $padding;
        font-weight: $font-weight-medium;
        letter-spacing: 0;
    }

    .page-links {
        display: flex;
        justify-content: flex-start;
        flex-flow: column nowrap;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        flex: 1 1;
    }

    .page-link {
        display: flex;
        align-items: center;
        transition: $quick-transition;
        cursor: pointer;
        margin: $margin-s;
        padding: $padding-s;
        background: $white;

        .link-text {
            width: 100%;
            color: $darker-gray;
            text-decoration: none;
            transition: $quick-transition;
        }

        &:link {
            text-decoration: none;
        }

        &.current {
            background: $lighter-gray;
            border-radius: $radius;

            .link-text {
                font-weight: $font-weight-medium;
                color: $secondary-color;
            }
        }

        &.open {
            border: none;
        }

        .arrow-icon {
            transition: transform 0.5s;
            margin-left: auto;
            margin-right: $margin-s;
            stroke-width: 3;
            font-size: 0.5rem;
            font-weight: $font-weight-medium;
            color: $darker-gray;

            &.open {
                transform: rotate(180deg);
            }
        }
    }
}
</style>
