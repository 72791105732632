<template>
    <div
        class="kitchen-details"
        v-if="showButtons"
        :class="{ 'divider-margin': showRemarks || showDeliverer }"
    >
        <div class="details">
            <div class="title">{{ label }}</div>
            <DeliveryDetails :delivery-type="deliveryType"></DeliveryDetails>
        </div>
        <div class="reference-number" v-if="showRefNr && order.refnr">
            {{ order.refnr }}
        </div>
        <OrderPrice :order="order" />
        <div class="order-buttons">
            <ButtonBase
                icon="receipt"
                class="order-receipt"
                @mousedown.stop="clickHandler('enroute')"
                :type="
                    order.status === 'enroute' || order.status === 'delivered'
                        ? 'primary'
                        : 'regular'
                "
            ></ButtonBase>
            <ButtonBase
                icon="check"
                class="order-check"
                @mousedown.stop="clickHandler('delivered')"
                :type="order.status === 'delivered' ? 'primary' : 'regular'"
            ></ButtonBase>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue"
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue"
import { DeliveryType } from "@/pages/orders/order/utils/getDeliveryType"
import { useI18n } from "vue-i18n"
import OrderPrice from "@/pages/orders/order/components/OrderPrice.vue"
import DeliveryDetails from "@/pages/orders/order/components/DeliveryDetails.vue"
import { Order, OrderStatusType } from "@/interface/orders/Order"
import { useSettingsStore } from "@/store/Settings"
export default defineComponent({
    name: "KitchenDetails",
    components: {
        ButtonBase,
        OrderPrice,
        DeliveryDetails,
    },
    props: {
        order: { type: Object as PropType<Order>, required: true },
        deliveryType: { type: Object as PropType<DeliveryType> },
        showButtons: {
            type: Boolean,
            default: false,
        },
        showRemarks: Boolean,
        showDeliverer: Boolean,
    },
    setup(props, context) {
        const { t: translate } = useI18n()
        const settings = useSettingsStore().settings
        const label = computed(() => {
            if (props.order.source.type === "kiosk") {
                return "Kiosk"
            }
            if (props.order.source.type === "counter") {
                return props.order.label
            }
            if (props.order.source.type === "online") {
                return "QR"
            }
            return ""
        })
        const clickHandler = (status: OrderStatusType) => {
            context.emit("status", status)
        }
        const showRefNr = computed(() => settings.ob_show_refnr === "1")
        return {
            translate,
            label,
            clickHandler,
            showRefNr,
        }
    },
    emits: ["status"],
})
</script>

<style lang="scss">
.kitchen-details {
    display: flex;
    align-items: center;
    .details {
        .title {
            font-weight: $font-weight-medium;
        }
    }
    .price {
        margin-left: auto;
        margin-right: $margin-m;
    }
    .order-buttons {
        display: flex;
        .button {
            padding: 0;
            margin-right: 0;
            width: 2.5rem;
            height: 2.5rem;
        }
        .order-check {
            margin-left: $margin-s;
        }
    }
}
</style>
