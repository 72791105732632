import { defineStore } from "pinia"
export interface networkIssuesStoreState {
    endpointsWithIssues: any
}
export const networkIssuesStore = defineStore("networkIssuesStore", {
    state: (): networkIssuesStoreState => <networkIssuesStoreState>({
            endpointsWithIssues: [],
        } as networkIssuesStoreState),
    getters: {
        hasNetworkIssues(state) {
            return state.endpointsWithIssues.length > 0
        },
    },
    actions: {
        addIssue(endpoint: string) {
            console.log("add issue", endpoint)
            this.endpointsWithIssues.push(endpoint)
        },
        removeIssue(endpoint: string) {
            if (this.endpointsWithIssues.includes(endpoint)) {
                this.endpointsWithIssues.splice(
                    this.endpointsWithIssues.indexOf(endpoint),
                    1
                )
            }
        },
        resetIssues() {
            this.endpointsWithIssues = []
        },
    },
})
