<template>
    <TabOptions
        class="dispatch-options"
        :options="optionsArray"
        v-model:value="store.currentCart.cart.type"
        :unselectable="true"
    ></TabOptions>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from "vue"
import { useI18n } from "vue-i18n"
import TabOptions from "@/ui-elements/input/TabOptions.vue"
import { useCartStore } from "@/store/cart/Cart"
import { useSettingsStore } from "@/store/Settings"
import { DispatchType } from "@/interface/Cart"
import {
    getDefaultDispatchType,
    getInitialDispatchType,
} from "@/utils/settings/dispatchType"

export default defineComponent({
    name: "DispatchField",
    components: { TabOptions },
    setup() {
        const { t: translate } = useI18n()
        const store = useCartStore()
        const settingsStore = useSettingsStore()
        const defaultDispatchType = getInitialDispatchType()

        const dispatchConditions = {
            [DispatchType.Deliver]:
                defaultDispatchType !== DispatchType.PickUpOnly,
            [DispatchType.PickUp]:
                defaultDispatchType !== DispatchType.DeliverOnly,
            [DispatchType.EatIn]:
                settingsStore.settings.dinein === "1" ||
                settingsStore.settings.dinein_menu === "1",
        } as Record<DispatchType, boolean>
        const optionsArray = computed(() =>
            [
                {
                    value: DispatchType.Deliver,
                    label: translate("delivery"),
                },
                {
                    value: DispatchType.PickUp,
                    label: translate("takeaway"),
                },
                {
                    value: DispatchType.EatIn,
                    label: translate("restaurant"),
                },
            ].filter(
                (dispatchOption) => dispatchConditions[dispatchOption.value]
            )
        )

        watch(
            () => store.currentCart.cart.type,
            () => store.updateCart()
        )

        return {
            store,
            optionsArray,
            translate,
        }
    },
})
</script>
<style lang="scss">
.dispatch-options {
    .option {
        padding: $padding-s 0 !important;
    }
}
</style>
