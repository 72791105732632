import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableCell = _resolveComponent("TableCell")!
  const _component_TableRow = _resolveComponent("TableRow")!
  const _component_BaseTable = _resolveComponent("BaseTable")!

  return (_openBlock(), _createBlock(_component_BaseTable, {
    selectable: false,
    deletable: false,
    class: "mb-4"
  }, {
    "list-header": _withCtx(() => [
      _createVNode(_component_TableCell, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate("online_orders")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_TableCell, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate("online_paid")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_TableCell, null, {
        default: _withCtx(() => [
          _createTextVNode(" GMB ")
        ]),
        _: 1
      }),
      _createVNode(_component_TableCell, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate("top_of_clients")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_TableCell, null, {
        default: _withCtx(() => [
          _createTextVNode(" #AF ")
        ]),
        _: 1
      }),
      _createVNode(_component_TableCell, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate("start")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_TableCell, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate("client")) + " " + _toDisplayString(_ctx.translate("status")), 1)
        ]),
        _: 1
      })
    ]),
    list: _withCtx(() => [
      _createVNode(_component_TableRow, { selectable: false }, {
        default: _withCtx(() => [
          _createVNode(_component_TableCell, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.adminInfo?.online_orders?.counts?.all || 0) + " / " + _toDisplayString(_ctx.adminInfo?.online_orders?.commission || "0.00%") + " / " + _toDisplayString(_ctx.adminInfo?.online_orders?.avg_amount || "€0.00"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_TableCell, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.adminInfo?.online_orders?.counts?.online_paid || 0), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_TableCell, {
            class: _normalizeClass([
                        _ctx.adminInfo?.google_my_business?.is_enabled
                            ? 'active'
                            : 'inactive',
                    ])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.adminInfo?.google_my_business?.is_enabled
                            ? _ctx.translate("active")
                            : _ctx.translate("inactive")) + " / " + _toDisplayString(_ctx.adminInfo?.google_my_business?.posts || 0), 1)
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_TableCell, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.adminInfo?.ranking?.current_rank || 0) + " / " + _toDisplayString(_ctx.adminInfo?.ranking?.lowest_rank || 0), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_TableCell, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.adminInfo?.abo_inv || 0), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_TableCell, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.adminInfo?.startdate || ""), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_TableCell, {
            class: _normalizeClass([_ctx.isReadOnly ? 'inactive' : 'active'])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isReadOnly
                            ? _ctx.translate("blocked_or_archived")
                            : _ctx.translate("active")), 1)
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}