import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "map-control" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "zoom-in",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.zoomIn && _ctx.zoomIn(...args)))
    }, "+"),
    _createElementVNode("div", {
      class: "zoom-out",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.zoomOut && _ctx.zoomOut(...args)))
    }, "-"),
    _createElementVNode("div", {
      class: "home",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.home && _ctx.home(...args)))
    }, "🚚")
  ]))
}