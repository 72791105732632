import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5bbef26e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "result",
  tabindex: "-1"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "email",
  tabindex: "-1"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 1,
  class: "phone",
  tabindex: "-1"
}
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "address",
      tabindex: "-1",
      innerHTML: _ctx.html
    }, null, 8, _hoisted_2),
    (_ctx.result.email)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_IconBase, { icon: "envelope" }),
          _createElementVNode("div", { innerHTML: _ctx.email }, null, 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true),
    (_ctx.result.phone)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_IconBase, { icon: "phone" }),
          _createElementVNode("div", { innerHTML: _ctx.phone }, null, 8, _hoisted_6)
        ]))
      : _createCommentVNode("", true)
  ]))
}