import { authName, authStructure } from "@/pageStructure/AuthStructure"
import { RouteRecordRaw } from "vue-router"

export const AuthRoutes: RouteRecordRaw = {
    path: "/auth",
    meta: { name: authName },
    component: () => import("@/pages/auth/AuthLayout.vue"),
    children: [
        {
            path: authStructure.login.path,
            name: authStructure.login.name,
            meta: { subName: authStructure.login.name },
            component: () => import("@/pages/auth/LoginComponent.vue"),
        },
        {
            path: authStructure.logout.path,
            name: authStructure.logout.name,
            meta: { subName: authStructure.logout.name },
            component: () => import("@/pages/auth/LogoutComponent.vue"),
        },
        {
            path: authStructure.request.path,
            name: authStructure.request.name,
            meta: { subName: authStructure.request.name },
            component: () => import("@/pages/auth/RequestComponent.vue"),
        },
        {
            path: authStructure.reset.path + "/:token",
            meta: { subName: authStructure.reset.name },
            component: () => import("@/pages/auth/ResetComponent.vue"),
            props: true,
        },
    ],
}
