import { Ref, ref } from "vue"
import {
    FilesResponse,
    ServerDisks,
    ServerFile,
} from "@/interface/components/FileBrowser"
import axios, { AxiosError, AxiosResponse } from "axios"
import { ApiResponse } from "@/interface/api/ApiError"
import { useFileBrowserStore } from "@/store/fileBrowserStore"

export const getFiles = () => {
    const fileBrowserStore = useFileBrowserStore()
    const loading = ref(false)
    const dataReady = ref(false)
    const selectedDisk = ref("")
    const disks = ref({}) as Ref<ServerDisks>
    const files = ref([]) as Ref<ServerFile[]>
    const handleError = (error: AxiosError) => {
        console.warn("Failed API call: /client/media")
    }
    const getFiles = async () => {
        loading.value = true
        const response: AxiosResponse<ApiResponse<FilesResponse>> =
            await axios.get("/client/media")
        disks.value = response.data.data.disks
        files.value = response.data.data.files
        selectedDisk.value = Object.keys(disks.value)[0]
        fileBrowserStore.disks = response.data.data.disks
        dataReady.value = true
    }
    const handleRequest = () =>
        getFiles()
            .catch(handleError)
            .finally(() => (loading.value = false))

    handleRequest()
    return {
        loading,
        dataReady,
        selectedDisk,
        disks,
        files,
        handleRequest,
    }
}

export const useUploadFiles = (reloadRequest: () => Promise<void>) => {
    const loading = ref(false)
    const errorMessage = ref("")
    const handleError = (error: AxiosError) => {
        errorMessage.value = error.response
            ? error.response.data.message
            : error.message
    }
    const uploadFiles = async (files: File[]) => {
        loading.value = true
        const data = new FormData()
        files.forEach((file) => data.append("files[]", file))
        await axios.post("/client/media/upload", data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        await reloadRequest()
    }
    const handleRequest = (files: File[]) =>
        uploadFiles(files)
            .catch(handleError)
            .finally(() => (loading.value = false))

    return {
        loading,
        errorMessage,
        handleRequest,
    }
}
export const useDeleteFile = (reloadRequest: () => Promise<void>) => {
    const loading = ref(false)
    const fileBrowserStore = useFileBrowserStore()
    const handleError = (error: AxiosError) => {
        console.warn(error)
    }
    const deleteFile = async (file: ServerFile) => {
        loading.value = true
        await axios.post("/client/media/delete", file)
        await reloadRequest()
        fileBrowserStore.resetState()
    }
    const handleRequest = (file: ServerFile) => {
        deleteFile(file)
            .catch(handleError)
            .finally(() => (loading.value = false))
    }
    return {
        loading,
        handleRequest,
    }
}
