import { useCartStore } from "@/store/cart/Cart"

export type DebugModule =
    | "DataHydration"
    | "SyncOrders"
    | "Update"
    | "Printer"
    | "OrderStore"
    | "TableStore"
    | "DelivererMap"
    | "Barcode"
    | "ExtrasPopup"
    | "CallerId"

export type OBWindowLogger = {
    debug: Array<DebugModule>
    log: Function
    enableDebug: Function
    disableDebug: Function
    enableDebugModule: Function
    disableDebugModule: Function
    debugModules: Function
    hasDebugModule: Function
    disableLoggingTimeout: any
    setDisableLoggingTimeout: Function
    disableLoggingAfter: number
    overrideLogDisabling: Function
    dumpCalculatorLog: Function
}

export const mountOrderBuddyWindowLogger = () => {
    window.ob = {
        debug: [],

        log: (message: string) => {
            console.log(`[WindowLogger] ${message}.`)
        },

        enableDebug: () => {
            window.ob.setDisableLoggingTimeout()
            window.ob.debug = [
                "DataHydration",
                "SyncOrders",
                "Update",
                "Printer",
                "OrderStore",
                "TableStore",
                "DelivererMap",
                "Barcode",
                "ExtrasPopup",
                "CallerId",
            ]

            return window.ob.debugModules()
        },
        disableDebug: () => {
            window.ob.debug = []

            window.ob.log("WindowLogger has been disabled.")

            if (window.ob.disableLoggingAfter != 300000) {
                window.ob.disableLoggingAfter = 300000
            }

            return window.ob.debugModules()
        },
        enableDebugModule: (module: DebugModule) => {
            window.ob.setDisableLoggingTimeout()
            // keep modules unique
            if (window.ob.debug.indexOf(module) === -1) {
                window.ob.debug.push(module)
            }
            return window.ob.debugModules()
        },
        disableDebugModule: (module: DebugModule) => {
            window.ob.debug.splice(window.ob.debug.indexOf(module), 1)
            return window.ob.debugModules()
        },
        debugModules: () => {
            return window.ob.debug
        },

        hasDebugModule: (module: DebugModule) => {
            return window.ob.debug.includes(module)
        },

        disableLoggingAfter: 300000,

        disableLoggingTimeout: -1,

        setDisableLoggingTimeout: () => {
            window.ob.log(
                `WindowLogger has been enabled, and will automatically be disabled after ${
                    window.ob.disableLoggingAfter / 1000
                } seconds`
            )

            return setTimeout(
                () => window.ob.disableDebug(),
                window.ob.disableLoggingAfter
            )
        },

        overrideLogDisabling: (newTimeout: number) => {
            window.ob.disableLoggingAfter = newTimeout

            if (window.ob.disableLoggingTimeout > 0) {
                clearTimeout(window.ob.disableLoggingTimeout)
                window.ob.setDisableLoggingTimeout()
            }
        },

        dumpCalculatorLog: () => {
            const cartStore = useCartStore()
            console.log(cartStore.currentCart._log)
        },
    } as OBWindowLogger
}
