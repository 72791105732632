import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bdf6ff7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "application" }
const _hoisted_2 = { class: "deliverer-content" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "data"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["deliverer-tile", { selected: _ctx.selected }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["circle", { enabled: _ctx.deliverer.has_app }])
      }, [
        _createVNode(_component_IconBase, {
          icon: "scooter",
          class: _normalizeClass(["scooter-icon", { enabled: _ctx.deliverer.has_app }])
        }, null, 8, ["class"])
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "name",
        style: _normalizeStyle(`color: ${_ctx.deliverer.orders_color}`)
      }, _toDisplayString(_ctx.deliverer.first_name) + " " + _toDisplayString(_ctx.deliverer.last_name), 5),
      (_ctx.multiLocation.isActive)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.multiLocation.location(_ctx.deliverer.client_id)?.company), 1))
        : _createCommentVNode("", true),
      (_ctx.moreData)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.date) + " (" + _toDisplayString(_ctx.translate("orders")) + ": " + _toDisplayString(_ctx.deliverer.n) + ") ", 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}