<template>
    <PopUpModal
        :show="apiStore.requirePincode"
        @close="onClose"
        @submit="onSubmit"
        :submit-button-text="translate('confirm')"
        :loading="loading"
        class="pincode-modal"
    >
        <div class="wrapper">
            <IconBase icon="lock" class="icon"></IconBase>
            <div class="title">{{ translate("pincode_modal_title") }}</div>
            <div class="description">
                {{ translate("pincode_modal_description") }}
            </div>
            <AnnouncementComponent
                :announcement-data="{
                    type: 'error',
                    text: formErrors.processingError,
                    icon: 'error',
                }"
                :show-close="false"
                v-if="formErrors.processingError"
            />
            <!--autocomplete="new-password" is the only way that works to prevent autofill in chrome-->
            <TextField
                v-model:text="pinCode"
                ref="input"
                placeholder="****"
                type="password"
                :message="formErrors.processingError"
                :error="!!formErrors.processingError"
                :autofocus="true"
                @keydown.enter="onSubmit"
                autocomplete="new-password"
                data-testid="pincode"
            />
        </div>
    </PopUpModal>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from "vue"
import { useI18n } from "vue-i18n"
import { IconBase, PopUpModal, TextField } from "@/ui-elements"
import { useAPIStore } from "@/store/API"
import { useFormErrors } from "@/utils/useFormErrors"
import { enableSudoMode } from "@/services/auth/sudoMode"
import AnnouncementComponent from "@/ui-elements/AnnouncementComponent.vue"

export default defineComponent({
    name: "PincodeModal",
    components: { AnnouncementComponent, PopUpModal, IconBase, TextField },
    setup() {
        const { t: translate } = useI18n()
        const apiStore = useAPIStore()
        const pinCode: Ref<string> = ref("")
        const loading: Ref<boolean> = ref(false)

        const { formErrors, handleApiFormErrors, clearFormErrors } =
            useFormErrors()

        const closePopUp = () => {
            clearFormErrors()
            pinCode.value = ""
            apiStore.requirePincode = false
        }

        const onClose = () => {
            if (apiStore.pincodeReject) {
                apiStore.pincodeReject()
            }
            closePopUp()
        }

        const setSudoMode = async () => {
            const response = await enableSudoMode(
                pinCode.value,
                apiStore.validateOnly
            )
            if (!apiStore.validateOnly) {
                apiStore.enableSudoMode(response.data.data.expires_at)
            }
            if (apiStore.pincodeResolve) {
                apiStore.pincodeResolve()
            }
            closePopUp()
        }

        const onSubmit = () => {
            loading.value = true
            setSudoMode()
                .catch(handleApiFormErrors)
                .finally(() => (loading.value = false))
        }

        return {
            translate,
            apiStore,
            pinCode,
            formErrors,
            loading,
            onClose,
            onSubmit,
        }
    },
    mounted() {
        this.$nextTick(() => {
            ;(this.$refs.input as any).$el
                .getElementsByTagName("input")[0]
                .focus()
        })
    },
})
</script>

<style lang="scss">
.pop-up .wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: $padding-xl;

    .icon {
        font-size: 3.4rem;
        color: $primary-color;
        margin-bottom: $margin-xl;
    }

    .title {
        font-size: $font-size-h2;
        color: $secondary-color;
        font-weight: $font-weight-medium;
        margin-bottom: $margin-m;
    }

    .description {
        font-size: $font-size-base;
        color: $secondary-color;
        margin-bottom: $margin-xl;
    }
}
</style>
