<template>
    <tr
        class="table-row"
        @click="onClick"
        :class="{ 'table-header': head, disabled, link: isLink }"
    >
        <TableCell
            v-if="isDraggable"
            class="draggableHandle rotate135"
            @click.stop
        >
            <IconBase icon="arrow-expand" :class="{ 'text-white': head }" />
        </TableCell>
        <TableCell v-if="selectable">
            <CheckBox
                :checked="selected"
                :indeterminate="head && indeterminate"
                @click.stop
                @update:checked="$emit('update:selected', $event)"
                :disabled="disabled"
            />
        </TableCell>
        <slot></slot>
        <TableCell class="undo" v-if="deletable">
            <ButtonBase
                v-if="deleted"
                @click.stop="$emit('undo:delete')"
                icon="return-1"
            />
        </TableCell>
    </tr>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import { CheckBox } from "@/ui-elements"
import { RouteLocationRaw, useRouter } from "vue-router"
import TableCell from "@/ui-elements/layout/tables/TableCell.vue"
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue"
import IconBase from "@/ui-elements/IconBase.vue"
export default defineComponent({
    name: "TableRow",
    components: {
        IconBase,
        CheckBox,
        TableCell,
        ButtonBase,
    },
    props: {
        selected: {
            type: Boolean,
            default: false,
        },
        indeterminate: {
            type: Boolean,
            default: false,
        },
        isLink: {
            type: Boolean,
            default: true,
        },
        to: {
            type: Object as PropType<RouteLocationRaw>,
        },
        head: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        deleted: Boolean,
        selectable: {
            type: Boolean,
            default: true,
        },
        deletable: {
            type: Boolean,
            default: true,
        },
        isDraggable: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const router = useRouter()

        const onClick = () => {
            if (props.isLink && props.to && !props.disabled) {
                router.push(props.to)
            }
        }
        return {
            onClick,
        }
    },
    emits: ["update:selected", "undo:delete"],
})
</script>

<style lang="scss" scoped>
.table-row {
    text-decoration: none;

    &:not(.table-header):nth-child(odd) {
        background: $lighter-gray;
    }

    &.link:not(.table-header):not(.disabled) {
        cursor: pointer;
    }

    &.disabled {
        :deep(.table-cell:not(.undo)) {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}

.table-header {
    border-bottom: $normal-border;
    background: $white;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;

    :deep(.table-cell) {
        font-weight: $font-weight-medium;
    }

    .text-white {
        color: $white;
    }
}

.rotate135 {
    transform: rotateY(0deg) rotate(135deg) !important;
}
</style>
