import {
    createRouter,
    createWebHistory,
    LocationQuery,
    RouteRecordRaw,
} from "vue-router"
import { SettingsRoutes } from "@/pages/settings/routes"
import { ShopRoutes } from "@/pages/marketing/routes"
import { FinancialRoutes } from "@/pages/financial/routes"
import { CustomersRoutes } from "@/pages/customers/routes"
import { ArchiveRoutes } from "@/pages/archive/routes"
import { SalesRoutes } from "@/pages/sales-statistics/routes"
import { POSRoutes } from "@/pages/pos/routes"
import { OrdersRoutes } from "@/pages/orders/routes"
import { AuthRoutes } from "@/pages/auth/routes"
import { useSettingsStore } from "@/store/Settings"
import { buildRoutes } from "@/pages/settings/utils/routesGenerator"
import { isEmpty } from "lodash"
import { settingsName } from "@/pageStructure/SettingsStructure"
import { DebugRoutes } from "@/pages/debug/routes"
import { useAPIStore } from "@/store/API"
import qs from "qs"
import { KitchenRoutes } from "@/pages/kitchen/routes"
import { useUserStore } from "@/store/User"
import { disableSudoMode } from "@/services/auth/sudoMode"
import { updatesStore } from "@/store/Updates"
import { dataHydration } from "@/services/DataHydrationService"

// TODO: split this up into separate files and import them here
const routes: Array<RouteRecordRaw> = [
    {
        path: "",
        redirect: () => {
            if (localStorage.getItem("authToken")) {
                return { name: "orders" }
            }
            return { name: "login" }
        },
    },
    {
        path: "/client_sso/:authorizationToken",
        name: "clientSso",
        meta: { name: "clientSso" },
        component: () =>
            import(/* webpackPrefetch: true */ "@/pages/ClientSso.vue"),
    },
    {
        path: "/customer-screen",
        name: "customerScreen",
        meta: { name: "customerScreen" },
        component: () =>
            import(
                /* webpackPrefetch: true */ "@/pages/customer-screen/CustomerScreen.vue"
            ),
    },
    {
        path: "/impersonate/:authorizationToken",
        name: "impersonate",
        meta: { name: "impersonate" },
        component: () =>
            import(/* webpackPrefetch: true */ "@/pages/Impersonation.vue"),
    },
    {
        path: "/number-buddy",
        name: "numberBuddy",
        meta: { name: "numberBuddy" },
        component: () =>
            import(
                /* webpackPrefetch: true */ "@/pages/number-buddy/NumberBuddy.vue"
            ),
    },
    POSRoutes,
    OrdersRoutes,
    ArchiveRoutes,
    CustomersRoutes,
    SalesRoutes,
    FinancialRoutes,
    ShopRoutes,
    SettingsRoutes,
    KitchenRoutes,
    AuthRoutes,
    DebugRoutes,
    {
        path: "/:catchAll(.*)",
        name: "pageNotFound",
        meta: {
            name: "pageNotFound",
            subName: "pageNotFound",
        },
        component: () => import("@/pages/PageNotFound.vue"),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    // we need use custom stringifier/parser to work with array query params correctly
    parseQuery: (query) => {
        return qs.parse(query) as LocationQuery
    },
    stringifyQuery: (params) => {
        return qs.stringify(params, {
            skipNulls: true,
        })
    },
})
// Add a query parameter to the URL to force the user to update the frontend
router.beforeEach(async (to, _from, next) => {
    await updatesStore().applyFrontendUpdate(to.fullPath)
    next()
})
// Remove the query parameter after the user has updated the frontend
router.afterEach(async (to) => {
    if (to?.query?.version) {
        window.history.replaceState({}, "", window.location.pathname)
    }
})

router.beforeEach(async (to) => {
    const settingsStore = useSettingsStore()
    const apiStore = useAPIStore()
    await useAPIStore().loadBearerToken()
    if (
        to.meta.name !== "auth" &&
        to.name !== "impersonate" &&
        to.name !== "clientSso"
    ) {
        if (!apiStore.hasBearerToken()) {
            return { name: "login" }
        } else {
            dataHydration.start() // it is correct that this is not asynchronous, we just start the hydration here we do not need to wait for it
            if (isEmpty(settingsStore.routesConfig)) {
                try {
                    await settingsStore.fetchRoutesConfig()
                    const routes = buildRoutes(settingsStore.routesConfig)
                    routes.forEach((route) => {
                        router.addRoute(settingsName, route)
                    })
                    return { path: to.fullPath }
                } catch (e) {
                    return { name: "orders" }
                }
            }
        }
        return true
    }
    return true
})

router.beforeEach(async (to) => {
    const userStore = useUserStore()
    if (to.name !== "pos" && to.name !== "orders") {
        return true
    }
    if (to.name === "pos" && Number(userStore.user.is_read_only)) {
        return { name: "archive" }
    }
    if (to.name === "orders" && Number(userStore.user.is_read_only)) {
        return { name: "archive" }
    }
})

router.afterEach(async (to, from) => {
    const apiStore = useAPIStore()
    const settingsStore = useSettingsStore()
    if (!Number(settingsStore.settings.pin_req_auto_off)) {
        return
    }
    const getReqEx = (page: string) => new RegExp("/" + page)
    const isGoingOutOfPage = (pageTest: RegExp) =>
        !pageTest.test(to.path) && pageTest.test(from.path)
    const shouldDisableSudo = ["settings", "archive", "financial"]
        .map(getReqEx)
        .map(isGoingOutOfPage)
        .some((goingOut) => goingOut)
    if (shouldDisableSudo) {
        await disableSudoMode()
        apiStore.disableSudoMode()
    }
})

export default router
