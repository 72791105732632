<template>
    <PopUpModal
        :show="true"
        @close="onClose"
        @submit="onSubmit"
        :submit-button-text="translate('setup_as_master')"
        :close-button-text="translate('setup_as_slave')"
    >
        <div class="wrapper">
            <notification-component
                notification-type="warning"
                v-if="otherMasterIsActive"
                class="master-active-notification"
            >
                <template #notificationLabel>{{
                    translate("another_master_active_title")
                }}</template>
                <template #notificationMessage
                    ><span
                        v-html="translate('another_master_active_description')"
                /></template>
            </notification-component>
            <div class="title">{{ translate("setup_master_slave") }}</div>
            <div
                class="description"
                v-html="translate('master_slave_description')"
            ></div>
        </div>
    </PopUpModal>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue"
import { useI18n } from "vue-i18n"
import PopUpModal from "@/ui-elements/layout/modals/PopUpModal.vue"
import { useMasterSlave } from "@/utils/useMasterSlave"
import { useSettingsStore } from "@/store/Settings"
import NotificationComponent from "@/ui-elements/NotificationComponent.vue"
import { useUserStore } from "@/store/User"
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification"

export default defineComponent({
    name: "SystemSetupMasterSlaveModal",
    components: {
        NotificationComponent,
        PopUpModal,
    },
    setup() {
        const { t: translate } = useI18n()
        const settingsStore = useSettingsStore()
        const userStore = useUserStore()
        const otherMasterIsActive = computed(
            () =>
                settingsStore.settings.master_ob_id &&
                settingsStore.settings.master_ob_id !==
                    localStorage.getItem("ob_id") // should be checked by parent, before showing modal, but just to play it safe
        )

        const onClose = () => userStore.setHasSetupMasterSlave("1")
        const onSubmit = () => {
            useMasterSlave().setMaster()
            userStore.setHasSetupMasterSlave("1")
        }

        return {
            translate,
            onClose,
            onSubmit,
            otherMasterIsActive,
        }
    },
})
</script>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: {
        bottom: 0;
    }

    .master-active-notification {
        margin: {
            bottom: $margin;
        }
    }

    .title {
        font-size: $font-size-h2;
        color: $secondary-color;
        font-weight: $font-weight-medium;
        margin-bottom: $margin-m;
    }

    .description {
        font-size: $font-size-base;
        color: $secondary-color;
    }
}
</style>
