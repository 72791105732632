<template>
    <div class="message-view">
        <div class="message-content" ref="scrollArea">
            <div class="subject">{{ message.subject }}</div>
            <div class="created-date">
                {{ toLongDateFormat(message.created_at) }}
            </div>
            <div class="message" v-html="message.message"></div>
        </div>
        <PageFooter :scroll-area="scrollArea">
            <ButtonBase
                @click="toPreviousMessage"
                type="regular"
                variant="outlined"
                icon="arrow-left"
                :disabled="disablePreviousButton"
            >
                {{ translate("previous_post") }}
            </ButtonBase>
            <ButtonBase
                @click="toNextMessage"
                type="regular"
                variant="outlined"
                icon="arrow-right"
                :disabled="disableNextButton"
            >
                {{ translate("next_post") }}
            </ButtonBase>
        </PageFooter>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue"
import { toLongDateFormat } from "@/utils/useRefactorDate"
import { ButtonBase } from "@/ui-elements"
import { useI18n } from "vue-i18n"
import PageFooter from "@/ui-elements/layout/footer/PageFooter.vue"
import { MessageObject } from "@/interface/message-box/MessageObject"

export default defineComponent({
    name: "MessageView",
    components: {
        PageFooter,
        ButtonBase,
    },
    props: {
        message: {
            type: Object as PropType<MessageObject>,
            required: true,
        },
        disablePreviousButton: {
            type: Boolean,
            required: true,
        },
        disableNextButton: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, context) {
        const { t: translate } = useI18n()
        const scrollArea = ref()

        const scrollToTop = () => {
            scrollArea.value.scroll({ top: 0, behavior: "smooth" })
        }

        const toNextMessage = () => {
            scrollToTop()
            context.emit("toNextMessage")
        }

        const toPreviousMessage = () => {
            scrollToTop()
            context.emit("toPreviousMessage")
        }

        return {
            translate,
            scrollArea,
            toNextMessage,
            toPreviousMessage,
            toLongDateFormat,
        }
    },
    emits: ["update:selectedId", "toNextMessage", "toPreviousMessage"],
})
</script>

<style scoped lang="scss">
.message-view {
    .message-content {
        padding: $padding-l;
        height: calc(100% - 4.75rem);
        overflow-y: auto;

        .subject {
            font-size: $font-size-h2;
            font-weight: $font-weight-medium;
            margin-bottom: $margin-m;
        }

        .created-date {
            font-size: $font-size-h5;
            color: $dark-gray;
            font-weight: $font-weight-medium;
            margin-bottom: $margin-l;
        }
    }

    .page-footer {
        box-shadow: none;
    }
}
</style>
