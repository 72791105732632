<template>
    <defs>
        <linearGradient id="donut-down">
            <stop offset="0%" stop-color="#48E5A1" />
            <stop offset="100%" stop-color="#40AC83" />
        </linearGradient>
        <linearGradient id="donut-up">
            <stop offset="0%" stop-color="#E06633" />
            <stop offset="100%" stop-color="#c24e1d" />
        </linearGradient>
    </defs>
</template>
