<template>
    <div class="customer" ref="boundingElement">
        <Tooltip
            :content="translate('can_pay_by_invoice')"
            :hover="canPayByInvoice"
            arrow
        >
            <div class="title">
                {{ translate("customer_details") }}
                <IconBase
                    v-if="canPayByInvoice"
                    class="invoice-icon"
                    icon="receipt"
                />
            </div>
        </Tooltip>
        <div class="form">
            <SearchableTextField
                :label="translate('company')"
                customer-property="company"
                :bounding-element="boundingElement"
                name="company"
            />
            <SearchableTextField
                :label="translate('first_name')"
                customer-property="first_name"
                :bounding-element="boundingElement"
                name="first_name"
            />
            <SearchableTextField
                :label="translate('last_name')"
                customer-property="last_name"
                :bounding-element="boundingElement"
                name="last_name"
            />
            <SearchableTextField
                :label="translate('postcode')"
                customer-property="zipcode"
                :bounding-element="boundingElement"
                name="zipcode"
            />
            <SearchableTextField
                :label="translate('house')"
                customer-property="street_number"
                :bounding-element="boundingElement"
                name="street_number"
            />
            <SearchableTextField
                name="address2"
                :label="translate('flat_number')"
                :bounding-element="boundingElement"
                customer-property="address2"
            />
            <SearchableTextField
                name="address3"
                :label="translate('house_name')"
                :bounding-element="boundingElement"
                customer-property="address3"
            />
            <SearchableTextField
                :label="translate('street')"
                customer-property="street"
                :bounding-element="boundingElement"
                name="street"
                :isLoading="isAutoCompletingAddress && isNL"
            />
            <SearchableTextField
                :label="translate('city')"
                customer-property="city"
                :bounding-element="boundingElement"
                name="city"
                :isLoading="isAutoCompletingAddress"
            />
            <CountryField />
            <PhoneField
                :bounding-element="boundingElement"
                @validation="$emit('phoneValidation', $event)"
            />
            <SearchableTextField
                :label="translate('email')"
                customer-property="email"
                :bounding-element="boundingElement"
                name="email"
            />
            <SelectComponent
                v-if="cartStore.customer.address.email"
                class="w-full"
                :label="translate('pos_modal_customer_data_discountCode')"
                :options="discountCodes"
                v-model:value="cartStore.customer.discount"
            />
            <NotesField />
            <MailingField />
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, Ref, ref } from "vue"
import { useI18n } from "vue-i18n"
import MailingField from "@/pages/pos/modal/customer/fields/MailingField.vue"
import NotesField from "@/pages/pos/modal/customer/fields/NotesField.vue"
import SearchableTextField from "@/pages/pos/modal/customer/fields/SearchableTextField.vue"
import PhoneField from "@/pages/pos/modal/customer/fields/PhoneField.vue"
import CountryField from "@/pages/pos/modal/customer/fields/CountryField.vue"
import { useCartStore } from "@/store/cart/Cart"
import SelectComponent from "@/ui-elements/input/select/SelectComponent.vue"
import { useCartDiscountsStore } from "@/store/CartDiscount"
import IconBase from "@/ui-elements/IconBase.vue"
import Tooltip from "@/ui-elements/Tooltip.vue"

export default defineComponent({
    name: "CustomerFields",
    components: {
        SelectComponent,
        SearchableTextField,
        NotesField,
        MailingField,
        PhoneField,
        CountryField,
        IconBase,
        Tooltip,
    },
    props: {
        isAutoCompletingAddress: {
            type: Boolean,
        },
        formErrors: {
            type: Object,
        },
    },
    setup() {
        const { t: translate } = useI18n()
        const cartStore = useCartStore()
        const discountsStore = useCartDiscountsStore()
        const boundingElement = ref()
        const isNL = computed(
            () =>
                String(
                    cartStore.customer.address.country ?? "NL"
                ).toUpperCase() === "NL"
        )

        const discountCodes = computed(() =>
            discountsStore.discounts.simple.map((discount) => ({
                value: discount.id,
                label: discount.full_label || discount.label,
            }))
        )

        const canPayByInvoice = computed(
            () => cartStore.customer?.invoice?.enabled
        )
        return {
            translate,
            boundingElement,
            cartStore,
            discountCodes,
            canPayByInvoice,
            isNL,
        }
    },
    emits: ["phoneValidation"],
})
</script>

<style lang="scss">
.customer {
    .form {
        display: flex;
        flex-flow: row wrap;
        row-gap: $margin;
        justify-content: space-between;
        .searchable-field {
            &.first_name,
            &.last_name,
            &.street_number,
            &.zipcode,
            &.address2,
            &.address3 {
                width: 49%;
            }
        }

        .phone {
            width: 100%;

            label.phone {
                display: flex;

                .text-field {
                    flex: 1 1;
                }
            }
        }
        .input-field {
            margin: 0;
        }
    }

    .title {
        display: flex;
        align-items: center;
        column-gap: $margin-s;

        .invoice-icon {
            font-weight: $font-weight-medium;
            font-size: $font-size-h5;
            color: $primary-color;
        }
    }
}
</style>
