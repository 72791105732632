<template>
    <InputField
        class="check-input-wrapper"
        :disabled="disabled"
        :error="error"
        :success="success"
        :label="label"
        :name="name"
        :label-icon="labelIcon"
        :label-icon-class="labelIconClass"
    >
        <template v-slot:input>
            <div
                class="check-content"
                @click.prevent="$emit('update:checked', !checked)"
                :class="{ checked: checked, disabled: disabled }"
            >
                <div
                    class="check-box"
                    :class="{
                        error: error,
                        indeterminate: indeterminate,
                    }"
                >
                    <IconBase class="checkAnimation" icon="check"></IconBase>
                </div>
                <slot>
                    {{ description }}
                </slot>
            </div>
        </template>
    </InputField>
</template>

<script lang="ts">
import IconBase from "../../IconBase.vue"
import { defineComponent, PropType } from "vue"
import InputField from "@/ui-elements/input/InputField.vue"
export default defineComponent({
    name: "CheckBox",
    components: {
        IconBase,
        InputField,
    },
    props: {
        checked: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        indeterminate: {
            type: Boolean,
            default: false,
        },
        success: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
        },
        name: {
            type: [Array, String] as PropType<string | string[]>,
            default: () => [],
        },
        labelIcon: {
            type: String,
        },
        labelIconClass: {
            type: String,
        },
        description: {
            type: String,
        },
    },
    emits: ["update:checked"],
})
</script>

<style lang="scss">
.check-input-wrapper {
    .input-field {
        margin-bottom: 0;
    }

    .border {
        border: unset;
        background: unset !important;
        align-items: center;
        gap: $margin;
    }
}

.check-content {
    display: flex;
    column-gap: $margin-m;
    cursor: pointer;

    &:hover:not(.disabled) {
        .check-box {
            border: $thick-important-border;
        }
    }

    &.checked {
        .check-box {
            background: $secondary-color;
            border: $thick-important-border;
        }
    }

    &.disabled {
        color: $gray;
        cursor: default;
        pointer-events: none;

        .check-box {
            border-color: $light-gray;
            background-color: $white;
        }

        &.checked {
            .check-box {
                border-color: $gray;
                background-color: $gray;
            }
            .icon-check:before {
                color: $white;
            }
        }
    }
}

.check-box {
    width: 1.2rem;
    height: 1.2rem;
    position: relative;
    border: $thick-border;
    border-radius: 4px;
    cursor: pointer;
    background: $white;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $quick-transition;

    .icon-check::before {
        color: $white;
    }

    &.checked {
        background: $secondary-color;
        border: $thick-important-border;

        .icon-check::before {
            color: $white;
        }
    }

    &.indeterminate {
        background: $secondary-color;
        border: $thick-important-border;
        color: $secondary-color;

        .icon-check::before {
            color: transparent;
        }

        &::after {
            content: "";
            position: absolute;
            display: flex;
            width: 60%;
            height: 2px;
            background: $white;
            border-radius: 1px;
        }
    }

    &.error {
        border: $thick-error-border;
    }
}
</style>
