import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "menu-list"
}
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "child-text" }
const _hoisted_5 = { class: "child-text" }
const _hoisted_6 = { class: "text" }
const _hoisted_7 = {
  key: 1,
  class: "menu-list"
}
const _hoisted_8 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_DeviceCheck = _resolveComponent("DeviceCheck")!
  const _component_ButtonBase = _resolveComponent("ButtonBase")!
  const _component_UserData = _resolveComponent("UserData")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DeviceCheck, null, {
      default: _withCtx(({ isMobile }) => [
        isMobile
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (
                            !menuItem.subMenu || menuItem.subMenu.length === 0
                        )
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: menuItem.text,
                        to: { name: menuItem.name },
                        class: _normalizeClass(["menu-item", [
                            menuItem.name,
                            { current: menuItem.name === _ctx.currentRoute },
                        ]])
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_2, _toDisplayString(menuItem.text), 1)
                        ]),
                        _: 2
                      }, 1032, ["to", "class"]))
                    : (_openBlock(), _createBlock(_component_Accordion, {
                        key: menuItem.name,
                        onClick: ($event: any) => (_ctx.toggleChildren(menuItem.name)),
                        class: "settings-accordion",
                        isOpened: _ctx.expandedMenuItemName === menuItem.name
                      }, {
                        title: _withCtx(() => [
                          _createElementVNode("div", {
                            class: _normalizeClass(["menu-item", [
                                    menuItem.name,
                                    { current: menuItem.name === _ctx.currentRoute },
                                ]])
                          }, [
                            _createElementVNode("span", _hoisted_3, _toDisplayString(menuItem.text), 1)
                          ], 2)
                        ]),
                        content: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem.subMenu, (child) => {
                            return (_openBlock(), _createElementBlock(_Fragment, {
                              key: child.text
                            }, [
                              (Object.keys(child.subMenu).length)
                                ? (_openBlock(), _createBlock(_component_Accordion, {
                                    key: 0,
                                    onClick: ($event: any) => (_ctx.toggleChildrenChild(child.name)),
                                    class: "settings-accordion",
                                    isOpened: 
                                            _ctx.expandedMenuItemChildName ===
                                            child.name
                                        
                                  }, {
                                    title: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_4, _toDisplayString(child.text), 1)
                                    ]),
                                    content: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(child.subMenu, (child2) => {
                                        return (_openBlock(), _createElementBlock(_Fragment, {
                                          key: child2.name
                                        }, [
                                          (child2.subMenu)
                                            ? (_openBlock(), _createBlock(_component_router_link, {
                                                key: 0,
                                                to: {
                                                            name: child2.name,
                                                        },
                                                class: _normalizeClass(["children", {
                                                            current:
                                                                child2.name ===
                                                                _ctx.currentRoute,
                                                        }])
                                              }, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("span", _hoisted_5, _toDisplayString(child2.text), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["to", "class"]))
                                            : _createCommentVNode("", true)
                                        ], 64))
                                      }), 128))
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick", "isOpened"]))
                                : (_openBlock(), _createBlock(_component_router_link, {
                                    key: 1,
                                    to: { name: child.name },
                                    class: _normalizeClass(["menu-item no-children", [
                                            child.name,
                                            {
                                                current:
                                                    child.name === _ctx.currentRoute,
                                            },
                                        ]])
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", _hoisted_6, _toDisplayString(child.text), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["to", "class"]))
                            ], 64))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["onClick", "isOpened"]))
                ], 64))
              }), 256))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  key: menuItem.text,
                  to: { name: menuItem.name },
                  class: _normalizeClass(["menu-item", [
                        menuItem.name,
                        { current: menuItem.name === _ctx.currentRoute },
                    ]])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_IconBase, {
                      icon: menuItem.icon
                    }, null, 8, ["icon"]),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(menuItem.text), 1)
                  ]),
                  _: 2
                }, 1032, ["to", "class"]))
              }), 128))
            ]))
      ]),
      _: 1
    }),
    _createVNode(_component_ButtonBase, {
      onClick: _ctx.back,
      type: "primary",
      icon: "chevron-left",
      class: "back"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.translate("back_to_orderbuddy")), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_UserData, { isSettings: true })
  ], 64))
}