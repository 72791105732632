import axios, { AxiosResponse } from "axios"
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification"
import { SelectedItem } from "@/interface/components/SelectedItems"
import { ref, Ref, toRaw } from "vue"
import { useI18n } from "vue-i18n"

type ItemId = string | number
type DeletingBatches = {
    [timer: string]: ItemId[]
}
export const useBulkDelete = (
    selectedItems: Ref<SelectedItem[]>,
    url: string,
    fetchData: () => Promise<void>,
    clearSelection: () => void
) => {
    const { t: translate } = useI18n()
    const deletingBatches = ref({} as DeletingBatches)
    const deleteItem = (itemId: ItemId) => {
        return axios.delete(url + "/" + itemId)
    }
    const setBatchForDelete = (itemIds: ItemId[]) => {
        const timer = window.setTimeout(
            (ids: ItemId[]) => {
                const promises = ids.map((id) => deleteItem(id))
                Promise.all(promises)
                    .then((responseArray) => {
                        if (responseArray.length) {
                            fetchData()
                        }
                    })
                    .catch(() => {
                        setToastNotification(
                            translate("something_went_wrong"),
                            translate("please_try_again"),
                            "danger"
                        )
                    })
                    .finally(() => delete deletingBatches.value[timer])
            },
            5000,
            itemIds
        )
        deletingBatches.value[timer] = itemIds
    }
    const onDelete = () => {
        setBatchForDelete(
            selectedItems.value
                .filter((item) => item.selected)
                .map((item) => item.id)
        )
        clearSelection()
    }
    const isDeleting = (itemId: ItemId) =>
        Object.keys(deletingBatches.value)
            .flatMap((key) => deletingBatches.value[key])
            .includes(itemId)

    const undoDelete = (itemId: ItemId) => {
        const batches = toRaw(deletingBatches.value)
        Object.keys(batches).forEach((batchTimer: any) => {
            window.clearTimeout(batchTimer)
            const batchItems = batches[batchTimer].filter(
                (item) => item !== itemId
            )
            delete deletingBatches.value[batchTimer]
            setBatchForDelete(batchItems)
        })
    }
    return {
        onDelete,
        undoDelete,
        isDeleting,
    }
}
