<template>
    <div class="logo" ref="logo" @click.stop="click">
        <img
            src="/assets/images/Logo_Foodticket_Icon.svg"
            alt="foodticket-logo"
        />
        <StatusPanel
            v-if="showStatusPanel"
            @closeStatusPanel="showStatusPanel = false"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue"
import { useClickAway } from "@/utils/useClickAway"
import StatusPanel from "@/ui-elements/page-header/StatusPanel.vue"
import { useRouter } from "vue-router"
import { useDoubleClick } from "@/utils/useDoubleClick"
import { useSplitPaymentStore } from "@/store/SplitPayment"

export default defineComponent({
    name: "MenuLogo",
    components: {
        StatusPanel,
    },
    setup() {
        const router = useRouter()
        const showStatusPanel = ref(false)
        const logo = ref()
        useClickAway(logo, () => {
            showStatusPanel.value = false
        })
        const click = useDoubleClick(
            () =>
                !useSplitPaymentStore().isPartiallyPaid
                    ? router.push({ name: "orders" })
                    : null,
            () => (showStatusPanel.value = !showStatusPanel.value),
            250
        )

        return { showStatusPanel, click }
    },
})
</script>
<style lang="scss" scoped>
.logo {
    margin-right: $margin;
    cursor: pointer;

    & img {
        width: 2.75rem;
        height: 2.75rem;
    }
    &.settings img {
        filter: saturate(0%) brightness(200%);
    }
}

@media only screen and (max-width: $screen-mobile) {
    .logo {
        width: 4.5rem;
        height: auto;

        img {
            width: 4.5rem;
            height: auto;
        }
    }
}
</style>
