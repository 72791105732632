<template>
    <div class="panel" ref="scrollAreaRef">
        <slot name="header"></slot>
        <div class="panel-content">
            <LoadingIndicator v-if="loading" :cover="true" />
            <slot></slot>
        </div>
        <PageFooter
            v-if="footer"
            :scrollArea="scrollArea"
            :pagination="pagination"
            @update:pagination="$emit('update:pagination', $event)"
            :show-pagination="showPagination"
            :show-total-count="showTotalCount"
            :ready="ready"
        >
            <slot name="footer"></slot>
        </PageFooter>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed } from "vue"
import { Pagination } from "@/interface/components/Pagination"
import PageFooter from "@/ui-elements/layout/footer/PageFooter.vue"
import LoadingIndicator from "@/ui-elements/loaders/LoadingIndicator.vue"

export default defineComponent({
    name: "PanelComponent",
    components: {
        LoadingIndicator,
        PageFooter,
    },
    props: {
        scrollAreaEl: {
            type: Object as PropType<HTMLElement>,
        },
        pagination: {
            type: Object as PropType<Pagination>,
        },
        showPagination: {
            type: Boolean,
            default: true,
        },
        showTotalCount: {
            type: Boolean,
            default: true,
        },
        footer: {
            type: Boolean,
            default: true,
        },
        ready: Boolean,
        loading: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const scrollAreaRef = ref()
        const scrollArea = computed(() =>
            props.scrollAreaEl ? props.scrollAreaEl : scrollAreaRef.value
        )

        return {
            scrollAreaRef,
            scrollArea,
        }
    },
    emits: ["update:pagination"],
})
</script>

<style lang="scss">
.panel {
    background: $white;
    border-radius: $radius;
    display: flex;
    flex-flow: column nowrap;
    position: relative;

    .panel-content {
        width: 100%;
        flex: 1 1;
        display: flex;
        flex-flow: column nowrap;
    }
}
</style>
