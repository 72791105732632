<template>
    <div
        class="notifications"
        :class="{
            [notificationType]: notificationType,
            small: small,
            light: light,
        }"
    >
        <div class="title-section" v-if="!hideTitle">
            <IconBase :icon="notification.icon" v-if="!hideIcon"></IconBase>
            <div class="notification-title-style">
                <slot name="notificationLabel">{{ notification.title }}</slot>
            </div>
        </div>
        <div>
            <slot name="notificationMessage"></slot>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue"
import { IconBase } from "@/ui-elements"
import { getNotificationData } from "@/utils/ui-elements/getNotificationData"

export default defineComponent({
    name: "NotificationComponent",
    components: { IconBase },
    props: {
        notificationType: {
            type: String as PropType<"success" | "warning" | "error" | "info">,
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        light: {
            type: Boolean,
            default: false,
        },
        hideIcon: {
            type: Boolean,
            default: false,
        },
        hideTitle: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const notification = ref(getNotificationData(props.notificationType))

        return {
            notification,
        }
    },
})
</script>

<style lang="scss" scoped>
.notifications {
    display: inline-flex;
    flex-direction: column;
    row-gap: $margin-s;
    background: $lightest-gray;
    border-radius: $radius;
    padding: $padding-m;
    border-left: $border-lg;

    .notification-title-style {
        font-size: $font-size-h4;
        padding: 0 0 0 $padding-s;
        color: inherit;
    }

    .icon-base,
    .icon-base::before {
        color: inherit;
    }

    .title-section {
        display: flex;
        align-items: center;

        .icon-base {
            font-size: $font-size-h5;
        }
    }

    &.success {
        border-color: $success-color;

        .title-section {
            color: $success-color;
        }
    }

    &.warning {
        border-color: $warning-color;

        .title-section {
            color: $warning-color;
        }
    }

    &.error {
        border-color: $error-color;

        .title-section {
            color: $error-color;
        }
    }

    &.info {
        border-color: $info-color;

        .title-section {
            color: $info-color;
        }
    }

    &.small {
        flex-direction: row;
        column-gap: $margin-m;
        align-items: center;

        .notification-title-style {
            display: none;
        }
    }

    &.light {
        background: $white;
    }
}
</style>
