import { defineStore } from "pinia"

type CustomerReceiptState = {
    showModal: boolean
    confirmationResolve: (value: void | PromiseLike<void>) => void
    confirmationReject: (value: void | PromiseLike<void>) => void
}

export const useCustomerReceiptStore = defineStore({
    id: "customer-receipt",
    state: () =>
        ({
            showModal: false,
            confirmationResolve: () => {},
            confirmationReject: () => {},
        } as CustomerReceiptState),
    actions: {
        askConfirmation(): Promise<void> {
            this.showModal = true

            return new Promise((resolve, reject) => {
                this.confirmationResolve = resolve
                this.confirmationReject = reject
            })
        },
        clearState() {
            this.showModal = false
            this.confirmationResolve = () => {}
            this.confirmationReject = () => {}
        },
    },
})
