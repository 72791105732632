import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tabs-menu" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "badge pulse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuOptions, (tab) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["menu-item", [
                    tab.id,
                    { selected: tab.id === _ctx.orderModalStore.openedTab },
                ]]),
          key: tab.id,
          onClick: ($event: any) => (_ctx.selectTab(tab.id))
        }, [
          _createTextVNode(_toDisplayString(tab.label) + " ", 1),
          _withDirectives(_createElementVNode("div", _hoisted_3, [
            _createVNode(_component_IconBase, { icon: "notification" })
          ], 512), [
            [_vShow, tab.id === 'remarks' && _ctx.order.remarks]
          ])
        ], 10, _hoisted_2))
      }), 128))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["tab", _ctx.selectedTab?.tabClass])
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.selectedTab?.component), {
        order: _ctx.order,
        "onUpdate:order": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:order', $event))),
        "scroll-area-ref": _ctx.scrollAreaRef
      }, null, 40, ["order", "scroll-area-ref"]))
    ], 2)
  ]))
}