import { DispatchType } from "@/interface/Cart"
import { useSettingsStore } from "@/store/Settings"
import { PaymentMethod } from "@/pages/pos/ticket/PaymentMethod"
import { openDrawer, printOrder } from "@/services/printer/PrinterService"
import { receiptToBackendAndSendInvoice } from "@/utils/generateReceipt"
import { useCartStore } from "@/store/cart/Cart"
import { usePOSStore } from "@/store/POS"
import { useCustomerModalStore } from "@/store/CustomerModal"
import { useOrdersStore } from "@/store/Orders"
import { Order } from "@/interface/orders/Order"
import { dataHydration } from "@/services/DataHydrationService"
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification"
import { usePosMenusStore } from "@/store/PosMenus"
import { usePinPaymentStore } from "@/store/PinPayment"
import i18n from "@/i18n"
import axios from "axios"
import { getParameter } from "@/utils/useUrlParameters"
import * as Sentry from "@sentry/vue"
import { offlineModeStore } from "@/store/offlineMode"

export const hasAdyenPinEnabled = () => {
    // Get the value from localStorage
    const poiIdFromLocalStorage = localStorage.getItem("adyen_pin_poiid_local")

    // If local storage has 0 id, it means that the desktop doesn't have its own terminal, but that there's a terminal
    // somewhere else in the store
    if (poiIdFromLocalStorage === "0") {
        return false
    }

    // Get the value from settings store
    const poiIdFromSettingsStore = useSettingsStore().settings.adyen_pin_poiid

    // Use the value from localStorage if it's not null or undefined, otherwise use the value from settings store
    const finalPoiId = poiIdFromLocalStorage ?? poiIdFromSettingsStore

    // We return true only if Adyen pin is active and there's a PoiId
    return (
        Boolean(Number(useSettingsStore().settings.adyen_pin_active)) &&
        !!finalPoiId
    )
}

export const submitCartOrder = async () => {
    const cartStore = useCartStore()
    const posStore = usePOSStore()
    const settingsStore = useSettingsStore()
    const pinPaymentStore = usePinPaymentStore()

    pinPaymentStore.resetPaymentStatuses()
    pinPaymentStore.clearPayment()

    // Check for online payment method
    if (
        cartStore.currentCart.cart.payment_method === PaymentMethod.ONLINE &&
        !cartStore.customer.phone &&
        !cartStore.customer.email
    ) {
        // We do not allow saving the order if there is no email or phonenumber present
        posStore.toggleModal("onlinePayment")
        return
    }

    // Validate customer address if needed
    const customerModalStore = useCustomerModalStore()
    const customerRequired = Number(settingsStore.settings.pos_customer)
    const showDispatchOptions = Number(
        settingsStore.settings.pos_show_cust_delivery_buttons
    )
    const isDelivery = cartStore.currentCart.cart.type === DispatchType.Deliver
    const addressRequired =
        customerRequired || (showDispatchOptions && isDelivery)

    if (addressRequired && !customerModalStore.hasValidAddress) {
        customerModalStore.doValidation = true
        customerModalStore.showModal = true
        return
    }

    // Save order before processing payment
    const orderResponse = await storeCartOrder()
    if (!orderResponse) {
        return
    }

    await cartStore.loadCart(orderResponse.id)

    if (cartStore.isPinPayment) {
        if (hasAdyenPinEnabled()) {
            await processPayment(orderResponse)
            // Order ref nr is created only after a successful payment. So we need to fetch it.
            const response = await axios.get(
                `client/orders/${orderResponse.id}?fields[orders]=refnr,payment`
            )
            orderResponse.refnr = response.data.data.refnr
            orderResponse.payment.status = response.data.data.payment.status
        }
    }

    await markOrderAsPlaced(orderResponse)

    await printOrder(orderResponse, {
        printCustomerReceipts: undefined,
        existingOrder: cartStore.existingOrder,
        force: false,
    }).then(async () => await markTableOrderAsPrinted(orderResponse))
    pinPaymentStore.clearPayment()

    // Send invoice
    if (orderResponse.customer?.id && !offlineModeStore().isOffline) {
        receiptToBackendAndSendInvoice(orderResponse)
    }
}

export const saveOrder = async () => {
    const cartStore = useCartStore()

    cartStore.currentCart.cart.payment_method = ""
    const orderResponse = await storeCartOrder()

    if (!orderResponse) {
        return
    }

    await markOrderAsPlaced(orderResponse)

    await printOrder(orderResponse, {
        printCustomerReceipts: false,
        existingOrder: false,
        force: false,
    }).then(async () => await markTableOrderAsPrinted(orderResponse))
}

export const markTableOrderAsPrinted = async (order: Order) => {
    if (!order.table_id && !Number(order.source.url)) {
        return true
    }

    return await axios.patch(`/client/archive/${order.id}`, {
        has_seen: true,
        source: {
            url: null,
        },
    })
}

const storeCartOrder = async () => {
    const cartStore = useCartStore()
    const { t: translate } = i18n.global
    const pinPaymentStore = usePinPaymentStore()

    pinPaymentStore.resetPaymentStatuses()
    pinPaymentStore.clearPayment()
    cartStore.submittingOrder = true

    try {
        const response = await useOrdersStore().storeOrder(cartStore)

        const orderResponse: Order = response.data.data
        cartStore.currentCart.cart.order_id = orderResponse.id
        if (cartStore.currentCart.table_id) {
            await dataHydration.hydrateModuleIfNoWebsocket("tables")
        }
        return orderResponse
    } catch (e) {
        Sentry.captureException(e)
        setToastNotification(
            translate("order_was_not_placed"),
            translate("pos_notifications_orderNotPlaced"),
            "danger"
        )
        return null
    } finally {
        cartStore.submittingOrder = false
    }
}

const processPayment = async (orderData: any) => {
    const pinPaymentStore = usePinPaymentStore()
    const { t: translate } = i18n.global

    try {
        await makePayment(orderData)
    } catch (e: any) {
        Sentry.captureException(e)
        await pinPaymentStore.abort()
        pinPaymentStore.errorMessage =
            e.message || e.response?.data?.message || translate("unknown_error")
        throw pinPaymentStore.errorMessage
    } finally {
        pinPaymentStore.isLoading = false
        pinPaymentStore.pinState = ""
    }
}

const markOrderAsPlaced = async (order: Order) => {
    const cartStore = useCartStore()

    cartStore.orderPlaced = true
    setTimeout(() => (cartStore.orderPlaced = false), 2500)

    finishPlacingOrder(order)

    if (order.payment.method === "cash") {
        await openCashDrawer(order)
    }
}

export const openCashDrawer = async (order: Order) => {
    const settingsStore = useSettingsStore()

    const noOpenFuture = !!Number(
        settingsStore.settings.pos_printer_drawer_no_open_future
    )

    if (!noOpenFuture || (noOpenFuture && !order.time)) {
        await openDrawer()
    }
}

export const finishPlacingOrder = async (order: Order): Promise<void> => {
    const cartStore = useCartStore()
    const { t: translate } = i18n.global

    await dataHydration.hydrateModuleIfNoWebsocket("orders")
    if (order.table_id) {
        await dataHydration.hydrateModuleIfNoWebsocket("tables")
    }

    setToastNotification(
        translate("order_placed"),
        translate("pos_notifications_orderPlaced", {
            id: order.id,
        }),
        "success"
    )

    await cartStore.clearCurrentCart()
    usePosMenusStore().resetSelection()
}

const makePayment = async (order: any) => {
    const cartStore = useCartStore()

    const isPinCash =
        cartStore.currentCart.cart.payment_method === PaymentMethod.PIN_CASH
    const total = isPinCash
        ? cartStore.currentCart.cart.payment2?.amount
        : order.total

    const response: any = await usePinPaymentStore()
        .executePayment(
            String(order.id),
            total,
            (state: any) => (usePinPaymentStore().pinState = state.event)
        )
        .catch((e: any) => {
            Sentry.captureException(e)
            throw new Error(e.message || "")
        })

    console.log(
        "SentryDevInfo: cartOrderUtils/initiatePayment response",
        response
    )

    // @todo, use pinPayment: unpackAdyenResponse
    let initialError = ""

    if (
        response.data.SaleToPOIResponse &&
        response.data.SaleToPOIResponse?.PaymentResponse
    ) {
        initialError = getParameter(
            "refusalReason",
            false,
            response.data.SaleToPOIResponse.PaymentResponse.Response
                .AdditionalResponse
        )

        initialError =
            initialError ||
            getParameter(
                "message",
                false,
                response.data.SaleToPOIResponse.PaymentResponse.Response
                    .AdditionalResponse
            )
    }

    if (!initialError && response.data.SaleToPOIRequest?.EventNotification) {
        initialError = getParameter(
            "message",
            false,
            response.data.SaleToPOIRequest.EventNotification
        )

        if (!initialError) {
            initialError = getParameter(
                "message",
                false,
                response.data.SaleToPOIRequest.EventNotification.EventDetails
            )
        } else {
            initialError = "Unknown error"
        }
    }

    if (initialError) {
        let prependString = String(
            response.data.SaleToPOIResponse?.PaymentResponse?.Response
                ?.Result || ""
        )
        prependString += prependString !== "" ? ": " : ""

        throw new Error(prependString + initialError)
    }

    if (
        response?.data.SaleToPOIResponse?.PaymentResponse?.Response?.Result ===
        "Success"
    ) {
        await markPinPaymentSuccess()
    } else {
        let error = ""

        await usePinPaymentStore()
            .verify(order.id)
            .then((response: any) => {
                console.log(
                    "SentryDevInfo: cartOrderUtils/pinPaymentStore.verify response",
                    response
                )
                if (response?.is_paid) {
                    return markPinPaymentSuccess()
                }

                error = getParameter(
                    "refusalReason",
                    false,
                    response.adyen.SaleToPOIResponse.TransactionStatusResponse
                        .RepeatedMessageResponse.RepeatedResponseMessageBody
                        .PaymentResponse.Response.AdditionalResponse
                )

                error =
                    error ||
                    getParameter(
                        "message",
                        false,
                        response.data.SaleToPOIResponse
                            .TransactionStatusResponse.RepeatedMessageResponse
                            .RepeatedResponseMessageBody.PaymentResponse
                            .Response.AdditionalResponse
                    )

                if (
                    !error &&
                    response.data.SaleToPOIResponse.TransactionStatusResponse
                        .RepeatedMessageResponse.RepeatedResponseMessageBody
                        .EventNotification
                ) {
                    error = getParameter(
                        "message",
                        false,
                        response.data.SaleToPOIResponse
                            .TransactionStatusResponse.RepeatedMessageResponse
                            .RepeatedResponseMessageBody.EventNotification
                    )
                }
            })

        let prependString = String(
            response.data.SaleToPOIResponse.PaymentResponse.Response.Result ||
                ""
        )
        prependString += prependString !== "" ? ": " : ""

        throw new Error(prependString + String(error))
    }
}

const markPinPaymentSuccess = async (): Promise<void> => {
    // @todo, set order in offline store at paid
    usePinPaymentStore().success = true
    // @todo, why is the timeout needed?
    setTimeout((): void => {
        usePinPaymentStore().resetPaymentStatuses()
        usePinPaymentStore().clearPayment()
    }, 2500)
}
