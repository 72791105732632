import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "vertical-line"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!

  return (_openBlock(), _createBlock(_component_Tag, {
    class: "price",
    "tag-style": _ctx.tagStyle,
    "small-tag": true
  }, {
    tagContent: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.paymentTotal) + " ", 1),
      (_ctx.isActuallyPaid(_ctx.order) || _ctx.isPinFinal(_ctx.order))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1))
        : _createCommentVNode("", true),
      (_ctx.isActuallyPaid(_ctx.order) || _ctx.isPinFinal(_ctx.order))
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.paymentStatus), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["tag-style"]))
}