import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e880cde"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-window cursor-default" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "#modals"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["modal cursor-not-allowed", [
                _ctx.$attrs.class,
                _ctx.type === 'alert' ? 'alert-overlay' : 'overlay',
            ]]),
          ref: "overlay"
        }, [
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}