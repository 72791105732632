import { defineStore } from "pinia"
type BreakStore = {
    show: boolean
}
export const useBrakeStore = defineStore({
    id: "brake",
    state: () =>
        ({
            show: false,
        } as BreakStore),
    actions: {
        showModal() {
            this.show = true
        },
        closeModal() {
            this.show = false
        },
    },
})
export type BreakSettings = {
    closed_tmp: boolean
    pickup_closed_tmp: boolean
    pickup_min_time_tmp: number
    delivery_closed_tmp: boolean
    delivery_min_time_tmp: number
}
