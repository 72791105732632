import {
    customersMenu,
    customersName,
} from "@/pageStructure/CustomersStructure"
import { RouteRecordRaw } from "vue-router"
export const CustomersRoutes: RouteRecordRaw = {
    path: "/customers/",
    meta: { name: customersName },
    component: () =>
        import(
            /* webpackPrefetch: true */ "@/pages/customers/CustomersLayout.vue"
        ),
    children: [
        {
            path: "",
            name: customersName,
            redirect: { name: "index" },
        },
        {
            path: "",
            name: customersMenu.customers.name,
            meta: { subName: customersMenu.customers.name },
            component: () =>
                import("@/pages/customers/customers/list/CustomersList.vue"),
        },
        {
            path: "edit/:id",
            name: "customers/edit",
            props: true,
            meta: { subName: customersMenu.customers.name },
            component: () =>
                import(
                    "@/pages/customers/customers/edit/CustomerEditLayout.vue"
                ),
        },
        {
            path: customersMenu.invoices.path,
            name: customersMenu.invoices.name,
            meta: { subName: customersMenu.invoices.name },
            component: () =>
                import(
                    "@/pages/customers/invoices/list/CustomersInvoicesList.vue"
                ),
        },
        {
            path: customersMenu.invoices.path + "/edit/:id",
            name: "customers/invoice",
            props: true,
            meta: { subName: customersMenu.invoices.name },
            component: () =>
                import(
                    "@/pages/customers/invoices/edit/CustomersInvoicesEditLayout.vue"
                ),
        },
        {
            path: customersMenu.reviews.path,
            name: customersMenu.reviews.name,
            meta: { subName: customersMenu.reviews.name },
            component: () =>
                import(
                    "@/pages/customers/reviews/list/CustomersReviewsList.vue"
                ),
        },
        {
            path: customersMenu.reviews.path + "/edit/:id",
            name: "customers/review",
            props: true,
            meta: { subName: customersMenu.reviews.name },
            component: () =>
                import(
                    "@/pages/customers/reviews/edit/CustomerReviewEditLayout.vue"
                ),
        },
    ],
}
