import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
} from "@apollo/client/core"

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
export const dataBridgeApolloClient = new ApolloClient({
    link: createHttpLink({
        uri:
            process.env.VUE_APP_DATABRIDGE_URL ??
            "https://databridge.foodticket.io/graphql",
        headers: {
            "x-api-key": process.env.VUE_APP_DATABRIDGE_API_KEY ?? "",
        },
    }),
    cache,
    headers: {
        "x-api-key": process.env.VUE_APP_DATABRIDGE_API_KEY ?? "",
    },
    connectToDevTools: true,
})
