import { lsPersistentObject } from "@/utils/external-APIs/sot/helper"
import NovacomApi from "@/utils/external-APIs/sot/novacom/novacomApi"
import { useSettingsStore } from "@/store/Settings"
import { RiceCooker } from "@/riceCooker/riceCooker"
import { useUserStore } from "@/store/User"
import Queue from "@/utils/external-APIs/sot/queue"

export default class Novacom extends NovacomApi {
    // # is a private identifier
    #debug: number
    #client: string
    #waiter: number
    #cash_payment_id: number
    #pin_payment_id: number
    #deposit_id: number
    #client_id: number

    novacomQueueSeen_date: Date
    novacomQueueSeen: any

    constructor() {
        super()

        const settingsStore = useSettingsStore()
        const userStore = useUserStore()

        this.#debug = 1
        this.#client = settingsStore.settings.novacom_apiClient ?? "ORDERLINK1"
        this.#waiter = settingsStore.settings.novacom_apiWaiterId ?? 13
        this.#cash_payment_id =
            settingsStore.settings.novacom_paymentid_cash ?? 1
        this.#pin_payment_id = settingsStore.settings.novacom_paymentid_pin ?? 1
        this.#deposit_id = 0
        this.#client_id = userStore.user.id

        this.novacomQueueSeen_date = new Date()
        this.novacomQueueSeen = lsPersistentObject(
            "novacomQueueSeen",
            Number(
                (this.novacomQueueSeen_date.getHours() >= 5 &&
                    this.novacomQueueSeen_date.setDate(
                        this.novacomQueueSeen_date.getDate() + 1
                    ),
                this.novacomQueueSeen_date.setHours(6),
                this.novacomQueueSeen_date.setMinutes(0)) / 1000
            ) - Math.round(Date.now() / 1000)
        )
        this._novacomLogin(this.#client_id)
    }

    publicsetHostPort = (h: string, p: number) => {
        Novacom.host = h || Novacom.host
        Novacom.port = p || Novacom.port
        Novacom.setBaseUrls()
    }

    setPaymentIds = (cash: number, pin: number): void => {
        this.#cash_payment_id = cash || this.#cash_payment_id
        this.#pin_payment_id = pin || this.#pin_payment_id
    }

    setClientWaiter = (client: string, waiter: number): void => {
        this.#client = client || this.#client
        this.#waiter = waiter || this.#waiter
    }

    setDepositId = (deposit_id: number): void => {
        this.#deposit_id = deposit_id || this.#deposit_id
    }

    paymentMedia = () => {
        return Novacom.novacom_fetch(
            "GET",
            Novacom.sessionId,
            Novacom.baseurl_data + "paymentMedia"
        )
    }

    private _novacomLogin = async (client_id: any) => {
        const registerClient = await Novacom.registerClient(
            client_id || this.#client
        )
        if (registerClient) {
            Novacom.getSessionId(registerClient)
        }
        const login = await Novacom.authLogin(this.#waiter)
        return login
    }

    private _cancelPushOrder = (
        table_name: any,
        table_id: any,
        order: { id: { toString: () => string | number } },
        cb?: (arg0: any) => any,
        result?: any
    ) => {
        Novacom.cancelOrder(table_id) // free table for retry by id...
        Novacom.cancelOrder(table_name) // try also with table name like 90, because id does not (always) work?
        delete this.novacomQueueSeen[order.id.toString()] // unmark busy/done
        cb && cb(result)
        return result
    }

    pushOrder = async (
        order: {
            refnr: any
            nr: any
            id: string
            source_info: any
            products: string | any[]
            total: any
            payment: string
        },
        cb: (arg0: any) => any
    ) => {
        console.log(
            "novacom.pushOrder(): id ",
            order.refnr,
            order.nr,
            order.id,
            order.source_info
        )
        let table_name = order.source_info
        const matches = table_name.matchAll(/^([^,]+),(.*)$/) // if comma, than <client-id>,<table id>
        for (const match of matches) {
            this.#client_id = match[1]
            table_name = match[2]
        }
        const login = await this._novacomLogin(this.#client_id)
        if (!Novacom.checkResult(login, "novacom.login")) {
            cb && cb(login)
            return login
        }
        if (this.novacomQueueSeen[order.id.toString()]) {
            console.log(
                "novacom.pushOrder(): already processed this session " +
                    order.id
            )
            cb && cb(login)
            return login // return login as succesful return value
        }

        this.novacomQueueSeen[order.id.toString()] = 1 // mark busy/done
        const openByName = await Novacom.openByName(
            table_name,
            order.refnr || order.nr || order.id
        )
        if (!Novacom.checkResult(openByName)) {
            cb && cb(openByName)
            return openByName
        }
        const table_id = openByName.data.subTables[0].id

        const deposit_line = {
            quantity: 0,
            articleId: this.#deposit_id,
            enteredPrice: 0,
            modifiers: [],
        }

        for (let i = 0; i < order.products.length; i++) {
            // no $.each because of await novacom.orderLines.add
            const product = order.products[i]
            const line: any = {
                quantity: product.n,
                articleId: product.external_id,
                enteredPrice: Novacom.convertToAmount(product.prod_price), // or prod_price_discount
                modifiers: [],
            }

            // Patrick's comment leaving it here just in case
            //if (i == 0) { /// add order id
            //	line.modifiers.push({
            //		modifierGroupId: 'text',
            //		textInput: order.refnr || order.nr || order.id
            //	});
            //}

            if (product.external_opt == "~container") {
                // ~container = box, set total price box (extras can not have price)
                line.enteredPrice = Novacom.convertToAmount(product.price)
            }

            if (product.external_opt == "~ar") {
                // ~ar = add title as remark/text
                line.modifiers.push({
                    modifierGroupId: "text",
                    textInput: product.title,
                })
                product.external_opt = ""
            }

            if (Novacom.convertToAmount(product.deposit) > 0) {
                const n = parseInt(product.n)
                deposit_line.quantity += n
                deposit_line.enteredPrice =
                    Novacom.convertToAmount(product.deposit) / n // means we expect deposit is 1 price for all
            }

            if (product.external_opt == "~container") {
                // ~container = box, add extras/products as remarks
                line.modifiers.push({
                    modifierGroupId: "text",
                    textInput: product.extras_array
                        .map((extra: { title: string; n: number }) => {
                            return (
                                extra.title +
                                (extra.n > 1 ? " " + extra.n + "x" : "")
                            )
                        })
                        .join(", "),
                })
            } else {
                for (let ii = 0; ii < product.extras_array.length; ii++) {
                    const extra = product.extras_array[ii]
                    const extraIdMatch = extra.external_id.matchAll(/~(\d+)/)

                    if (extra.external_id != "-1") {
                        if (/~eid/.test(product.external_id)) {
                            // take extra id (can only be one)
                            line.articleId = extra.external_id
                        } else if (extraIdMatch) {
                            // size/id modifier
                            let offset = 0
                            for (const match of extraIdMatch) {
                                offset = Number(match[1] - 1)
                            }
                            if (/,/.test(line.articleId)) {
                                const list = line.articleId.split(",")
                                line.articleId = list[offset] || list[0]
                                line.enteredPrice = (
                                    parseInt(line.enteredPrice) +
                                    Novacom.convertToAmount(extra.price)
                                ).toString()
                            } else {
                                let found = 0
                                line.modifiers.forEach((e: any, i: any) => {
                                    if (
                                        /,/.test(e.choices[0].modifierChoiceId)
                                    ) {
                                        const list =
                                            e.choices[0].modifierChoiceId.split(
                                                ","
                                            )
                                        e.choices[0].modifierChoiceId =
                                            list[offset] || list[0]
                                        found = 1
                                    }
                                })
                                if (!found) {
                                    // there are situations where size extra is available, but some products may have just 1 size.
                                    //return _cancelPushOrder(table_name, table_id, order, cb, { status: '400', message: product.title+': no size article id found' });
                                }
                            }
                        } else {
                            if (extra.external_opt == "~ar") {
                                // ~ar = add title as remark/text
                                extra.external_opt = ""
                            }
                            line.modifiers.push({
                                modifierGroupId:
                                    extra.external_opt || product.external_opt,
                                choices: [
                                    {
                                        modifierChoiceId: extra.external_id,
                                        choicePrice: Novacom.convertToAmount(
                                            extra.price
                                        ),
                                        amount: extra.n,
                                    },
                                ],
                            })
                        }
                    }
                }

                // remove size modifier ids = menu issue where product has sizes but no size given
                line.articleId = line.articleId.split(",")[0]
                line.modifiers.forEach((m: any, i: any) => {
                    if (m.choices) {
                        m.choices.forEach((c: any, ii: any) => {
                            c.modifierChoiceId =
                                c.modifierChoiceId.split(",")[0]
                        })
                    }
                })
            }
            const add = await Novacom.addOrderLines(table_name, line)
            if (
                !Novacom.checkResult(
                    add,
                    "novacom.orderLines.add (" + product.title + ")"
                )
            ) {
                return this._cancelPushOrder(
                    table_name,
                    table_id,
                    order,
                    cb,
                    add
                )
            }
        }

        if (deposit_line.quantity > 0 && this.#deposit_id) {
            const add = await Novacom.addOrderLines(table_name, deposit_line)
            if (!Novacom.checkResult(add, "novacom.orderLines.add (deposit)")) {
                return this._cancelPushOrder(
                    table_name,
                    table_id,
                    order,
                    cb,
                    add
                )
            }
        }

        const finalizeOrder = await Novacom.finalizeOrder(table_name)
        if (
            !Novacom.checkResult(finalizeOrder, "novacom.tables.finalizeOrder")
        ) {
            return this._cancelPushOrder(
                table_name,
                table_id,
                order,
                cb,
                finalizeOrder
            )
        }

        const pay = await Novacom.subTables({
            payments: [
                {
                    amountPaid: Novacom.convertToAmount(order.total),
                    paymentMediumId:
                        order.payment == "cash"
                            ? this.#cash_payment_id
                            : this.#pin_payment_id,
                    authCode: this.#waiter,
                },
            ],
            tip: 0,
            printer: "",
            subTableIds: [table_id],
        })

        if (!Novacom.checkResult(pay, "novacom.pay.subTables")) {
            this._cancelPushOrder(table_name, table_id, order)
        }

        cb && cb(pay)
        return pay
    }

    cancelNovacomOrder = async (table: number) => {
        const result = await Novacom.cancelOrder(table ?? 90)
        if (/^2/.test(result.status)) {
            return
        } else {
            console.log(
                "Oops...",
                "Cancel order failed: " + result.message,
                "error"
            ) // show msg...
        }
    }

    novacomPushOrder = (
        order: any,
        cb: (result: { status: string; message: any }) => void
    ) => {
        const novacomQueue = new Queue()
        const _order = structuredClone(order)
        const _cb = structuredClone(cb)

        console.log(
            "novacomPushOrder: add task order " +
                _order.id +
                " (running: " +
                novacomQueue.running +
                ")"
        )

        novacomQueue.addTask(() => {
            console.log("novacomPushOrder add task execute: order " + _order.id)
            this.pushOrder(_order, (result: any) => {
                console.log(
                    "novacomPushOrder Novacom.pushOrder done: order " +
                        _order.id
                )
                _cb && _cb(result)
                console.log("novacomPushOrder next: order " + _order.id)
                novacomQueue.next()
            })
            return false // return false since cb will call next
        })
        console.log(
            "novacomPushOrder: added task order " +
                _order.id +
                " (running: " +
                novacomQueue.running +
                ")"
        )
    }
}
