<template>
    <InputField
        class="multi-select"
        :disabled="disabled"
        :error="error"
        :success="success"
        :label="label"
        :label-icon="labelIcon"
        :label-icon-class="labelIconClass"
        :name="name"
    >
        <template v-slot:input>
            <div class="left-side half" ref="leftRef">
                <draggable
                    class="options-list selected-list"
                    v-model="selectedItems"
                    item-key="value"
                    :force-fallback="true"
                    :disabled="!sortable"
                    filter=".no-drag"
                >
                    <template #item="{ element: option, index }">
                        <div
                            class="option"
                            :class="{
                                marked: isIncluded(option, markedLeft),
                                'pre-marked': isPreMarked(
                                    index,
                                    selectedItems,
                                    markedLeft
                                ),
                                sortable: sortable,
                            }"
                            @click.prevent="
                                clickHandler($event, option, index, 'left')
                            "
                            @contextmenu.prevent="
                                clickHandler($event, option, index, 'left')
                            "
                            @dblclick="unSelectOne(option)"
                        >
                            <div class="option-content">
                                <IconBase
                                    v-if="sortable"
                                    icon="arrow-up-down"
                                    class="arrow-icon"
                                />
                                <span class="text">{{ option.label }}</span>
                            </div>
                        </div>
                    </template>
                </draggable>
                <PageFooter
                    v-if="isMounted"
                    :scrollArea="leftRef"
                    :ready="true"
                ></PageFooter>
            </div>
            <div class="swap-items">
                <div
                    class="swap swap-left"
                    @click="moveLeft"
                    :class="{ disabled: !markedRight.length }"
                >
                    <IconBase
                        class="arrow-icon left-arrow-icon"
                        icon="chevron-left"
                    ></IconBase>
                </div>
                <div class="horizontal-line"></div>
                <div
                    class="swap swap-right"
                    @click="moveRight"
                    :class="{ disabled: !markedLeft.length }"
                >
                    <IconBase
                        class="arrow-icon right-arrow-icon"
                        icon="chevron-right"
                    ></IconBase>
                </div>
            </div>
            <div class="right-side half" ref="rightRef">
                <TextField
                    v-model:text="search"
                    class="multi-select-search"
                    icon="search"
                ></TextField>
                <div class="options-list un-selected-list">
                    <div
                        class="option"
                        v-for="(option, i) in unSelectedItems"
                        :key="i"
                        :class="{
                            marked: isIncluded(option, markedRight),
                            'pre-marked': isPreMarked(
                                i,
                                unSelectedItems,
                                markedRight
                            ),
                            disabled: option.disabled,
                            'group-title': option.optGroup,
                        }"
                        @click.prevent="
                            clickHandler($event, option, i, 'right')
                        "
                        @contextmenu.prevent="
                            clickHandler($event, option, i, 'right')
                        "
                        @dblclick="selectOne(option)"
                    >
                        <div class="option-content">
                            <div class="text">{{ option.label }}</div>
                        </div>
                    </div>
                </div>
                <PageFooter
                    v-if="isMounted"
                    :scrollArea="rightRef"
                    :ready="true"
                ></PageFooter>
            </div>
        </template>
    </InputField>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, Ref, ref } from "vue"
import InputField from "@/ui-elements/input/InputField.vue"
import { Option } from "@/interface/components/Option"
import TextField from "@/ui-elements/input/text-field/TextField.vue"
import IconBase from "@/ui-elements/IconBase.vue"
import PageFooter from "@/ui-elements/layout/footer/PageFooter.vue"
import { onKeyStroke, useMounted } from "@vueuse/core"
import draggable from "vuedraggable"

export type MultiSelectOption = {
    value: string | number
    label: string
    disabled?: boolean
    optGroup?: boolean
}

export default defineComponent({
    name: "MultiSelect",
    components: { TextField, InputField, IconBase, PageFooter, draggable },
    props: {
        options: {
            type: Array as PropType<MultiSelectOption[]>,
            required: true,
            validator: (value: Option[]) => {
                return value.reduce((acc, cur) => {
                    const keys = Object.keys(cur)
                    return (
                        acc && keys.includes("value") && keys.includes("label")
                    )
                }, true)
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        success: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
        },
        name: {
            type: [Array, String] as PropType<string | string[]>,
            default: () => [],
        },
        labelIcon: {
            type: String,
        },
        labelIconClass: {
            type: String,
        },
        values: {
            type: Array as PropType<(string | number)[]>,
            default: () => [],
        },
        sortable: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, context) {
        const isMounted = useMounted()
        const leftRef = ref()
        const rightRef = ref()
        const search = ref("")

        const selectedItems = computed({
            get: () => {
                // iterating over values instead of options to keep the order of values
                let selectedOptions: MultiSelectOption[] = []
                const values = props.values || []
                values.forEach((value: string | number) => {
                    const existingOption = props.options.find(
                        (option) => option.value === value
                    )
                    if (existingOption) {
                        selectedOptions.push(existingOption)
                    }
                })
                return selectedOptions
            },
            set: (newSelectedItems) => {
                const newValues = newSelectedItems.map(
                    (selectedItem) => selectedItem.value
                )
                context.emit("update:values", newValues)
            },
        })

        const filteredItems = computed(() => {
            return search.value !== ""
                ? props.options.filter((option) =>
                      (option.label || "")
                          .toLowerCase()
                          .includes(search.value.toLowerCase())
                  )
                : props.options
        })

        const unSelectedItems = computed(() => {
            return filteredItems.value.filter(
                (option) => !props.values.includes(option.value)
            )
        })

        const isIncluded = (
            option: MultiSelectOption,
            options: MultiSelectOption[]
        ) =>
            options.findIndex(
                (arrOption) => arrOption.value === option.value
            ) >= 0

        const markedLeft: Ref<MultiSelectOption[]> = ref([])
        const markedRight: Ref<MultiSelectOption[]> = ref([])

        const markOrUnmark = (
            option: MultiSelectOption,
            array: Ref<MultiSelectOption[]>
        ) => {
            const index = array.value.indexOf(option)
            if (index >= 0) {
                array.value.splice(index, 1)
            } else {
                array.value.push(option)
            }
        }
        const markOrUnmarkMultiple = (
            options: MultiSelectOption[],
            array: Ref<MultiSelectOption[]>
        ) => {
            options.forEach((option) => {
                if (!option.disabled && !option.optGroup) {
                    markOrUnmark(option, array)
                }
            })
        }

        const markOrUnmarkLeft = (option: MultiSelectOption) =>
            markOrUnmark(option, markedLeft)

        const markOrUnmarkRight = (option: MultiSelectOption) =>
            markOrUnmark(option, markedRight)

        const markOrUnmarkLeftMultiple = (options: MultiSelectOption[]) =>
            markOrUnmarkMultiple(options, markedLeft)

        const markOrUnmarkRightMultiple = (options: MultiSelectOption[]) =>
            markOrUnmarkMultiple(options, markedRight)

        const isPreMarked = (
            index: number,
            array: MultiSelectOption[],
            markedArray: MultiSelectOption[]
        ) => {
            const current = array[index]
            if (index + 1 < array.length) {
                const previous = array[index + 1]
                if (
                    isIncluded(previous, markedArray) &&
                    !isIncluded(current, markedArray)
                ) {
                    return true
                }
            }
            return false
        }

        const updateMarked = (array: MultiSelectOption[], state: boolean) => {
            const markedValues = array.map((option) => option.value)
            let newValues: (string | number)[] = props.values

            if (state) {
                newValues = [...newValues, ...markedValues]
            } else {
                newValues = newValues.filter(
                    (value) => !markedValues.includes(value)
                )
            }
            context.emit("update:values", newValues)
        }

        const selectOne = (option: MultiSelectOption) => {
            context.emit("update:values", [...props.values, option.value])
        }

        const unSelectOne = (option: MultiSelectOption) => {
            const newValues = props.values.filter(
                (value) => value !== option.value
            )
            context.emit("update:values", newValues)
        }

        const moveLeft = () => {
            updateMarked(markedRight.value, true)
            markedRight.value = []
        }

        const moveRight = () => {
            updateMarked(markedLeft.value, false)
            markedLeft.value = []
        }

        const lastClickedLeft = ref(-1)
        const lastClickedRight = ref(-1)
        const shiftClick = (index: number, type: "left" | "right") => {
            // shift click: select all between last click and current
            let lastClick = -1
            if (type === "left") {
                lastClick = lastClickedLeft.value
            } else if (type === "right") {
                lastClick = lastClickedRight.value
            }
            let from
            let to
            if (lastClick >= 0) {
                if (index > lastClick) {
                    from = lastClick + 1
                    to = index + 1
                } else {
                    from = index
                    to = lastClick
                }
            } else {
                from = index
                to = index + 1
            }
            if (type === "left") {
                const options = selectedItems.value.slice(from, to)
                markOrUnmarkLeftMultiple(options)
            } else if (type === "right") {
                const options = unSelectedItems.value.slice(from, to)
                markOrUnmarkRightMultiple(options)
            }
        }
        const ctrlClick = (
            option: MultiSelectOption,
            index: number,
            type: "left" | "right"
        ) => {
            // ctrl click: add to selection
            if (type === "left") {
                markOrUnmarkLeft(option)
            } else if (type === "right") {
                markOrUnmarkRight(option)
            }
        }
        const click = (
            option: MultiSelectOption,
            index: number,
            type: "left" | "right"
        ) => {
            //normal click: select one
            markedLeft.value = []
            markedRight.value = []
            if (type === "left") {
                markOrUnmarkLeft(option)
            } else if (type === "right") {
                markOrUnmarkRight(option)
            }
        }
        const clickHandler = (
            event: MouseEvent,
            option: MultiSelectOption,
            index: number,
            type: "left" | "right"
        ) => {
            if (event.shiftKey) {
                shiftClick(index, type)
            } else if (event.ctrlKey) {
                ctrlClick(option, index, type)
            } else {
                click(option, index, type)
            }
            if (type === "left") {
                lastClickedLeft.value = index
            } else if (type === "right") {
                lastClickedRight.value = index
            }
        }
        onKeyStroke("a", (e) => {
            if (e.ctrlKey) {
                if (markedLeft.value.length) {
                    markedLeft.value = []
                    markOrUnmarkLeftMultiple(selectedItems.value)
                }
                if (markedRight.value.length) {
                    markedRight.value = []
                    markOrUnmarkRightMultiple(unSelectedItems.value)
                }
            }
        })

        return {
            leftRef,
            rightRef,
            search,
            selectedItems,
            unSelectedItems,
            isPreMarked,
            markOrUnmarkLeft,
            markOrUnmarkRight,
            markedRight,
            markedLeft,
            updateMarked,
            selectOne,
            unSelectOne,
            isIncluded,
            isMounted,
            moveLeft,
            moveRight,
            markOrUnmarkLeftMultiple,
            markOrUnmarkRightMultiple,
            clickHandler,
        }
    },
})
</script>

<style lang="scss" scoped>
.multi-select {
    min-height: 300px;
    max-height: 550px;
    display: grid;
    grid-template-areas: "label" "field";
    grid-template-rows: [label] auto [field] 1fr;
    position: relative;

    :deep(.input-label) {
        grid-area: label;
        height: 1.65rem;
    }

    :deep(.input-field) {
        grid-area: field;
        overflow: auto;
    }

    :deep(.border) {
        height: 100% !important;
        position: relative;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .half {
        width: 50%;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
    }

    .right-side {
        border-left: $normal-border;
    }

    .options-list {
        height: 100%;
        overflow-y: auto;
        padding: 0 $padding;
        flex: 1 1;

        .option {
            max-width: 100%;
            cursor: pointer;
            border-bottom: $light-border;

            .option-content {
                padding: $padding-m;
                border-radius: $radius;
                user-select: none;
                display: flex;
                align-items: center;
                column-gap: $margin-s;

                .arrow-icon {
                    color: $darker-gray;
                }
            }

            &:hover:not(.disabled) {
                background: $lighter-gray;
            }

            &.sortable {
                cursor: grab;
            }

            &.marked {
                background: $light-gray;
                border: none;

                .text {
                    color: $primary-color;
                    font-weight: $font-weight-medium;
                }
            }

            &.pre-marked {
                border: none;
            }

            &:last-child {
                border: none;
            }

            &.disabled {
                pointer-events: none;
                cursor: not-allowed;

                div {
                    color: $gray;
                }
            }

            &.group-title {
                cursor: default;

                pointer-events: none;
                div {
                    font-weight: $font-weight-medium;
                    color: $darker-gray;
                }
            }
        }

        .group-title ~ .option:not(.group-title) {
            .text {
                padding-left: calc(2 * #{$padding});
            }
        }
    }

    .swap-items {
        position: absolute;
        z-index: 10;
        background: $white;
        border-radius: $radius;
        display: flex;
        flex-direction: column;
        margin: auto;
        box-shadow: $shadow-m;

        .swap {
            padding: $padding;
            cursor: pointer;

            &.disabled {
                cursor: default;
                pointer-events: none;

                .arrow-icon {
                    color: $gray;
                }
            }
        }

        .horizontal-line {
            border: $normal-border;
        }
    }

    .multi-select-search {
        border: none;
        border-radius: 0;
        box-shadow: $shadow-m;
        position: sticky;
        padding: $padding-s $padding;
        top: 0;
        .input-field {
            margin: 0;
        }
    }

    .footer {
        position: sticky;
        bottom: 0;
        padding: $padding-s $padding-m;
    }
}
</style>
