<template>
    <div class="emergency-brake-tab brake-tab" :class="d">
        <NotificationComponent notification-type="error">
            <template #notificationLabel>
                {{ translate("components_brake_delay_message") }}
            </template>
            <template #notificationMessage>
                {{ translate("components_brake_message") }}
            </template>
        </NotificationComponent>
        <CheckSwitch
            :label="translate('components_brake_collection')"
            :checked="settings.pickup_closed_tmp"
            @update:checked="updateBrakeSettings($event, 'pickup_closed_tmp')"
            :checked-label="translate('yes')"
            :unchecked-label="translate('no')"
        ></CheckSwitch>
        <div
            class="slider pickup-slider"
            :class="{
                disabled: settings.pickup_closed_tmp,
            }"
        >
            <div class="slider-text">
                {{ translate("delay_pickup_orders") }}
                <template v-if="settings.pickup_closed_tmp">
                    [{{ translate("disabled") }}]
                </template>
                :
            </div>
            <VueSlider
                :modelValue="settings.pickup_min_time_tmp"
                @update:modelValue="
                    updateBrakeSettings($event, 'pickup_min_time_tmp')
                "
                v-bind="sliderConfig"
                :disabled="settings.pickup_closed_tmp"
            >
                <template v-slot:tooltip="{ value }">
                    <SliderTooltip
                        :value="value"
                        @minus="
                            updateBrakeSettings(
                                Number(settings.pickup_min_time_tmp) -
                                    minutesIncrement,
                                'pickup_min_time_tmp'
                            )
                        "
                        @plus="
                            updateBrakeSettings(
                                Number(settings.pickup_min_time_tmp) +
                                    minutesIncrement,
                                'pickup_min_time_tmp'
                            )
                        "
                        :disabled="settings.pickup_closed_tmp"
                    ></SliderTooltip>
                </template>
            </VueSlider>
        </div>
        <CheckSwitch
            :label="translate('components_brake_delivery')"
            :checked="settings.delivery_closed_tmp"
            @update:checked="updateBrakeSettings($event, 'delivery_closed_tmp')"
            :checked-label="translate('yes')"
            :unchecked-label="translate('no')"
        ></CheckSwitch>
        <div
            class="slider delivery-slider"
            :class="{
                disabled: settings.delivery_closed_tmp,
            }"
        >
            <div class="slider-text">
                {{ translate("delay_delivery_orders") }}
                <template v-if="settings.delivery_closed_tmp">
                    [{{ translate("disabled") }}]
                </template>
                :
            </div>
            <VueSlider
                :modelValue="settings.delivery_min_time_tmp"
                @update:modelValue="
                    updateBrakeSettings($event, 'delivery_min_time_tmp')
                "
                v-bind="sliderConfig"
                :disabled="settings.delivery_closed_tmp"
            >
                <template v-slot:tooltip="{ value }">
                    <SliderTooltip
                        :value="value"
                        @minus="
                            updateBrakeSettings(
                                Number(settings.delivery_min_time_tmp) -
                                    minutesIncrement,
                                'delivery_min_time_tmp'
                            )
                        "
                        @plus="
                            updateBrakeSettings(
                                Number(settings.delivery_min_time_tmp) +
                                    minutesIncrement,
                                'delivery_min_time_tmp'
                            )
                        "
                        :disabled="settings.delivery_closed_tmp"
                    ></SliderTooltip>
                </template>
            </VueSlider>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import { useI18n } from "vue-i18n"
import SliderTooltip from "@/pages/orders/modals/brake/components/SliderTooltip.vue"
import NotificationComponent from "@/ui-elements/NotificationComponent.vue"
import { BreakSettings } from "@/store/Brake"
import CheckSwitch from "@/ui-elements/input/check-switch/CheckSwitch.vue"

export default defineComponent({
    name: "BrakeTab",
    components: {
        NotificationComponent,
        CheckSwitch,
        SliderTooltip,
    },
    props: {
        settings: Object as PropType<BreakSettings>,
    },
    setup(props, context) {
        const { t: translate } = useI18n()
        const sliderConfig = {
            min: 0,
            max: 50,
            interval: 5,
            dotSize: "22",
            height: 20,
            "drag-on-click": true,
            tooltip: "always",
            tooltipPlacement: "bottom",
            marks: [0, 10, 20, 30, 40, 50],
        }
        const minutesIncrement = 5
        const updateBrakeSettings = (value: any, key: string) =>
            context.emit("update:settings", {
                ...props.settings,
                [key]: value,
            })
        return {
            translate,
            sliderConfig,
            minutesIncrement,
            updateBrakeSettings,
        }
    },
    emits: ["update:settings"],
})
</script>

<style lang="scss">
.brake-tab {
    .slider {
        padding-bottom: $padding-xl;
        transition: all 0.3s;

        .vue-slider {
            width: 100%;
            transition: all 0.3s;
            margin: 0 2.5rem;
        }

        .vue-slider-rail {
            background: $light-gray;
        }

        .vue-slider-process {
            background: $primary-gradient;
        }

        .vue-slider-mark-step {
            visibility: hidden;
        }

        .vue-slider-mark-label {
            font-size: $font-size-h4;
            color: $darker-gray;
            margin-top: $margin-s;
        }

        .vue-slider-dot-handle {
            cursor: grab;
            box-shadow: 0 0 0 0.6rem rgb(255 255 255 / 84%),
                0 0.15rem 0.7rem 0.6rem rgb(0 0 0 / 18%);
        }
        .vue-slider-dot-handle-disabled {
            cursor: not-allowed;
        }
    }

    .slider-text {
        height: 1.15rem;
        line-height: 1.15rem;
        font-weight: $font-weight-medium;
        letter-spacing: 0;
        margin-bottom: $margin-s;
    }

    .slider.disabled .vue-slider {
        filter: grayscale(100%);
        opacity: 0.6;
    }

    .pickup-slider {
        margin-bottom: $margin-l;
    }
}
</style>
