export const lsPersistentObject = (key: string, expires: number) => {
    // in seconds
    if (expires) {
        const secondsSinceEpoch = Math.round(Date.now() / 1000)
        if (
            !localStorage[key + "_expires"] ||
            parseInt(localStorage[key + "_expires"]) < secondsSinceEpoch
        ) {
            delete localStorage[key]
            localStorage[key + "_expires"] = secondsSinceEpoch + expires
        }
    }
    const _this = JSON.parse(localStorage[key] || "{}")
    return new Proxy(_this, {
        get(obj, prop) {
            return obj[prop]
        },
        set(obj, prop, value) {
            obj[prop] = value
            localStorage[key] = JSON.stringify(obj)
            return true
        },
        deleteProperty(obj, prop) {
            delete obj[prop]
            localStorage[key] = JSON.stringify(obj)
            return true
        },
    })
}
