import { PaymentMethod } from "@/pages/pos/ticket/PaymentMethod"

export type SplitPaymentPerson = {
    identifier: String
    set: boolean
    paid: boolean
    subtotal: number
    total: number
    deposit: number
    discount: number
    tip: number
    received: number
    productsCount: number
    products: SplitPaymentProduct[]
    paymentMethod?: PaymentMethod
    paymentError?: string
    roundingDifference?: number
    pinPayment: {
        reference: null | string
        is_successful: boolean
    }
}

export enum SplitPaymentSplitType {
    Amount = 1,
    Product,
}
export enum SplitPaymentStep {
    Number = 1,
    Type,
    Pay,
}

export type SplitPaymentProduct = {
    title: string
    packaging_costs: number | null
    cartIndex: number
    availableQuantity: number
    selectedQuantity: number
    orderProductId: number | null
    total: number
    price: number
    discount: number
    deposit: number
    extras?: string
}
