import {
    createI18n,
    IntlNumberFormats,
    LocaleMessages,
    VueI18nOptions,
    VueMessageType,
} from "vue-i18n"

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): VueI18nOptions {
    const locales = require.context("./lang", true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const formats = require.context(
        "./formats",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    )
    const messages: LocaleMessages<VueMessageType> = {}
    const numberFormats: IntlNumberFormats = {}
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    formats.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const format = matched[1]
            numberFormats[format] = formats(key)
        }
    })

    return {
        // @ts-ignore
        messages: messages,
        numberFormats: numberFormats,
    }
}

const loadedLocales = loadLocaleMessages()

export default createI18n({
    legacy: false,
    locale: process.env.VUE_APP_I18N_LOCALE || "nl",
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
    messages: loadedLocales.messages,
    numberFormats: loadedLocales.numberFormats,
    warnHtmlInMessage: "off",
})
