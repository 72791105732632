<template>
    <div class="emergency-brake-tab webshop-tap">
        <NotificationComponent notification-type="error">
            <template #notificationLabel>
                {{ translate("components_brake_close_message") }}
            </template>
            <template #notificationMessage>
                {{ translate("components_brake_message") }}
            </template>
        </NotificationComponent>
        <h3>{{ translate("webshop_closed") }}</h3>
        <CheckSwitch
            :checked="settings.closed_tmp"
            @update:checked="
                $emit('update:settings', {
                    ...settings,
                    closed_tmp: $event,
                })
            "
            :checked-label="translate('webshop_closed')"
            :unchecked-label="translate('webshop_open')"
            uncheck-icon="shopping-store"
            check-icon="warning"
        >
        </CheckSwitch>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import { useI18n } from "vue-i18n"
import NotificationComponent from "@/ui-elements/NotificationComponent.vue"
import CheckSwitch from "@/ui-elements/input/check-switch/CheckSwitch.vue"
import { BreakSettings } from "@/store/Brake"

export default defineComponent({
    name: "WebshopTab",
    components: {
        NotificationComponent,
        CheckSwitch,
    },
    props: {
        settings: Object as PropType<BreakSettings>,
    },
    setup() {
        const { t: translate } = useI18n()
        return {
            translate,
        }
    },
    emits: ["update:settings"],
})
</script>
