import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filter-select" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "filters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_OptionList = _resolveComponent("OptionList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["frame", { open: _ctx.filtersOpen }])
    }, [
      _createElementVNode("div", {
        class: "visible",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openSubject.next()))
      }, [
        _createVNode(_component_IconBase, { icon: "filter" }),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.translate("visible")) + ": ", 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.selectedOptions), 1),
        _createVNode(_component_IconBase, {
          class: _normalizeClass(["arrow", { up: _ctx.filtersOpen }]),
          icon: "chevron-down"
        }, null, 8, ["class"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_OptionList, {
          options: _ctx.filterOptions,
          value: _ctx.statusFilter,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:statusFilter', $event))),
          type: "switch"
        }, null, 8, ["options", "value"])
      ])
    ], 2)
  ]))
}