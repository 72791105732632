import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55aed880"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RoutePlannerMap = _resolveComponent("RoutePlannerMap")!
  const _component_TabOptions = _resolveComponent("TabOptions")!

  return (_openBlock(), _createElementBlock("div", {
    class: "tab-content route-planner",
    ref: _ctx.scrollAreaRef()
  }, [
    (_ctx.order.coordinates)
      ? (_openBlock(), _createBlock(_component_RoutePlannerMap, {
          key: 0,
          origin: 
                _ctx.multiLocation.isActive
                    ? _ctx.multiLocation.location(_ctx.order.client_id)?.coordinates
                    : _ctx.userStore.user.coordinates
            ,
          destination: _ctx.order.coordinates,
          deliverer: _ctx.order.deliverer,
          mode: _ctx.mode,
          onRouting: _cache[0] || (_cache[0] = ($event: any) => (_ctx.routingMessage = $event))
        }, null, 8, ["origin", "destination", "deliverer", "mode"]))
      : _createCommentVNode("", true),
    _createVNode(_component_TabOptions, {
      options: _ctx.mapModes,
      value: _ctx.mode,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.mode) = $event)),
      class: "mode"
    }, null, 8, ["options", "value"]),
    (_ctx.routingMessage)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "routing-message",
          innerHTML: _ctx.routingMessage
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true)
  ], 512))
}