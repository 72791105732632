<template>
    <div class="filter-select">
        <div class="frame" :class="{ open: filtersOpen }">
            <div class="visible" @click="openSubject.next()">
                <IconBase icon="filter"></IconBase>
                <span class="title">{{ translate("visible") }}:&nbsp;</span>
                <span class="text">{{ selectedOptions }}</span>
                <IconBase
                    class="arrow"
                    :class="{ up: filtersOpen }"
                    icon="chevron-down"
                ></IconBase>
            </div>
            <div class="filters">
                <OptionList
                    :options="filterOptions"
                    :value="statusFilter"
                    @update:value="$emit('update:statusFilter', $event)"
                    type="switch"
                ></OptionList>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue"
import { IconBase } from "@/ui-elements"
import { Subject } from "rxjs"
import { useSubscription } from "@vueuse/rxjs"
import { map } from "rxjs/operators"
import { useI18n } from "vue-i18n"
import OptionList from "@/ui-elements/input/OptionList.vue"
import { OrderStatusType } from "@/interface/orders/Order"
export default defineComponent({
    name: "OrdersFilter",
    components: {
        IconBase,
        OptionList,
    },
    props: {
        statusFilter: {
            type: Array as PropType<(OrderStatusType | "deliverers")[]>,
            required: true,
        },
    },
    setup(props) {
        const { t: translate } = useI18n()

        const filtersOpen = ref(false)
        const openSubject = new Subject()
        useSubscription(
            openSubject.pipe(map(() => !filtersOpen.value)).subscribe((_) => {
                filtersOpen.value = _
            })
        )
        const statusTranslations = {
            init: translate("new"),
            pending: translate("kitchen"),
            "pending-ready": translate("ready"),
            enroute: translate("orders_map_filters_enroute"),
            delivered: translate("delivered"),
            deliverers: translate("deliverers"),
        }
        const selectedOptions = computed(() =>
            props.statusFilter
                .map((status) => statusTranslations[status])
                .join(", ")
        )
        const filterOptions = ref([
            {
                label: statusTranslations.init,
                value: "init",
            },
            {
                label: statusTranslations.pending,
                value: "pending",
            },
            {
                label: statusTranslations["pending-ready"],
                value: "pending-ready",
            },
            {
                label: statusTranslations.enroute,
                value: "enroute",
            },
            {
                label: statusTranslations.delivered,
                value: "delivered",
            },
            {
                label: translate("deliverers"),
                value: "deliverers",
            },
        ])
        return {
            translate,
            filtersOpen,
            openSubject,
            selectedOptions,
            filterOptions,
        }
    },
    emits: ["update:statusFilter"],
})
</script>
<style lang="scss">
.filter-select {
    width: inherit;
    height: 3.4rem;
    z-index: 100;
    position: relative;
    cursor: pointer;

    .frame {
        width: 100%;
        max-height: 100%;
        transition: max-height 0.3s;
        background: $white;
        backdrop-filter: blur(2px);
        overflow: hidden;
        box-shadow: $shadow-l;
    }

    .frame.open {
        max-height: 22rem;
        transition: max-height 0.5s;
    }

    .visible {
        height: 3.4rem;
        display: flex;
        align-items: center;
        padding: 0 $padding;
        overflow-x: hidden;
        text-overflow: ellipsis;

        .title {
            font-weight: $font-weight-bold;
            color: $secondary-color;
            margin: 0 $margin-s 0 $margin-m;
        }

        .text {
            display: block;
            flex: 1 1;
            color: #8a8da7;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: $margin;
        }

        .arrow {
            transition: transform 0.3s;
        }

        .up {
            transform: rotate(180deg);
        }
    }

    .filters {
        padding: 0 $padding;
        .option-list-wrapper {
            padding-bottom: $padding-s;
        }
        .input-field {
            margin: 0;
        }
    }
}
</style>
