import { useSettingsStore } from "@/store/Settings"
import { computed, ComputedRef } from "vue"
import axios from "axios"
import { useUserStore } from "@/store/User"

export function getOrCreateObId(): string {
    let obId = localStorage.getItem("ob_id")
    if (obId) {
        return obId
    }

    obId = (
        Math.random().toString(36).substring(2) + new Date().getMilliseconds()
    ).toUpperCase()
    localStorage.setItem("ob_id", obId)

    return obId
}

export const useMasterSlave = () => {
    const settings = useSettingsStore()
    const obId: string = getOrCreateObId()
    const isActive: ComputedRef<boolean> = computed(() =>
        Boolean(Number(settings.settings.masterslave))
    )
    const forceMaster: boolean = Boolean(
        localStorage.getItem("ob_force_master")
    )
    const isMaster: ComputedRef<boolean> = computed((): boolean =>
        useUserStore().user.is_admin ||
        ["finoverview", "splash"].includes(useUserStore().user.status)
            ? false
            : !isActive.value
            ? true
            : forceMaster || settings.settings.master_ob_id === obId
    )

    const isSlave: ComputedRef<boolean> = computed(() => !isMaster.value)

    const setForceMaster = (forceMaster: boolean): void => {
        if (forceMaster) {
            localStorage.setItem("ob_force_master", "1")
        } else {
            localStorage.removeItem("ob_force_master")
        }
    }

    const setMaster = (master: boolean = true) => {
        return axios.post("/client/master_id", {
            id: master ? obId : "",
        })
    }

    return {
        obId,
        isMaster,
        isSlave,
        setForceMaster,
        setMaster,
        isActive,
    }
}
