<template>
    <audio
        v-if="Number(settings.sound_play) && settings.sound_file"
        :loop="
            !!Number(settings.sound_loop) ||
            !once ||
            (Number(settings.print_auto) === 0 &&
                settings.print_when !== 'direct')
        "
        autoplay
    >
        <source
            :src="'/assets/sounds/' + settings.sound_file"
            type="audio/mpeg"
        />
    </audio>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { useSettingsStore } from "@/store/Settings"
import { storeToRefs } from "pinia"
import { useOrdersStore } from "@/store/Orders"

export default defineComponent({
    name: "OrderAudioNotification",
    props: {
        autoPrint: {
            type: Boolean,
            default: false,
        },
        once: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { settings } = storeToRefs(useSettingsStore())

        if (props.autoPrint) {
            setTimeout(() => (useOrdersStore().playSoundOnce = false), 3000)
        }

        return {
            settings,
        }
    },
})
</script>

<style lang="scss" scoped>
audio {
    display: none;
}
</style>
