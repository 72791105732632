<template>
    <slot :isMobile="isMobile" :isTablet="isTablet" :isDesktop="isDesktop" />
</template>

<script>
import { ref, watchEffect, onMounted, watch } from "vue"
import { setFontSizeOnHTML, useFontSize } from "@/store/FontSize"
import { useUtilsStore } from "@/store/Utils"

export default {
    setup(_, { slots }) {
        const isMobile = ref(false)
        const isTablet = ref(false)
        const isDesktop = ref(false)
        const utilsStore = useUtilsStore()

        const checkDevice = () => {
            const width = window.innerWidth
            isMobile.value = width <= 768 // Adjust the mobile width breakpoint as needed
            isTablet.value = width > 768 && width <= 1024 // Adjust the tablet width breakpoints as needed
            isDesktop.value = width > 1024 // Adjust the desktop width breakpoint as needed
        }

        watchEffect(() => {
            window.addEventListener("resize", checkDevice, { passive: true })
            checkDevice()

            // Set fontsize to 70%
            if (isMobile.value) {
                setFontSizeOnHTML(12)
                utilsStore.isMobile = true
            } else {
                const fontSize = useFontSize()
                const value = ref(fontSize.getSize())
                fontSize.setSize(value.value)
                setFontSizeOnHTML(value.value)
                utilsStore.isMobile = false
            }

            return () => {
                window.removeEventListener("resize", checkDevice)
            }
        })

        return {
            isMobile,
            isTablet,
            isDesktop,
        }
    },
}
</script>
