<template>
    <div class="country-field" ref="field">
        <SelectComponent
            :label="translate('country')"
            v-model:value="cartStore.customer.address.country"
            :options="countryOptions"
            ref="inputField"
            name="country"
        ></SelectComponent>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue"
import SelectComponent from "@/ui-elements/input/select/SelectComponent.vue"
import { useI18n } from "vue-i18n"
import { useUtilsStore } from "@/store/Utils"
import { useCartStore } from "@/store/cart/Cart"
export default defineComponent({
    name: "CountryField",
    components: {
        SelectComponent,
    },

    setup() {
        const { t: translate } = useI18n()
        const cartStore = useCartStore()

        const utilsStore = useUtilsStore()
        const countryOptions = ref(
            Object.keys(utilsStore.countries).map((countryId) => ({
                value: countryId,
                label: utilsStore.countries[countryId],
            }))
        )
        return {
            translate,
            cartStore,
            countryOptions,
        }
    },
})
</script>

<style lang="scss">
.country-field {
    width: 100%;
}
</style>
