import { MenuStructure } from "@/interface/MenuStructure"

export const ordersName = "orders"
export const ordersMenu: MenuStructure = {
    orders: {
        text: "orders",
        name: "orders",
        path: "orders",
        subMenu: {},
    },
}
