<template>
    <Transition name="bounce-in">
        <div
            class="top-bar caller-id cursor-pointer"
            @click="findCustomer"
            v-if="VoipStore.callerIdActive && VoipStore.callerId"
        >
            <span class="text">
                {{ translate("incoming_call_from") }}: {{ VoipStore.callerId }}
            </span>
        </div>
    </Transition>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue"
import { websocket } from "@/services/WebsocketService"
import { useCustomerModalStore } from "@/store/CustomerModal"
import * as Sentry from "@sentry/vue"
import { useRouter } from "vue-router"
import { useI18n } from "vue-i18n"
import { useSplitPaymentStore } from "@/store/SplitPayment"
import { useCallerId } from "@/utils/useCallerId"

export default defineComponent({
    name: "CallerId",
    setup() {
        const { t: translate } = useI18n()
        const customerStore = useCustomerModalStore()
        const router = useRouter()

        const { VoipStore, unSubscribeToCallerIdEvents } = useCallerId()

        const findCustomer = async () => {
            if (useSplitPaymentStore().isPartiallyPaid) {
                return
            }

            customerStore.search = VoipStore.callerId ?? ""
            customerStore.showModal = true
            customerStore.isCallerId = true
            await customerStore.fetchResults(customerStore.search, "search")
            await router.push({ name: "pos" })
            VoipStore.callerId = ""
            VoipStore.callerIdActive = false
        }

        onBeforeUnmount(() => {
            unSubscribeToCallerIdEvents()
        })

        return {
            translate,
            findCustomer,
            VoipStore,
        }
    },
})
</script>
