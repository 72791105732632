<template>
    <div class="search-results" tabindex="-1">
        <div class="results" ref="scrollArea" tabindex="-1">
            <SearchResult
                v-for="result of modalStore.results"
                :property="property"
                :key="result.id"
                :result="result"
                @click="onClick(result)"
            ></SearchResult>
            <LoadingIndicator v-if="loading" cover></LoadingIndicator>
        </div>
        <PageFooter :ready="true" :scroll-area="scrollArea"></PageFooter>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue"
import SearchResult from "@/pages/pos/modal/customer/search/SearchResult.vue"
import { Customer } from "@/interface/Customers/Customer"
import PageFooter from "@/ui-elements/layout/footer/PageFooter.vue"
import { useCartStore } from "@/store/cart/Cart"
import { useCustomerModalStore } from "@/store/CustomerModal"
import { CustomerAddress } from "@/interface/Customers/CustomerAddress"
import LoadingIndicator from "@/ui-elements/loaders/LoadingIndicator.vue"

export default defineComponent({
    name: "SearchResults",
    components: { SearchResult, PageFooter, LoadingIndicator },
    props: {
        property: {
            type: String as PropType<keyof CustomerAddress>,
            default: "",
        },
        loading: {
            type: Boolean,
        },
    },
    setup(props, context) {
        const cartStore = useCartStore()
        const modalStore = useCustomerModalStore()
        const scrollArea = ref()
        const onClick = (customer: Customer) => {
            cartStore.saveCustomer(customer)
            context.emit("select")
        }
        return {
            modalStore,
            onClick,
            scrollArea,
        }
    },
})
</script>

<style lang="scss" scoped>
.search-results {
    position: absolute;
    min-width: 18rem;
    width: 100%;
    z-index: 1;
    box-shadow: $shadow-l;
    .results {
        display: flex;
        flex-flow: column;
        max-height: 15rem;
        min-width: 14rem;
        width: 100%;
        overflow: auto;
        background-color: $white;
        padding: 0 $padding-m;
    }
    .footer {
        padding: $padding-s;
    }
    &.down {
        top: 100%;
    }
    &.up {
        bottom: 100%;
    }
}
</style>
