<template>
    <PanelComponent
        id="table-component"
        :pagination="pagination"
        @update:pagination="$emit('update:pagination', $event)"
        :show-pagination="showPagination"
        :show-total-count="showTotalCount"
        :scrollAreaEl="scrollArea"
        :ready="ready"
    >
        <template #default>
            <slot name="filter-bar" />
            <div class="filter-options" v-if="showFiltersPanel">
                <slot name="filters-panel" />
            </div>
            <div>
                <slot name="notification" />
            </div>
            <BaseTable
                :loading="loading"
                :selectable="selectable"
                :deletable="deletable"
                :selection-object="selectionObject"
                :empty="empty"
                :isDraggable="isDraggable"
            >
                <template #list-header>
                    <slot name="list-header" />
                </template>
                <template #list>
                    <slot name="list" />
                </template>
                <template #no-rows>
                    <slot name="no-rows" />
                </template>
            </BaseTable>
        </template>
        <template #footer>
            <slot name="list-footer"></slot>
        </template>
    </PanelComponent>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import { useI18n } from "vue-i18n"
import PanelComponent from "@/ui-elements/layout/PanelComponent.vue"
import { Pagination } from "@/interface/components/Pagination"
import BaseTable from "@/ui-elements/layout/tables/BaseTable.vue"
import { SelectionObject } from "@/utils/useSelectedItems"
export default defineComponent({
    name: "TablePage",
    components: {
        PanelComponent,
        BaseTable,
    },
    props: {
        selectable: {
            type: Boolean,
            default: true,
        },
        pagination: {
            type: Object as PropType<Pagination>,
        },
        showPagination: {
            type: Boolean,
            default: true,
        },
        showTotalCount: {
            type: Boolean,
            default: true,
        },
        scrollArea: {
            type: Object as PropType<HTMLElement>,
        },
        ready: Boolean,
        loading: Boolean,
        showFiltersPanel: {
            type: Boolean,
            default: false,
        },
        deletable: {
            type: Boolean,
            default: true,
        },
        selectionObject: {
            type: Object as PropType<SelectionObject>,
            default: () => ({
                indeterminate: false,
                allChecked: false,
                setAllItems: () => {},
            }),
        },
        empty: {
            type: Boolean,
            default: false,
        },
        isDraggable: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { t: translate } = useI18n()

        return {
            translate,
        }
    },
    emits: ["update:pagination"],
})
</script>
<style lang="scss">
#table-component {
    flex: 1 1;
    display: flex;
    flex-flow: column nowrap;

    .filter-options {
        display: flex;
        padding: $padding $padding;
        border-top: $normal-border;
        z-index: 10;
    }
}

.panel-content {
    position: relative;
}
</style>
