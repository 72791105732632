<template>
    <DeviceCheck>
        <template v-slot="{ isMobile }">
            <div class="menu-list" v-if="isMobile">
                <template v-for="menuItem in menuItems">
                    <router-link
                        v-if="
                            !menuItem.subMenu || menuItem.subMenu.length === 0
                        "
                        :key="menuItem.text"
                        :to="{ name: menuItem.name }"
                        class="menu-item"
                        :class="[
                            menuItem.name,
                            { current: menuItem.name === currentRoute },
                        ]"
                    >
                        <span class="text">{{ menuItem.text }}</span>
                    </router-link>

                    <Accordion
                        v-else
                        :key="menuItem.name"
                        @click="toggleChildren(menuItem.name)"
                        class="settings-accordion"
                        :isOpened="expandedMenuItemName === menuItem.name"
                    >
                        <template v-slot:title>
                            <div
                                class="menu-item"
                                :class="[
                                    menuItem.name,
                                    { current: menuItem.name === currentRoute },
                                ]"
                            >
                                <span class="text">{{ menuItem.text }}</span>
                            </div>
                        </template>
                        <template v-slot:content>
                            <template
                                v-for="child in menuItem.subMenu"
                                :key="child.text"
                            >
                                <template
                                    v-if="Object.keys(child.subMenu).length"
                                >
                                    <Accordion
                                        @click="toggleChildrenChild(child.name)"
                                        class="settings-accordion"
                                        :isOpened="
                                            expandedMenuItemChildName ===
                                            child.name
                                        "
                                    >
                                        <template v-slot:title>
                                            <span class="child-text">{{
                                                child.text
                                            }}</span>
                                        </template>
                                        <template v-slot:content>
                                            <template
                                                v-for="child2 in child.subMenu"
                                                :key="child2.name"
                                            >
                                                <template v-if="child2.subMenu">
                                                    <router-link
                                                        :to="{
                                                            name: child2.name,
                                                        }"
                                                        class="children"
                                                        :class="{
                                                            current:
                                                                child2.name ===
                                                                currentRoute,
                                                        }"
                                                    >
                                                        <span
                                                            class="child-text"
                                                            >{{
                                                                child2.text
                                                            }}</span
                                                        >
                                                    </router-link>
                                                </template>
                                            </template>
                                        </template>
                                    </Accordion>
                                </template>
                                <template v-else>
                                    <router-link
                                        :to="{ name: child.name }"
                                        class="menu-item no-children"
                                        :class="[
                                            child.name,
                                            {
                                                current:
                                                    child.name === currentRoute,
                                            },
                                        ]"
                                    >
                                        <span class="text">{{
                                            child.text
                                        }}</span>
                                    </router-link>
                                </template>
                            </template>
                        </template>
                    </Accordion>
                </template>
            </div>
            <div class="menu-list" v-else>
                <router-link
                    v-for="menuItem in menuItems"
                    :key="menuItem.text"
                    :to="{ name: menuItem.name }"
                    class="menu-item"
                    :class="[
                        menuItem.name,
                        { current: menuItem.name === currentRoute },
                    ]"
                >
                    <IconBase :icon="menuItem.icon"></IconBase>
                    <span class="text">{{ menuItem.text }}</span>
                </router-link>
            </div>
        </template>
    </DeviceCheck>
    <ButtonBase @click="back" type="primary" icon="chevron-left" class="back">
        {{ translate("back_to_orderbuddy") }}
    </ButtonBase>
    <UserData :isSettings="true" />
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue"
import IconBase from "../IconBase.vue"
import { useSettingsStore } from "@/store/Settings"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue"
import Accordion from "@/ui-elements/Accordion.vue"
import DeviceCheck from "@/utils/responsive/DeviceCheck.vue"
import UserData from "@/ui-elements/page-header/UserData.vue"

export default defineComponent({
    name: "SettingsMenu",
    components: {
        UserData,
        IconBase,
        ButtonBase,
        Accordion,
        DeviceCheck,
    },
    setup() {
        const { t: translate } = useI18n()
        const router = useRouter()

        const currentRoute = computed(
            () => router.currentRoute.value.meta.name as string
        )
        const settingsStore = useSettingsStore()
        const menuItems = computed(() => {
            const items = Object.keys(settingsStore.settingsMenu).map(
                (key) => settingsStore.settingsMenu[key]
            )
            return [
                {
                    text: "overview",
                    name: "settings/overview",
                    icon: "home",
                },
                ...items,
            ]
        })

        const back = () => router.push({ name: "orders" })

        const expandedMenuItemName = ref("")

        const toggleChildren = (menuItemName: string) => {
            if (expandedMenuItemName.value === menuItemName) {
                expandedMenuItemName.value = ""
            } else {
                expandedMenuItemName.value = menuItemName
            }
        }

        const expandedMenuItemChildName = ref("")
        const toggleChildrenChild = (menuItemName: string) => {
            if (expandedMenuItemChildName.value === menuItemName) {
                expandedMenuItemChildName.value = ""
            } else {
                expandedMenuItemChildName.value = menuItemName
            }
        }

        return {
            menuItems,
            translate,
            currentRoute,
            back,
            toggleChildren,
            expandedMenuItemName,
            toggleChildrenChild,
            expandedMenuItemChildName,
        }
    },
})
</script>

<style lang="scss">
.page-header.settings {
    background: $primary-gradient;

    .menu-list .menu-item {
        &::after {
            background: $white;
        }

        .icon-base {
            color: $white;
        }

        .text {
            color: $white;
        }

        &:hover {
            color: $light-gray;

            span {
                color: $light-gray;
            }
        }

        &.current {
            color: $secondary-color;

            span {
                color: $secondary-color;
            }
        }
    }

    .button.minimize::after {
        background: $white;
    }

    .back {
        margin-left: auto;
        background: $transparent-black;
    }
}

@media only screen and (max-width: $screen-mobile) {
    .settings-accordion {
        color: white;
        margin-left: -1rem;

        .content {
            margin-left: 2rem !important;

            &::before {
                content: none !important;
            }

            .text {
                color: rgb(0, 0, 0);
                font-size: 1rem;
            }

            .children {
                .child-text {
                    color: rgb(0, 0, 0) !important;
                }
            }

            .wrapper {
                .content {
                    .children {
                        .child-text {
                            color: rgb(0, 0, 0) !important;
                        }
                    }
                }
            }
        }
    }

    .no-children {
        margin-left: -1rem;
    }

    .page-header.settings {
        background: $primary-gradient;

        .menu-list .menu-item {
            &::after {
                background: $white;
            }

            .icon-base {
                color: $white;
            }

            .text {
                color: rgb(0, 0, 0);
            }
        }
    }
}
</style>
