import { useI18n } from "vue-i18n"

export const getNotificationData = (type: string) => {
    const { t: translate } = useI18n()

    switch (type) {
        case "success":
            return {
                title: translate("success"),
                icon: "check-circle",
            }
        case "warning":
            return {
                title: translate("warning"),
                icon: "warning",
            }
        case "info":
            return {
                title: translate("info"),
                icon: "help-circle",
            }
        default:
            return {
                title: translate("critical_error"),
                icon: "error",
            }
    }
}
