<template>
    <teleport to="#modals" v-if="show">
        <div class="modal" :class="$attrs.class">
            <PanelComponent class="floating-modal" :scroll-area-el="scrollArea">
                <template #default>
                    <div class="header">
                        <slot name="title">
                            <h3 class="title">
                                <slot name="icon"></slot>
                                {{ title }}
                            </h3>
                        </slot>
                    </div>
                    <div class="body">
                        <slot></slot>
                    </div>
                </template>
                <template #footer>
                    <slot name="actions">
                        <ButtonBase
                            type="regular"
                            variant="filled"
                            class="save"
                            @click="$emit('submit')"
                        >
                            {{ submitButtonText || translate("save") }}
                        </ButtonBase>
                    </slot>
                </template>
            </PanelComponent>
        </div>
    </teleport>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import PanelComponent from "@/ui-elements/layout/PanelComponent.vue"
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue"
import { useI18n } from "vue-i18n"
export default defineComponent({
    name: "FloatingModal",
    components: { PanelComponent, ButtonBase },
    props: {
        show: Boolean,
        title: String,
        submitButtonText: String,
        scrollArea: {
            type: Object as PropType<HTMLElement>,
        },
    },
    setup() {
        const { t: translate } = useI18n()

        return {
            translate,
        }
    },
    emits: ["submit"],
})
</script>
<style lang="scss" scoped>
.modal {
    position: fixed;
    top: 0;
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    display: grid;
    justify-content: end;
    align-items: end;
    padding: $padding-s;
    z-index: 1000;
    pointer-events: none;
    -webkit-app-region: no-drag;
    .floating-modal {
        background: $white;
        border-radius: $radius;
        display: flex;
        flex-flow: column nowrap;
        grid-row-start: 2;
        box-shadow: $shadow-dark;
        pointer-events: auto;
        .header {
            padding: $padding-s;
        }
    }
}
</style>
