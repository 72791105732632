export default class Queue {
    queue: any
    running: boolean

    constructor() {
        this.queue = []
        this.running = false
    }

    addTask = (callback: () => any) => {
        //add callback to the queue
        this.queue.push(callback)
        if (!this.running) {
            // if nothing is running, then start the engines!
            this.next()
        }

        return this // for chaining fun!
    }

    push = (callback: () => any) => {
        const finished = callback()
        if (typeof finished === "undefined" || finished) {
            //  if callback returns `false`, then you have to
            //  call `next` somewhere in the callback
            this.next()
        }
    }

    next = () => {
        console.log("Queue next in queue: " + this.queue.length)
        this.running = false
        //get the first element off the queue
        const shift = this.queue.shift()
        if (shift) {
            this.running = true
            shift()
        }
    }
}
