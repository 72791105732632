import { paramsSerializer } from "@/utils/api/paramsSerializer"
import { defineStore } from "pinia"
import { Deliverer } from "@/interface/deliverer/Deliverer"
import axios from "axios"
import { useOrdersStore } from "@/store/Orders"
import { multiLocationStore } from "@/store/MultiLocation"

export const useDelivererStore = defineStore({
    id: "deliverer",
    state: () => ({ deliverers: [] } as { deliverers: Deliverer[] }),
    actions: {
        /**
         * For multi location we fetch the deliverers from all location, regardless of which location is selected
         */
        async fetchDeliverers(): Promise<boolean> {
            try {
                const response = await axios.get(
                    "/client/deliverers/selection",
                    {
                        params: {
                            main_location_id: multiLocationStore().isActive
                                ? multiLocationStore().main_location_id
                                : 0,
                        },
                        paramsSerializer: paramsSerializer,
                    }
                )
                this.deliverers = response.data.data
                return true
            } catch (e) {
                return false
            }
        },
    },
    getters: {
        getDeliverer: (state) => (id: number) =>
            state.deliverers.find(
                (deliverer: Deliverer) => deliverer.id === id
            ),
        activeDeliverers: (state) => {
            const orderStore = useOrdersStore()
            return state.deliverers.filter((deliverer: Deliverer) => {
                return (
                    orderStore.orders.findIndex(
                        (order) => order.deliverer_id === deliverer.id
                    ) >= 0
                )
            })
        },
        inactiveDeliverers: (state) => {
            const orderStore = useOrdersStore()
            return state.deliverers.filter((deliverer: Deliverer) => {
                return (
                    orderStore.orders.findIndex(
                        (order) => order.deliverer_id === deliverer.id
                    ) === -1
                )
            })
        },
    },
})
