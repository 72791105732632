export type DeliveryType = {
    icon: string
    label: string
}
export function getDeliveryType(delivery: string) {
    const type: Record<string, DeliveryType> = {
        deliver: { icon: "scooter", label: "delivery" },
        pickup: { icon: "shopping-bag", label: "takeaway" },
    }
    return (
        type[delivery] || {
            icon: "tables",
            label: "restaurant",
        }
    )
}
