import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateTimeSelect = _resolveComponent("DateTimeSelect")!

  return (!_ctx.disabled)
    ? (_openBlock(), _createBlock(_component_DateTimeSelect, {
        key: 0,
        label: _ctx.translate('date'),
        date: _ctx.store.currentCart.cart.schedule.date,
        "onUpdate:date": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.store.currentCart.cart.schedule.date) = $event)),
        placeholder: _ctx.translate('today')
      }, null, 8, ["label", "date", "placeholder"]))
    : _createCommentVNode("", true)
}