import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_AnnouncementComponent = _resolveComponent("AnnouncementComponent")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_PopUpModal = _resolveComponent("PopUpModal")!

  return (_openBlock(), _createBlock(_component_PopUpModal, {
    show: _ctx.apiStore.requirePincode,
    onClose: _ctx.onClose,
    onSubmit: _ctx.onSubmit,
    "submit-button-text": _ctx.translate('confirm'),
    loading: _ctx.loading,
    class: "pincode-modal"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_IconBase, {
          icon: "lock",
          class: "icon"
        }),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.translate("pincode_modal_title")), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.translate("pincode_modal_description")), 1),
        (_ctx.formErrors.processingError)
          ? (_openBlock(), _createBlock(_component_AnnouncementComponent, {
              key: 0,
              "announcement-data": {
                    type: 'error',
                    text: _ctx.formErrors.processingError,
                    icon: 'error',
                },
              "show-close": false
            }, null, 8, ["announcement-data"]))
          : _createCommentVNode("", true),
        _createVNode(_component_TextField, {
          text: _ctx.pinCode,
          "onUpdate:text": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pinCode) = $event)),
          ref: "input",
          placeholder: "****",
          type: "password",
          message: _ctx.formErrors.processingError,
          error: !!_ctx.formErrors.processingError,
          autofocus: true,
          onKeydown: _withKeys(_ctx.onSubmit, ["enter"]),
          autocomplete: "new-password",
          "data-testid": "pincode"
        }, null, 8, ["text", "message", "error", "onKeydown"])
      ])
    ]),
    _: 1
  }, 8, ["show", "onClose", "onSubmit", "submit-button-text", "loading"]))
}