<template>
    <PopUpModal
        show
        :title="translate('customer_receipt_confirm')"
        @close="close"
        @submit="confirm"
        :submitButtonText="translate('confirm')"
    >
        <div class="confirmation-content">
            <span>{{ translate("print_customer_receipt") }}</span>
        </div>
    </PopUpModal>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { PopUpModal } from "@/ui-elements"
import { useI18n } from "vue-i18n"
import { useCustomerReceiptStore } from "@/store/CustomerReceiptConfirmation"

export default defineComponent({
    name: "CustomerReceiptConfirmation",
    components: {
        PopUpModal,
    },
    setup() {
        const { t: translate } = useI18n()
        const store = useCustomerReceiptStore()

        const close = () => {
            if (store.confirmationReject) {
                store.confirmationReject()
            }
            store.clearState()
        }

        const confirm = () => {
            if (store.confirmationResolve) {
                store.confirmationResolve()
            }
            store.clearState()
        }

        return {
            translate,
            close,
            confirm,
        }
    },
})
</script>

<style lang="scss">
.confirmation-content {
    padding: $padding-l 0;
}
</style>
