import { MenuStructure } from "@/interface/MenuStructure"

export const kitchenName = "kitchen"
export const kitchenMenu: MenuStructure = {
    kitchen: {
        text: "kitchen_screen",
        name: "kitchenBuddy",
        path: "kitchen-screen",
        subMenu: {},
    },
}
