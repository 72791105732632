<template>
    <div class="order-source" :style="{ background }">
        <div class="status-and-time">
            <StatusIcon
                :order-status="order.status"
                :deliverer-id="order.deliverer_id"
                :style="{ filter }"
            />
            <div class="order-time" :style="{ filter }">
                {{ toShortTime(order.time_set || order.time || "") }}
            </div>
        </div>

        <div
            class="icon-and-asap"
            :class="{ 'divider-margin': showRemarks || showDeliverer }"
        >
            <div class="order-source-type">
                <IconBase
                    :icon="orderSourceType"
                    class="source-type-icon"
                ></IconBase>
            </div>
            <div
                class="order-time"
                v-if="order.time === 'asap'"
                :style="{ filter }"
            >
                {{ translate("asap") }}
            </div>
        </div>

        <template v-if="showRemarks">
            <div class="divider"></div>
            <div class="remarks-icon-divider">
                <IconBase icon="comment"></IconBase>
            </div>
        </template>
        <template v-if="showDeliverer">
            <div class="divider"></div>
            <div class="remarks-icon-divider">
                <IconBase icon="scooter"></IconBase>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, toRef } from "vue"
import IconBase from "@/ui-elements/IconBase.vue"
import { getOrderSourceType } from "@/pages/orders/order/utils/getOrderSourceType"
import { useSettingsStore } from "@/store/Settings"
import { useI18n } from "vue-i18n"
import { Order } from "@/interface/orders/Order"
import StatusIcon from "@/pages/orders/StatusIcon.vue"
import { darker, lighter } from "@/utils/useColor"
import { fromUTC } from "@/utils/useTimeShift"
import { getOrderColor, useOrderColor } from "@/utils/useOrderColor"
export default defineComponent({
    name: "OrderSource",
    components: {
        StatusIcon,
        IconBase,
    },
    props: {
        order: { type: Object as PropType<Order>, required: true },
        showRemarks: Boolean,
        showDeliverer: Boolean,
    },
    setup(props) {
        const { t: translate } = useI18n()
        const settings = useSettingsStore().settings

        const toShortTime = (time: string) => {
            if (time === "asap") {
                return time.toUpperCase()
            }

            return time
        }
        const orderSourceType = computed(() =>
            getOrderSourceType(props.order.source.type, props.order.source.info)
        )

        const { filter, background } = useOrderColor(toRef(props, "order"))

        return {
            translate,
            toShortTime,
            orderSourceType,
            settings,
            filter,
            background,
            fromUTC,
        }
    },
})
</script>
<style lang="scss">
.order-source {
    padding: $padding-m $padding-s;
    display: flex;
    border-radius: $radius;
    flex-direction: column;
    background: $order-delivered-or-being-delivered;

    div,
    span {
        color: $white;
    }

    .status-and-time {
        display: flex;
        align-items: center;

        .order-time {
            padding-left: $padding-xs;
            font-weight: $font-weight-medium;
        }
    }

    .icon-and-asap {
        display: flex;
        align-items: center;
        column-gap: $margin-xs;
        margin-top: $margin-xs;
    }

    .order-source-type {
        border-radius: 2.7rem;
        width: 1.25rem;
        height: 1.25rem;
        background: rgb(0 0 0 / 30%);
        display: flex;
        align-items: center;
        justify-content: center;

        .source-type-icon {
            font-size: $font-size-nav;
        }
    }

    .divider-margin {
        margin-bottom: $margin-s;
    }

    .divider {
        border-top: 0.5px solid rgb(255 255 255 / 70%);
    }

    .remarks-icon-divider {
        margin: auto;
        padding: $padding-m 0 $padding-xs 0;
    }
}
</style>
