<template>
    <Tag class="price" :tag-style="tagStyle" :small-tag="true">
        <template v-slot:tagContent>
            {{ paymentTotal }}
            <div
                v-if="isActuallyPaid(order) || isPinFinal(order)"
                class="vertical-line"
            />
            <span v-if="isActuallyPaid(order) || isPinFinal(order)">{{
                paymentStatus
            }}</span>
        </template>
    </Tag>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue"
import Tag from "@/ui-elements/Tag.vue"
import { useFormatCurrency } from "@/utils/useCurrencySymbol"
import { useI18n } from "vue-i18n"
import { isActuallyPaid, isPinFinal } from "@/utils/useIsOrderPaid"
import { Order } from "@/interface/orders/Order"

export default defineComponent({
    name: "OrderPrice",
    components: {
        Tag,
    },
    props: {
        order: {
            type: Object as PropType<Order>,
            default: () => {},
        },
    },
    setup(props) {
        const { t: translate } = useI18n()
        const tagStyle = computed(() =>
            props.order.paid_at !== null ? "success" : "secondary"
        )
        const paymentTotal = computed(() =>
            useFormatCurrency(props.order.total)
        )
        const paymentStatus = computed(() => {
            if (!props.order) {
                return ""
            }
            return translate(
                "var_order.paymentStatus." + props.order.payment.status
            )
        })

        return {
            tagStyle,
            paymentStatus,
            paymentTotal,
            isActuallyPaid,
            isPinFinal,
        }
    },
})
</script>

<style lang="scss">
.tag.price {
    height: max-content;
    font-weight: $font-weight-medium;
    display: flex;
    justify-content: center;
    white-space: nowrap;
}
</style>
