import {
    ORDER_DELIVERIES,
    ORDER_STATUSES_FOR_LABELS,
    Order,
} from "@/interface/orders/Order"
import { useI18n } from "vue-i18n"
import { Deliverer } from "@/interface/deliverer/Deliverer"
import { SelectOption } from "@/ui-elements/input/select/SelectComponent.vue"
import { useUtilsStore } from "@/store/Utils"
import { PAYMENT_STATUSES } from "@/interface/orders/Payment"
import { computed, Ref } from "vue"
import { useDelivererStore } from "@/store/Deliverer"
import { isMethodShown } from "@/pages/orders/utils/usePaymentMethods"
import { useFormatCurrency } from "@/utils/useCurrencySymbol"
import { useSettingsStore } from "@/store/Settings"

export const useOrderOptions = (
    order: Ref<Order>,
    initialPaymentMethod?: string
) => {
    const delivererStore = useDelivererStore()
    const utilsStore = useUtilsStore()
    const { t: translate } = useI18n()

    const statusOptions: SelectOption[] = ORDER_STATUSES_FOR_LABELS.map(
        (status) => {
            return {
                label: translate("var_order.status." + status),
                value: status,
            }
        }
    )

    const deliveryOptions: SelectOption[] = ORDER_DELIVERIES.map((delivery) => {
        return {
            label: translate("var_order.delivery." + delivery),
            value: delivery,
        }
    })

    const delivererOptions = computed(() => {
        if (order.value.delivery !== "deliver") {
            return []
        }

        const options = delivererStore.deliverers.map(
            (deliverer: Deliverer) => {
                return {
                    value: deliverer.id,
                    label: deliverer.first_name + " " + deliverer.last_name,
                }
            }
        )

        return [{ value: null, label: "None" }, ...options]
    })

    const paymentStatusOptions: SelectOption[] = PAYMENT_STATUSES.map(
        (status) => {
            return {
                label: translate("var_order.paymentStatus." + status),
                value: status,
            }
        }
    )

    const paymentMethodOptions = computed(() => {
        return Object.keys(utilsStore.paymentMethods)
            .filter((methodKey) => {
                return (
                    utilsStore.paymentMethods[methodKey].active &&
                    isMethodShown(methodKey)
                )
            })
            .map((methodKey) => {
                const settingsStore = useSettingsStore()
                let label = utilsStore.paymentMethods[methodKey].name
                let isDisabled = false
                if (order.value.payment2.amount) {
                    label =
                        utilsStore.paymentMethods[methodKey].name +
                        (order.value.payment2.method
                            ? " (" +
                              useFormatCurrency(
                                  order.value.total -
                                      order.value.payment2.amount
                              ) +
                              ")"
                            : "")
                }

                if (
                    methodKey === "pin" &&
                    !!Number(settingsStore.settings.adyen_pin_active) &&
                    (settingsStore.settings.adyen_pin_poiid ||
                        localStorage.getItem("adyen_pin_poiid_local"))
                ) {
                    isDisabled = true
                }

                return {
                    label: label,
                    value: methodKey,
                    disabled: isDisabled,
                }
            })
    })

    return {
        statusOptions,
        deliveryOptions,
        delivererOptions,
        paymentStatusOptions,
        paymentMethodOptions,
    }
}
