import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-630ae80b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["wrapper", _ctx.appearance])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["accordion", { open: _ctx.showContent }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["title", { 'selected-title': _ctx.showContent }])
      }, [
        _renderSlot(_ctx.$slots, "title", {}, undefined, true)
      ], 2),
      _createVNode(_component_IconBase, {
        class: "chevron-icon",
        icon: _ctx.showContent ? 'chevron-up' : 'chevron-down'
      }, null, 8, ["icon"])
    ], 2),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "content", {}, undefined, true)
    ], 512), [
      [_vShow, _ctx.showContent]
    ])
  ], 2))
}