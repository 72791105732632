export const PAYMENT_STATUSES = [
    "init",
    "pending",
    "paid",
    "refund",
    "delivered",
] as const

export type PaymentStatusType = typeof PAYMENT_STATUSES[number]

export interface OrderPayment {
    option: string
    costs: number
    status: PaymentStatusType
    status_name: string
    result: string
    method: "cash" | "pin" | "account" | "online"
    method_name: string
}

export type PaymentMethodIdentifiers = {
    [key: string]: {
        name: string
        active: boolean
        enabled: boolean
    }
}

export type PaymentMethodMap = Record<string, string>
