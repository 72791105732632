import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tag", [
            _ctx.tagStyle.includes('tag-') ? _ctx.tagStyle : `tag-${_ctx.tagStyle}`,
            { smallTag: _ctx.smallTag },
        ]])
  }, [
    _renderSlot(_ctx.$slots, "tagContent", {}, undefined, true)
  ], 2))
}