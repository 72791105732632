<template>
    <PanelComponent class="statistics-tile" :footer="false" :class="type">
        <template v-if="type === 'donut'">
            <template v-for="chart in chartsData" :key="chart.name">
                <div class="spacer"></div>
                <DonutDisplay
                    :data="chart"
                    :isLoading="isLoading"
                ></DonutDisplay>
            </template>
            <div class="spacer"></div>
        </template>
        <template v-else-if="type === 'saldo'">
            <SaldoDisplay :data="saldoData"></SaldoDisplay>
        </template>
    </PanelComponent>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import DonutDisplay from "@/ui-elements/stats-tile/DonutDisplay.vue"
import PanelComponent from "@/ui-elements/layout/PanelComponent.vue"
import {
    DonutChartData,
    SaldoData,
    StatisticsTileType,
} from "@/interface/components/StatisticsTile"
import SaldoDisplay from "@/ui-elements/stats-tile/SaldoDisplay.vue"
export default defineComponent({
    name: "StatisticsTile",
    components: {
        SaldoDisplay,
        DonutDisplay,
        PanelComponent,
    },
    props: {
        type: {
            type: String as PropType<StatisticsTileType>,
            default: "donut",
        },
        chartsData: {
            type: Array as PropType<DonutChartData[]>,
        },
        saldoData: {
            type: Object as PropType<SaldoData>,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
})
</script>

<style lang="scss">
.panel.statistics-tile {
    background: $dark-gradient;
    margin-bottom: $margin-s;

    .panel-content {
        display: flex;
    }

    &.donut {
        padding: $padding-xl 0;

        .panel-content {
            flex-flow: row nowrap;
        }
    }

    &.saldo {
        padding: $padding $padding-l;

        .panel-content {
            flex-flow: column nowrap;
        }
    }

    .spacer {
        flex: 1 1;
    }
}
</style>
