import { cents } from "@/utils/math/Currency"

export const floor = (value: string | number, spacing: number = 5) =>
    Math.floor(
        (typeof value === "string" ? parseInt(value) : value) / spacing
    ) * spacing

/** Familiarize yourself with the use of toNearest, since it's not intuitive.
 * For example with 20, it rounds to the nearest 5 cents. **/
export const round = (
    value: string | number,
    digits: number,
    toNearest: number | undefined
): number => {
    value = typeof value === "string" ? parseInt(value) : value
    if (typeof toNearest === "number") {
        value = Math.round(value * toNearest) / toNearest
    }
    const precision: number = Math.pow(10, digits)
    return Math.round(value * precision) / precision
}

export const generateRandomString = (length: number = 7): string =>
    (Math.random() + 1).toString(36).substring(length)

export const divideAmountOverPayers = (
    numberOfPayers: number = 2,
    amountToShare: number = 0
): {
    personTotal: number
    lastPersonTotal: number
} => {
    const personTotal: number = cents(amountToShare / numberOfPayers)

    // The last person may need a small correction due to amounts that
    // cannot be divided totally equally (for example 100 / 3)
    const lastPersonTotal: number = cents(
        amountToShare - personTotal * (numberOfPayers - 1) // The amount which is paid by the others
    )

    return {
        personTotal: personTotal,
        lastPersonTotal: lastPersonTotal,
    }
}
