<template>
    <BaseTable :selectable="false" :deletable="false" class="mb-4">
        <template #list-header>
            <TableCell> {{ translate("online_orders") }} </TableCell>
            <TableCell> {{ translate("online_paid") }} </TableCell>
            <TableCell> GMB </TableCell>
            <TableCell> {{ translate("top_of_clients") }} </TableCell>
            <TableCell> #AF </TableCell>
            <TableCell> {{ translate("start") }} </TableCell>
            <TableCell
                >{{ translate("client") }} {{ translate("status") }}</TableCell
            >
        </template>
        <template #list>
            <TableRow :selectable="false">
                <TableCell>
                    {{ adminInfo?.online_orders?.counts?.all || 0 }} /
                    {{ adminInfo?.online_orders?.commission || "0.00%" }} /
                    {{ adminInfo?.online_orders?.avg_amount || "€0.00" }}
                </TableCell>
                <TableCell>
                    {{ adminInfo?.online_orders?.counts?.online_paid || 0 }}
                </TableCell>
                <TableCell
                    :class="[
                        adminInfo?.google_my_business?.is_enabled
                            ? 'active'
                            : 'inactive',
                    ]"
                >
                    {{
                        adminInfo?.google_my_business?.is_enabled
                            ? translate("active")
                            : translate("inactive")
                    }}
                    /
                    {{ adminInfo?.google_my_business?.posts || 0 }}
                </TableCell>
                <TableCell>
                    {{ adminInfo?.ranking?.current_rank || 0 }} /
                    {{ adminInfo?.ranking?.lowest_rank || 0 }}
                </TableCell>
                <TableCell>
                    {{ adminInfo?.abo_inv || 0 }}
                </TableCell>
                <TableCell>
                    {{ adminInfo?.startdate || "" }}
                </TableCell>
                <TableCell :class="[isReadOnly ? 'inactive' : 'active']">
                    {{
                        isReadOnly
                            ? translate("blocked_or_archived")
                            : translate("active")
                    }}
                </TableCell>
            </TableRow>
        </template>
    </BaseTable>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent } from "vue"
import { useI18n } from "vue-i18n"
import BaseTable from "@/ui-elements/layout/tables/BaseTable.vue"
import TableCell from "@/ui-elements/layout/tables/TableCell.vue"
import TableRow from "@/ui-elements/layout/tables/TableRow.vue"
import { useUserStore } from "@/store/User"
import { User } from "@/interface/user/User"

export default defineComponent({
    name: "AdminClientStatistics",
    components: {
        BaseTable,
        TableCell,
        TableRow,
    },
    setup() {
        const { t: translate } = useI18n()
        const userStore = useUserStore()

        const isReadOnly = computed(() => userStore?.user?.is_read_only)

        const adminInfo: ComputedRef<User["admin_info"]> = computed(
            () => userStore?.user?.admin_info
        )

        return {
            translate,
            adminInfo,
            isReadOnly,
        }
    },
})
</script>
