<template>
    <div class="announcement" :class="type">
        <IconBase :icon="iconName" :class="type" />
        <span class="announcement-text">{{ announcementData.text }}</span>
        <span v-if="announcementData.link"
            ><a class="link-text">{{ announcementData.link }}</a></span
        >
        <ButtonBase
            class="close"
            variant="text"
            name="announcement-close"
            icon="delete"
            v-if="showClose"
        ></ButtonBase>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue"
import { Announcement } from "@/interface/components/Announcement"
import { IconBase } from "@/ui-elements"
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue"

export default defineComponent({
    name: "AnnouncementComponent",
    components: {
        ButtonBase,
        IconBase,
    },
    props: {
        announcementData: {
            type: Object as PropType<Announcement>,
            required: true,
        },
        showClose: {
            type: Boolean,
            required: true,
        },
    },
    setup(props) {
        const iconName = ref(props.announcementData.icon)
        const type = ref(props.announcementData.type)

        return {
            iconName,
            type,
        }
    },
})
</script>

<style scoped lang="scss">
.announcement {
    display: flex;
    align-items: center;
    font-size: $font-size-base;
    border-radius: $radius;
    padding: $padding;

    &.warning,
    .warning {
        background: lighten($warning-color, 40);
        color: $warning-color;
    }

    &.success,
    .success {
        background: lighten($success-color, 35);
        color: $success-color;
    }

    &.error,
    .error {
        background: lighten($error-color, 35);
        color: $error-color;
    }

    &.info,
    .info {
        background: lighten($info-color, 5);
        color: $info-color;
    }

    .announcement-text {
        padding: 0 $padding-s;
    }

    .close {
        margin-left: auto;
        color: $black;
    }

    .link-text {
        font-weight: $font-weight-medium;
        color: $black;
    }
}
</style>
