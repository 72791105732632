import { RouteRecordRaw } from "vue-router"
import { ordersMenu } from "@/pageStructure/OrdersStructure"

export const OrdersRoutes: RouteRecordRaw = {
    path: "/" + ordersMenu.orders.path,
    name: ordersMenu.orders.name,
    meta: { name: ordersMenu.orders.name },
    component: () =>
        import(/* webpackPrefetch: true */ "@/pages/orders/OrdersIndex.vue"),
    props: true,
}
