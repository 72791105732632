import { MenuStructure } from "@/interface/MenuStructure"

export const posName = "pos"
export const posMenu: MenuStructure = {
    pos: {
        text: "pos",
        name: "pos",
        path: "pos",
        subMenu: {},
    },
}
