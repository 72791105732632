<template>
    <div class="tab-content mr-3" :ref="scrollAreaRef()">
        <div class="order-table">
            <div class="row header">
                <div class="cell quantity text-right">
                    {{ translate("quantity") }}
                </div>
                <div class="cell product">
                    {{ translate("product") }}
                </div>
                <div
                    class="cell extras"
                    v-if="!Number(settings.orderpopup_extras_array)"
                >
                    {{ translate("extras") }}
                </div>
                <div class="cell remarks">
                    {{ translate("remarks") }}
                </div>
                <div class="cell total text-right">
                    {{ translate("total_price") }}
                </div>
            </div>
            <ProductRow
                v-for="product of order.products"
                :key="product.id"
                :product="product"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import { useI18n } from "vue-i18n"
import ProductRow from "@/pages/orders/modals/order/tabs/ProductRow.vue"
import { Order } from "@/interface/orders/Order"
import { useSettingsStore } from "@/store/Settings"

export default defineComponent({
    name: "OrderTab",
    components: {
        ProductRow,
    },
    props: {
        order: {
            type: Object as PropType<Order>,
            required: true,
        },
        scrollAreaRef: Function,
    },
    setup() {
        const { t: translate } = useI18n()
        const { settings } = useSettingsStore()
        return {
            translate,
            settings,
        }
    },
})
</script>

<style lang="scss">
.order-table {
    display: table;
    width: 100%;

    .header {
        .cell {
            font-weight: $font-weight-medium;
        }
    }
    .row {
        display: table-row;
        width: 100%;
    }

    .cell {
        display: table-cell;
        vertical-align: middle;
        padding: $padding-s $padding-m;
    }

    .total {
        display: flex;
        row-gap: $margin-s;
        flex-direction: column;

        .discount {
            color: $darker-gray;
        }
    }
}
</style>
