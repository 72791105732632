<template>
    <PopUpModal
        :show="
            updatesStore().riceCookerUpdateModalIsVisible &&
            !route.fullPath.includes('customer-screen')
        "
        class="update-modal"
    >
        <template #header>
            <div class="header">
                <p>{{ translate("software_update") }}</p>
                <IconBase
                    @click="closeRiceCookerUpdateModal()"
                    icon="delete"
                    class="icon-close"
                    :class="[
                        updatesStore().riceCookerIsUpdating ? 'disabled' : '',
                    ]"
                />
            </div>
        </template>
        <div
            class="content"
            :class="[
                !updatesStore().riceCookerUpdateErrorIsVisible
                    ? 'content-white-spaced'
                    : '',
            ]"
        >
            <h3>{{ translate("updates_available") }}</h3>
            <p>{{ translate("update_available_to_install") }}</p>
            <p class="text-xs text-gray-500 italic mt-1">
                {{ translate("update_fails_or_long") }}
                <a
                    href="https://downloads.foodticket.nl/OrderBuddy.exe"
                    class="text-xs"
                    @click="downloadUpdateManually()"
                    download
                    >{{ translate("update_download_manual") }}</a
                >.
            </p>
            <p
                v-if="manualDownloadNotice"
                class="text-xs italic mt-2 w-full p-2 bg-amber-300 rounded max-w-[30rem]"
            >
                {{ translate("update_download_manual_notice") }}
            </p>
        </div>
        <NotificationComponent
            class="notification"
            notification-type="error"
            v-if="updatesStore().riceCookerUpdateErrorIsVisible"
        >
            <template #notificationLabel>{{
                translate("update_failed")
            }}</template>
            <template #notificationMessage>{{
                translate("please_try_again")
            }}</template>
        </NotificationComponent>
        <template #actions>
            <div class="flex justify-end gap-4 w-full">
                <SelectComponent
                    :disabled="updatesStore().riceCookerIsUpdating"
                    :value="updatesStore().riceCookerPostponeUpdateValue"
                    :options="riceCookerPostponeUpdateOptions"
                    :can-search="false"
                    @update:value="
                        updatesStore().postponeRiceCookerUpdate($event)
                    "
                    :drop-up="true"
                    class="w-full"
                />

                <ButtonBase
                    :loading="updatesStore().riceCookerIsUpdating"
                    @click="updatesStore().applyRiceCookerUpdate()"
                    class="w-full"
                >
                    <template v-if="!updatesStore().riceCookerIsUpdating">
                        {{ translate("install_now") }} ({{
                            Math.floor(
                                updatesStore().riceCookerAutomaticUpdateTimer /
                                    60
                            )
                        }}:
                        {{
                            updatesStore().riceCookerAutomaticUpdateTimer -
                            Math.floor(
                                updatesStore().riceCookerAutomaticUpdateTimer /
                                    60
                            ) *
                                60
                        }})
                    </template>
                    <template v-else>
                        {{ translate("installing") }}
                    </template>
                </ButtonBase>
            </div>
        </template>
    </PopUpModal>
</template>

<script lang="ts" setup>
import { IconBase } from "@/ui-elements"
import { useI18n } from "vue-i18n"
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue"
import NotificationComponent from "@/ui-elements/NotificationComponent.vue"
import SelectComponent from "@/ui-elements/input/select/SelectComponent.vue"
import PopUpModal from "@/ui-elements/layout/modals/PopUpModal.vue"
import { updatesStore, riceCookerPostponeUpdateOptions } from "@/store/Updates"
import { useRoute } from "vue-router"
import { ref } from "vue"
import { gtmSendCustomEvent } from "@/utils/gtmSendEvent"
const { t: translate } = useI18n()
const route = useRoute()
const manualDownloadNotice = ref(false)
function closeRiceCookerUpdateModal() {
    if (!updatesStore().riceCookerIsUpdating) {
        gtmSendCustomEvent("RiceCookerUpdateModal", "closeUpdateModal")
        updatesStore().postponeRiceCookerUpdate(5, false)
    }
}
function downloadUpdateManually() {
    gtmSendCustomEvent("RiceCookerUpdateModal", "downloadUpdateManually")
    manualDownloadNotice.value = true
}
</script>

<style lang="scss" scoped>
.notification {
    @apply p-5 ml-3 mr-3 mt-3;
}
.content {
    @apply bg-white p-5 rounded;
}
.content-white-spaced {
    @apply p-5 pb-20;
}
// TODO: why are these not in the global styles?
.header {
    color: $white;
    background: $primary-gradient;
    margin: -25px -15px 0 -15px !important;
    padding-top: 15px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
    flex-wrap: nowrap;
    p {
        margin: 15px;
        font-weight: $font-weight-bold;
        font-size: $font-size-h2;
    }
}
.icon-close {
    font-size: 0.9rem;
    padding: 10px;
    margin-right: $margin;
    font-weight: bold;
    cursor: pointer;
    &.icon-base {
        color: white;
    }
    &.disabled {
        cursor: default;
        &.icon-base {
            color: #b0b0b0;
        }
    }
}
</style>
