import L from "leaflet"

export const isCoordinate = (_: string) => {
    if (_) {
        const coord = _.split(",")
        const twoCoords = coord.length === 2
        const number = coord
            .map((_) => parseFloat(_))
            .reduce((acc, curr) => acc && !isNaN(curr), true)
        return twoCoords && number
    } else {
        return false
    }
}

export const convertCoordinate = (coordinate: string) =>
    coordinate.split(",").map((string) => parseFloat(string))

export const centerOfCoordinates = (
    array: number[][],
    defaultCoordinate: number[]
) => {
    const center = array
        .reduce(
            (accumulatorCoordinate, currentCoordinate) =>
                accumulatorCoordinate.map(
                    (number, index) => number + currentCoordinate[index]
                ),
            [0, 0]
        )
        .map((number) => number / array.length)
    return array.length ? center : defaultCoordinate
}
export const boundsOfCoordinates = (array: number[][]) => {
    return array.length
        ? L.latLngBounds(
              array.map((coordinate) => coordinateToLatLng(coordinate))
          )
        : undefined
}

export const coordinateToLatLng = (coordinate: number[]) =>
    new L.LatLng(coordinate[0], coordinate[1])

export const geoJsonCoordinateToLatLng = (coordinate: number[]) =>
    new L.LatLng(coordinate[1], coordinate[0])
