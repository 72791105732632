import { defineStore } from "pinia"
import axios from "axios"

export type ClosingEntry = {
    id: number
    client_id: number
    type: "deliver" | "pickup" | "dinein"
    from: {
        date: string | null
        time: string | null
    }
    till: {
        date: string | null
        time: string | null
    }
    // 0=closed all day
    // 1=modified hours
    // 2=extra hours
    hours: 0 | 1 | 2
    reason: string | null
    custom: null // you can disregard this, it's unused
}

export type ClosingHoursStore = {
    closingHours: ClosingEntry[]
}

export const useClosingHoursStore = defineStore({
    id: "closingHours",
    state: () =>
        ({
            closingHours: [],
        } as ClosingHoursStore),
    actions: {
        async fetchClosingHours(): Promise<boolean> {
            try {
                const response = await axios.get(
                    "client/hours/closed/selection"
                )
                this.closingHours = response.data.data
                return true
            } catch (e) {
                return false
            }
        },
    },
})
