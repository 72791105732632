import Pusher from "pusher-js"
import Echo from "laravel-echo"
import axios from "axios"
import * as Sentry from "@sentry/vue"
import { useUserStore } from "@/store/User"
import { dataHydration } from "@/services/DataHydrationService"
import { useOrdersStore } from "@/store/Orders"
import { useSettingsStore } from "@/store/Settings"
import { useTablesStore } from "@/store/Tables"

class WebsocketService {
    pusher: Pusher | undefined
    echo: Echo | undefined
    subscribed: boolean = false
    websocketChannel: any = undefined

    start() {
        if (this.isConnected()) {
            return
        }
        if (typeof this.pusher === "undefined" && dataHydration.isActive()) {
            this.pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY || "", {
                forceTLS: false,
                disableStats: true,
                enabledTransports: ["ws", "wss"],
                cluster: process.env.VUE_APP_PUSHER_CLUSTER,
                authorizer: (channel) => {
                    return {
                        authorize: (socketId: string, callback: Function) => {
                            axios
                                .post("/broadcasting/auth", {
                                    socket_id: socketId,
                                    channel_name: channel.name,
                                })
                                .then((response) => {
                                    callback(null, response.data)
                                })
                                .catch((error) => {
                                    callback(error)
                                })
                        },
                    }
                },
            })
        }
        if (typeof this.echo === "undefined" && dataHydration.isActive()) {
            const options = {
                broadcaster: "pusher",
                key: process.env.VUE_APP_PUSHER_KEY,
                forceTLS: false,
                encrypted: true,
                disableStats: true,
                enabledTransports: ["ws", "wss"],
                cluster: process.env.VUE_APP_PUSHER_CLUSTER,
            }
            this.echo = new Echo({
                ...options,
                client: this.pusher,
            })
        }

        if (
            typeof this.pusher !== "undefined" &&
            typeof this.echo !== "undefined"
        ) {
            const clientId = useUserStore().user.id

            this.pusher.connection.bind(
                "connecting",
                () => (this.subscribed = false)
            )

            const listenAndHydrateSingleInstancesOfAModule = (
                channel: any,
                eventPrefix: string,
                module: string,
                store: any
            ) => {
                channel
                    .listen(".table.created", (event: any) =>
                        useTablesStore().objectCreated(event?.id)
                    )
                    .listen(".order.placed", (event: any) =>
                        useOrdersStore().objectPlaced(event?.id)
                    )
                    .listen(`.${eventPrefix}.updated`, (event: any) =>
                        store.objectUpdated(event?.id)
                    )
                    .listen(`.${eventPrefix}.deleted`, (event: any) =>
                        store.objectDeleted(event?.id)
                    )
            }

            const listenAndRehydrateModule = (
                channel: any,
                eventPrefix: string,
                module: string
            ) => {
                channel
                    .listen(`.${eventPrefix}.created`, () =>
                        dataHydration.hydrateModule(module, false, 2000)
                    )
                    .listen(`.${eventPrefix}.updated`, () =>
                        dataHydration.hydrateModule(module, false, 2000)
                    )
                    .listen(`.${eventPrefix}.deleted`, () =>
                        dataHydration.hydrateModule(module, false, 2000)
                    )
            }

            const listenAndRehydrateSettings = (channel: any) => {
                const eventPrefix = "setting"
                const module = "settings"
                const store = useSettingsStore()

                channel
                    .listen(`.${eventPrefix}.created`, () =>
                        dataHydration.hydrateModule(module, false, 2000)
                    )
                    .listen(`.${eventPrefix}.updated`, () =>
                        dataHydration.hydrateModule(module, false, 2000)
                    )
                    .listen(`.${eventPrefix}.deleted`, (event: any) =>
                        store.removeSetting(event?.id)
                    )
            }

            const channel = this.echo
                .private(`client.${clientId}`)
                .subscribed(() => (this.subscribed = true))

            this.websocketChannel = channel

            listenAndRehydrateModule(channel, "user", "user")
            listenAndRehydrateModule(channel, "deliverer", "deliverers")
            listenAndRehydrateModule(channel, "discount", "discounts")
            listenAndRehydrateModule(channel, "discountproduct", "discounts")
            listenAndRehydrateModule(
                channel,
                "discountproductproduct",
                "discounts"
            )
            // listenAndRehydrateModule(channel, "order", "orders")
            listenAndHydrateSingleInstancesOfAModule(
                channel,
                "order",
                "orders",
                useOrdersStore()
            )
            listenAndHydrateSingleInstancesOfAModule(
                channel,
                "table",
                "tables",
                useTablesStore()
            )
            listenAndRehydrateModule(channel, "category", "posMenu")
            listenAndRehydrateModule(channel, "product", "posMenu")
            listenAndRehydrateModule(channel, "extra", "posMenu")
            listenAndRehydrateModule(channel, "item", "posMenu")
            listenAndRehydrateModule(channel, "clientzipcode", "zipcodes")
            listenAndRehydrateModule(channel, "printer", "printers")
            // listenAndRehydrateModule(channel, "setting", "settings")
            listenAndRehydrateSettings(channel)
            listenAndRehydrateModule(channel, "clientopen", "openingHours")
            listenAndRehydrateModule(channel, "clientclosed", "closingHours")
            listenAndRehydrateModule(channel, "device", "localIps")
        }
    }

    stop() {
        this.pusher?.disconnect()
        this.echo?.disconnect()
        this.pusher = undefined
        this.echo = undefined
        this.subscribed = false
    }

    isConnected(): boolean {
        return this.getConnectionState() === "connected" && this.subscribed
    }

    getConnectionState(): string {
        return this.pusher?.connection.state || "offline (stopped)"
    }
}

export const websocket = new WebsocketService()
