import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!

  return (_openBlock(), _createBlock(_component_TextField, {
    label: _ctx.translate('discount_code'),
    text: _ctx.discount,
    "onUpdate:text": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.discount) = $event)),
    disabled: _ctx.disabled,
    error: !!_ctx.errorMessage,
    message: _ctx.errorMessage,
    icon: _ctx.discountValid ? 'check' : ''
  }, null, 8, ["label", "text", "disabled", "error", "message", "icon"]))
}