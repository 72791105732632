import { ref, Ref, SetupContext, toRaw, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import { toShortDateFormat } from "@/utils/useRefactorDate"

export const useGetURLParams = <ParamsType extends Record<string, any>>() => {
    const route = useRoute()

    return route.query as ParamsType
}
export const createFilterParameters = <
    OptionsType extends { [key: string]: any },
    ParamsType
>(
    filterOptions: Ref<OptionsType>
): ParamsType => {
    const rawObject = toRaw(filterOptions.value)
    const active = Object.keys(filterOptions.value).filter((optionKey) => {
        const value = rawObject[optionKey]
        return !(
            value === "" ||
            (Array.isArray(value) && value.length === 0) ||
            value === false
        )
    })
    return active.reduce((object, optionKey) => {
        const newObj = object
        const value = rawObject[optionKey]
        if (value instanceof Date && !isNaN(value.getTime())) {
            //@ts-ignore
            newObj[optionKey] = toShortDateFormat(value)
        } else if (typeof value === "boolean") {
            //@ts-ignore
            newObj[optionKey] = value ? 1 : 0
        } else {
            //@ts-ignore
            newObj[optionKey] = value
        }
        return newObj
    }, {} as ParamsType)
}

export const removeProperties = (
    object: { [key: string]: any },
    propertiesToRemove: string[]
) =>
    Object.keys(object).reduce((newObject, parameter) => {
        if (!propertiesToRemove.includes(parameter)) {
            newObject[parameter] = object[parameter]
        }
        return newObject
    }, {} as { [key: string]: any })

export const useChangeURLParams = (
    filterParameters: Ref<{ [key: string]: any }>,
    backendOnly: string[]
) => {
    const router = useRouter()
    const route = useRoute()

    watch(filterParameters, () => {
        const objectCopy = Object.assign(toRaw(filterParameters.value), {})
        const newParameters = removeProperties(objectCopy, backendOnly)
        router.push({ path: route.path, query: newParameters })
    })
}

export const onChangeEmit = <OptionsType>(
    field: keyof OptionsType,
    value: any,
    options: OptionsType,
    context: SetupContext
) => {
    context.emit(
        "update:options",
        Object.assign(options, {
            [field]: value,
        })
    )
}
export const emitDate = <OptionsType extends { [key: string]: any }>(
    dateArray: (Date | string)[],
    options: OptionsType,
    context: SetupContext
) => {
    if (dateArray.length === 2) {
        onChangeEmit("start_date", dateArray[0], options, context)
        onChangeEmit("end_date", dateArray[1], options, context)
    }
}

export const useFiltering = <OptionsType, ParamsType>(
    initializeOptions: (queryParams: ParamsType) => OptionsType,
    frontendOnly: string[] = [],
    backendOnly: string[] = []
) => {
    const queryParams = useGetURLParams<ParamsType>()
    const filterOptions = ref(
        initializeOptions(queryParams)
    ) as Ref<OptionsType>
    const filterParameters = ref({}) as Ref<ParamsType>

    watch(
        filterOptions,
        () => {
            filterParameters.value = createFilterParameters<
                OptionsType,
                ParamsType
            >(filterOptions)
        },
        { immediate: true, deep: true }
    )
    useChangeURLParams(filterParameters, backendOnly)
    return {
        filterOptions,
        filterParameters,
    }
}
