export function gtmSendEvent(eventName: string, data: any) {
    const options = {
        event: eventName,
        ...data,
    }
    pushDataLayer(options)
}

function pushDataLayer(options: any) {
    setTimeout(() => {
        if (window["dataLayer"] !== undefined) window["dataLayer"].push(options) // eslint-disable-line
    }, 0)
}

export function gtmSendLoginEvent(data: any) {
    gtmSendEvent("login", data)
}

export function gtmSendCustomEvent(
    eventCategory: string,
    eventAction?: string,
    eventValue?: any
) {
    let eventData = ""
    if (eventAction) {
        eventData = eventAction
    }
    if (eventValue) {
        eventData += `_${eventValue}`
    }
    gtmSendEvent("customEvent", {
        eventParameter: eventCategory,
        eventValue: eventData,
    })
}
