import { formatLeadingZero } from "@/utils/useLeadingZero"

const darkerOperations = [
    (_: number) => (_ / 255) * 0.8 * 255, //Math.tanh(_ / 255) * 255,
    Math.floor,
    (_: number) => Math.min(255, _),
]
const lighterOperations = [
    (_: number) => ((_ / 255) * 0.8 + 0.2) * 255, //(Math.tanh(_ / 255 - 1) + 1) * 255,
    Math.floor,
    (_: number) => Math.min(255, _),
]
const stringToComponent = (color: string, index: number) =>
    parseInt(color.slice(1 + index * 2, 1 + (index + 1) * 2), 16)

const changeColor =
    (operations: Array<(number: number) => number>) => (color: string) =>
        "#" +
        [...Array(3).keys()]
            .map((_: number) => stringToComponent(color, _))
            .map((_: number) =>
                operations.reduce((value, func) => func(value), _)
            )
            .map((_: number) => formatLeadingZero(_.toString(16)))
            .join("")

export const darker = (color: string) => {
    return changeColor(darkerOperations)(color)
}
export const lighter = (color: string) => {
    return changeColor(lighterOperations)(color)
}
