import { CustomerPhoneType } from "@/interface/Customers/Customer"
import { useUserStore } from "@/store/User"

export interface BaseCustomerAddress {
    address2: string
    address3: string
    alt_phone: string
    alt_phone_type: CustomerPhoneType
    city: string
    company: string | null
    country: string
    email: string
    phone: string
    phone_type: CustomerPhoneType
    street: string
    street_number: string
    zipcode: string
    vat?: string
    coc?: string
}

export interface CustomerAddress extends BaseCustomerAddress {
    first_name: string
    last_name: string
    phone_code: string | null
}

export interface CustomerBillingAddress extends BaseCustomerAddress {
    name: string
}

export const getCustomerAddress = (): CustomerAddress => {
    return {
        address2: "",
        address3: "",
        alt_phone: "",
        alt_phone_type: CustomerPhoneType.Unknown,
        city: "",
        company: "",
        country: useUserStore().user.address.country,
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        phone_type: CustomerPhoneType.Unknown,
        phone_code: null,
        street: "",
        street_number: "",
        zipcode: "",
    }
}

export const getCustomerBillingAddress = (): CustomerBillingAddress => {
    return {
        address2: "",
        address3: "",
        alt_phone: "",
        alt_phone_type: CustomerPhoneType.Unknown,
        city: "",
        company: "",
        country: useUserStore().user.address.country,
        email: "",
        name: "",
        phone: "",
        phone_type: CustomerPhoneType.Unknown,
        street: "",
        street_number: "",
        zipcode: "",
    }
}
