import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  class: "body",
  ref: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_ButtonBase = _resolveComponent("ButtonBase")!
  const _component_TabOptions = _resolveComponent("TabOptions")!
  const _component_BrakeTab = _resolveComponent("BrakeTab")!
  const _component_WebshopTab = _resolveComponent("WebshopTab")!
  const _component_PanelComponent = _resolveComponent("PanelComponent")!
  const _component_ModalWindow = _resolveComponent("ModalWindow")!

  return (_openBlock(), _createBlock(_component_ModalWindow, {
    title: _ctx.translate('components_brake_title'),
    show: "",
    onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.brakeStore.closeModal())),
    class: "emergency-brake"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PanelComponent, { ready: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_IconBase, { icon: "warning" }),
            _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.translate("components_brake_title")), 1),
            _createVNode(_component_ButtonBase, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.brakeStore.closeModal())),
              icon: "delete",
              type: "regular",
              variant: "filled",
              class: "close"
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_TabOptions, {
              options: _ctx.tabOptions,
              value: _ctx.tab,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event))
            }, null, 8, ["options", "value"]),
            (_ctx.tab === 'brake')
              ? (_openBlock(), _createBlock(_component_BrakeTab, {
                  key: 0,
                  settings: _ctx.brakeSettings,
                  "onUpdate:settings": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.brakeSettings) = $event))
                }, null, 8, ["settings"]))
              : (_openBlock(), _createBlock(_component_WebshopTab, {
                  key: 1,
                  settings: _ctx.brakeSettings,
                  "onUpdate:settings": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.brakeSettings) = $event))
                }, null, 8, ["settings"]))
          ], 512)
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_ButtonBase, {
            type: "secondary",
            variant: "outlined",
            onClick: _ctx.cancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("cancel")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_ButtonBase, {
            class: "save",
            onClick: _ctx.save
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("save")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}