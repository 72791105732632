import { paramsSerializer } from "@/utils/api/paramsSerializer"
import { defineStore } from "pinia"
import axios from "axios"
import { PaymentMethodIdentifiers } from "@/interface/orders/Payment"

type locale = {
    [key: string]: {
        locale: string
        name: string
    }
}

export const useUtilsStore = defineStore({
    id: "utils",
    state: () => ({
        locales: {} as locale,
        countries: {} as Record<string, string>,
        paymentMethods: {} as PaymentMethodIdentifiers,
        isMobile: false as boolean,
    }),
    getters: {
        haveLocales: (state) => state.locales !== {},
        haveCountries: (state) => state.countries !== {},
    },
    actions: {
        async fetchLocales(): Promise<boolean> {
            try {
                const response = await axios.get("/utils/locales")
                this.locales = response.data.data
                return true
            } catch (e) {
                return false
            }
        },
        async fetchCountries(): Promise<boolean> {
            try {
                const response = await axios.get("/utils/countries")
                this.countries = response.data.data
                return true
            } catch (e) {
                return false
            }
        },
        async fetchPaymentMethods(): Promise<boolean> {
            try {
                const response = await axios.get("/client/payment_methods", {
                    params: {
                        source: "counter",
                    },
                    paramsSerializer: paramsSerializer,
                })
                this.paymentMethods = response.data.data
                return true
            } catch (e) {
                return false
            }
        },
    },
})
