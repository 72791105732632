import { defineStore } from "pinia"
import axios from "axios"
import { RiceCooker } from "@/riceCooker/riceCooker"
import { dataHydration } from "@/services/DataHydrationService"
import { websocket } from "@/services/WebsocketService"
import { useCartStore } from "@/store/cart/Cart"
import { usePrintersStore } from "@/store/Printers"
import { useOrdersStore } from "@/store/Orders"
import { useSettingsStore } from "@/store/Settings"
import { usePosMenusStore } from "@/store/PosMenus"
import { usePOSStore } from "@/store/POS"
import { useUserStore } from "@/store/User"
import { useDelivererStore } from "@/store/Deliverer"
import { useBrakeStore } from "@/store/Brake"
import { useTablesStore } from "@/store/Tables"
import { useCartDiscountsStore } from "@/store/CartDiscount"
import { useClosingHoursStore } from "@/store/ClosingHours"
import { useOBMessagesStore } from "@/store/OBMessage"
import { useOpeningHoursStore } from "@/store/OpeningHours"
import { useZipcodesStore } from "@/store/Zipcodes"
import { useCustomerModalStore } from "@/store/CustomerModal"
import { offlineModeStore } from "@/store/offlineMode"
import { multiLocationStore } from "@/store/MultiLocation"

/**
 * When requirePincode = true, show modal on the screen, when pincode passed
 * call the pincodeResolve(), whenever the user cancels the pincode (closes the modal) then
 * call the pincodeReject()
 */
export const useAPIStore = defineStore({
    id: "api",
    state: () =>
        ({
            bearerToken: "",
            requirePincode: false,
            pincodeResolve: null,
            pincodeReject: null,
            isSudoMode: getInitialSudoMode(),
            preRedirectRoute: "",
            validateOnly: false,
        } as {
            bearerToken: string
            requirePincode: boolean
            pincodeResolve: Function | null
            pincodeReject: Function | null
            isSudoMode: boolean
            preRedirectRoute: string
            // to get access to some functionality only once by validating pincode
            validateOnly: boolean
        }),
    actions: {
        async loadBearerToken() {
            const localStorageToken = localStorage.getItem("authToken")
            if (localStorageToken) {
                await this.setAxiosBearerToken(localStorageToken)
            }
        },
        hasBearerToken() {
            return Boolean(this.bearerToken)
        },
        async removeBearerToken() {
            localStorage.removeItem("authToken")
            await this.setAxiosBearerToken("")
            await RiceCooker.logout()
        },
        async setBearerToken(token: string) {
            localStorage.setItem("authToken", token)
            await this.setAxiosBearerToken(token)
        },
        async setAxiosBearerToken(token: string) {
            this.bearerToken = token
            axios.defaults.headers.common["Authorization"] = "Bearer " + token
        },
        setRequirePincode(
            value: boolean,
            validateOnly = false
        ): Promise<string> {
            this.requirePincode = value
            if (!value) {
                return Promise.resolve("")
            }

            this.validateOnly = validateOnly
            return new Promise((resolve, reject) => {
                this.pincodeResolve = resolve
                this.pincodeReject = reject
            })
        },
        enableSudoMode(expirationDate: string) {
            localStorage.setItem("sudoExpirationDate", expirationDate)
            this.isSudoMode = true
        },
        disableSudoMode() {
            localStorage.removeItem("sudoExpirationDate")
            this.isSudoMode = false
        },
        setPreRedirectRoute(routePath: string) {
            this.preRedirectRoute = routePath !== "/auth/login" ? routePath : ""
        },
        clearPreRedirectRoute() {
            this.preRedirectRoute = ""
        },
        /**
         * For multi location (kitchen and branch) we use the logout function
         * to clear the stores, except for multiLocation and all local
         * storage items concerning multi location. However, if the logout
         * button is used, then we clear everything.
         */
        logout(keepMultiLocationStorage: boolean = false) {
            // if in RC and offline, do not allow to logout
            if (RiceCooker.isPresent() && offlineModeStore().isOffline) {
                return Promise.resolve(true)
            }

            dataHydration.rcTablesCreated = false
            localStorage.removeItem("foodticketresellerimpersonation")
            axios.defaults.headers.common["foodticketresellerimpersonation"] =
                ""

            const resets: Array<any> = [
                useAPIStore().removeBearerToken(),
                useBrakeStore().$reset(),
                useCartStore().$reset(),
                useCartDiscountsStore().$reset(),
                useClosingHoursStore().$reset(),
                useCustomerModalStore().$reset(),
                useDelivererStore().$reset(),
                useOBMessagesStore().$reset(),
                useOpeningHoursStore().$reset(),
                useOrdersStore().$reset(),
                usePOSStore().$reset(),
                usePrintersStore().$reset(),
                useSettingsStore().$reset(),
                useTablesStore().$reset(),
                useUserStore().$reset(),
                useZipcodesStore().$reset(),
                dataHydration.stop(),
                websocket.stop(),
            ]

            if (!keepMultiLocationStorage) {
                localStorage.removeItem("multi_location_type")
                localStorage.removeItem("multi_main_location_id")
                localStorage.removeItem("multi_selected_location_id")
                resets.push(multiLocationStore().$reset())
                resets.push(usePosMenusStore().$reset())
            }

            return Promise.allSettled(resets)
        },
    },
})

const getInitialSudoMode = (): boolean => {
    const sudoExpirationDate = localStorage.getItem("sudoExpirationDate")
    if (!sudoExpirationDate) {
        return false
    }
    const currentTime = new Date().getTime()
    const sudoExpirationTime = new Date(sudoExpirationDate).getTime()
    if (currentTime < sudoExpirationTime) {
        return true
    }
    localStorage.removeItem("sudoExpirationDate")
    return false
}
