<template>
    <InputField
        class="switch-input-wrapper"
        :error="error"
        :success="success"
        :label="label"
        :name="name"
        :label-icon="labelIcon"
        :label-icon-class="labelIconClass"
        :class="{ disabled: disabled }"
    >
        <template v-slot:input>
            <div class="flex items-center switch-wrapper">
                <span
                    class="wrapper-label"
                    v-if="uncheckedLabel"
                    :class="{ gray: checked }"
                    >{{ uncheckedLabel }}</span
                >
                <div
                    class="check-switch"
                    @click.stop="$emit('update:checked', !checked)"
                    :class="{ checked: checked }"
                >
                    <div class="mark un-checkmark">
                        <IconBase
                            v-if="uncheckIcon"
                            :icon="uncheckIcon"
                        ></IconBase>
                    </div>
                    <div class="mark checkmark">
                        <IconBase v-if="checkIcon" :icon="checkIcon"></IconBase>
                    </div>
                </div>
                <span
                    class="wrapper-label"
                    v-if="checkedLabel"
                    :class="{ gray: !checked }"
                    >{{ checkedLabel }}</span
                >
                <h5 v-if="description" class="wrapper-description">
                    {{ description }}
                </h5>
            </div>
        </template>
    </InputField>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import IconBase from "@/ui-elements/IconBase.vue"
import InputField from "@/ui-elements/input/InputField.vue"
export default defineComponent({
    name: "CheckSwitch",
    components: {
        IconBase,
        InputField,
    },
    props: {
        checked: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        checkIcon: {
            type: String,
            default: "",
        },
        uncheckIcon: {
            type: String,
            default: "",
        },
        error: {
            type: Boolean,
            default: false,
        },
        success: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
        },
        name: {
            type: [Array, String] as PropType<string | string[]>,
            default: () => [],
        },
        labelIcon: {
            type: String,
        },
        labelIconClass: {
            type: String,
        },
        uncheckedLabel: {
            type: String,
        },
        checkedLabel: {
            type: String,
        },
        description: {
            type: String,
        },
    },
    emits: ["update:checked"],
})
</script>

<style lang="scss">
$ease-out: background 0.1s cubic-bezier(0.32, 0, 0.67, 0); // ease in cubic
$ease-in: background 0.1s cubic-bezier(0.33, 1, 0.68, 1); // ease out cubic
$slide: left 0.1s cubic-bezier(0, 0.5, 1, 0.5);
$height: 1.4rem;
$width: 2.6rem;

.switch-input-wrapper {
    .border {
        border: unset;
        background: unset;
        align-items: center;
    }

    .input-field {
        margin: 0;
    }

    .switch-wrapper {
        column-gap: $margin-s;

        .wrapper-label {
            &.gray {
                color: $dark-gray;
            }
            &:not(.gray) {
                font-weight: $font-weight-medium;
            }
        }
    }
    &.disabled {
        pointer-events: none;

        .check-switch {
            opacity: 0.5;
        }

        .wrapper-label,
        .wrapper-description {
            color: $gray;
        }
    }
}

.check-switch {
    position: relative;
    height: $height;
    width: $width;
    cursor: pointer;
    background-color: $light-gray;
    border-radius: calc(#{$height} / 2);
    transition: $quick-transition;

    .mark {
        position: absolute;
        left: 0;
        top: 0;
        height: $height;
        width: $height;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: left 0.25s cubic-bezier(0.305, 0.82, 0.73, 0.16),
            opacity 0.25s linear;
    }

    .un-checkmark {
        background: $light-gradient;
        opacity: 1;
    }

    .checkmark {
        background: $primary-gradient;
        opacity: 0;
    }

    &.checked {
        .mark {
            left: $width - $height;
        }

        .un-checkmark {
            opacity: 0;
        }

        .checkmark {
            opacity: 1;
        }
    }
}
</style>
