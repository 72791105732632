import { defineStore } from "pinia"
import axios from "axios"

export type OpeningEntry = {
    id: number
    client_id: number
    type: "delivery" | "pickup" | "dinein"
    day: number
    is_open: boolean
    from: string | null
    till: string | null
    closed_from: string | null
    closed_till: string | null
}

export type OpeningHoursStore = {
    openingHours: OpeningEntry[]
}

export const useOpeningHoursStore = defineStore({
    id: "openingHours",
    state: () =>
        ({
            openingHours: [],
        } as OpeningHoursStore),
    actions: {
        async fetchOpeningHours(): Promise<boolean> {
            try {
                const response = await axios.get("client/hours/open/selection")
                this.openingHours = response.data.data
                return true
            } catch (e) {
                return false
            }
        },
    },
})
