<template>
    <ModalWindow :show="show" name="map-modal" class="route-map-modal">
        <OrdersMap
            :deliverers="deliverers"
            :orders-coordinates="ordersCoordinates"
            :ordersGeoJSON="ordersGeoJSON"
            @hover="hoveredInMap = $event"
            :highlight="hoveredInSidebar"
        ></OrdersMap>
        <MapSideBar
            :deliverers="deliverers"
            :ready="ordersStore.dataReady"
            v-model:statusFilter="orderModalStore.MapModalFilters"
            :orders="filteredOrders"
            @close="$emit('close')"
            @hover="hoveredInSidebar = $event"
            :highlight="hoveredInMap"
        ></MapSideBar>
    </ModalWindow>
</template>

<script lang="ts">
import {
    computed,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    Ref,
    ref,
    toRaw,
    watch,
} from "vue"
import ModalWindow from "@/ui-elements/layout/modals/ModalWindow.vue"
import { useI18n } from "vue-i18n"
import OrdersMap from "@/pages/orders/modals/map/OrdersMap.vue"
import MapSideBar from "@/pages/orders/modals/map/MapSideBar.vue"
import { useDelivererStore } from "@/store/Deliverer"
import { Order, OrderStatusType } from "@/interface/orders/Order"
import {
    OrderFeature,
    OrderFeatureCollection,
} from "@/interface/orders/OrdersMap"
import { useOrdersStore } from "@/store/Orders"
import GeoJSON from "geojson"
import { RiceCooker } from "@/riceCooker/riceCooker"
import { useOrderModalStore } from "@/store/OrderModal"

export default defineComponent({
    name: "OrdersMapModal",
    components: {
        MapSideBar,
        OrdersMap,
        ModalWindow,
    },
    props: {
        show: Boolean,
    },
    setup(props) {
        const _log = (message: string, context: any = "") => {
            if (window.ob.hasDebugModule("DelivererMap")) {
                console.log(`[DelivererMap] ${message}`, context)
            }
        }
        const { t: translate } = useI18n()
        const ordersStore = useOrdersStore()
        const orderModalStore = useOrderModalStore()
        const deliverers = computed(() => {
            if (orderModalStore.MapModalFilters.includes("deliverers")) {
                return useDelivererStore()
                    .deliverers.map((deliverer) => {
                        const orders = ordersStore.orders.filter(
                            (order) => order.deliverer_id === deliverer.id
                        ).length
                        return {
                            ...deliverer,
                            n: orders,
                        }
                    })
                    .filter((deliverer) => deliverer.n)
            } else {
                return []
            }
        })

        const filteredOrders = computed(() => {
            const filteredByDelivery = toRaw(ordersStore.orders).filter(
                (order) => order.delivery === "deliver" && order.coordinates
            )
            return filteredByDelivery.filter((order) =>
                orderModalStore.MapModalFilters.includes(order.status)
            )
        })

        function generateGeoJSON<
            EntityType extends {
                id: number
                refnr: number
                coordinates: GeoJSON.Point
            }
        >(array: EntityType[]) {
            const features: OrderFeature[] = array.map((entity) => ({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [...entity.coordinates.coordinates].reverse(),
                },
                properties: {
                    id: entity.id,
                    refnr: entity.refnr,
                },
            }))
            const collection: OrderFeatureCollection = {
                type: "FeatureCollection",
                features: features,
            }
            return collection
        }

        const ordersGeoJSON = computed(() => {
            return generateGeoJSON<Order>(filteredOrders.value)
        })

        const ordersCoordinates = computed(() =>
            filteredOrders.value
                .filter((order) => order.coordinates)
                .map((order) => ({
                    id: order.id,
                    coord: order.coordinates.coordinates, //randomCoord(),
                }))
        )

        const hoveredInSidebar = ref(0)
        const hoveredInMap = ref(0)

        const refreshDelivererInterval: any = ref() // @todo, it seems it's not used?

        watch(
            () => props.show,
            () => {
                if (props.show) {
                    _log("start polling deliverers every 5 seconds.")
                    refreshDelivererInterval.value = setInterval(async () => {
                        _log("refreshing Deliverers.", {
                            success:
                                await useDelivererStore().fetchDeliverers(),
                        })
                    }, 10000)
                } else {
                    _log("stop polling deliverers")
                    clearInterval(refreshDelivererInterval.value)
                }
            }
        )

        return {
            translate,
            orderModalStore,
            filteredOrders,
            deliverers,
            ordersCoordinates,
            hoveredInSidebar,
            hoveredInMap,
            ordersGeoJSON,
            ordersStore,
            refreshDelivererInterval,
        }
    },
})
</script>

<style lang="scss">
.overlay.route-map-modal {
    .modal-window {
        height: 97.5vh;
        width: 97.5vw;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr) 23rem;
        grid-template-rows: 100%;
    }

    .for-defs {
        height: 0;
    }

    .route-map {
        flex: 1 1;
        border-radius: $radius 0 0 $radius;
    }
}
</style>
