<template>
    <span class="icon-base" :class="'icon-' + icon"></span>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
    props: {
        icon: {
            type: String,
            default: "",
        },
    },
})
</script>

<style lang="scss" scoped>
.warning {
    color: $warning-color;
}
</style>
