<template>
    <InputField
        class="date-picker"
        :class="{ filter: filterStyle, [name]: true }"
        ref="DateTimePickerRef"
        :disabled="disabled"
        :error="error"
        :success="success"
        :label="label"
        :label-icon="labelIcon"
        :label-icon-class="labelIconClass"
        :name="name"
        :focus="focused"
    >
        <template v-slot:visual>
            <IconBase
                v-if="!enableDate"
                icon="clock"
                class="clock-icon"
            ></IconBase>
            <IconBase v-else class="calendar-icon" icon="calendar"></IconBase>
        </template>
        <template v-slot:input>
            <FlatPickr
                :modelValue="date"
                :config="flatpickrConfig"
                :disabled="disabled"
                :placeholder="placeholder"
            >
            </FlatPickr>
            <div v-if="clearable && !cleared" class="clear" @click="onClear">
                <IconBase icon="delete" class="clear-icon"></IconBase>
            </div>
        </template>
    </InputField>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, toRef } from "vue"
import InputField from "@/ui-elements/input/InputField.vue"
import IconBase from "@/ui-elements/IconBase.vue"
import FlatPickr from "vue-flatpickr-component"
import "flatpickr/dist/flatpickr.css"
import { useDateTimeSelect } from "@/ui-elements/input/date-time-select/useDateTimeSelect"

export type EnabledTime = {
    start: string
    end: string
}

export default defineComponent({
    name: "DateTimeSelect",
    components: { InputField, FlatPickr, IconBase },
    props: {
        date: {
            type: [Date, String, Array, null] as PropType<
                Date | string | Array<Date | string> | null
            >,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        success: {
            type: Boolean,
            default: false,
        },
        filterStyle: {
            type: Boolean,
            default: false,
        },
        range: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
        },
        name: {
            type: [Array, String] as PropType<string | string[]>,
            default: () => [],
        },
        enableTime: {
            type: Boolean,
            default: false,
        },
        enableDate: {
            type: Boolean,
            default: true,
        },
        enabledTime: {
            type: Object as PropType<EnabledTime>,
            default: () => ({
                start: "00:00",
                end: "23:59",
            }),
        },
        enabledDates: {
            type: Object as PropType<EnabledTime>,
            default: () => ({
                start: "",
                end: "",
            }),
        },
        withSeconds: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "",
        },
        labelIcon: {
            type: String,
        },
        labelIconClass: {
            type: String,
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        useLast10Years: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, context) {
        const { flatpickrConfig, focused } = useDateTimeSelect(
            toRef(props, "range"),
            toRef(props, "enableTime"),
            toRef(props, "enableDate"),
            toRef(props, "withSeconds"),
            toRef(props, "enabledTime"),
            toRef(props, "enabledDates"),
            toRef(props, "placeholder"),
            toRef(props, "useLast10Years")
        )

        const cleared = computed(() => {
            if (!props.date) {
                return true
            }
            return Array.isArray(props.date)
                ? props.date.every((element) => !element)
                : false
        })

        const onClear = () => {
            const newValue = props.range ? ["", ""] : ""
            context.emit("update:date", newValue)
        }

        return {
            cleared,
            focused,
            flatpickrConfig,
            onClear,
        }
    },
    emits: ["update:date"],
})
</script>

<style lang="scss">
$input-padding-x: $padding-l + $padding-m;

.date-picker {
    position: relative;

    .placeholder {
        color: $dark-gray;
        pointer-events: none;
    }

    .form-control.input {
        width: 100%;
        height: 100%;
        padding: 0 $input-padding-x;
        outline: none;
    }

    .visual,
    .clear {
        position: absolute;
    }

    .visual {
        left: 0;
        pointer-events: none;
        cursor: pointer;
    }

    .visual .icon-base,
    .clear {
        margin: 0 $margin-m;
        cursor: pointer;
    }

    .clear {
        right: 0;
    }

    .clock-icon {
        stroke-width: 2;
    }

    .input {
        width: 100%;
    }
}

.date-picker.disabled {
    background: $lightest-gray;
    cursor: not-allowed;

    .delimiter,
    .clock-icon,
    .calendar-icon {
        color: $darker-gray;
    }
}

.date-picker.filter .border {
    border: none;
    background: $lightest-gray;
}

//////// date picker calendar
$month: 3.2rem;
$time: 3rem;

.flatpickr-calendar.open {
    border: $normal-border;
    box-shadow: $shadow;
}

.flatpickr-calendar.open:not(.noCalendar) {
    width: 21.8rem;
    height: 23rem;

    &.arrowTop::before {
        border-bottom-color: $secondary-color;
    }

    .flatpickr-months {
        height: $month;
        padding-top: $padding-l;
        margin: 0 0.8rem;

        & > span {
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0;

            svg {
                width: 0.8rem;
                height: 0.8rem;

                path {
                    stroke: $darker-gray;
                }
            }
        }
    }

    .flatpickr-month {
        height: 100%;
    }

    .flatpickr-current-month {
        height: 100%;
        padding: 0;
        display: flex;
        gap: $margin-s;
        justify-content: center;
    }

    .flatpickr-monthDropdown-months {
        font-weight: $font-weight-medium;
    }

    .yearSelect {
        font-weight: $font-weight-medium;
        font-size: $font-size-h4;
        cursor: pointer;

        &:hover,
        &:focus {
            background: $lighter-gray;
            outline: none;
        }
    }

    .flatpickr-innerContainer {
        height: calc(100% - #{$month});
        padding: 0 $padding-m $padding-m $padding-m;
    }

    .flatpickr-rContainer {
        width: 100%;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: 3.2rem 1fr;
    }

    .flatpickr-weekdays {
        height: 3.2rem;
    }

    .flatpickr-weekday {
        color: $darker-gray;
    }

    .flatpickr-days {
        width: 100%;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: minmax(0, 1fr);

        .dayContainer {
            width: 100%;
            max-width: 100%;
            height: 100%;
            min-width: 100%;
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            grid-template-rows: repeat(6, 1fr);
            gap: $padding-xs;
        }

        .flatpickr-day {
            border-radius: $radius;
            border: none;
            display: flex;
            align-items: center;
            line-height: unset;
            max-width: unset;
            height: unset;
            width: unset;
            flex-basis: unset;

            &.today {
                border: $normal-border;
            }
        }

        .flatpickr-day:hover {
            background-color: $lighter-gray;

            &.selected {
                background-color: $primary-color;
            }

            &.today {
                color: $secondary-color;
            }
        }

        .prevMonthDay,
        .nextMonthDay {
            color: $darker-gray;
            background-color: $light-gray;
        }

        .inRange {
            background-color: $transparent-primary;
            box-shadow: none;
        }

        .flatpickr-day.startRange {
            background-color: $secondary-color;
        }

        .flatpickr-day.endRange {
            background-color: $primary-color;
            box-shadow: none;
        }

        .selected {
            background-color: $primary-color;
            font-weight: $font-weight-medium;
        }
    }

    .flatpickr-time {
        height: $time;
        border: none;
    }

    &.hasTime {
        .flatpickr-innerContainer {
            height: calc(100% - #{$month + $time});
        }
    }
}

.flatpickr-calendar.open.hasTime.noCalendar {
    &:before {
        border-bottom-color: $gray;
    }

    .flatpickr-time {
        border: none;
    }
}

@media only screen and (max-width: $screen-mobile) {
    .label-component {
        display: flex;
        min-height: 5vh;
    }

    .clock-icon {
        display: none;
    }

    .visual .icon-base,
    .clear {
        margin: 0 $margin-m;
        cursor: pointer;
        display: none;
    }
}
</style>
