export const getPaymentMethodsEditable = (
    method: string,
    payment_result: string | null
) => {
    // If paid with Adyen, it can't be changed anymore
    if (payment_result && payment_result.startsWith("Adyen: OK")) {
        return false
    }

    return ["cash", "account", "pin"].includes(method)
}

export const isMethodShown = (method: string) =>
    ["cash", "account", "pin"].includes(method)
