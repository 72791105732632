<template>
    <Popper v-bind="$attrs" class="tooltip">
        <slot />
        <template #content="props">
            <slot name="content" v-bind="props" />
        </template>
    </Popper>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import Popper from "vue3-popper"

export default defineComponent({
    name: "Tooltip",
    components: {
        Popper,
    },
})
</script>
<style lang="scss" scoped>
:deep(.popper) {
    background: $black !important;
    padding: $padding-m $padding !important;
    border-radius: 0.6rem !important;
    color: $white !important;
}

:deep(.popper #arrow::before) {
    background: $black !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
    background: $black !important;
}

.tooltip.select-component,
.tooltip.date-picker {
    border: 0 !important;
    margin: 0 !important;
}
</style>
