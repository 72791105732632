import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "confirmation-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopUpModal = _resolveComponent("PopUpModal")!

  return (_openBlock(), _createBlock(_component_PopUpModal, {
    show: "",
    title: _ctx.translate('customer_receipt_confirm'),
    onClose: _ctx.close,
    onSubmit: _ctx.confirm,
    submitButtonText: _ctx.translate('confirm')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.translate("print_customer_receipt")), 1)
      ])
    ]),
    _: 1
  }, 8, ["title", "onClose", "onSubmit", "submitButtonText"]))
}