import { getNumberOfDecimals } from "@/utils/math/Currency"
import i18n from "@/i18n"

export const limitNumber = (
    number: string,
    step: number,
    min: number,
    max: number
) => {
    const limitDecimals = parseFloat(
        parseFloat(number).toFixed(getNumberOfDecimals(step))
    )
    return Math.min(Math.max(limitDecimals, min), max)
}
const exception = (text: string, step: number) => {
    if (
        text === "" ||
        text === "-" ||
        text[text.length - 1] === "." ||
        text[text.length - 1] === ","
    ) {
        return {
            text,
            exception: true,
        }
    }

    if (text[text.length - 1] !== "0") {
        return {
            text,
            exception: false,
        }
    }

    const decimalsNumber = getNumberOfDecimals(step)
    let zeroesCount = 0
    let separatorFound = false

    for (let i = text.length - 1; i >= 0; i--) {
        if (text[text.length - 1] === "0") {
            // count quantity of zeroes at the end of the string
            zeroesCount++
            if (text[i - 1] === "." || text[i - 1] === ",") {
                // we found comma or dot before 0
                separatorFound = true
                i = -1
            }
        }
    }

    // if there is no comma/dot we don't need to do anything
    if (!separatorFound) {
        return {
            text,
            exception: false,
        }
    }

    // check if we need to cut unnecessary zeroes
    const unnecessaryZeroes = zeroesCount - decimalsNumber
    if (unnecessaryZeroes > 0) {
        text = text
            .split("")
            .slice(0, text.length - unnecessaryZeroes)
            .join("")
    }
    return {
        text,
        exception: true,
    }
}

export const escape = (
    text: string,
    step: number,
    min: number,
    max: number
) => {
    // escape everything, but numbers, '.' or '-'
    // replace several commas/dots by one comma/dot
    const escaped = text
        .replace(/[^0-9^.,-]/g, "")
        .replace(/\.+/g, ".")
        .replace(/,+/g, ".")
    const exceptionObject = exception(escaped, step)
    if (exceptionObject.exception) {
        return exceptionObject.text
    } else {
        const fixDecimal = escaped.replace(",", ".")
        return limitNumber(fixDecimal, step, min, max)
    }
}
export const useNumberFormat = (amount: number) => {
    const { locale } = i18n.global

    return new Intl.NumberFormat(locale.value, {
        style: "decimal",
    }).formatToParts(amount)
}
export const formatNumber = (number: string | number) =>
    useNumberFormat(Number(number))
        .filter((part) =>
            ["integer", "decimal", "fraction", "minusSign"].includes(part.type)
        )
        .map((part) => part.value)
        .join("")

export const format = (text: number | string, step: number) => {
    const exceptionObject = exception(text + "", step)
    if (exceptionObject.exception) {
        return exceptionObject.text
    } else {
        return formatNumber(text)
    }
}
