import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "order-fields" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DispatchField = _resolveComponent("DispatchField")!
  const _component_DateField = _resolveComponent("DateField")!
  const _component_TimeField = _resolveComponent("TimeField")!
  const _component_DiscountCodeField = _resolveComponent("DiscountCodeField")!
  const _component_RemarksField = _resolveComponent("RemarksField")!
  const _component_ReferenceField = _resolveComponent("ReferenceField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.translate("order")), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_DispatchField),
      _createVNode(_component_DateField),
      _createVNode(_component_TimeField, { "is-loaded-from-order": false }),
      _createVNode(_component_DiscountCodeField),
      _createVNode(_component_RemarksField),
      _createVNode(_component_ReferenceField)
    ])
  ]))
}