import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "modal-header"
}
const _hoisted_3 = { class: "modal-title flex items-center" }
const _hoisted_4 = { class: "id" }
const _hoisted_5 = { class: "tag" }
const _hoisted_6 = {
  key: 0,
  class: "vertical-line"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "content" }
const _hoisted_9 = { class: "left" }
const _hoisted_10 = { class: "right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonBase = _resolveComponent("ButtonBase")!
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_ModalStatus = _resolveComponent("ModalStatus")!
  const _component_ModalTabs = _resolveComponent("ModalTabs")!
  const _component_PopUpModal = _resolveComponent("PopUpModal")!
  const _component_OrderDeletePopUp = _resolveComponent("OrderDeletePopUp")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PopUpModal, {
      show: true,
      onClose: _ctx.close,
      cancellable: !_ctx.seesOrderFirstTime,
      "scroll-area": _ctx.scrollArea,
      class: "order-details-modal",
      loading: _ctx.isLoading
    }, {
      header: _withCtx(() => [
        (_ctx.multiLocation.isActive)
          ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_ctx.multiLocation.location(_ctx.order.client_id)?.company), 1))
          : _createCommentVNode("", true),
        (_ctx.order)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.showPayButton)
                ? (_openBlock(), _createBlock(_component_ButtonBase, {
                    key: 0,
                    type: "primary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate("pay_order")), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("h3", _hoisted_3, [
                _createVNode(_component_IconBase, {
                  icon: _ctx.deliveryType.icon
                }, null, 8, ["icon"]),
                _createVNode(_component_IconBase, { icon: _ctx.sourceTypeIcon }, null, 8, ["icon"]),
                _createElementVNode("span", _hoisted_4, "#" + _toDisplayString(_ctx.order.source.id
                                ? `${_ctx.order.source.type.toUpperCase()}${
                                      _ctx.order.source.id
                                  }`
                                : _ctx.order.id), 1),
                _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
              ]),
              _createVNode(_component_Tag, { "tag-style": _ctx.tagStyle }, {
                tagContent: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.total), 1),
                    (
                                    _ctx.isActuallyPaid(_ctx.order) || _ctx.isPinFinal(_ctx.order)
                                )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                      : _createCommentVNode("", true),
                    (
                                    _ctx.isActuallyPaid(_ctx.order) || _ctx.isPinFinal(_ctx.order)
                                )
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.paymentStatus), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["tag-style"])
            ]))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          (_ctx.order)
            ? (_openBlock(), _createBlock(_component_ModalStatus, {
                key: 0,
                status: _ctx.order.status,
                delivery: _ctx.order.delivery,
                "onUpdate:status": _ctx.updateStatus,
                class: "order-statuses"
              }, null, 8, ["status", "delivery", "onUpdate:status"]))
            : _createCommentVNode("", true),
          (_ctx.order)
            ? (_openBlock(), _createBlock(_component_ModalTabs, {
                key: 1,
                order: _ctx.order,
                "onUpdate:order": _ctx.saveOrder,
                "scroll-area-ref": _ctx.getScrollAreaRef
              }, null, 8, ["order", "onUpdate:order", "scroll-area-ref"]))
            : _createCommentVNode("", true)
        ])
      ]),
      actions: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          (_ctx.deleting)
            ? (_openBlock(), _createBlock(_component_ButtonBase, {
                key: 0,
                onClick: _ctx.toggleDeleteModal,
                name: "delete",
                icon: "bin",
                type: "primary-gradient"
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_ButtonBase, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.print(true))),
            name: "print",
            icon: "printer",
            variant: "outlined"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("print")), 1)
            ]),
            _: 1
          }),
          (_ctx.showPosOpenButton)
            ? (_openBlock(), _createBlock(_component_ButtonBase, {
                key: 1,
                name: "pos",
                icon: "pos",
                variant: "outlined",
                onClick: _ctx.openInPos
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("open")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_10, [
          (_ctx.order && _ctx.seesOrderFirstTime)
            ? (_openBlock(), _createBlock(_component_ButtonBase, {
                key: 0,
                onClick: _ctx.accept,
                name: "accept",
                type: "success",
                icon: "check",
                loading: _ctx.isLoading,
                class: "animate-pulse-object"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("accept")), 1)
                ]),
                _: 1
              }, 8, ["onClick", "loading"]))
            : (_openBlock(), _createBlock(_component_ButtonBase, {
                key: 1,
                onClick: _ctx.close,
                name: "close",
                variant: "text"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("close")), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
        ])
      ]),
      _: 1
    }, 8, ["onClose", "cancellable", "scroll-area", "loading"]),
    (_ctx.isDeleteModalOpened)
      ? (_openBlock(), _createBlock(_component_OrderDeletePopUp, {
          key: 0,
          id: _ctx.orderId,
          onClose: _ctx.toggleDeleteModal,
          callback: _ctx.close
        }, null, 8, ["id", "onClose", "callback"]))
      : _createCommentVNode("", true)
  ], 64))
}