import { RouteRecordRaw } from "vue-router"
import { debugStructure } from "@/pageStructure/DebugStructure"

export const DebugRoutes: RouteRecordRaw = {
    path: "/debug",
    meta: { name: "debug" },
    component: () =>
        import(/* webpackPrefetch: true */ "@/pages/debug/DebugLayout.vue"),
    children: [
        {
            path: "",
            name: "debug",
            redirect: { name: debugStructure.general.name },
        },
        {
            path: debugStructure.general.path,
            name: debugStructure.general.name,
            meta: { subName: debugStructure.general.name },
            component: () => import("@/pages/debug/DebugIndex.vue"),
        },
        {
            path: debugStructure.sysinfo.path,
            name: debugStructure.sysinfo.name,
            meta: { subName: debugStructure.sysinfo.name },
            component: () => import("@/pages/debug/DebugSystemInformation.vue"),
        },
        {
            path: debugStructure.printers.path,
            name: debugStructure.printers.name,
            meta: { subName: debugStructure.printers.name },
            component: () => import("@/pages/debug/DebugPrinters.vue"),
        },
        {
            path: debugStructure.dataHydration.path,
            name: debugStructure.dataHydration.name,
            meta: { subName: debugStructure.dataHydration.name },
            component: () => import("@/pages/debug/DebugDataHydration.vue"),
        },
    ],
}
