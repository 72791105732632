import { MenuStructure } from "@/interface/MenuStructure"

export const salesName = "sales-statistics"
export const salesStructure: MenuStructure = {
    orders: {
        text: "general_statistics.orders.title",
        name: "general-statistics",
        path: "orders",
        subMenu: {
            conversion: {
                text: "general_statistics.orders.conversion.title",
                name: "conversion",
                path: "conversion",
                subMenu: {},
            },
        },
    },
    areas: {
        text: "delivery_areas",
        name: "sales/areas",
        path: "delivery-areas",
        subMenu: {},
    },
    turnover: {
        text: "turnover",
        name: "sales/turnover",
        path: "turnover",
        subMenu: {
            hourly: {
                text: "hourly",
                name: "hourly",
                path: "hourly",
                subMenu: {},
            },
        },
    },
    products: {
        text: "products",
        name: "sales/products",
        path: "products",
        subMenu: {},
    },
}
