<template>
    <PopUpModal :show="show" @close="$emit('cancel')" @save="$emit('confirm')">
        <template #default>
            <div class="content">
                <IconBase icon="warning" class="icon" />
                <h2 class="title">{{ translate(title) }}</h2>
                <span
                    v-html="translate(description)"
                    :class="descriptionClass"
                />
            </div>
        </template>

        <template #actions>
            <ButtonBase variant="text" @click="$emit('cancel')">{{
                translate("cancel")
            }}</ButtonBase>
            <ButtonBase @click="$emit('confirm')" type="primary">{{
                translate(action)
            }}</ButtonBase>
        </template>
    </PopUpModal>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { useI18n } from "vue-i18n"
import PopUpModal from "@/ui-elements/layout/modals/PopUpModal.vue"
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue"
import IconBase from "@/ui-elements/IconBase.vue"

export default defineComponent({
    name: "ConfirmationModal",
    components: { IconBase, ButtonBase, PopUpModal },
    props: {
        show: Boolean,
        title: {
            type: String,
            default: () => "are_you_sure",
        },
        description: {
            type: String,
            default: () => "orders_modal_delete_description",
        },
        action: {
            type: String,
            default: () => "ok",
        },
        descriptionClass: {
            type: String,
            default: () => "",
        },
    },
    setup() {
        const { t: translate } = useI18n()

        return {
            translate,
        }
    },
    emits: ["cancel", "confirm"],
})
</script>

<style lang="scss" scoped>
.content {
    padding: $padding-m $padding-m $padding-l $padding-m;
    text-align: center;
}

.title {
    margin: $margin-m 0;
}

.icon {
    font-size: 3rem;
    color: $primary-color;
}
</style>
