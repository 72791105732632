import { RoutesConfig, RouteType } from "@/pages/settings/utils/RoutesConfig"
import { MenuStructure, MenuStructureItem } from "@/interface/MenuStructure"

const MAX_DEPTH = 2

export const buildMenu = (config: RoutesConfig) => {
    const menu: MenuStructure = {}

    Object.keys(config).forEach((sectionId) => {
        const sectionMenu = buildMenuStructure(
            { ...config[sectionId], children: undefined },
            sectionId
        )
        if (!sectionMenu) {
            return
        }

        const sectionSubMenu: MenuStructure = {}

        const groups = config[sectionId].children || {}

        Object.keys(groups).forEach((groupId) => {
            const subGroups: any = groups[groupId].children || {}

            Object.keys(subGroups).forEach((subGroupId) => {
                const subGroupMenuItem = buildMenuStructure(
                    subGroups[subGroupId],
                    subGroupId
                )
                if (subGroupMenuItem) {
                    sectionSubMenu[subGroupId] = subGroupMenuItem
                }
            })
        })

        menu[sectionId] = {
            ...sectionMenu,
            subMenu: sectionSubMenu,
        }
    })

    return menu
}

const buildMenuStructure = (
    item: RouteType,
    itemId: string,
    depth = 1
): MenuStructureItem | null => {
    if ("excludeFromSidebar" in item && item.excludeFromSidebar) {
        return null
    }
    const menuStructure: MenuStructureItem = {
        text: item.title || "",
        icon: "icon" in item ? item.icon : "",
        iconClass: "iconClass" in item ? item.iconClass : "",
        name: itemId,
        path: item.path || "",
        subMenu: {},
    }

    if (depth >= MAX_DEPTH) {
        return menuStructure
    }

    if ("children" in item && item.children) {
        Object.keys(item.children).forEach((pageId: any) => {
            const child = buildMenuStructure(
                item.children![pageId],
                pageId,
                depth++
            )
            if (child) {
                menuStructure.subMenu[pageId] = child
            }
        })
    }
    return menuStructure
}
