import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_TableCell = _resolveComponent("TableCell")!
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_ButtonBase = _resolveComponent("ButtonBase")!

  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass(["table-row", { 'table-header': _ctx.head, disabled: _ctx.disabled, link: _ctx.isLink }]),
    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    (_ctx.isDraggable)
      ? (_openBlock(), _createBlock(_component_TableCell, {
          key: 0,
          class: "draggableHandle rotate135",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconBase, {
              icon: "arrow-expand",
              class: _normalizeClass({ 'text-white': _ctx.head })
            }, null, 8, ["class"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.selectable)
      ? (_openBlock(), _createBlock(_component_TableCell, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_CheckBox, {
              checked: _ctx.selected,
              indeterminate: _ctx.head && _ctx.indeterminate,
              onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
              "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:selected', $event))),
              disabled: _ctx.disabled
            }, null, 8, ["checked", "indeterminate", "disabled"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.deletable)
      ? (_openBlock(), _createBlock(_component_TableCell, {
          key: 2,
          class: "undo"
        }, {
          default: _withCtx(() => [
            (_ctx.deleted)
              ? (_openBlock(), _createBlock(_component_ButtonBase, {
                  key: 0,
                  onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$emit('undo:delete')), ["stop"])),
                  icon: "return-1"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}