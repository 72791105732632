<template>
    <ModalWindow
        :show="show"
        :title="title"
        :class="$attrs.class"
        @close="$emit('close')"
    >
        <PanelComponent
            class="pop-up"
            :loading="loading"
            :scroll-area-el="scrollArea"
        >
            <template #default>
                <div class="content-wrapper">
                    <slot name="header">
                        <div class="header">
                            <slot name="title">
                                <h3 class="title">
                                    <slot name="icon"></slot>
                                    {{ title }}
                                </h3>
                            </slot>
                            <ButtonBase
                                v-if="cancellable"
                                @click="$emit('close')"
                                icon="delete"
                                type="regular"
                                variant="filled"
                                class="close"
                            />
                        </div>
                    </slot>
                    <div class="body">
                        <slot></slot>
                    </div>
                </div>
            </template>
            <template #footer>
                <slot name="actions">
                    <ButtonBase
                        v-if="cancellable"
                        type="secondary"
                        variant="outlined"
                        @click="$emit('close')"
                    >
                        {{ closeButtonText || translate("cancel") }}
                    </ButtonBase>
                    <ButtonBase
                        type="regular"
                        variant="filled"
                        class="save"
                        @click="$emit('submit')"
                        data-testid="save"
                    >
                        {{ submitButtonText || translate("save") }}
                    </ButtonBase>
                </slot>
            </template>
        </PanelComponent>
    </ModalWindow>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue"
import { useI18n } from "vue-i18n"
import ModalWindow from "@/ui-elements/layout/modals/ModalWindow.vue"
import PanelComponent from "@/ui-elements/layout/PanelComponent.vue"
export default defineComponent({
    name: "PopUpModal",
    components: { ModalWindow, ButtonBase, PanelComponent },
    props: {
        show: Boolean,
        title: String,
        submitButtonText: String,
        closeButtonText: String,
        loading: {
            type: Boolean,
            default: false,
        },
        scrollArea: {
            type: Object as PropType<HTMLElement>,
        },
        cancellable: {
            type: Boolean,
            default: true,
        },
    },
    inheritAttrs: false,
    setup() {
        const { t: translate } = useI18n()

        return {
            translate,
        }
    },
    emits: ["close", "submit"],
})
</script>

<style lang="scss" scoped>
.pop-up {
    width: auto;

    .content-wrapper {
        padding: $padding-m;
    }

    .panel-content {
        overflow-y: auto !important;
    }

    .header {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        margin-bottom: $margin;

        .title {
            font-size: $font-size-h3;
            color: $secondary-color;
            display: flex;
            align-items: center;
            column-gap: $margin-m;

            :deep(.icon-base) {
                font-size: $font-size-h3;
            }
        }

        .button {
            margin-right: 0;

            :deep(.icon-base) {
                font-size: $font-size-s;
            }
        }
    }
}

@media only screen and (max-width: $screen-mobile) {
    .pop-up {
        width: 85vw;
    }
}
</style>
