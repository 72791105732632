<template>
    <div class="row" :class="{ deleted: product.is_deleted }">
        <div class="cell quantity text-right">{{ product.quantity }}x</div>
        <div class="cell product">
            {{
                product.product?.category
                    ? product.product?.category?.title?.default
                    : null
            }}
            <br v-if="product.product?.category" />
            {{ label }}
        </div>
        <div class="cell extras" v-if="!showSeparateExtras">
            <span v-if="product.extras">{{
                parseProductExtras(product.extras).join(", ")
            }}</span>
        </div>
        <div class="cell remarks">
            <span>{{ product.remarks }}</span>
        </div>
        <div class="cell total text-right">
            {{ useFormatCurrency(product.total) }}
            <div class="discount" v-if="product.discount">
                -{{ useFormatCurrency(product.discount) }}
            </div>
        </div>
    </div>
    <template v-if="showSeparateExtras">
        <div class="extra-item" v-for="extra in product.extras" :key="extra.id">
            <div class="extra-quantity text-right">{{ extra.quantity }}x</div>
            <div class="extra">{{ extra.title }}</div>
            <div></div>
            <div></div>
        </div>
    </template>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue"
import { OrderProduct } from "@/interface/orders/OrderProduct"
import { useFormatCurrency } from "@/utils/useCurrencySymbol"
import { parseProductExtras } from "@/utils/useProductExtras"
import { useSettingsStore } from "@/store/Settings"

export default defineComponent({
    name: "ProductRow",
    props: {
        product: {
            type: Object as PropType<OrderProduct>,
            required: true,
        },
    },
    setup(props) {
        const settingsStore = useSettingsStore()
        const label = computed(() => {
            let returnValue = ""
            if (
                Number(
                    settingsStore.settings.orderpopup_products_kitchentitle
                ) &&
                props.product.product?.title?.extra
            ) {
                returnValue = props.product.product?.title?.extra
            }
            return (
                returnValue ||
                props.product.product?.title?.counter ||
                props.product.product?.title?.default ||
                props.product.description ||
                ""
            )
        })
        const showSeparateExtras = computed(() =>
            Number(settingsStore.settings.orderpopup_extras_array)
        )
        return {
            useFormatCurrency,
            parseProductExtras,
            showSeparateExtras,
            label,
        }
    },
})
</script>

<style scoped lang="scss">
.deleted {
    text-decoration: line-through;
}
.row,
.extra-item {
    &:nth-child(odd):not(:first-child) {
        background: $light-gray;
    }
}
.extra-item {
    display: table-row;
    color: $darker-gray;

    div {
        padding: $padding-xs $padding-m;
        display: table-cell;
        color: inherit;
    }
}
.quantity,
.product {
    font-weight: $font-weight-medium;
}
</style>
