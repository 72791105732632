<template>
    <div class="page-footer footer">
        <div class="footer-wrap">
            <div class="page-buttons">
                <ScrollButtons :scrollArea="scrollArea"></ScrollButtons>
            </div>
            <div class="horizontal-line"></div>
            <div
                class="actions page-footer-actions"
                :class="{ 'no-margin': pagination && showPagination }"
            >
                <slot></slot>
                <div class="count" v-if="pagination && showTotalCount">
                    {{ translate("app_result", pagination.total) }}
                </div>
            </div>
            <PaginationComponent
                v-if="pagination && showPagination"
                :pagination="pagination"
                @update:pagination="$emit('update:pagination', $event)"
                :hide-per-page="hidePerPage"
            ></PaginationComponent>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import ScrollButtons from "@/ui-elements/layout/footer/ScrollButtons.vue"
import PaginationComponent from "@/ui-elements/layout/footer/PaginationComponent.vue"
import { Pagination } from "@/interface/components/Pagination"
import { useI18n } from "vue-i18n"

export default defineComponent({
    name: "PageFooter",
    components: {
        ScrollButtons,
        PaginationComponent,
    },
    props: {
        scrollArea: {
            type: Object as PropType<HTMLElement>,
        },
        pagination: {
            type: Object as PropType<Pagination>,
            default: () => {},
        },
        showPagination: {
            type: Boolean,
            default: true,
        },
        showTotalCount: {
            type: Boolean,
            default: true,
        },
        ready: Boolean,
        hidePerPage: Boolean,
    },
    setup() {
        const { t: translate } = useI18n()

        return {
            translate,
        }
    },
    emits: ["update:pagination"],
})
</script>
<style lang="scss" scoped>
.footer {
    padding: $padding;
    background: $white;
    border-radius: 0 0 $radius $radius;
    box-shadow: $shadow-up;
    position: sticky;
    bottom: -1px;
    width: 100%;
    z-index: 1;

    .footer-wrap {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
    }

    .page-buttons {
        display: flex;

        .icon-base {
            color: $white;
        }
    }

    .page-nav {
        margin-left: auto;
    }

    .horizontal-line {
        height: 1.7rem;
        border-left: $normal-border;
        margin: 0 $margin 0 $margin-s;
    }

    .actions {
        display: flex;
        align-items: center;
        margin-left: auto;
        column-gap: $margin;
        width: 100%;

        :deep(.left),
        :deep(.right) {
            display: flex;
            column-gap: $margin;
        }

        &.no-margin {
            margin-left: unset;
        }
    }
}

@media only screen and (max-width: $screen-mobile) {
    .footer {
        bottom: -10px;
    }
}
</style>
