<template>
    <InputField
        class="color-select"
        @mousedown.prevent="click($event)"
        :focus="opened || textFocussed"
        :disabled="disabled"
        :error="error"
        :success="success"
        :label="label"
        :name="name"
        :label-icon="labelIcon"
        :label-icon-class="labelIconClass"
    >
        <template v-slot:visual>
            <div
                class="square"
                :style="{ background: color, 'border-color': darkerColor }"
            >
                <div class="default" v-if="colorValue === undefined"></div>
            </div>
        </template>
        <template v-slot:input>
            <input
                class="color-hidden"
                type="color"
                v-model="colorValue"
                ref="select"
                @blur="opened = false"
                @focus="opened = true"
            />
            <IconBase
                class="arrow-icon"
                :class="{ open: opened }"
                icon="chevron-down"
            ></IconBase>
            <div class="color-text">
                <input
                    type="text"
                    v-model="colorValue"
                    @click="focusText($event)"
                    @focus="textFocussed = true"
                    @blur="textFocussed = false"
                    ref="textInput"
                    placeholder="Default"
                />
            </div>
            <div class="clear" @click="colorValue = undefined" @mousedown.stop>
                <IconBase icon="delete" class="clear-icon"></IconBase>
            </div>
        </template>
    </InputField>
</template>

<script lang="ts">
import { computed, ref, watch, defineComponent, PropType } from "vue"
import IconBase from "@/ui-elements/IconBase.vue"
import { darker } from "@/utils/useColor"
import InputField from "@/ui-elements/input/InputField.vue"
export default defineComponent({
    name: "ColorSelect",
    components: {
        IconBase,
        InputField,
    },
    props: {
        color: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        success: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
        },
        name: {
            type: [Array, String] as PropType<string | string[]>,
            default: () => [],
        },
        labelIcon: {
            type: String,
        },
        labelIconClass: {
            type: String,
        },
    },
    setup(props, context) {
        const colorValue = ref(props.color ? props.color : undefined)
        watch(colorValue, () => {
            context.emit("update:color", colorValue.value)
        })
        const darkerColor = computed(() => {
            const color = colorValue.value
            return color !== undefined ? darker(color) : "#8C8E9A"
        })
        const select = ref()
        const textInput = ref()

        const opened = ref(false)
        function click(event: MouseEvent) {
            if (props.disabled) {
                return
            }
            if (opened.value === false && event.target !== textInput.value) {
                select.value.click()
                select.value.focus()
            } else {
                select.value.blur()
            }
        }
        const textFocussed = ref(false)
        function focusText(event: MouseEvent) {
            ;(event.target as HTMLElement)?.focus()
        }
        return {
            colorValue,
            darkerColor,
            select,
            textInput,
            click,
            opened,
            focusText,
            textFocussed,
        }
    },
    emits: ["update:color"],
})
</script>

<style lang="scss">
.color-select {
    position: relative;
    cursor: pointer;

    .border {
        padding-left: $padding-m;
        align-items: center;
        flex-flow: row nowrap;
        overflow: hidden;
    }

    .color-hidden {
        width: 0;
        height: 0;
        opacity: 0;
        position: absolute;
        pointer-events: none;
        bottom: -$margin-s;
    }

    .square {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: $radius;
        border: $white-border;
        overflow: hidden;
    }

    .default {
        height: 100%;
        width: calc(99 * 100% / 70);
        border: 0.1rem solid $red;
        transform: translate(1px) rotate(-45deg);
        transform-origin: bottom left;
    }

    .color-text {
        margin-left: $margin-s;

        input {
            border: none;
            border-radius: $radius;
            width: 3.5rem;
            outline: none;
        }
    }

    .arrow-icon {
        transition: transform 0.5s;
        color: $secondary-color;
        margin-left: $margin-s;

        &.open {
            transform: rotateZ(180deg);
        }
    }

    .clear {
        margin-left: auto;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: not-allowed;
        padding: 0 $padding;
    }
}
</style>
