import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextArea = _resolveComponent("TextArea")!

  return (_openBlock(), _createBlock(_component_TextArea, {
    label: _ctx.translate('remarks'),
    text: _ctx.store.currentCart.cart.remarks,
    "onUpdate:text": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.store.currentCart.cart.remarks) = $event))
  }, null, 8, ["label", "text"]))
}