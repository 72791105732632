import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "left" }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { class: "error" }
const _hoisted_4 = { class: "right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingIndicator = _resolveComponent("LoadingIndicator")!
  const _component_BrowserHeader = _resolveComponent("BrowserHeader")!
  const _component_ImageComponent = _resolveComponent("ImageComponent")!
  const _component_ButtonBase = _resolveComponent("ButtonBase")!
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_PanelComponent = _resolveComponent("PanelComponent")!
  const _component_ModalWindow = _resolveComponent("ModalWindow")!

  return (_openBlock(), _createBlock(_component_ModalWindow, {
    class: "file-browser",
    show: _ctx.fileBrowserStore.openModal,
    onClose: _ctx.fileBrowserStore.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PanelComponent, { ready: _ctx.dataReady }, _createSlots({
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.fileBrowserStore.selectedFile.path !== '')
              ? (_openBlock(), _createBlock(_component_ButtonBase, {
                  key: 0,
                  type: "primary-gradient",
                  icon: _ctx.loadingDelete ? 'cog' : 'bin',
                  onClick: _ctx.deleteFile
                }, null, 8, ["icon", "onClick"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "upload",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openFileDialog && _ctx.openFileDialog(...args)))
            }, [
              (_ctx.loadingUpload)
                ? (_openBlock(), _createBlock(_component_LoadingIndicator, { key: 0 }))
                : (_openBlock(), _createBlock(_component_IconBase, {
                    key: 1,
                    icon: "upload"
                  })),
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.translate("upload_your_image_here")), 1)
            ]),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ButtonBase, {
              type: "primary",
              variant: "text",
              onClick: _ctx.cancel
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("cancel")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_ButtonBase, { onClick: _ctx.save }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("save")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 2
      }, [
        (_ctx.loading)
          ? {
              name: "default",
              fn: _withCtx(() => [
                _createVNode(_component_LoadingIndicator)
              ]),
              key: "0"
            }
          : {
              name: "default",
              fn: _withCtx(() => [
                _createVNode(_component_BrowserHeader, {
                  search: _ctx.searchText,
                  "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
                  number: _ctx.filteredFiles.length,
                  "disk-options": _ctx.diskOptions,
                  disk: _ctx.selectedDisk,
                  "onUpdate:disk": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedDisk) = $event))
                }, null, 8, ["search", "number", "disk-options", "disk"]),
                _createElementVNode("div", { class: "images" }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredFiles, (file) => {
                    return (_openBlock(), _createBlock(_component_ImageComponent, {
                      key: file.path,
                      src: _ctx.makeSrc(_ctx.disks, file),
                      selected: 
                            file.path === _ctx.fileBrowserStore.selectedFile.path
                        ,
                      onSelect: ($event: any) => (_ctx.fileBrowserStore.selectedFile = file),
                      onDblclick: ($event: any) => (_ctx.copyFileUrlToClipboard(file))
                    }, null, 8, ["src", "selected", "onSelect", "onDblclick"]))
                  }), 128))
                ])
              ]),
              key: "1"
            }
      ]), 1032, ["ready"])
    ]),
    _: 1
  }, 8, ["show", "onClose"]))
}