import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48921bae"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "title-section"
}
const _hoisted_2 = { class: "notification-title-style" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["notifications", {
            [_ctx.notificationType]: _ctx.notificationType,
            small: _ctx.small,
            light: _ctx.light,
        }])
  }, [
    (!_ctx.hideTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.hideIcon)
            ? (_openBlock(), _createBlock(_component_IconBase, {
                key: 0,
                icon: _ctx.notification.icon
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "notificationLabel", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.notification.title), 1)
            ], true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "notificationMessage", {}, undefined, true)
    ])
  ], 2))
}