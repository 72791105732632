<template>
    <div class="font relative ml-6" ref="modifier">
        <div class="font-button cursor-pointer" @click="open = !open">
            <IconBase icon="text-font-size text-xl"></IconBase>
            <IconBase icon="chevron-down text-xs"></IconBase>
        </div>
    </div>
    <div class="font-size-modifier fixed" v-if="open">
        <VueSlider
            :modelValue="value"
            @change="value = $event"
            v-bind="sliderConfig"
            :data="sizeOptions"
        ></VueSlider>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue"
import { useI18n } from "vue-i18n"
import { setFontSizeOnHTML, useFontSize } from "@/store/FontSize"
import { useClickAway } from "@/utils/useClickAway"
import IconBase from "@/ui-elements/IconBase.vue"
export default defineComponent({
    name: "FontSizeModifier",
    components: {
        IconBase,
    },
    setup() {
        const { t: translate } = useI18n()
        const modifier = ref()
        const open = ref(false)

        const sliderConfig = {
            height: 14,
            contained: true,
            dataValue: "value",
            dataLabel: "label",
            duration: 0.2,
            tooltip: "always",
            tooltipPlacement: "bottom",
            marks: (value: number) => ({
                label: [17, 21].includes(value) ? "Aa" : " ",
            }),
        }
        const sizeOptions = [
            {
                value: 10,
                label: "60%",
            },
            {
                value: 11,
                label: "65%",
            },
            {
                value: 12,
                label: "70%",
            },
            {
                value: 13,
                label: "75%",
            },
            {
                value: 14,
                label: "80%",
            },
            {
                value: 15,
                label: "85%",
            },
            {
                value: 16,
                label: "90%",
            },
            {
                value: 17,
                label: "95%",
            },
            {
                value: 18,
                label: "100%",
            },
            {
                value: 19,
                label: "105%",
            },
            {
                value: 20,
                label: "110%",
            },
            {
                value: 21,
                label: "115%",
            },
        ]
        const fontSize = useFontSize()
        const value = ref(fontSize.getSize())
        watch(value, () => {
            fontSize.setSize(value.value)
            setFontSizeOnHTML(value.value)
        })
        useClickAway(modifier, () => (open.value = false))

        return {
            translate,
            value,
            sizeOptions,
            sliderConfig,
            open,
            modifier,
        }
    },
})
</script>
<style lang="scss">
.font .font-button {
    font-size: 14px !important;
    display: flex;
    align-items: center;
}

.font-size-modifier {
    background: $white;
    width: 35vh;
    height: 15vh;
    right: 15vw;
    top: 9vh;
    padding: 7px;
    border: $thick-important-border;
    border-radius: $radius;
    display: flex;
    flex-flow: column nowrap;
    box-shadow: $shadow;
    z-index: 20;

    .vue-slider {
        margin-top: 2vh;
    }
    .vue-slider-rail {
        background: none;
        border-bottom: $thick-border;
        border-radius: 0;
    }
    .vue-slider-process {
        background: $white;
    }
    .vue-slider-marks {
        top: 7px;
    }
    .vue-slider-mark-step {
        border-left: $thick-border;
        background: none;
        border-radius: 0;
        width: 50%;
        margin-left: 50%;
    }
    .vue-slider-mark:first-child,
    .vue-slider-mark:last-child {
        .vue-slider-mark-step {
            display: unset;
        }
    }
    .vue-slider-mark-label {
        font-weight: $font-weight-medium;
        margin-top: 4px !important;
    }
    .vue-slider-mark:last-child .vue-slider-mark-label {
        font-size: 14px !important;
        margin-top: 4px !important;
    }
    .vue-slider-dot {
        top: 13px !important;
    }
    .vue-slider-dot-handle {
        background: $white;
        border: $thick-border;
    }
    .vue-slider-dot-handle-focus {
        box-shadow: none;
    }
    .vue-slider-dot-tooltip {
        bottom: -7px;
        border: $thick-important-border;
        border-radius: $radius;
        background: $white;
        line-height: 1;
        padding: 0 $margin-s;
        .vue-slider-dot-tooltip-inner {
            background: $transparent-white;
            border-color: $secondary-color;
        }
    }
    .save {
        margin: auto 0 0 auto;
    }
}
</style>
