import { defineStore } from "pinia"
import axios from "axios"

export type Zipcode = {
    id: number
    client_id: number
    costs: number
    free_from: number
    is_available: boolean
    min_order_amount: number
    name: string
    start: string
    end: string
    time: number
    coordinates: GeoJSON.Point
    availability: {
        days: number[] | null
        from: {
            date: string | null
            time: string | null
        }
        till: {
            date: string | null
            time: string | null
        }
    }
}

export type ZipcodesStore = {
    zipcodes: Zipcode[]
}

export const useZipcodesStore = defineStore({
    id: "zipcodes",
    state: () =>
        ({
            zipcodes: [],
        } as ZipcodesStore),
    actions: {
        async fetchZipcodes(): Promise<boolean> {
            try {
                const response = await axios.get("client/zipcodes/selection")
                this.zipcodes = response.data.data
                return true
            } catch (e) {
                return false
            }
        },
    },
})
