import { Order } from "@/interface/orders/Order"

export const isInStore = (order: Order) => {
    return (
        order.source.type === "counter" ||
        order.source.type === "kiosk" ||
        (order.source.type === "online" &&
            (order.source.info === "qr" || order.source.info === "qr_table"))
    )
}
