import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonBase = _resolveComponent("ButtonBase")!
  const _component_CloseConfirmationModal = _resolveComponent("CloseConfirmationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ButtonBase, {
      onClick: _ctx.minimize,
      variant: "outlined",
      icon: "minus",
      class: "minimize"
    }, null, 8, ["onClick"]),
    _createVNode(_component_ButtonBase, {
      onClick: _ctx.maximize,
      variant: "outlined",
      icon: "arrow-expand",
      class: "maximize"
    }, null, 8, ["onClick"]),
    _createVNode(_component_ButtonBase, {
      onClick: _ctx.onClick,
      disabled: _ctx.isOffline || _ctx.splitPaymentStore.isPartiallyPaid,
      icon: "delete",
      class: "close"
    }, null, 8, ["onClick", "disabled"]),
    (_ctx.openModal)
      ? (_openBlock(), _createBlock(_component_CloseConfirmationModal, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal = false)),
          onConfirm: _ctx.close
        }, null, 8, ["onConfirm"]))
      : _createCommentVNode("", true)
  ], 64))
}