import { RoutesConfig } from "@/pages/settings/utils/RoutesConfig"
import i18n from "@/i18n"

export const SETTINGS_ROUTE_OVERRIDES: RoutesConfig = {
    general: {
        icon: "cogs",
        children: {
            default: {
                children: {
                    "general-delivery": {
                        children: {
                            "general-delivery-deliverers": {
                                component: import(
                                    "@/pages/settings/general/delivery/deliverers/DeliverersList.vue"
                                ),
                            },
                        },
                    },
                    "general-printers": {
                        children: {
                            "general-printers-receipt-layouts": {
                                component: import(
                                    "@/pages/settings/general/printers/receipt-layouts/ReceiptLayouts.vue"
                                ),
                            },
                        },
                    },
                },
            },
        },
    },
    menu: {
        icon: "food",
        children: {
            default: {
                children: {
                    "menu-menu": {
                        children: {
                            "menu-menu-products": {
                                component: import(
                                    "@/pages/settings/menu/products/ProductsList.vue"
                                ),
                            },
                            "menu-menu-extras": {
                                component: import(
                                    "@/pages/settings/menu/extras/ExtrasList.vue"
                                ),
                            },
                            "menu-menu-categories": {
                                component: import(
                                    "@/pages/settings/menu/categories/SettingsCategoriesList.vue"
                                ),
                            },
                        },
                    },
                    "menu-promos": {
                        children: {
                            "menu-promos-discount-codes": {
                                component: import(
                                    "@/pages/settings/menu/discounts/discount-codes/DiscountCodes.vue"
                                ),
                            },
                            "menu-promos-free-products-at-specific-amount": {
                                component: import(
                                    "@/pages/settings/menu/discounts/free-products-at-amount/FreeProductsAtAmount.vue"
                                ),
                            },
                            "menu-promos-discounted-products-at-specific-amount":
                                {
                                    component: import(
                                        "@/pages/settings/menu/discounts/discount-products-at-amount/DiscountProductsAtAmount.vue"
                                    ),
                                },
                            "menu-promos-free-products-with-products": {
                                component: import(
                                    "@/pages/settings/menu/discounts/free-products-with-products/FreeProductsWithProducts.vue"
                                ),
                            },
                            "menu-promos-discounted-products-with-products": {
                                component: import(
                                    "@/pages/settings/menu/discounts/discount-products-with-products/DiscountProductsWithProducts.vue"
                                ),
                            },
                        },
                    },
                },
            },
        },
    },
    modules: {
        icon: "foodticket",
    },
    connections: {
        icon: "link",
        children: {
            default: {
                children: {
                    "connections-general-email-connections": {
                        children: {
                            "connections-general-email-connections-index": {
                                component: import(
                                    "@/pages/settings/connections/GeneralEmailConnections.vue"
                                ),
                            },
                        },
                    },
                    "connections-thuisbezorgd": {
                        children: {
                            "connections-thuisbezorgd-index": {
                                component: import(
                                    "@/pages/settings/connections/Thuisbezorgd.vue"
                                ),
                            },
                        },
                    },
                    "connections-deliveroo": {
                        children: {
                            "connections-deliveroo-index": {
                                component: import(
                                    "@/pages/settings/connections/Deliveroo.vue"
                                ),
                            },
                        },
                    },
                    "connections-ubereats": {
                        children: {
                            "connections-ubereats-index": {
                                component: import(
                                    "@/pages/settings/connections/ubereats/UberEats.vue"
                                ),
                            },
                            "connections-ubereats-pairing-codes": {
                                path: "pairing-codes",
                                title: i18n.global.t("manage_pairing_codes"),
                                component: import(
                                    "@/pages/settings/connections/ubereats/pairing codes/UberEatsPairingCodes.vue"
                                ),
                            },
                        },
                    },
                },
            },
        },
    },
}
