<template>
    <div class="tab-content route-planner" :ref="scrollAreaRef()">
        <RoutePlannerMap
            v-if="order.coordinates"
            :origin="
                multiLocation.isActive
                    ? multiLocation.location(order.client_id)?.coordinates
                    : userStore.user.coordinates
            "
            :destination="order.coordinates"
            :deliverer="order.deliverer"
            :mode="mode"
            @routing="routingMessage = $event"
        ></RoutePlannerMap>

        <TabOptions
            :options="mapModes"
            v-model:value="mode"
            class="mode"
        ></TabOptions>
        <div
            class="routing-message"
            v-if="routingMessage"
            v-html="routingMessage"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, Ref, ref } from "vue"
import RoutePlannerMap, {
    RouteModeType,
} from "@/pages/orders/modals/order/tabs/RoutePlannerMap.vue"
import { Order } from "@/interface/orders/Order"
import { useUserStore } from "@/store/User"
import TabOptions from "@/ui-elements/input/TabOptions.vue"
import { multiLocationStore } from "@/store/MultiLocation"

export default defineComponent({
    name: "RoutePlannerTab",
    components: {
        RoutePlannerMap,
        TabOptions,
    },
    props: {
        order: {
            type: Object as PropType<Order>,
            required: true,
        },
        scrollAreaRef: Function,
    },
    setup() {
        const mode: Ref<RouteModeType> = ref("bicycling")
        const userStore = useUserStore()

        const mapModes = ref([
            {
                value: "bicycling",
                icon: "scooter",
            },
            {
                value: "driving",
                icon: "car",
            },
            {
                value: "walking",
                icon: "walking",
            },
        ])
        const routingMessage = ref("")

        return {
            mode,
            userStore,
            mapModes,
            routingMessage,
            multiLocation: multiLocationStore(),
        }
    },
})
</script>

<style lang="scss" scoped>
.route-planner {
    position: relative;
    height: 100%;

    .route-map {
        z-index: 1;
    }

    .mode {
        position: absolute;
        z-index: 2;
        top: 1rem;
        right: 1.5rem;
    }

    .routing-message {
        position: absolute;
        z-index: 2;
        top: 5rem;
        right: 1.5rem;
        background: rgba($white, 0.75);
        color: $primary-color;
        padding: $padding-m;
        border-radius: $radius;
    }
}
</style>
