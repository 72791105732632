<template>
    <div
        @click="$emit('update:checked', !checked)"
        class="check-radio-wrapper"
        :class="{ hasText: !!text }"
    >
        <div
            class="check-radio"
            :class="{ check: checked, disabled: disabled }"
        />
        <div>{{ text }}</div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
export default defineComponent({
    name: "CheckRadio",
    props: {
        text: String,
        checked: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:checked"],
})
</script>

<style lang="scss" scoped>
.check-radio-wrapper {
    display: flex;
    cursor: pointer;
    &.hasText {
        column-gap: $margin-m;
    }
}

.check-radio {
    height: 1.2rem;
    width: 1.2rem;
    cursor: pointer;
    border-radius: 0.6rem;
    transition: $quick-transition;
    border: $thick-border;

    &.check {
        border: $thick-primary-border;
        background: $primary-color;
        box-shadow: inset 0 0 0 3px $white;
    }
}
</style>
