import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "phone-field",
  ref: "parent"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhoneInput = _resolveComponent("PhoneInput")!
  const _component_ButtonBase = _resolveComponent("ButtonBase")!
  const _component_SearchResults = _resolveComponent("SearchResults")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PhoneInput, {
      label: _ctx.translate('phone'),
      value: _ctx.cartStore.customer.address.phone || '',
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.cartStore.customer.address.phone = $event)),
      ref: "inputField",
      error: _ctx.error,
      name: "phone",
      validate: _ctx.cartStore.customer.address.phone !== '',
      onValidation: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('validation', $event)))
    }, null, 8, ["label", "value", "error", "validate"]),
    (_ctx.callsEnabled && _ctx.hasPhone)
      ? (_openBlock(), _createBlock(_component_ButtonBase, {
          key: 0,
          onClick: _ctx.call
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translate("call")), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true),
    (_ctx.display)
      ? (_openBlock(), _createBlock(_component_SearchResults, {
          key: 1,
          class: _normalizeClass(_ctx.upOrDown),
          onSelect: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focussed = false)),
          property: "phone"
        }, null, 8, ["class"]))
      : _createCommentVNode("", true)
  ], 512))
}