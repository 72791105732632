import { computed, ComputedRef, inject, reactive } from "vue"
import { AxiosError } from "axios"

export type FormErrors = {
    processingError: string
    validationErrors: Record<string, string[]>
}

export const useFormErrors = () => {
    const formErrors: FormErrors = reactive({
        processingError: "",
        validationErrors: {},
    })

    const handleApiFormErrors = (error: AxiosError) => {
        setProcessingError(error.response?.data?.message)
        setFormErrors(error.response?.data?.data)
        requestAnimationFrame(scrollToError)
    }

    const setFormErrors = (errors: FormErrors["validationErrors"] = {}) => {
        formErrors.validationErrors = errors
    }

    const setProcessingError = (error: FormErrors["processingError"] = "") => {
        formErrors.processingError = error
    }

    const clearFormErrors = () => {
        formErrors.processingError = ""
        formErrors.validationErrors = {}
    }

    const getErrorByName = (name: string) => {
        const errors: ComputedRef<FormErrors> | undefined = inject(
            "errors",
            computed(() => ({
                processingError: "",
                validationErrors: {},
            }))
        )
        if (
            errors?.value.validationErrors &&
            errors.value.validationErrors[name]
        ) {
            const result = errors.value.validationErrors[name]

            if (Array.isArray(result)) {
                return result.length === 1 ? `${result[0]}.` : result.join(". ")
            }

            return result
        }
        return ""
    }

    const scrollToError = () => {
        const element = document.querySelector(".validation-message.error")
        if (element && element.parentElement) {
            const parentElement = element.closest(".input-field-wrapper")
            if (parentElement) {
                const inputElement = parentElement
                    .querySelector(".input-field.error")
                    ?.querySelector("input")
                inputElement?.focus()
                parentElement.scrollIntoView({ behavior: "smooth" })
            }
        }
    }

    return {
        formErrors,
        handleApiFormErrors,
        setFormErrors,
        clearFormErrors,
        getErrorByName,
        scrollToError,
    }
}
