<template>
    <PopUpModal
        :show="true"
        @close="close"
        :cancellable="!seesOrderFirstTime"
        :scroll-area="scrollArea"
        class="order-details-modal"
        :loading="isLoading"
    >
        <template #header>
            <h3 v-if="multiLocation.isActive">
                {{ multiLocation.location(order.client_id)?.company }}
            </h3>
            <div class="modal-header" v-if="order">
                <ButtonBase type="primary" v-if="showPayButton">
                    {{ translate("pay_order") }}
                </ButtonBase>
                <h3 class="modal-title flex items-center">
                    <IconBase :icon="deliveryType.icon" />
                    <IconBase :icon="sourceTypeIcon" />
                    <span class="id"
                        >#{{
                            order.source.id
                                ? `${order.source.type.toUpperCase()}${
                                      order.source.id
                                  }`
                                : order.id
                        }}</span
                    >
                    {{ title }}
                </h3>
                <Tag :tag-style="tagStyle">
                    <template #tagContent>
                        <div class="tag">
                            <span>{{ total }}</span>
                            <div
                                class="vertical-line"
                                v-if="
                                    isActuallyPaid(order) || isPinFinal(order)
                                "
                            />
                            <span
                                v-if="
                                    isActuallyPaid(order) || isPinFinal(order)
                                "
                                >{{ paymentStatus }}</span
                            >
                        </div>
                    </template>
                </Tag>
            </div>
        </template>

        <template #default>
            <div class="content">
                <ModalStatus
                    v-if="order"
                    :status="order.status"
                    :delivery="order.delivery"
                    @update:status="updateStatus"
                    class="order-statuses"
                />

                <ModalTabs
                    v-if="order"
                    :order="order"
                    @update:order="saveOrder"
                    :scroll-area-ref="getScrollAreaRef"
                />
            </div>
        </template>

        <template #actions>
            <div class="left">
                <ButtonBase
                    v-if="deleting"
                    @click="toggleDeleteModal"
                    name="delete"
                    icon="bin"
                    type="primary-gradient"
                />

                <ButtonBase
                    @click="print(true)"
                    name="print"
                    icon="printer"
                    variant="outlined"
                >
                    {{ translate("print") }}
                </ButtonBase>

                <ButtonBase
                    v-if="showPosOpenButton"
                    name="pos"
                    icon="pos"
                    variant="outlined"
                    @click="openInPos"
                >
                    {{ translate("open") }}
                </ButtonBase>
            </div>
            <div class="right">
                <ButtonBase
                    @click="accept"
                    name="accept"
                    type="success"
                    icon="check"
                    v-if="order && seesOrderFirstTime"
                    :loading="isLoading"
                    class="animate-pulse-object"
                >
                    {{ translate("accept") }}
                </ButtonBase>
                <ButtonBase @click="close" name="close" variant="text" v-else>
                    {{ translate("close") }}
                </ButtonBase>
            </div>
        </template>
    </PopUpModal>
    <OrderDeletePopUp
        :id="orderId"
        v-if="isDeleteModalOpened"
        @close="toggleDeleteModal"
        :callback="close"
    />
</template>

<script lang="ts">
import { paramsSerializer } from "@/utils/api/paramsSerializer"
import { computed, ComputedRef, defineComponent, ref } from "vue"
import { useI18n } from "vue-i18n"
import ModalStatus from "@/pages/orders/modals/order/ModalStatus.vue"
import ModalTabs from "@/pages/orders/modals/order/ModalTabs.vue"
import { ButtonBase } from "@/ui-elements"
import { OrderStatusType } from "@/interface/orders/Order"
import PopUpModal from "@/ui-elements/layout/modals/PopUpModal.vue"
import Tag, { TagStyleType } from "@/ui-elements/Tag.vue"
import axios from "axios"
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification"
import IconBase from "@/ui-elements/IconBase.vue"
import { getOrderSourceType } from "@/pages/orders/order/utils/getOrderSourceType"
import { getDeliveryType } from "@/pages/orders/order/utils/getDeliveryType"
import { useFormatCurrency } from "@/utils/useCurrencySymbol"
import { printOrder } from "@/services/printer/PrinterService"
import { useSettingsStore } from "@/store/Settings"
import { useOrdersStore } from "@/store/Orders"
import { Order } from "orderbuddy-calculations/src/input/types/Order"
import { storeToRefs } from "pinia"
import { usePosOpenButton } from "@/utils/usePosOpenButton"
import { useMasterSlave } from "@/utils/useMasterSlave"
import { updateOrderStatus } from "@/pages/orders/order/utils/updateStatus"
import OrderDeletePopUp from "@/pages/archive/edit/components/OrderDeletePopUp.vue"
import { isPinFinal, isActuallyPaid } from "@/utils/useIsOrderPaid"
import { useOrderModalStore } from "@/store/OrderModal"
import { multiLocationStore } from "@/store/MultiLocation"

export default defineComponent({
    name: "OrderModal",
    components: {
        IconBase,
        OrderDeletePopUp,
        Tag,
        PopUpModal,
        ButtonBase,
        ModalStatus,
        ModalTabs,
    },
    props: {
        orderId: {
            type: Number,
            required: true,
        },
    },
    setup(props, context) {
        const { t: translate } = useI18n()
        const ordersStore = useOrdersStore()
        const { settings } = storeToRefs(useSettingsStore())
        const scrollArea = ref()
        const order = computed(() => ordersStore.orderData(props.orderId))
        const isLoading = ref(false)
        const isDeleteModalOpened = ref(false)
        const orderModalStore = useOrderModalStore()

        // If the restaurant sees the order for the first time, they only see the accept button (and it's printed)
        const seesOrderFirstTime = !order?.value?.has_seen

        const deleting = settings.value.delete_orders === "1"

        // Order doesn't exist
        if (!order.value) {
            ordersStore.closeOrderModal()
        }

        const tagStyles: { [key: string]: TagStyleType } = {
            init: "warning",
            pending: "warning",
            paid: "success",
            refund: "error",
            delivered: "success",
        }

        const title = computed(() => {
            if (!order.value) {
                return ""
            }
            return `${translate(
                "var_order.delivery." + order.value.delivery
            )}: #${order.value.refnr || order.value.nr || order.value.id}`
        })

        const total = computed(() => {
            if (!order.value) {
                return ""
            }
            return useFormatCurrency(order.value.total)
        })

        const paymentStatus = computed(() => {
            if (!order.value) {
                return ""
            }
            return translate(
                "var_order.paymentStatus." + order.value.payment.status
            )
        })

        const showPayButton = computed(() => {
            if (!order.value) {
                return ""
            }
            return (
                order.value.payment?.status === "init" ||
                order.value.payment?.status === "pending"
            )
        })

        const { showPosOpenButton, goToOrder } = usePosOpenButton(order)
        const openInPos = () => {
            close()
            goToOrder()
        }

        const deleteOrder = async () => {
            try {
                isLoading.value = true
                await axios.delete("client/archive/" + props.orderId)
                await ordersStore.fetchOrders()
                toggleDeleteModal()
                context.emit("close")
            } catch (e) {
                setToastNotification(
                    translate("something_went_wrong"),
                    translate("please_try_again"),
                    "danger"
                )
            } finally {
                isLoading.value = false
            }
        }

        const print = async (force: boolean = false) => {
            return printOrder(order.value!, {
                printCustomerReceipts: undefined,
                existingOrder: false,
                force: force,
            })
        }

        const { isSlave } = useMasterSlave()
        const close = () => {
            if (isLoading.value) {
                return
            }

            const isImpersonated = !!Number(
                localStorage.getItem("foodticketresellerimpersonation")
            )
            // If you are slave, or impersonating, do not mark order as seen/accepted.
            // If the order is already accepted, just close the modal.
            if (isSlave.value || isImpersonated || !seesOrderFirstTime) {
                context.emit("close")
                return
            }
            accept()
        }

        const acceptPrint = async () => {
            // Print, if setting enabled
            if (
                settings.value.print_auto !== "0" &&
                settings.value.print_when === "accept"
            ) {
                await print()
            }
        }

        const accept = async () => {
            try {
                isLoading.value = true

                if (!order?.value?.has_seen) {
                    await acceptPrint()
                }

                await ordersStore.acceptOrder(props.orderId)
                context.emit("close")
            } catch (e) {
                setToastNotification(
                    translate("something_went_wrong"),
                    translate("please_try_again"),
                    "danger"
                )
            } finally {
                isLoading.value = false
            }
        }
        const save = (newOrder: Order) =>
            axios.patch("client/orders/" + props.orderId, newOrder, {
                params: {
                    main_location_id: multiLocationStore().isActive
                        ? multiLocationStore().main_location_id
                        : 0,
                },
                paramsSerializer: paramsSerializer,
            })

        const saveOrder = async (
            newOrder: any,
            triggerLoading: boolean = true
        ) => {
            const success = ref(false)

            if (triggerLoading) {
                isLoading.value = true
            }

            await save(newOrder)
                .then((response) => {
                    useOrdersStore().saveOrder(response.data.data)
                    success.value = true
                    // This modal got opened because it was auto opened and we've shown the time modal immediately
                    // This means that because we updated the time, we can close this modal
                    if (ordersStore.showTimeImmediately) {
                        acceptPrint()
                        context.emit("close")
                        ordersStore.showTimeImmediately = false
                    }
                })
                .catch(() => {
                    setToastNotification(
                        translate("something_went_wrong"),
                        translate("please_try_again"),
                        "danger"
                    )
                })
                .finally(() => {
                    isLoading.value = false
                })

            return success.value
        }

        const updateStatus = async (status: OrderStatusType) => {
            await updateOrderStatus(status, order.value!, saveOrder)
            if (status === "enroute" && !order.value?.deliverer_id) {
                orderModalStore.openedTab = "deliverers"
            }
        }

        const toggleDeleteModal = () => {
            // If it's open and this function is called, it means the order is deleted
            // We need to close both modals and we stop displaying the order in the overview
            if (isDeleteModalOpened.value) {
                ordersStore.closeOrderModal()
                ordersStore.objectDeleted(order.value?.id ?? 0)
            }
            isDeleteModalOpened.value = !isDeleteModalOpened.value
        }

        const getScrollAreaRef = () => scrollArea

        const sourceTypeIcon = computed(() => {
            if (!order.value) {
                return ""
            }

            return getOrderSourceType(
                order.value.source.type,
                order.value.source.info
            )
        })
        const deliveryType = computed(() =>
            getDeliveryType(order.value!.delivery)
        )

        const tagStyle: ComputedRef<TagStyleType> = computed(() => {
            if (!order.value) {
                return "" as TagStyleType
            }

            return tagStyles[order.value.payment?.status]
        })

        return {
            translate,
            ordersStore,
            title,
            total,
            paymentStatus,
            showPosOpenButton,
            deleteOrder,
            print,
            close,
            accept,
            tagStyle,
            scrollArea,
            updateStatus,
            showPayButton,
            saveOrder,
            order,
            isLoading,
            isDeleteModalOpened,
            toggleDeleteModal,
            getScrollAreaRef,
            sourceTypeIcon,
            deliveryType,
            deleting,
            openInPos,
            isActuallyPaid,
            isPinFinal,
            seesOrderFirstTime,
            multiLocation: multiLocationStore(),
        }
    },
    emits: ["close"],
})
</script>

<style lang="scss">
.modal-header {
    display: flex;
    align-items: center;
    column-gap: $margin-l;

    .close {
        margin-right: $margin;
    }

    .modal-title {
        display: flex;
        gap: $margin;

        .id {
            font-weight: inherit;
            font-size: inherit;
        }

        span {
            color: $gray;
        }

        .icon-base {
            color: inherit;
            font-size: 1.8rem;
        }
    }
}

.order-details-modal {
    .pop-up {
        width: 75vw;
        max-width: 100vw;

        .header {
            padding: 0 $padding-m;
        }
    }

    .content {
        height: 70vh;
    }

    .footer {
        padding-right: $padding-l;
        padding-left: $padding-l;

        .actions {
            justify-content: space-between;
            width: 100%;
        }
    }
}

.tag {
    display: flex;
    align-items: center;

    .vertical-line {
        min-height: 1rem;
        height: 100%;
        border-left: $normal-border;
        margin: 0 $margin-s;
    }
}
@media only screen and (max-width: $screen-mobile) {
    .order-details-modal {
        .pop-up {
            display: flex;
            width: 95vw;
            height: auto;
            flex-wrap: wrap;
            justify-content: space-around;

            .content-wrapper {
                padding: 1rem 0.75rem 1rem 0.75rem;
                height: auto;
            }

            .header {
                padding: 0;
                .button {
                    width: 2.5rem;
                    height: 2.5rem;
                    .icon-base {
                        font-size: 0.9rem;
                    }
                }
            }

            .content {
                height: auto;
            }
        }
        .footer {
            .footer-wrap {
                .page-buttons,
                .horizontal-line {
                    display: none;
                }
            }
        }
    }
}
</style>
