import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a6ca371a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-table" }
const _hoisted_2 = { class: "table-list" }
const _hoisted_3 = {
  key: 0,
  class: "list"
}
const _hoisted_4 = {
  key: 0,
  class: "no-rows"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableRow = _resolveComponent("TableRow")!
  const _component_LoadingIndicator = _resolveComponent("LoadingIndicator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createVNode(_component_TableRow, {
          "onUpdate:selected": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectionObject.setAllItems($event))),
          isLink: false,
          head: true,
          selectable: _ctx.selectable,
          deletable: _ctx.deletable,
          selected: _ctx.selectionObject?.allChecked || false,
          indeterminate: _ctx.selectionObject?.indeterminate,
          isDraggable: _ctx.isDraggable
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "list-header", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["selectable", "deletable", "selected", "indeterminate", "isDraggable"])
      ]),
      (_ctx.isDraggable)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (!_ctx.loading && !_ctx.empty)
              ? _renderSlot(_ctx.$slots, "list", { key: 0 }, undefined, true)
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (!_ctx.loading && !_ctx.empty)
              ? (_openBlock(), _createElementBlock("tbody", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "list", {}, undefined, true)
                ]))
              : _createCommentVNode("", true)
          ], 64))
    ]),
    (_ctx.empty || _ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_LoadingIndicator, { key: 0 }))
            : _renderSlot(_ctx.$slots, "no-rows", { key: 1 }, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}