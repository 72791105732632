<template>
    <div class="order-fields">
        <div class="title">
            {{ translate("order") }}
        </div>
        <div class="form">
            <DispatchField />
            <DateField />
            <TimeField :is-loaded-from-order="false" />
            <DiscountCodeField />
            <RemarksField />
            <ReferenceField />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { useI18n } from "vue-i18n"
import DateField from "@/pages/pos/modal/customer/fields/DateField.vue"
import DiscountCodeField from "@/pages/pos/modal/customer/fields/DiscountCodeField.vue"
import DispatchField from "@/pages/pos/modal/customer/fields/DispatchField.vue"
import RemarksField from "@/pages/pos/modal/customer/fields/RemarksField.vue"
import TimeField from "@/pages/pos/modal/customer/fields/TimeField.vue"
import ReferenceField from "@/pages/pos/modal/customer/fields/ReferenceField.vue"
export default defineComponent({
    name: "CustomerOrder",
    components: {
        ReferenceField,
        DateField,
        DiscountCodeField,
        DispatchField,
        RemarksField,
        TimeField,
    },

    setup() {
        const { t: translate } = useI18n()

        return {
            translate,
        }
    },
})
</script>

<style lang="scss">
.order-fields {
    display: flex;
    flex-flow: column nowrap;

    .form {
        display: flex;
        flex-flow: column nowrap;
        gap: $margin;
    }
}
</style>
