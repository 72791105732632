import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = ["type", "inputmode", "disabled", "placeholder", "autocomplete", "autofocus", "step", "min", "max", "data-testid"]
const _hoisted_2 = {
  key: 2,
  class: "buttons"
}
const _hoisted_3 = {
  key: 3,
  class: "append-at-last"
}
const _hoisted_4 = {
  key: 4,
  class: "append-at-last cursor-pointer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_LoadingIndicator = _resolveComponent("LoadingIndicator")!
  const _component_PasswordStrength = _resolveComponent("PasswordStrength")!
  const _component_InputField = _resolveComponent("InputField")!

  return (_openBlock(), _createBlock(_component_InputField, {
    class: _normalizeClass(["text-field", _ctx.name]),
    disabled: _ctx.loading || _ctx.disabled,
    focus: _ctx.focussed,
    error: _ctx.error,
    success: _ctx.success,
    label: _ctx.label,
    "label-icon": _ctx.labelIcon,
    "label-icon-class": _ctx.labelIconClass,
    name: _ctx.name
  }, _createSlots({
    input: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_LoadingIndicator, { key: 0 }))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", {
        ref: "input",
        type: _ctx.inputType,
        inputmode: _ctx.type === 'number' ? 'numeric' : 'text',
        onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.focussed = true)),
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focussed = false)),
        onFocusin: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focussed = true)),
        onFocusout: _cache[3] || (_cache[3] = ($event: any) => (_ctx.focussed = false)),
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.content) = $event)),
        disabled: _ctx.loading || _ctx.disabled,
        placeholder: _ctx.placeholder,
        autocomplete: _ctx.autocomplete,
        autofocus: _ctx.autofocus,
        step: _ctx.step,
        min: _ctx.min,
        max: _ctx.max,
        "data-testid": _ctx.dataTestid
      }, null, 40, _hoisted_1), [
        [_vModelDynamic, _ctx.content]
      ]),
      (_ctx.type === 'password')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["toggle", { active: _ctx.inputType === 'text' }]),
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.toggleType && _ctx.toggleType(...args)))
          }, null, 2))
        : _createCommentVNode("", true),
      (_ctx.showButtons && _ctx.type === 'number')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_IconBase, {
              icon: "minus",
              onClick: _ctx.minus
            }, null, 8, ["onClick"]),
            _createVNode(_component_IconBase, {
              icon: "plus",
              onClick: _ctx.plus
            }, null, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.append)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.append), 1))
        : _createCommentVNode("", true),
      (_ctx.appendIcon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_IconBase, {
              icon: _ctx.appendIcon,
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('appendIconClick')))
            }, null, 8, ["icon"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (_ctx.prepend || _ctx.icon || _ctx.currencyPrepend)
      ? {
          name: "visual",
          fn: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.currencyPrepend || _ctx.prepend) + " ", 1),
            (_ctx.icon)
              ? (_openBlock(), _createBlock(_component_IconBase, {
                  key: 0,
                  icon: _ctx.icon
                }, null, 8, ["icon"]))
              : _createCommentVNode("", true)
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.message || (_ctx.type === 'password' && _ctx.passwordStrength))
      ? {
          name: "message",
          fn: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.message) + " ", 1),
            (_ctx.type === 'password' && _ctx.passwordStrength)
              ? (_openBlock(), _createBlock(_component_PasswordStrength, {
                  key: 0,
                  password: _ctx.text
                }, null, 8, ["password"]))
              : _createCommentVNode("", true)
          ]),
          key: "1"
        }
      : undefined
  ]), 1032, ["disabled", "focus", "error", "success", "label", "label-icon", "label-icon-class", "name", "class"]))
}