import { CalculatedCart } from "@/interface/Cart"
import { useSettingsStore } from "@/store/Settings"
import {
    getDefaultDispatchType,
    getInitialDispatchType,
} from "@/utils/settings/dispatchType"
import { PaymentMethod } from "@/pages/pos/ticket/PaymentMethod"

export const getDefaultPaymentMethod = () => {
    const settingsStore = useSettingsStore()
    return settingsStore.settings.pos_def_payment
}

export const getInitialCart = (): CalculatedCart => {
    const settingsStore = useSettingsStore()
    return {
        cart: {
            products: [],
            free_products: [],
            tip: 0,
            type:
                settingsStore.settings.pos_show_cust_delivery_buttons === "1"
                    ? getInitialDispatchType()
                    : getDefaultDispatchType(),
            points: 0,
            zip_code: "",
            schedule: {
                date: "",
                time: "",
                asap: false,
            },
            discounts: [],
            payment_method: getDefaultPaymentMethod() as PaymentMethod,
            remarks: "",
            additional_delivery_cost: 0,
            products_can_be_updated: true,
        },
        delivery_fee_conditions: {
            free_from: Number(settingsStore.settings.delivery_free),
            minimum: Number(settingsStore.settings.delivery_min),
            zipcode_id: null,
        },
        costs: {
            payment: 0,
            delivery: 0,
            packaging: 0,
            deposit: 0,
            tip: 0,
        },
        choices: [],
        discounts: [],
        vat: {
            rates: {},
            total: 0,
        },
        points: {
            spent: 0,
            earned: 0,
        },
        discount: 0,
        subtotal: 0,
        total: 0,
        cash_amount: null,
        table_id: 0,
        is_loaded_from_order: false,
        _log: {},
    }
}
