import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonBase = _resolveComponent("ButtonBase")!

  return (_ctx.active)
    ? (_openBlock(), _createBlock(_component_ButtonBase, {
        key: 0,
        variant: "outlined",
        name: "deliverers",
        url: "/settings/general/delivery/deliverers",
        icon: "scooter",
        disabled: _ctx.disabled
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.showName ? _ctx.translate("deliverers") : ""), 1)
        ]),
        _: 1
      }, 8, ["disabled"]))
    : _createCommentVNode("", true)
}