import { defineStore } from "pinia"
import axios from "axios"
import { DiscountType } from "@/interface/Cart"
import {
    DiscountSimple,
    DiscountDiscountedProduct,
    DiscountFreeProduct,
} from "orderbuddy-calculations/src/input/types/Discount"

export type DiscountsStore = {
    discounts: {
        [DiscountType.SIMPLE]: DiscountSimple[]
        [DiscountType.DISCOUNTED_PRODUCT]: DiscountDiscountedProduct[]
        [DiscountType.FREE_PRODUCT]: DiscountFreeProduct[]
    }
}

export const useCartDiscountsStore = defineStore({
    id: "cartDiscounts",
    state: () =>
        ({
            discounts: {
                [DiscountType.SIMPLE]: [],
                [DiscountType.DISCOUNTED_PRODUCT]: [],
                [DiscountType.FREE_PRODUCT]: [],
            },
        } as DiscountsStore),
    actions: {
        async fetchCartDiscounts(): Promise<boolean> {
            try {
                const response = await axios.get("client/pos/discounts")
                this.discounts = response.data.data
                return true
            } catch (e) {
                return false
            }
        },
    },
})
