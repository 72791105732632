import { defineStore } from "pinia"
import { ServerDisks, ServerFile } from "@/interface/components/FileBrowser"
import { makeSrc } from "@/ui-elements/file-browser/useFileBrowser"
type FileBrowserStoreState = {
    openModal: boolean
    selectedFile: ServerFile
    targetField: string | Array<string>
    saving: boolean
    disks: ServerDisks
}

export const useFileBrowserStore = defineStore({
    id: "fileBrowser",
    state: () =>
        ({
            openModal: false,
            selectedFile: { disk: "", path: "" },
            targetField: "",
            disks: {},
            saving: false,
        } as FileBrowserStoreState),
    actions: {
        open() {
            this.openModal = true
        },
        close() {
            this.openModal = false
        },
        resetState() {
            this.selectedFile = { disk: "", path: "" }
            this.saving = false
            this.targetField = ""
        },
    },
    getters: {
        fileWithPreview: (state) => ({
            ...state.selectedFile,
            url: makeSrc(state.disks, state.selectedFile),
        }),
    },
})
