import { shopMenu, shopName } from "@/pageStructure/MarketingStructure"
import { RouteRecordRaw } from "vue-router"

export const ShopRoutes: RouteRecordRaw = {
    path: "/marketing/",
    meta: { name: shopName },
    component: () =>
        import(
            /* webpackPrefetch: true */
            "@/pages/marketing/MarketingLayout.vue"
        ),
    children: [
        {
            path: "",
            name: shopName,
            redirect: { name: shopMenu.promotionMaterials.name },
        },
        {
            path: shopMenu.promotionMaterials.path,
            name: shopMenu.promotionMaterials.name,
            meta: { subName: shopMenu.promotionMaterials.name },
            component: () =>
                import("@/pages/marketing/PromotionalMaterials.vue"),
        },
        {
            path: shopMenu.prizeWheel.path,
            name: shopMenu.prizeWheel.name,
            meta: { subName: shopMenu.prizeWheel.name },
            component: () =>
                import("@/pages/marketing/prizewheel/MarketingPrizeWheel.vue"),
        },
        {
            path: shopMenu.resources.path,
            name: shopMenu.resources.name,
            meta: { subName: shopMenu.resources.name },
            component: () =>
                import("@/pages/marketing/resources/MarketingResources.vue"),
        },
        {
            path: shopMenu.email.path,
            name: shopMenu.email.name,
            meta: { subName: shopMenu.email.name },
            component: () => import("@/pages/marketing/email/EmailList.vue"),
        },
        {
            path: shopMenu.email.path + "/edit/:id",
            props: true,
            name: "email/edit",
            meta: { subName: shopMenu.email.name },
            component: () =>
                import("@/pages/marketing/email/edit/EmailEditLayout.vue"),
        },
        {
            path: shopMenu.sms.path,
            name: shopMenu.sms.name,
            meta: { subName: shopMenu.sms.name },
            component: () =>
                import("@/pages/marketing/sms/MarketingSMSList.vue"),
        },
        {
            path: shopMenu.sms.path + "/edit/:id",
            props: true,
            name: shopMenu.sms.name + "/edit",
            meta: { subName: shopMenu.sms.name },
            component: () =>
                import("@/pages/marketing/sms/MarketingSMSEditLayout.vue"),
        },
        {
            path: shopMenu.push.path,
            name: shopMenu.push.name,
            meta: { subName: shopMenu.push.name },
            component: () =>
                import("@/pages/marketing/push/MarketingPushList.vue"),
        },
        {
            path: shopMenu.push.path + "/edit/:id",
            props: true,
            name: shopMenu.push.name + "/edit",
            meta: { subName: shopMenu.push.name },
            component: () =>
                import("@/pages/marketing/push/MarketingPushEditLayout.vue"),
        },
    ],
}
