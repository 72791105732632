import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "device-check-wrapper"
}
const _hoisted_2 = { class: "description" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = {
  key: 0,
  class: "title"
}
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminClientStatistics = _resolveComponent("AdminClientStatistics")!
  const _component_TableCell = _resolveComponent("TableCell")!
  const _component_TableRow = _resolveComponent("TableRow")!
  const _component_BaseTable = _resolveComponent("BaseTable")!
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_AdminInvoicesTable = _resolveComponent("AdminInvoicesTable")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_FloatingModal = _resolveComponent("FloatingModal")!
  const _component_DeviceCheck = _resolveComponent("DeviceCheck")!

  return (_openBlock(), _createBlock(_component_DeviceCheck, null, {
    default: _withCtx(({ isMobile }) => [
      (!isMobile)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_FloatingModal, {
              title: `${_ctx.translate('data')}: #${_ctx.user.id} | ${
                        _ctx.user.address.company
                    }`,
              submitButtonText: _ctx.translate('close'),
              onSubmit: _ctx.onSubmit,
              show: _ctx.adminInfoModalOpen,
              class: "client-info grid-cols-10"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_AdminClientStatistics),
                  _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.translate("miscellaneous")), 1),
                  _createVNode(_component_BaseTable, {
                    selectable: false,
                    deletable: false,
                    class: "mb-4"
                  }, {
                    "list-header": _withCtx(() => [
                      (_ctx.riceCookerVersion)
                        ? (_openBlock(), _createBlock(_component_TableCell, { key: 0 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("orderbuddy_desktop")), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.devGetFrontendVersion())
                        ? (_openBlock(), _createBlock(_component_TableCell, { key: 1 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("orderbuddy_web")), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_TableCell, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("prizewheel")), 1)
                        ]),
                        _: 1
                      }),
                      (
                                        _ctx.adminInfo?.is_part_of !== undefined &&
                                        _ctx.adminInfo?.is_part_of.type !== 'none'
                                    )
                        ? (_openBlock(), _createBlock(_component_TableCell, { key: 2 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate(
                                            _ctx.adminInfo.is_part_of.type ===
                                                "multi_kitchen"
                                                ? "multi_kitchen_of"
                                                : "multi_branch_of"
                                        )), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.adminInfo?.account_manager)
                        ? (_openBlock(), _createBlock(_component_TableCell, { key: 3 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("accountmanager")), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    list: _withCtx(() => [
                      _createVNode(_component_TableRow, { selectable: false }, {
                        default: _withCtx(() => [
                          (_ctx.riceCookerVersion)
                            ? (_openBlock(), _createBlock(_component_TableCell, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.riceCookerVersion), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.devGetFrontendVersion())
                            ? (_openBlock(), _createBlock(_component_TableCell, { key: 1 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.devGetFrontendVersion()), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_TableCell, {
                            class: _normalizeClass([
                                            _ctx.adminInfo?.is_prizewheel_active
                                                ? 'active'
                                                : 'inactive',
                                        ])
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.adminInfo?.is_prizewheel_active
                                                ? _ctx.translate("active")
                                                : _ctx.translate("inactive")), 1)
                            ]),
                            _: 1
                          }, 8, ["class"]),
                          (
                                            _ctx.adminInfo?.is_part_of !==
                                                undefined &&
                                            _ctx.adminInfo?.is_part_of.type !==
                                                'none'
                                        )
                            ? (_openBlock(), _createBlock(_component_TableCell, { key: 2 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.adminInfo.is_part_of.locations) + "  (#" + _toDisplayString(_ctx.adminInfo.is_part_of.parent_ids.join(
                                                ", #"
                                            )) + ") ", 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.adminInfo?.account_manager)
                            ? (_openBlock(), _createBlock(_component_TableCell, { key: 3 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.adminInfo.account_manager), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_ctx.adminInfo?.todos?.length > 0)
                    ? (_openBlock(), _createElementBlock("h3", _hoisted_4, " Todos "))
                    : _createCommentVNode("", true),
                  (_ctx.adminInfo?.todos?.length > 0)
                    ? (_openBlock(), _createBlock(_component_BaseTable, {
                        key: 1,
                        selectable: false,
                        deletable: false,
                        class: "mb-4"
                      }, {
                        "list-header": _withCtx(() => [
                          _createVNode(_component_TableCell, null, {
                            default: _withCtx(() => [
                              _createTextVNode(" # ")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_TableCell, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("subject")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_TableCell, null, {
                            default: _withCtx(() => [
                              _createTextVNode(" Prio ")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_TableCell, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("status")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_TableCell, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("assignee")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_TableCell, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("actions")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        list: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adminInfo?.todos, (todo) => {
                            return (_openBlock(), _createBlock(_component_TableRow, {
                              key: todo.id,
                              selectable: false,
                              onClick: ($event: any) => (_ctx.openTodoInNewTab(todo.id))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_TableCell, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(todo.id), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_TableCell, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(todo.subject_description), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_TableCell, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(todo.prio_description), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_TableCell, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(todo.status_description), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_TableCell, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(todo.assignee), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_TableCell, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_IconBase, { icon: "link" })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          }), 128))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.adminInfo?.invoices?.length > 0)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.translate("invoices")), 1),
                        _createVNode(_component_AdminInvoicesTable, {
                          invoices: _ctx.adminInfo.invoices
                        }, null, 8, ["invoices"])
                      ], 64))
                    : _createCommentVNode("", true),
                  _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.translate("remarks")), 1),
                  _createVNode(_component_TextArea, {
                    disabled: true,
                    text: _ctx.adminInfo?.remarks
                  }, null, 8, ["text"])
                ])
              ]),
              _: 1
            }, 8, ["title", "submitButtonText", "onSubmit", "show"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}