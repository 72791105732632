<template>
    <InputField
        class="select-component"
        :disabled="disabled"
        :error="error"
        :success="success"
        :transparent="transparent"
        :focus="opened"
        @click="componentClickHandler()"
        :class="name"
        :label="label"
        :name="name"
        :label-icon="labelIcon"
        :label-icon-class="labelIconClass"
        ref="root"
    >
        <template v-slot:input>
            <div
                class="select"
                :class="[{ open: opened }, name]"
                :style="styleObject"
                ref="select"
            >
                <div class="selected">
                    <span class="text">
                        <slot name="icon"></slot>
                        <span class="selected-text" v-html="selectedText" />
                    </span>
                    <IconBase icon="chevron-down"></IconBase>
                </div>
                <div
                    class="options-list"
                    ref="optionsList"
                    v-if="filteredOptions.length"
                >
                    <div
                        v-for="(item, index) in filteredOptions"
                        :key="index"
                        class="option"
                        :class="[
                            { 'selected-item': item.value === value },
                            'option-' + item.value,
                            { disabled: item.disabled },
                            { 'group-title': item.optGroup },
                        ]"
                        @click.stop="optionClickHandler(item)"
                        :style="{ top: optionTops[index] }"
                    >
                        <IconBase
                            v-if="item.icon"
                            :icon="item.icon"
                            class="option-icon"
                        />
                        <span class="option-label" v-html="item.label" />
                        <IconBase
                            v-if="item.value === value"
                            icon="check"
                        ></IconBase>
                    </div>
                </div>
                <div class="options-list" ref="optionsList" v-else>
                    <div class="option">
                        <span
                            class="option-label no-results"
                            v-html="translate('no_results_were_found')"
                        />
                    </div>
                </div>
                <div class="footer" @click.stop>
                    <ScrollButtons :scroll-area="optionsList" />
                    <TextField
                        appendIcon="delete"
                        @appendIconClick="resetFilteredOptions"
                        v-model:text="searchQuery"
                        icon="search"
                        class="w-full"
                        :placeholder="`${translate('search')}...`"
                        v-if="canSearch"
                    />
                </div>
            </div>
        </template>
    </InputField>
</template>
<script lang="ts">
import IconBase from "../../IconBase.vue"
import {
    computed,
    ref,
    defineComponent,
    PropType,
    onMounted,
    watch,
    Ref,
} from "vue"
import { useClickAway } from "@/utils/useClickAway"
import InputField from "@/ui-elements/input/InputField.vue"
import { useFontSize } from "@/store/FontSize"
import ScrollButtons from "@/ui-elements/layout/footer/ScrollButtons.vue"
import TextField from "@/ui-elements/input/text-field/TextField.vue"
import { useI18n } from "vue-i18n"
import { toLower } from "lodash"

export type SelectOption = {
    value: number | string | null
    label: string
    icon?: string
    disabled?: boolean
    optGroup?: boolean
}

export default defineComponent({
    name: "SelectComponent",
    components: { TextField, ScrollButtons, IconBase, InputField },
    props: {
        text: {
            type: String,
            default: "",
        },
        options: {
            type: Array as PropType<SelectOption[]>,
            required: true,
            validator: (value: Array<SelectOption>) => {
                return value.reduce((acc, cur) => {
                    const keys = Object.keys(cur)
                    return (
                        acc && keys.includes("value") && keys.includes("label")
                    )
                }, true)
            },
            default: () => [],
        },
        value: {
            type: [String, Number, null] as PropType<SelectOption["value"]>,
            required: true,
        },
        name: {
            type: [Array, String] as PropType<string | string[]>,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        success: {
            type: Boolean,
            default: false,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
        },
        labelIcon: {
            type: String,
        },
        labelIconClass: {
            type: String,
        },
        canSearch: {
            type: Boolean,
            default: true,
        },
        dropUp: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, context) {
        const root = ref()
        const select = ref()
        const optionsList = ref()
        const opened = ref(false)
        const fontSize = useFontSize().getSize()
        const searchQuery: Ref<string | null> = ref(null)
        const { t: translate } = useI18n()
        const filteredOptions = ref(props.options)

        const selectedText = computed(() => {
            let returnText = ""
            const selectedOption = props.options.find(
                (option) => option.value === props.value
            )
            if (props.text) {
                returnText = props.text + ": "
            }
            if (selectedOption) {
                returnText = returnText + selectedOption.label
            } else {
                returnText = returnText + "..."
            }
            return returnText
        })
        const height = ref(0)
        const top = ref(0)

        const windowHeight = ref(0)
        const windowTop = ref(0)

        const componentHeight = 2.75 * fontSize
        const footerHeight = 3 * fontSize
        const optionsListPadding = 0.25 * fontSize
        const calculateHeight = (length: number) => {
            const rowHeight = fontSize * 1.8
            return length * rowHeight
        }

        onMounted(() => {
            windowTop.value = 95 + fontSize
            windowHeight.value = window.innerHeight - windowTop.value - fontSize
        })

        const resetFilteredOptions = () => {
            searchQuery.value = null
            filteredOptions.value = props.options
        }

        const filterOptions = () => {
            if (searchQuery.value !== "" && searchQuery.value !== null) {
                return (filteredOptions.value = filteredOptions.value.filter(
                    (option: SelectOption) =>
                        toLower(option.label)?.includes(
                            toLower(String(searchQuery.value))
                        )
                ))
            }

            // If searchQuery is empty, return all options
            return (filteredOptions.value = props.options)
        }

        // Dirty fix for settings pages.
        // Sometimes options are fetched with a separate http req.
        // Or the loading of the data on the page takes a bit longer.
        watch(
            () => props.options,
            () => {
                filteredOptions.value = props.options
            }
        )

        watch(searchQuery, () => {
            filterOptions()
        })

        const setHeight = () => {
            const neededHeight =
                componentHeight + // top part
                calculateHeight(props.options.length) + //options
                optionsListPadding * 5 +
                footerHeight +
                2 // two borders
            const positionFromTop = select.value.getBoundingClientRect().top
            const maxHeight = window.innerHeight - fontSize - positionFromTop
            if (neededHeight < maxHeight) {
                height.value = neededHeight
            } else {
                height.value = maxHeight
            }
            top.value = 0
            // drop up
            if (
                positionFromTop + 16 * fontSize > window.innerHeight ||
                props.dropUp
            ) {
                if (neededHeight > positionFromTop) {
                    height.value = positionFromTop - fontSize
                } else {
                    height.value = neededHeight
                }
                top.value = -1 * height.value + fontSize * 2.75
            }
        }
        function componentClickHandler() {
            if (!props.disabled) {
                if (opened.value) {
                    opened.value = false
                } else {
                    setHeight()
                    opened.value = true
                }
            }
        }
        const styleObject = computed(() => {
            const style = {} as { [property: string]: string }
            if (opened.value) {
                style["min-height"] = "5rem"
                style["height"] = height.value / fontSize + "rem"
                style["top"] = top.value / fontSize + "rem"
                style["position"] = "absolute"
            }
            return style
        })
        const optionTops = computed(() =>
            props.options.map((_, index) =>
                opened.value
                    ? (calculateHeight(index) + optionsListPadding) / fontSize +
                      "rem"
                    : 0 + ""
            )
        )
        useClickAway(select, () => (opened.value = false))
        const optionClickHandler = (item: SelectOption) => {
            if (!item.disabled) {
                context.emit("update:value", item.value)
                opened.value = false
            }
        }
        watch(
            () => props.options,
            () => {
                if (opened.value) {
                    setHeight()
                }
            }
        )
        return {
            opened,
            selectedText,
            height,
            select,
            top,
            calculateHeight,
            root,
            styleObject,
            optionTops,
            optionClickHandler,
            optionsList,
            componentClickHandler,
            searchQuery,
            translate,
            filteredOptions,
            filterOptions,
            resetFilteredOptions,
        }
    },
    emits: ["update:value"],
})
</script>

<style lang="scss">
.select-component {
    border-width: 0 !important;
    min-width: 8rem;
    cursor: pointer;
    user-select: none;

    .border {
        user-select: none;
        position: relative;
        cursor: pointer;
        border-width: 0 !important;
    }

    .select {
        border-radius: inherit;
        border-color: inherit;
        border-style: inherit;
        border-width: 1px;
        width: 100%;
        height: 2.75rem;
        display: grid;
        grid-template-rows: 2.75rem 1fr;
        position: absolute;
        background: inherit;
        top: 0;

        .footer {
            display: none;
            padding: $padding-s 0;
            margin: 0 $margin;
            border-top: $normal-border;

            .button {
                width: 2rem;
                height: 2rem;
            }

            position: relative;
        }
    }

    .selected {
        height: 2.75rem;
        width: calc(100% - 2 * #{$margin});
        display: grid;
        grid-template-columns: 1fr 0.9rem;
        align-items: center;
        margin: 0 $margin;

        .text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .icon-base {
                margin-right: $margin-s;
            }
        }

        .selected-text {
            flex: 1 1;
            font-weight: $font-weight-medium;
        }

        .down {
            cursor: pointer;
            stroke-width: 3;
            color: $secondary-color;
        }
    }

    .options-list {
        padding: 0;
        position: relative;
        transform: translateY(-2.5rem);
        height: 100%;
        overflow-y: auto;
        opacity: 0;

        .option {
            position: absolute;
            top: 0;
            opacity: 0;
            width: 100%;
            cursor: pointer;
            display: flex;
            align-items: baseline;
            column-gap: $margin-s;
            padding: $padding-xs $padding;

            span {
                color: $darker-gray;
                white-space: pre;
            }

            .option-icon {
                width: 0.9rem;
            }

            .option-label {
                flex: 1 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .no-results {
                font-weight: $font-weight-bold;
            }

            .icon-check {
                width: 0.9rem;
                margin-left: auto;
            }

            &.selected-item {
                background: $lighter-gray;

                span {
                    color: $secondary-color !important;
                    font-weight: $font-weight-medium;
                }
            }

            &.disabled {
                cursor: not-allowed;
                pointer-events: none;

                span {
                    color: $gray;
                }
            }

            &.group-title {
                cursor: default;

                span {
                    font-weight: $font-weight-medium;
                    color: $darker-gray;
                }
            }
        }

        .group-title ~ .option:not(.group-title) {
            padding-left: calc(2 * #{$padding});
        }
    }

    &.disabled {
        .select {
            .text,
            .selected-text {
                color: $dark-gray;
            }
        }

        .selected .icon-base {
            cursor: unset;
        }

        .icon-chevron-down {
            color: $dark-gray;
        }
    }

    .open {
        z-index: 1300;
        min-width: 12rem;
        box-shadow: $shadow;

        .selected {
            border-bottom: $normal-border;
        }

        .selected .down {
            transform: rotateZ(180deg);
        }

        .options-list {
            opacity: 1;
            transform: translateY(0);
        }

        .option {
            opacity: 1;
        }

        .footer {
            display: flex;

            .button {
                margin-top: $margin-s;
            }
        }
    }
}
</style>
