import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "toast-content",
  "data-testid": "toast-notification"
}
const _hoisted_2 = { class: "toast-description" }
const _hoisted_3 = { class: "description-title" }
const _hoisted_4 = { class: "description-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingIndicator = _resolveComponent("LoadingIndicator")!
  const _component_IconBase = _resolveComponent("IconBase")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.toastType === 'loading')
        ? (_openBlock(), _createBlock(_component_LoadingIndicator, { key: 0 }))
        : (_openBlock(), _createBlock(_component_IconBase, {
            key: 1,
            icon: _ctx.icon,
            class: _normalizeClass(["toast-icon", _ctx.toastType === 'success' ? 'success-icon' : 'error-icon'])
          }, null, 8, ["icon", "class"])),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.toastTitle), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.toastContent), 1)
      ])
    ])
  ]))
}