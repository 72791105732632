import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "title-text" }
const _hoisted_3 = { class: "page-links" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "link-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_SubMenu = _resolveComponent("SubMenu")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_PanelComponent = _resolveComponent("PanelComponent")!

  return (_openBlock(), _createBlock(_component_PanelComponent, { class: "side-menu" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "icon"),
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
          return (_openBlock(), _createBlock(_component_RouterLink, {
            key: page.name,
            to: { name: page.name },
            custom: ""
          }, {
            default: _withCtx(({ navigate }) => [
              _createElementVNode("div", {
                class: _normalizeClass(["page-link", {
                        current: _ctx.currentPage === page.name,
                        open:
                            Object.keys(page.subMenu).length > 0 &&
                            _ctx.currentPage === page.name,
                    }]),
                onClick: ($event: any) => (!page.callback ? navigate() : page.callback())
              }, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.translations ? _ctx.translate(page.text) : page.text), 1),
                (
                            page.hasOwnProperty('subMenu') &&
                            Object.keys(page.subMenu).length
                        )
                  ? (_openBlock(), _createBlock(_component_IconBase, {
                      key: 0,
                      icon: "chevron-down",
                      class: _normalizeClass(["arrow-icon", {
                            open:
                                Object.keys(page.subMenu).length > 0 &&
                                _ctx.currentPage === page.name,
                        }])
                    }, null, 8, ["class"]))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_4),
              _createVNode(_Transition, { name: "open-submenu" }, {
                default: _withCtx(() => [
                  (
                            page.hasOwnProperty('subMenu') &&
                            _ctx.currentPage === page.name
                        )
                    ? (_openBlock(), _createBlock(_component_SubMenu, {
                        key: 0,
                        "menu-options": page.subMenu,
                        class: _normalizeClass({ open: _ctx.currentPage === page.name }),
                        translations: _ctx.translations
                      }, null, 8, ["menu-options", "class", "translations"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ])
    ]),
    _: 3
  }))
}