<template>
    <TextArea
        :label="translate('notes')"
        v-model:text="cartStore.customer.notes"
    ></TextArea>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import TextArea from "@/ui-elements/input/text-area/TextArea.vue"
import { useI18n } from "vue-i18n"
import { useCartStore } from "@/store/cart/Cart"

export default defineComponent({
    name: "NotesField",
    components: { TextArea },
    setup() {
        const { t: translate } = useI18n()
        const cartStore = useCartStore()

        return {
            translate,
            cartStore,
        }
    },
})
</script>
