import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "modal-title" }
const _hoisted_3 = {
  class: "body",
  id: "modalBody",
  ref: "scrollArea"
}
const _hoisted_4 = { class: "customer-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchField = _resolveComponent("SearchField")!
  const _component_ButtonBase = _resolveComponent("ButtonBase")!
  const _component_CustomerFields = _resolveComponent("CustomerFields")!
  const _component_CustomerOrder = _resolveComponent("CustomerOrder")!
  const _component_FormErrorsProvider = _resolveComponent("FormErrorsProvider")!
  const _component_PageFooter = _resolveComponent("PageFooter")!
  const _component_ModalWindow = _resolveComponent("ModalWindow")!

  return (_openBlock(), _createBlock(_component_ModalWindow, {
    show: "",
    title: _ctx.translate('customer'),
    class: "customer-modal",
    onClose: _ctx.cancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.translate("customer_details")), 1),
        _createVNode(_component_SearchField),
        _createVNode(_component_ButtonBase, {
          onClick: _ctx.cancel,
          name: "delete",
          icon: "delete",
          class: "delete"
        }, null, 8, ["onClick"])
      ]),
      _createVNode(_component_FormErrorsProvider, { errors: _ctx.validationErrors }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_CustomerFields, {
              "form-errors": _ctx.validationErrors,
              onPhoneValidation: _cache[0] || (_cache[0] = ($event: any) => (_ctx.phoneError = $event)),
              isAutoCompletingAddress: _ctx.isAutoCompletingAddress
            }, null, 8, ["form-errors", "isAutoCompletingAddress"]),
            _createVNode(_component_CustomerOrder)
          ], 512)
        ]),
        _: 1
      }, 8, ["errors"]),
      _createVNode(_component_PageFooter, { "scroll-area": _ctx.scrollArea }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ButtonBase, {
              variant: "text",
              name: "cancel",
              onClick: _ctx.cancel,
              class: "cancel"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("cancel")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_ButtonBase, {
              onClick: _ctx.reset,
              class: "reset"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("reset")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_ButtonBase, {
              name: "save",
              disabled: false,
              onClick: _ctx.submit,
              class: "save",
              "data-testid": "save"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("save")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["scroll-area"])
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}