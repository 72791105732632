<template>
    <div :class="data.name + '-display'" class="donut-display">
        <div class="info">
            <div class="text">
                {{ data.text }}
            </div>
            <h3 class="from" v-if="data.from">
                {{ data.from }}
            </h3>
        </div>
        <div class="donut">
            <svg
                class="donut-chart"
                viewBox="0 0 100 100"
                :class="[data.increasing ? 'up' : 'down']"
            >
                <SVGDefs></SVGDefs>
                <g
                    class="donut-circle"
                    :transform="'translate(' + radius + ',' + radius + ')'"
                >
                    <path :d="circle"></path>
                </g>
                <g
                    class="donut-arc"
                    :transform="'translate(' + radius + ',' + radius + ')'"
                >
                    <path :d="arc"></path>
                </g>
            </svg>
            <div class="numbers" :class="[data.increasing ? 'up' : 'down']">
                <div class="number" v-if="!isLoading">
                    {{ formatNumber(data.number) }}
                </div>
                <div class="number" v-else>
                    <LoadingIndicator />
                </div>
                <div class="change" v-if="data.change">
                    {{ formatNumber(data.change) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import * as d3 from "d3"
import { ref, computed, defineComponent, PropType } from "vue"
import SVGDefs from "@/ui-elements/SVGDefs.vue"
import { tweenOnMount } from "@/utils/useTween"
import { DonutChartData } from "@/interface/components/StatisticsTile"
import LoadingIndicator from "@/ui-elements/loaders/LoadingIndicator.vue"
import { formatNumber } from "@/utils/useNumberFormatting"
export default defineComponent({
    name: "DonutDisplay",
    components: {
        SVGDefs,
        LoadingIndicator,
    },
    props: {
        data: {
            type: Object as PropType<DonutChartData>,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const tween = ref({ t: 0.0 })
        tweenOnMount(tween, 1500, 500, "CubicOut")

        const radius = 50
        const arcGenerator = d3
            .arc()
            .startAngle(0)
            .innerRadius(radius - 5)
            .outerRadius(radius)
            .cornerRadius(2)

        const circle = computed(() =>
            arcGenerator({
                endAngle: 2 * Math.PI,
            })
        )
        const arc = computed(() =>
            arcGenerator({
                endAngle: 2 * Math.PI * props.data.percent * tween.value.t,
            })
        )

        return {
            radius,
            circle,
            arc,
            tween,
            formatNumber,
        }
    },
})
</script>

<style lang="scss">
.donut-display {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 100%;

    .text {
        color: $darker-gray;
        font-size: $font-size-h4;
    }

    .from {
        font-size: $font-size-h3;
        color: $white;
        padding-top: $padding;
    }

    .donut {
        position: relative;
        margin-left: $margin-l;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 10rem;
            height: 10rem;
        }

        g {
            fill: none;
        }

        .donut-circle {
            fill: #2d3043;
        }

        .donut-chart.down .donut-arc {
            fill: url("#donut-down");
        }

        .donut-chart.up .donut-arc {
            fill: url("#donut-up");
        }
    }

    .numbers {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        position: absolute;
    }

    .number {
        font-weight: $font-weight-bold;
        font-size: $font-size-large;
        color: $white;
        margin-bottom: $margin-s;
    }

    .change {
        padding: 0 $padding-s;
        text-align: center;
        border-radius: $radius;
    }

    .up .change {
        border: $primary-border;
        background: $transparent-primary;
        color: $primary-color;
    }

    .down .change {
        border: $success-border;
        background: $transparent-green;
        color: $medium-green;
    }
}
</style>
