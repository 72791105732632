<template>
    <div>
        <div class="tabs-menu">
            <div
                class="menu-item"
                v-for="tab in menuOptions"
                :key="tab.id"
                :class="[
                    tab.id,
                    { selected: tab.id === orderModalStore.openedTab },
                ]"
                @click="selectTab(tab.id)"
            >
                {{ tab.label }}
                <div
                    v-show="tab.id === 'remarks' && order.remarks"
                    class="badge pulse"
                >
                    <IconBase icon="notification" />
                </div>
            </div>
        </div>

        <div class="tab" :class="selectedTab?.tabClass">
            <component
                :is="selectedTab?.component"
                :order="order"
                @update:order="$emit('update:order', $event)"
                :scroll-area-ref="scrollAreaRef"
            ></component>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, Ref, ref } from "vue"
import { useI18n } from "vue-i18n"
import InfoTab from "@/pages/orders/modals/order/tabs/CustomerTab.vue"
import OrderTab from "@/pages/orders/modals/order/tabs/OrderTab.vue"
import RemarksTab from "@/pages/orders/modals/order/tabs/RemarksTab.vue"
import RoutePlannerTab from "@/pages/orders/modals/order/tabs/RoutePlannerTab.vue"
import DelivererTab from "@/pages/orders/modals/order/tabs/DelivererTab.vue"
import { Order } from "@/interface/orders/Order"
import IconBase from "@/ui-elements/IconBase.vue"
import { useOrderModalStore } from "@/store/OrderModal"

type Tab = {
    id: string
    label: string
    component: string
    tabClass?: string
}

export default defineComponent({
    name: "ModalTabs",
    components: {
        IconBase,
        InfoTab,
        OrderTab,
        RemarksTab,
        RoutePlannerTab,
        DelivererTab,
    },
    props: {
        order: {
            type: Object as PropType<Order>,
            required: true,
        },
        scrollAreaRef: Function,
    },
    setup(props) {
        const { t: translate } = useI18n()
        const orderModalStore = useOrderModalStore()

        const initialMenuOptions = orderModalStore.menuOptions

        const menuOptions = computed(() =>
            props.order.delivery === "deliver"
                ? initialMenuOptions
                : initialMenuOptions.slice(0, 3)
        )

        const selectedTab = computed(() => {
            return menuOptions.value.find(
                (option) => option.id === orderModalStore.openedTab
            )
        })

        const selectTab = (tabId: string) => {
            orderModalStore.openedTab = tabId
        }

        return {
            translate,
            menuOptions,
            orderModalStore,
            selectedTab,
            selectTab,
        }
    },
})
</script>

<style lang="scss">
@keyframes ringBell {
    0% {
        transform: rotate(-20deg);
    }

    5% {
        transform: rotate(18deg);
    }

    10% {
        transform: rotate(-16deg);
    }

    15% {
        transform: rotate(14deg);
    }

    20% {
        transform: rotate(-12deg);
    }

    25% {
        transform: rotate(10deg);
    }

    30% {
        transform: rotate(-8deg);
    }

    35% {
        transform: rotate(6deg);
    }

    40% {
        transform: rotate(-4deg);
    }

    45% {
        transform: rotate(2deg);
    }

    50% {
        transform: rotate(-1deg);
    }

    55% {
        transform: rotate(1deg);
    }

    60% {
        transform: rotate(0);
    }
}

.tabs-menu {
    display: flex;
    width: 100%;
    background: $primary-gradient-reversed;
    height: 3rem;
    justify-content: space-between;

    .menu-item {
        width: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.7);

        &:hover {
            cursor: pointer;
            color: $white;
        }

        &.selected {
            background: rgba(147, 61, 25, 0.7);
            font-weight: $font-weight-medium;
            color: $white;
        }
    }

    .badge {
        background: $primary-color;
        height: 1.25rem;
        width: 1.25rem;
        border-radius: 1.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(30deg) translate(-0.5rem, -0.25rem);

        .icon-base {
            color: $white;
            font-size: 0.7rem;
            animation: ringBell 2s infinite;
        }
    }
}

.tab {
    width: 100%;
    padding: $padding 0 $padding $padding-m;
    height: calc(70vh - 6rem);

    &.full-width {
        width: calc(100% + 1.5rem);
        margin-left: -0.75rem;
        padding: 0;
    }
}

.tabs-menu {
    width: calc(100% + 1.5rem);
    margin-left: -$margin-m;
}

.tab-content {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}

@media only screen and (max-width: $screen-mobile) {
    .tab {
        width: 100%;
        padding: $padding 0 0 $padding-m;
        height: calc(50vh - 6rem);

        &.full-width {
            width: calc(100% + 1.5rem);
            margin-left: -0.75rem;
            padding: 0;
        }

        .tab-content {
            overflow-y: scroll;
            overflow-x: hidden;
            height: 100%;
            display: flex;
            flex-wrap: wrap;

            .left {
                width: 100%;
            }

            .right {
                width: 100%;
                height: 30rem;
                margin-top: 1rem;
                display: block;
            }
        }
    }

    .tabs-menu {
        width: auto;
        margin: auto;
        border-radius: 4px;
    }

    .tabs-menu {
        .menu-item {
            &:hover {
                border-radius: 4px;
            }

            &.selected {
                border-radius: 4px;
            }
        }

        .menu-item:first-child {
            padding-left: 1rem;
        }

        .menu-item:nth-child(4) {
            padding-left: 1rem;
        }
    }

    :deep(.footer) {
        .footer-wrap {
            .page-buttons,
            .horizontal-line {
                display: none;
            }
        }
    }
}
</style>
