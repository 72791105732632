import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "slider-text" }
const _hoisted_2 = { class: "slider-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationComponent = _resolveComponent("NotificationComponent")!
  const _component_CheckSwitch = _resolveComponent("CheckSwitch")!
  const _component_SliderTooltip = _resolveComponent("SliderTooltip")!
  const _component_VueSlider = _resolveComponent("VueSlider")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["emergency-brake-tab brake-tab", _ctx.d])
  }, [
    _createVNode(_component_NotificationComponent, { "notification-type": "error" }, {
      notificationLabel: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.translate("components_brake_delay_message")), 1)
      ]),
      notificationMessage: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.translate("components_brake_message")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_CheckSwitch, {
      label: _ctx.translate('components_brake_collection'),
      checked: _ctx.settings.pickup_closed_tmp,
      "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateBrakeSettings($event, 'pickup_closed_tmp'))),
      "checked-label": _ctx.translate('yes'),
      "unchecked-label": _ctx.translate('no')
    }, null, 8, ["label", "checked", "checked-label", "unchecked-label"]),
    _createElementVNode("div", {
      class: _normalizeClass(["slider pickup-slider", {
                disabled: _ctx.settings.pickup_closed_tmp,
            }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.translate("delay_pickup_orders")) + " ", 1),
        (_ctx.settings.pickup_closed_tmp)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(" [" + _toDisplayString(_ctx.translate("disabled")) + "] ", 1)
            ], 64))
          : _createCommentVNode("", true),
        _createTextVNode(" : ")
      ]),
      _createVNode(_component_VueSlider, _mergeProps({
        modelValue: _ctx.settings.pickup_min_time_tmp,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (
                    _ctx.updateBrakeSettings($event, 'pickup_min_time_tmp')
                ))
      }, _ctx.sliderConfig, {
        disabled: _ctx.settings.pickup_closed_tmp
      }), {
        tooltip: _withCtx(({ value }) => [
          _createVNode(_component_SliderTooltip, {
            value: value,
            onMinus: _cache[1] || (_cache[1] = ($event: any) => (
                            _ctx.updateBrakeSettings(
                                Number(_ctx.settings.pickup_min_time_tmp) -
                                    _ctx.minutesIncrement,
                                'pickup_min_time_tmp'
                            )
                        )),
            onPlus: _cache[2] || (_cache[2] = ($event: any) => (
                            _ctx.updateBrakeSettings(
                                Number(_ctx.settings.pickup_min_time_tmp) +
                                    _ctx.minutesIncrement,
                                'pickup_min_time_tmp'
                            )
                        )),
            disabled: _ctx.settings.pickup_closed_tmp
          }, null, 8, ["value", "disabled"])
        ]),
        _: 1
      }, 16, ["modelValue", "disabled"])
    ], 2),
    _createVNode(_component_CheckSwitch, {
      label: _ctx.translate('components_brake_delivery'),
      checked: _ctx.settings.delivery_closed_tmp,
      "onUpdate:checked": _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateBrakeSettings($event, 'delivery_closed_tmp'))),
      "checked-label": _ctx.translate('yes'),
      "unchecked-label": _ctx.translate('no')
    }, null, 8, ["label", "checked", "checked-label", "unchecked-label"]),
    _createElementVNode("div", {
      class: _normalizeClass(["slider delivery-slider", {
                disabled: _ctx.settings.delivery_closed_tmp,
            }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.translate("delay_delivery_orders")) + " ", 1),
        (_ctx.settings.delivery_closed_tmp)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(" [" + _toDisplayString(_ctx.translate("disabled")) + "] ", 1)
            ], 64))
          : _createCommentVNode("", true),
        _createTextVNode(" : ")
      ]),
      _createVNode(_component_VueSlider, _mergeProps({
        modelValue: _ctx.settings.delivery_min_time_tmp,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (
                    _ctx.updateBrakeSettings($event, 'delivery_min_time_tmp')
                ))
      }, _ctx.sliderConfig, {
        disabled: _ctx.settings.delivery_closed_tmp
      }), {
        tooltip: _withCtx(({ value }) => [
          _createVNode(_component_SliderTooltip, {
            value: value,
            onMinus: _cache[5] || (_cache[5] = ($event: any) => (
                            _ctx.updateBrakeSettings(
                                Number(_ctx.settings.delivery_min_time_tmp) -
                                    _ctx.minutesIncrement,
                                'delivery_min_time_tmp'
                            )
                        )),
            onPlus: _cache[6] || (_cache[6] = ($event: any) => (
                            _ctx.updateBrakeSettings(
                                Number(_ctx.settings.delivery_min_time_tmp) +
                                    _ctx.minutesIncrement,
                                'delivery_min_time_tmp'
                            )
                        )),
            disabled: _ctx.settings.delivery_closed_tmp
          }, null, 8, ["value", "disabled"])
        ]),
        _: 1
      }, 16, ["modelValue", "disabled"])
    ], 2)
  ], 2))
}