<template>
    <PopUpModal
        :show="show"
        :title="translate('adjust_delivery_time')"
        @close="save"
        @submit="save"
        class="time-modal"
    >
        <div class="content">
            <TimeAdjust v-model:time="time"></TimeAdjust>
            <div class="switches">
                <CheckSwitch
                    :label="translate('sms_notification')"
                    :unchecked-label="translate('no')"
                    :checked-label="translate('yes')"
                    :checked="notifications.includes('sms')"
                    @update:checked="changeNotifications('sms')"
                    :disabled="smsDisabled"
                />
                <CheckSwitch
                    :label="translate('e_mail_notification')"
                    :unchecked-label="translate('no')"
                    :checked-label="translate('yes')"
                    :checked="notifications.includes('email')"
                    @update:checked="changeNotifications('email')"
                    :disabled="emailDisabled"
                />
                <CheckSwitch
                    disabled
                    :label="translate('push_notification')"
                    :unchecked-label="translate('no')"
                    :checked-label="translate('yes')"
                    :checked="notifications.includes('push')"
                    @update:checked="changeNotifications('push')"
                />
            </div>
        </div>
    </PopUpModal>
</template>

<script lang="ts">
import { defineComponent, Ref, ref } from "vue"
import { useI18n } from "vue-i18n"
import PopUpModal from "@/ui-elements/layout/modals/PopUpModal.vue"
import CheckSwitch from "@/ui-elements/input/check-switch/CheckSwitch.vue"
import TimeAdjust from "@/pages/orders/modals/time/TimeAdjust.vue"
import { NotificationType } from "@/pages/orders/modals/time/TimeData"

export default defineComponent({
    name: "TimeModal",
    components: { CheckSwitch, PopUpModal, TimeAdjust },
    props: {
        show: Boolean,
        orderTime: String,
        smsDisabled: Boolean,
        emailDisabled: Boolean,
    },
    setup(props, context) {
        const { t: translate } = useI18n()

        const notifications: Ref<NotificationType[]> = ref([])
        const time = ref(props.orderTime)

        const changeNotifications = (type: NotificationType) => {
            const index = notifications.value.indexOf(type)
            if (index >= 0) {
                notifications.value.splice(index, 1)
            } else {
                notifications.value.push(type)
            }
        }

        const save = () => {
            context.emit("update:time", {
                time_set: time.value,
                notifications: notifications.value,
            })
            context.emit("close")
        }

        return {
            translate,
            notifications,
            time,
            changeNotifications,
            save,
        }
    },
    emits: ["close", "update:time"],
})
</script>

<style lang="scss">
.time-modal {
    .content {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
    }
    .switches {
        margin-top: $margin;
        width: 100%;
        display: flex;
        justify-content: space-around;
        .border {
            justify-content: center;
        }
    }
}
</style>
