import { Order } from "@/interface/orders/Order"
import { getParameter } from "@/utils/useUrlParameters"
import { defineStore } from "pinia"
import axios from "axios"
import { websocket } from "@/services/WebsocketService"
import { useSettingsStore } from "@/store/Settings"
import { useMasterSlave } from "@/utils/useMasterSlave"
import { useUserStore } from "@/store/User"

export type POI = {
    default: boolean
    ipAddress: string | null
}

export type PinPaymentStore = {
    transactionId: string | null
    currentPoiid: string | null
    serviceId: string | null
    saleId: string | null
    POIs: { [key: string]: POI }
    isCancelling: boolean
    isLoading: boolean
    success: boolean
    errorMessage: string
    pinState: string
}

export const usePinPaymentStore = defineStore({
    id: "pinPayment",
    state: () =>
        ({
            transactionId: null,
            currentPoiid: null,
            serviceId: null,
            saleId: null,
            POIs: {},
            isCancelling: false,
            isLoading: false,
            success: false,
            errorMessage: "",
            pinState: "",
        } as PinPaymentStore),
    getters: {
        paymentInput: (
            state
        ): {
            transaction_id: string
            service_id: string | null
            poiid: string | null
            sale_id: string
        } => ({
            transaction_id: state.transactionId ?? "",
            service_id: state.serviceId,
            poiid: state.currentPoiid,
            sale_id: state.saleId ?? "",
        }),
    },
    actions: {
        initiatePayment(TransactionID: string): void {
            this.clearPayment()

            this.transactionId = TransactionID
            this.serviceId = Math.random().toString(36).substring(2, 12)
            this.saleId = this.getObId()
            this.currentPoiid =
                localStorage.getItem("adyen_pin_poiid_local") ||
                useSettingsStore().settings.adyen_pin_poiid

            this.isLoading = true
            this.success = false
            this.pinState = ""
            this.errorMessage = ""
        },
        async executePayment(
            TransactionID: string,
            amount: number,
            callback?: Function
        ): Promise<any> {
            this.initiatePayment(TransactionID)

            // listen on ws channel
            if (callback) {
                websocket.echo
                    ?.channel(this.getWsChannel())
                    .listen(".display", callback)
            }

            const paymentData: {
                transaction_id: string
                service_id: string | null
                poiid: string | null
                sale_id: string
                amount: number
            } = {
                ...this.paymentInput,
                amount,
            }
            console.log(
                "SentryDevInfo: PinPayment.ts POST client/pos/adyen/init data",
                paymentData
            )

            return await axios.post("client/pos/adyen/init", paymentData, {
                timeout: 1000 * (60 * 2), // need 2 minutes for adyen to handle the request
            })
        },
        async verify(
            orderId?: number,
            ignoreStatus: boolean = false
        ): Promise<any> {
            if (!this.serviceId) {
                return null
            }

            // todo: RC verify

            console.log(
                "SentryDevInfo: PinPayment.ts POST client/pos/adyen/verify data",
                {
                    service_id: this.serviceId,
                    poiid: this.currentPoiid,
                    sale_id: this.getObId(),
                    order_id: orderId || null,
                    ignore_status: ignoreStatus,
                }
            )

            return (
                await axios.post("client/pos/adyen/verify", {
                    service_id: this.serviceId,
                    poiid: this.currentPoiid,
                    sale_id: this.getObId(),
                    order_id: orderId || null,
                    ignore_status: ignoreStatus,
                })
            ).data
        },

        async abort(): Promise<boolean> {
            if (!this.serviceId) {
                return true
            }

            try {
                this.isCancelling = true

                console.log(
                    "SentryDevInfo: PinPayment.ts POST client/pos/adyen/cancel data",
                    {
                        service_id: this.serviceId,
                        poiid: this.currentPoiid,
                        sale_id: this.getObId(),
                    }
                )

                await axios.post("client/pos/adyen/cancel", {
                    service_id: this.serviceId,
                    poiid: this.currentPoiid,
                    sale_id: this.getObId(),
                })
            } finally {
                this.isCancelling = false
            }

            return true
        },

        clearPayment(): void {
            websocket.echo?.leaveChannel(this.getWsChannel())
            this.serviceId = null
            this.currentPoiid = null
        },

        getWsChannel(): string {
            return `adyen.${this.currentPoiid}.${this.serviceId}`
        },

        getObId(): string {
            return `${useUserStore().user.id}-${useMasterSlave().obId}`
        },
        resetPaymentStatuses(): void {
            this.success = false
            this.errorMessage = ""
        },
        async unpackAdyenResponse(
            response: any,
            order: Order
        ): Promise<{ is_successful: boolean; reference: string | undefined }> {
            let initialError: string = ""

            if (
                response.SaleToPOIResponse &&
                response.SaleToPOIResponse?.PaymentResponse
            ) {
                initialError = getParameter(
                    "refusalReason",
                    false,
                    response.SaleToPOIResponse.PaymentResponse.Response
                        .AdditionalResponse
                )

                initialError =
                    initialError ||
                    getParameter(
                        "message",
                        false,
                        response.SaleToPOIResponse.PaymentResponse.Response
                            .AdditionalResponse
                    )
            }

            if (!initialError && response.SaleToPOIRequest?.EventNotification) {
                initialError = getParameter(
                    "message",
                    false,
                    response.SaleToPOIRequest.EventNotification
                )

                if (!initialError) {
                    initialError = getParameter(
                        "message",
                        false,
                        response.SaleToPOIRequest.EventNotification.EventDetails
                    )
                } else {
                    initialError = "Unknown error"
                }
            }

            if (initialError) {
                let prependString: string = String(
                    response.SaleToPOIResponse?.PaymentResponse?.Response
                        ?.Result || ""
                )
                prependString += prependString !== "" ? ": " : ""

                this.errorMessage = initialError
                throw new Error(prependString + initialError)
            }

            if (
                response?.SaleToPOIResponse?.PaymentResponse?.Response
                    ?.Result === "Success"
            ) {
                this.success = true
                return {
                    reference:
                        response?.SaleToPOIResponse?.PaymentResponse
                            ?.PaymentResult?.PaymentAcquirerData
                            ?.AcquirerTransactionID?.TransactionID,
                    is_successful: true,
                }
            } else {
                const isPaid: {
                    is_successful: boolean
                    reference: string | undefined
                } = await this.verify(order.id, true).then(
                    (
                        response: any
                    ): {
                        is_successful: boolean
                        reference: string | undefined
                    } => {
                        console.log(
                            "SentryDevInfo: useSplitPaymentMethods/pinPaymentStore.verify response",
                            response
                        )
                        if (response?.is_paid) {
                            this.success = true
                            return {
                                reference:
                                    response?.adyen.SaleToPOIResponse
                                        ?.TransactionStatusResponse
                                        ?.RepeatedMessageResponse
                                        ?.RepeatedResponseMessageBody
                                        ?.PaymentResponse?.PaymentResult
                                        ?.PaymentAcquirerData
                                        ?.AcquirerTransactionID?.TransactionID,
                                is_successful: response?.is_paid,
                            }
                        }

                        let error: string = getParameter(
                            "refusalReason",
                            false,
                            response.adyen.SaleToPOIResponse
                                .TransactionStatusResponse
                                .RepeatedMessageResponse
                                .RepeatedResponseMessageBody.PaymentResponse
                                .Response.AdditionalResponse
                        )

                        error =
                            error ||
                            getParameter(
                                "message",
                                false,
                                response.adyen.SaleToPOIResponse
                                    .TransactionStatusResponse
                                    .RepeatedMessageResponse
                                    .RepeatedResponseMessageBody.PaymentResponse
                                    .Response.AdditionalResponse
                            )

                        if (
                            !error &&
                            response.adyen.SaleToPOIResponse
                                .TransactionStatusResponse
                                .RepeatedMessageResponse
                                .RepeatedResponseMessageBody.EventNotification
                        ) {
                            error = getParameter(
                                "message",
                                false,
                                response.adyen.SaleToPOIResponse
                                    .TransactionStatusResponse
                                    .RepeatedMessageResponse
                                    .RepeatedResponseMessageBody
                                    .EventNotification
                            )
                        }

                        let prependString: string = String(
                            response.adyen.SaleToPOIResponse.PaymentResponse
                                ?.Response?.Result || ""
                        )
                        prependString += prependString !== "" ? ": " : ""

                        this.errorMessage = String(error)
                        throw new Error(prependString + String(error))
                    }
                )

                if (isPaid) {
                    return isPaid
                }

                throw new Error(response?.error || "")
            }
        },
    },
})
