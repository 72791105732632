<template>
    <div class="footer-items">
        <ButtonBase
            @click="goToProducts"
            variant="outlined"
            icon="food"
            type="primary"
            >{{ translate("products") }}
        </ButtonBase>
        <ButtonMap type="primary" />
        <ButtonDeliverers :showName="true" type="primary" />
        <ButtonBrake type="primary" />
    </div>
    <div class="menu-list">
        <div v-for="menuItem in menuItems" :key="menuItem.text">
            <router-link
                v-if="!menuItem.hasChildren"
                :to="{ name: menuItem.name }"
                @click="onClick(menuItem.name)"
                class="menu-item"
                :class="[
                    menuItem.name,
                    { current: menuItem.name === currentRoute },
                ]"
            >
                <span
                    v-if="menuItem.name === 'archive' && futureOrderCount > 0"
                    class="statistic"
                    >{{ futureOrderCountLabel }}</span
                >

                <span class="text">{{ translate(menuItem.text) }}</span>
            </router-link>
            <Accordion v-else>
                <template #title>
                    {{ translate(menuItem.text) }}
                </template>
                <template #content>
                    <div v-for="child in menuItem.children" :key="child.name">
                        <router-link
                            :to="{ name: child.name }"
                            @click="onClick(child.name)"
                            class="children"
                        >
                            <span class="child-text">
                                {{ translate(child.text) }}
                            </span>
                        </router-link>
                    </div>
                </template>
            </Accordion>
        </div>
    </div>

    <div class="menu-list-right">
        <div
            class="messages cursor-pointer"
            @click="obMessages.showModal = true"
        >
            <span class="messages-label">{{ translate("message_box") }}</span>
            <IconBase
                icon="circle-filled"
                class="unread-messages animate-pulse-object"
                v-if="obMessages.unreadMessageIds.length"
            />
        </div>
    </div>

    <MessageModal
        v-if="obMessages.showModal"
        @close="obMessages.showModal = false"
    />

    <div class="logout-div" @click="goToLogout()">
        <IconBase class="logout-icon" :icon="'unlock'"></IconBase>
        <span class="logout-text">{{ translate("logout") }}</span>
    </div>

    <div class="language-selector">
        <LanguagePicker icon="web" :transparent="false" hide-selected />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue"
import IconBase from "@/ui-elements/IconBase.vue"
import { mainMenuStructure } from "@/pageStructure/mainMenuStructure"
import { useRoute, useRouter } from "vue-router"
import { useI18n } from "vue-i18n"
import { useClickAway } from "@/utils/useClickAway"
import { useAPIStore } from "@/store/API"
import MessageModal from "@/ui-elements/message-box/MessageModal.vue"
import { usePosMenusStore } from "@/store/PosMenus"
import { useCartStore } from "@/store/cart/Cart"
import { usePOSStore } from "@/store/POS"
import { dataHydration } from "@/services/DataHydrationService"
import { useOBMessagesStore } from "@/store/OBMessage"
import LanguagePicker from "@/ui-elements/LanguagePicker.vue"
import { useUserStore } from "@/store/User"
import { useOrdersStore } from "@/store/Orders"
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue"
import ButtonMap from "@/pages/orders/footer/ButtonMap.vue"
import ButtonDeliverers from "@/pages/orders/footer/ButtonDeliverers.vue"
import ButtonBrake from "@/pages/orders/footer/ButtonBrake.vue"
import Accordion from "@/ui-elements/Accordion.vue"
import { offlineModeStore } from "@/store/offlineMode"

export default defineComponent({
    name: "MobileMainMenu",
    components: {
        IconBase,
        MessageModal,
        LanguagePicker,
        ButtonBase,
        ButtonMap,
        ButtonDeliverers,
        ButtonBrake,
        Accordion,
    },
    setup() {
        const { t: translate } = useI18n()

        const router = useRouter()
        const route = useRoute()
        const apiStore = useAPIStore()
        const userStore = useUserStore()
        const obMessages = useOBMessagesStore()
        const orderStore = useOrdersStore()

        const futureOrderCount = computed(() => orderStore.futureOrderCount)
        // limit displayed label to 99. If over 99, show 99+
        const futureOrderCountLabel = computed(() =>
            orderStore.futureOrderCount <= 99
                ? orderStore.futureOrderCount
                : "99+"
        )

        const menuItems = computed(() =>
            mainMenuStructure.filter((item) => {
                const archiveMode = !!Number(userStore.user.is_read_only)
                const addPos = !(item.name === "pos" && archiveMode)
                const addOrders = !(item.name === "orders" && archiveMode)

                return addPos && addOrders
            })
        )

        const dropdownOpen = ref(false)
        const dropdown_ref = ref()

        const currentRoute = computed(
            () => router.currentRoute.value.meta.name as string
        )

        const showFlyerImage = computed(
            () => router.currentRoute.value.name === "flyer"
        )

        useClickAway(dropdown_ref, () => (dropdownOpen.value = false))

        const posMenusStore = usePosMenusStore()
        const cartStore = useCartStore()
        const posStore = usePOSStore()

        const onClick = (routeName: string) => {
            // reset the pos page when click on it the second time
            if (routeName === "pos" && route.name === "pos") {
                posStore.posBack()
                posMenusStore.resetSelection()
                posMenusStore.resetSelectedProduct()
                cartStore.clearCurrentCart()
            }

            // forces orders to load when clicking on order again
            if (routeName === "orders" && route.name === "orders") {
                dataHydration.hydrateModule("orders", true)
            }
        }

        const goToProducts = () => router.push({ name: "menu-menu-products" })

        const goToLogout = () => router.push({ name: "logout" })

        return {
            isOffline: offlineModeStore().isOffline,
            currentRoute,
            translate,
            dropdownOpen,
            apiStore,
            showFlyerImage,
            obMessages,
            futureOrderCount,
            futureOrderCountLabel,
            open,
            onClick,
            menuItems,
            goToProducts,
            goToLogout,
        }
    },
})
</script>

<style lang="scss" scoped></style>
