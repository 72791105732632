<template>
    <CheckSwitch
        class="customer-mailing"
        :label="translate('mailing')"
        v-model:checked="cartStore.customer.mailing"
        name="mailing"
        :checked-label="translate('yes')"
        :unchecked-label="translate('no')"
    ></CheckSwitch>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { useI18n } from "vue-i18n"
import { useCartStore } from "@/store/cart/Cart"
import CheckSwitch from "@/ui-elements/input/check-switch/CheckSwitch.vue"
export default defineComponent({
    name: "MailingField",
    components: { CheckSwitch },
    setup() {
        const { t: translate } = useI18n()
        const cartStore = useCartStore()
        return {
            translate,
            cartStore,
        }
    },
})
</script>
<style lang="scss">
.customer-mailing {
    margin-bottom: $margin;
}
</style>
