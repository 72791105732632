<template>
    <div class="dots-loader" :class="variant">
        <div class="dot" />
        <div class="dot" />
        <div class="dot" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
    name: "DotsLoader",
    props: {
        variant: String,
    },
})
</script>

<style lang="scss" scoped>
.dots-loader {
    display: inline-flex;
    column-gap: $margin-xs;

    .dot {
        height: 0.3rem;
        border-radius: 50%;
        width: 0.3rem;
        background: $white;
        animation: dot 1s infinite;

        &:nth-child(2) {
            animation-delay: 0.1s;
        }

        &:nth-child(3) {
            animation-delay: 0.2s;
        }
    }

    &.primary .dot {
        background: $primary-color;
    }
}

@keyframes dot {
    0% {
        transform: translateY(0.05rem);
    }
    50% {
        transform: translateY(-0.15rem);
    }
    100% {
        transform: translateY(0.05rem);
    }
}
</style>
