import { multiLocationStore } from "@/store/MultiLocation"
import { defineStore } from "pinia"
import { useCartStore } from "@/store/cart/Cart"

type PosComponentName =
    | "RestaurantTables"
    | "POSMenu"
    | "SplitPayment"
    | "Extras"
    | "POSTicket"
export const usePOSStore = defineStore({
    id: "pos",
    state: () =>
        ({
            numPadOpen: false,
            posComponent: "POSMenu",
            tipRoundUp: false,
            openedModal: null,
            toolsOverlay: false,
            cartOverlay: false,
            openedTicketLine: {
                type: null,
                index: null,
            },
            packaging_costs: null,
        } as {
            numPadOpen: boolean
            posComponent: PosComponentName
            tipRoundUp: boolean
            openedModal: string | null
            toolsOverlay: boolean
            cartOverlay: boolean
            openedTicketLine: {
                type: null | string
                index: null | number
            }
            packaging_costs: number | null
        }),
    getters: {
        hasCustomPackagingCosts: (state) => state.packaging_costs !== null,
        /**
         * In MultiLocation we allow the following functionalities if the menu of the logged location is viewed:
         * - tables
         * - menu tiles actions
         * - pos settings
         * - applying db defined promos (discount codes)
         */
        allowActions: (): boolean =>
            !multiLocationStore().isActive ||
            (multiLocationStore().mainLocationSelected
                ? multiLocationStore().main_location_id
                : multiLocationStore().selected_location_id) ===
                multiLocationStore().selected_location_id_for_menu,
    },
    actions: {
        switchNumPad(value: boolean) {
            this.numPadOpen = value
        },
        openMenuComponent(component: PosComponentName) {
            this.closeModal()
            this.posComponent = component
        },
        posBack() {
            this.posComponent = "POSMenu"
        },
        updateTipAmount(value: number) {
            this.tipRoundUp = false
            useCartStore().currentCart.cart.tip = value
            useCartStore().updateCart()
        },
        roundUpAmount(value: boolean) {
            this.tipRoundUp = value
            useCartStore().updateCart()
        },
        toggleModal(modal: string) {
            if (this.openedModal === null || this.openedModal !== modal) {
                this.openedModal = modal
                this.posBack()
            } else {
                this.closeModal()
            }
        },
        closeModal() {
            this.openedModal = null
        },
        setToolsOverlay(value: boolean) {
            this.toolsOverlay = value
        },
        setCartOverlay(value: boolean) {
            this.cartOverlay = value
        },
    },
})
