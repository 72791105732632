export const useDebounce = (fn: Function, delay: number) => {
    let timeout = 0

    return (...args: any) => {
        if (timeout) {
            window.clearTimeout(timeout)
        }

        timeout = window.setTimeout(() => {
            fn(...args)
        }, delay)
    }
}
