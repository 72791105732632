import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_SearchResults = _resolveComponent("SearchResults")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["searchable-field", _ctx.customerProperty]),
    ref: "parent"
  }, [
    _createVNode(_component_TextField, {
      label: _ctx.label,
      text: _ctx.cartStore.customer.address[_ctx.customerProperty],
      "onUpdate:text": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cartStore.customer.address[_ctx.customerProperty]) = $event)),
      autocomplete: "no",
      ref: "inputField",
      error: _ctx.error,
      message: _ctx.message,
      name: _ctx.name,
      loading: _ctx.isLoading
    }, null, 8, ["label", "text", "error", "message", "name", "loading"]),
    (_ctx.showResults)
      ? (_openBlock(), _createBlock(_component_SearchResults, {
          key: 0,
          class: _normalizeClass(_ctx.upOrDown),
          property: _ctx.customerProperty,
          loading: _ctx.loading,
          onSelect: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focussed = false))
        }, null, 8, ["class", "property", "loading"]))
      : _createCommentVNode("", true)
  ], 2))
}