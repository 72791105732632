<template>
    <div class="tab-options">
        <div
            class="option"
            v-for="o in options"
            :key="o.value"
            :class="{ selected: o.value === value, disabled: disabled }"
            @click="$emit('update:value', updateValue(o))"
        >
            <IconBase v-if="o.icon" :icon="o.icon" /> {{ o.label }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import IconBase from "@/ui-elements/IconBase.vue"

type Option = {
    value: string
    label: string
    icon: string
}
export default defineComponent({
    name: "TabOptions",
    components: {
        IconBase,
    },
    props: {
        options: {
            type: Array,
            required: true,
        },
        value: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        unselectable: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const updateValue = (clickedOption: Option) => {
            if (props.unselectable && props.value === clickedOption.value) {
                return ""
            }
            return clickedOption.value
        }
        return {
            updateValue,
        }
    },
    emits: ["update:value"],
})
</script>

<style lang="scss" scoped>
.tab-options {
    background: $light-gray;
    border-radius: $radius;
    padding: $padding-s;
    user-select: none;
    display: flex;

    .option {
        border-radius: $radius;
        padding: $padding-s $padding-l;
        font-size: $font-size-h5;
        font-weight: $font-weight-medium;
        color: $darker-gray;
        transition: all 0.3s;
        cursor: pointer;
        display: flex;
        justify-content: center;
        flex: 1 1;

        &.disabled {
            cursor: default;
            pointer-events: none;
        }
    }

    .selected {
        background: $white;
        color: $secondary-color;
        cursor: default;
    }
}

@media only screen and (max-width: $screen-mobile-small) {
    .tab-options {
        .option {
            padding: 0.5rem 0.5rem;
        }
    }
}
</style>
