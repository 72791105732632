import { defineStore } from "pinia"

export const useFontSize = defineStore({
    id: "font-size",
    state: () => ({}),
    actions: {
        setSize: (size: number) => {
            localStorage.setItem("font-size", size + "")
        },
        getSize: () => {
            const size = localStorage.getItem("font-size")
            return size ? parseFloat(size) : 16
        },
    },
})

export const setFontSizeOnHTML = (size: number) => {
    // We don't allow font sizes smaller than 10px to avoid that the application becomes unusable
    if (size < 10) {
        size = 10
    }
    document.getElementsByTagName("html")[0].style.fontSize = size + "px"
}
