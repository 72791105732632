export const getPagination = (
    count: number,
    oldPagination: { current_page: number; per_page: number }
) => {
    const lastPage = Math.ceil((count || 1) / oldPagination.per_page)
    return {
        current_page: Math.min(oldPagination.current_page, lastPage),
        last_page: lastPage,
        total: count || 0,
        per_page: oldPagination.per_page,
    }
}
