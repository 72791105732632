import { DateTime } from "luxon"
import { StatisticsProductsOptions } from "@/interface/sales-stats/Products"
import { ReportingPeriods } from "@/interface/ReportingPeriods"
import { Ref, ref } from "vue"

export const correctDateRange = (options: StatisticsProductsOptions) => {
    const startDate = DateTime.fromJSDate(options.start_date)
    const endDate = DateTime.fromJSDate(options.end_date)

    const dayPeriodDisabled = endDate.diff(startDate, "day").days > 31
    const weekPeriodDisabled = endDate.diff(startDate, "month").months > 12

    let period = options.period

    if (dayPeriodDisabled && options.period === "day") {
        period = "week"
    } else if (weekPeriodDisabled && options.period === "week") {
        period = "month"
    }

    return { ...options, period }
}

export const generateDateRange = (
    startDate: string,
    endDate: string
): Array<string> | [] => {
    const startDateObject = DateTime.fromISO(startDate)
    const endDateObject = DateTime.fromISO(endDate)

    if (startDate == "" && endDate == "") {
        return []
    }

    if (startDate === endDate) {
        return [startDateObject.toFormat("yyyy-MM-dd")]
    }

    // Get the difference in milliseconds between start and end dates
    const diffMillis = endDateObject.toMillis() - startDateObject.toMillis()

    // Calculate the number of days in the range
    const daysInRange = Math.ceil(diffMillis / (1000 * 60 * 60 * 24))

    // Generate the array using `map` and `plus` methods
    return Array.from({ length: daysInRange + 1 }, (_, index) =>
        startDateObject.plus({ days: index }).toFormat("yyyy-MM-dd")
    )
}

type OptionsWithDatesAndPeriod = {
    start_date: Date | string
    end_date: Date | string
    period: string
}
export const calculateDatesByPeriod = <
    OptionsType extends OptionsWithDatesAndPeriod
>(
    options: OptionsType
) => {
    if (
        !(options.start_date instanceof Date) ||
        !(options.end_date instanceof Date)
    ) {
        console.warn(
            "Warning: start_date or end_date are not instance of Date!"
        )
        return options
    }
    let startDateTime = DateTime.fromJSDate(options.start_date)
    let endDateTime = DateTime.fromJSDate(options.end_date)
    switch (options.period) {
        case "week":
            startDateTime = startDateTime.set({ weekday: 1 })
            endDateTime = endDateTime.set({ weekday: 7 })
            break
        case "week_us":
            startDateTime =
                //this fixes the issue of ever extending selection,
                // because 0 day is actually seen as 7 day
                startDateTime.weekday === 7
                    ? startDateTime
                    : // @ts-ignore US week starts with 0
                      startDateTime.set({ weekday: 0 })
            endDateTime = endDateTime.set({ weekday: 6 })
            break
        case "month":
            startDateTime = startDateTime.startOf("month")
            endDateTime = endDateTime.endOf("month")
            break
        case "quarter":
            startDateTime = startDateTime.startOf("quarter")
            endDateTime = endDateTime.endOf("quarter")
            break
        case "year":
            startDateTime = startDateTime.startOf("year")
            endDateTime = endDateTime.endOf("year")
            break
    }
    return {
        ...options,
        start_date: startDateTime.toJSDate(),
        end_date: endDateTime.toJSDate(),
    }
}

const getWeekStartEndDate = (period: string, is_us: boolean = false) => {
    // eslint-disable-next-line no-case-declarations
    const yearAndWeek = period.split("-")
    // eslint-disable-next-line no-case-declarations
    const dateTimeObject = DateTime.now()
        .set({
            year: Number(yearAndWeek[0]),
        })
        .set({
            weekNumber: Number(yearAndWeek[1]),
        })

    return {
        startDate: dateTimeObject
            .startOf("week")
            // make sure to go back one day, since us weeks start on sunday. The default is monday
            .minus({
                day: Number(is_us),
            }),
        endDate: dateTimeObject
            .endOf("week")
            // make sure to go back one day, since us weeks start on sunday. The default is monday
            .minus({
                day: Number(is_us),
            }),
    }
}

export const getStartAndEndDateForPeriod = (
    period: string,
    periodType: ReportingPeriods
) => {
    const startDate: Ref<DateTime> = ref(DateTime.now())
    const endDate: Ref<DateTime> = ref(DateTime.now())

    switch (periodType) {
        case "day":
            startDate.value = DateTime.fromISO(period)
            endDate.value = startDate.value
            break
        case "week":
            startDate.value = getWeekStartEndDate(period).startDate
            endDate.value = getWeekStartEndDate(period).endDate
            break
        case "week_us":
            startDate.value = getWeekStartEndDate(period, true).startDate
            endDate.value = getWeekStartEndDate(period, true).endDate
            break
        case "month":
            startDate.value = DateTime.fromISO(period).startOf("month")
            endDate.value = startDate.value.endOf("month")
            break
        case "quarter":
            startDate.value = DateTime.now()
                .set({
                    year: Number(period),
                })
                .startOf("quarter")
            endDate.value = startDate.value.endOf("quarter")
            break
        case "year":
            startDate.value = DateTime.now()
                .set({
                    year: Number(period),
                })
                .startOf("year")
            endDate.value = startDate.value.endOf("year")
            break
    }

    return {
        startDate: startDate.value,
        endDate: endDate.value,
    }
}
