<template>
    <ModalWindow
        show
        :title="translate('customer')"
        class="customer-modal"
        @close="cancel"
    >
        <div class="header">
            <h1 class="modal-title">
                {{ translate("customer_details") }}
            </h1>
            <SearchField />
            <ButtonBase
                @click="cancel"
                name="delete"
                icon="delete"
                class="delete"
            />
        </div>
        <FormErrorsProvider :errors="validationErrors">
            <div class="body" id="modalBody" ref="scrollArea">
                <CustomerFields
                    :form-errors="validationErrors"
                    @phoneValidation="phoneError = $event"
                    :isAutoCompletingAddress="isAutoCompletingAddress"
                />
                <CustomerOrder />
            </div>
        </FormErrorsProvider>
        <PageFooter :scroll-area="scrollArea">
            <div class="customer-footer">
                <ButtonBase
                    variant="text"
                    name="cancel"
                    @click="cancel"
                    class="cancel"
                >
                    {{ translate("cancel") }}
                </ButtonBase>
                <ButtonBase @click="reset" class="reset">
                    {{ translate("reset") }}
                </ButtonBase>
                <ButtonBase
                    name="save"
                    :disabled="false"
                    @click="submit"
                    class="save"
                    data-testid="save"
                >
                    {{ translate("save") }}
                </ButtonBase>
            </div>
        </PageFooter>
    </ModalWindow>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue"
import ModalWindow from "@/ui-elements/layout/modals/ModalWindow.vue"
import SearchField from "@/pages/pos/modal/customer/search/SearchField.vue"
import CustomerOrder from "@/pages/pos/modal/customer/CustomerOrder.vue"
import CustomerFields from "@/pages/pos/modal/customer/CustomerFields.vue"
import { useI18n } from "vue-i18n"
import { ButtonBase } from "@/ui-elements"
import { useCustomerModalStore } from "@/store/CustomerModal"
import { useCartStore } from "@/store/cart/Cart"
import PageFooter from "@/ui-elements/layout/footer/PageFooter.vue"
import { getInitialDispatchType } from "@/utils/settings/dispatchType"
import { dateAfterToday } from "@/pages/pos/modal/customer/data/useUtils"
import FormErrorsProvider from "@/ui-elements/FormErrorsProvider.vue"
import { useCustomerValidation } from "@/pages/pos/modal/customer/data/useCustomerValidation"
import { usePinPaymentStore } from "@/store/PinPayment"
import { cloneDeep } from "lodash"
export default defineComponent({
    name: "CustomerModal",
    components: {
        ButtonBase,
        CustomerFields,
        CustomerOrder,
        SearchField,
        ModalWindow,
        PageFooter,
        FormErrorsProvider,
    },
    setup() {
        const { t: translate } = useI18n()
        const modalStore = useCustomerModalStore()
        const cartStore = useCartStore()
        const skipErrors = ref(false)
        // Set default dispatch type if it's the first time customer modal
        // is opened (or opened after resetting modal state)
        if (
            !modalStore.dispatchTypeSet &&
            !cartStore.currentCart.is_loaded_from_order
        ) {
            cartStore.currentCart.cart.type = getInitialDispatchType()
            modalStore.dispatchTypeSet = true
            cartStore.currentCart.cart.schedule.asap = true
        }
        const scrollArea = ref()

        const {
            formErrors,
            phoneError,
            showValidationNotification,
            isAutoCompletingAddress,
            autoCompleteErrors,
        } = useCustomerValidation()

        const validationErrors = ref(formErrors.value)

        const concatFormAndValidationErrors = () => {
            const errors = formErrors.value

            if (autoCompleteErrors.value !== {}) {
                Object.keys(autoCompleteErrors.value).forEach(
                    (field: string) => {
                        if (errors.validationErrors[field]) {
                            Object.keys(
                                autoCompleteErrors.value[field]
                            ).forEach((key: any) => {
                                errors.validationErrors[field].unshift(
                                    autoCompleteErrors.value[field][key]
                                )
                            })
                        } else {
                            errors.validationErrors[field] =
                                autoCompleteErrors.value[field]
                        }
                    }
                )
            }

            validationErrors.value = errors
        }

        concatFormAndValidationErrors()

        watch([formErrors, autoCompleteErrors], () => {
            concatFormAndValidationErrors()
        })

        const submit = () => {
            modalStore.doValidation = true
            const hasErrors = showValidationNotification(skipErrors.value)
            if (hasErrors && !skipErrors.value) {
                skipErrors.value = true
                return
            }

            if (
                dateAfterToday(cartStore.currentCart.cart.schedule.date) &&
                !cartStore.currentCart.cart.schedule.time
            ) {
                return
            }

            modalStore.showModal = false
            cartStore.updateCart()
        }

        const resetCartSettings = () => {
            cartStore.currentCart.cart.type = getInitialDispatchType()
            cartStore.currentCart.cart.schedule = {
                date: "",
                time: "",
                asap: true,
            }
            cartStore.currentCart.cart.discounts =
                cartStore.currentCart.cart.discounts.filter(
                    (discount) => discount.type !== "code"
                )
            cartStore.currentCart.cart.remarks = ""
        }
        const reset = () => {
            resetCartSettings()
            cartStore.resetCustomer()
            modalStore.doValidation = false
        }
        const cancel = () => {
            modalStore.showModal = false
        }
        return {
            translate,
            scrollArea,
            submit,
            cancel,
            reset,
            modalStore,
            validationErrors,
            phoneError,
            isAutoCompletingAddress,
            autoCompleteErrors,
        }
    },
})
</script>
<style lang="scss">
.customer-modal.overlay {
    .modal-window {
        width: 57rem;
        max-height: 100%;
        height: 100%;
        position: relative;
    }

    .header {
        background: $primary-gradient;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: $padding-m $padding-m $padding $padding-l;
        margin: 0;
        border-radius: $radius $radius 0 0;
        .modal-title {
            margin: $margin $margin-l 0 0;
            color: white;
        }
        .delete {
            margin: 0 0 0 auto;
        }

        .search-field {
            width: 28rem;
            margin: $margin 0;
            .input-field {
                margin: 0;
            }
        }
    }

    .body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2 * $margin;
        padding: $padding $padding-l;
        overflow-y: scroll;

        .title {
            color: $secondary-color;
            font-size: $font-size-h4;
            font-weight: $font-weight-bold;
            margin-bottom: $margin-m;
        }
    }

    .modal-window .footer {
        margin-top: 0;
    }

    .customer-footer {
        display: flex;
        justify-content: flex-end;
        column-gap: $margin;
        width: 100%;
    }
}
</style>
