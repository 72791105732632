import { CustomerAddress } from "@/interface/Customers/CustomerAddress"
import { Customer } from "@/interface/Customers/Customer"
import { closest } from "@/utils/useClickAway"
import { ref, Ref } from "vue"
import { useEventListener } from "@vueuse/core"

export const calculateDirection = (
    boundingElement: HTMLElement | undefined,
    field: HTMLElement | undefined
) => {
    if (boundingElement && field) {
        const spaceTop =
            field.getBoundingClientRect().top -
            boundingElement.getBoundingClientRect().top
        const spaceBottom =
            boundingElement.getBoundingClientRect().bottom -
            field.getBoundingClientRect().bottom
        return spaceBottom > spaceTop ? "down" : "up"
    }
    return "bottom"
}
export const calculateDisplay = (
    focussed: boolean,
    customer: CustomerAddress,
    customerProperty: keyof CustomerAddress,
    results: Customer[]
) => {
    return (
        focussed &&
        !!customer[customerProperty] &&
        //@ts-ignore
        customer[customerProperty].length > 2 &&
        results.length > 0
    )
}
export const eventInParent = (
    target: EventTarget | null,
    parent: Ref<HTMLElement>
) => {
    if (
        target &&
        closest(
            target as HTMLElement,
            (element: HTMLElement) => element === parent.value
        )
    ) {
        return true
    } else {
        return false
    }
}
export const highlightPositions: (
    searchString: string,
    text: string
) => number[] = (searchString: string, text: string) => {
    const pos = text.toLowerCase().indexOf(searchString.toLowerCase())
    if (text && pos > -1) {
        const nextPositions = highlightPositions(
            searchString,
            text.slice(pos + 1)
        ).map((element) => element + pos + 1)
        return [pos, ...nextPositions]
    } else {
        return []
    }
}
export const recursiveFindAndReplace: (
    partOfText: string,
    positions: number[],
    length: number
) => string = (partOfText: string, positions: number[], length) => {
    if (positions.length) {
        return (
            recursiveFindAndReplace(
                partOfText.slice(0, positions[0]),
                positions.slice(1),
                length
            ) +
            "<strong>" +
            partOfText.slice(positions[0], positions[0] + length) +
            "</strong>" +
            partOfText.slice(positions[0] + length)
        )
    } else {
        return partOfText
    }
}
export const addStrong = (searchString: string, text: string) => {
    const positions = highlightPositions(searchString, text)
    return recursiveFindAndReplace(
        text,
        positions.reverse(),
        searchString.length
    )
}
export const escapeHtml = (text: string) => {
    return text
        ? text
              .replace(/&/g, "")
              .replace(/</g, "")
              .replace(/>/g, "")
              .replace(/"/g, "")
        : ""
}

export const useFocus = (focussed: Ref<boolean>) => {
    const parent = ref()
    const focusHandler = (event: FocusEvent) => {
        focussed.value = event.target
            ? eventInParent(event.target, parent)
            : false
    }
    const blurHandler = (event: FocusEvent) => {
        if (!eventInParent(event.relatedTarget, parent)) {
            focussed.value = false
        }
    }
    useEventListener(parent, "focusin", focusHandler)
    useEventListener(parent, "focusout", blurHandler)
    return parent
}
