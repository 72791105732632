export function getOrderSourceType(sourceType: string, sourceInfo?: string) {
    const sources = {
        tb: "thuisbezorgd",
        de: "deliveroo",
        ue: "uber-eats",
        kiosk: "atm-machine", // todo
        qr: "shopping-store",
    } as Record<string, string>

    return sources[sourceType] || sources[sourceInfo ?? ""] || "foodticket"
}
