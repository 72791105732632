import { RiceCooker } from "@/riceCooker/riceCooker"
export function devGetFrontendVersion() {
    return process?.env?.VUE_APP_BITBUCKET_BUILD_NUMBER
        ? process.env.VUE_APP_BITBUCKET_BUILD_NUMBER
        : "developer"
}

export async function devGetRiceCookerVersion() {
    return RiceCooker.isPresent() ? await window.riceCooker.getVersion() : null
}
