<template>
    <div class="result" tabindex="-1">
        <div class="address" tabindex="-1" v-html="html"></div>
        <div class="email" tabindex="-1" v-if="result.email">
            <IconBase icon="envelope"></IconBase>
            <div v-html="email"></div>
        </div>
        <div class="phone" tabindex="-1" v-if="result.phone">
            <IconBase icon="phone"></IconBase>
            <div v-html="phone"></div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue"
import { Customer } from "@/interface/Customers/Customer"
import IconBase from "@/ui-elements/IconBase.vue"
import { useCartStore } from "@/store/cart/Cart"
import { CustomerAddress } from "@/interface/Customers/CustomerAddress"
import {
    addStrong,
    escapeHtml,
} from "@/pages/pos/modal/customer/data/useSearchResults"
import { useCustomerModalStore } from "@/store/CustomerModal"

export default defineComponent({
    name: "SearchResult",
    components: {
        IconBase,
    },
    props: {
        result: { type: Object as PropType<Customer>, required: true },
        property: {
            type: String as PropType<keyof CustomerAddress | "">,
            default: "",
        },
    },
    setup(props) {
        const cartStore = useCartStore()
        const customerStore = useCustomerModalStore()
        const highlightString = computed(() => {
            if (props.property === "") {
                return customerStore.search
            } else {
                return cartStore.customer.address[props.property] || ""
            }
        })

        const name = computed(() => {
            return addStrong(
                highlightString.value,
                (props.result.address.company
                    ? escapeHtml(props.result.address.company) + ", "
                    : "") +
                    (escapeHtml(props.result.address.first_name) || "") +
                    " " +
                    (escapeHtml(props.result.address.last_name) || "")
            )
        })
        const address = computed(() => {
            return addStrong(
                highlightString.value,
                escapeHtml(props.result.address.street) +
                    " " +
                    escapeHtml(props.result.address.street_number) +
                    (props.result.address.address2
                        ? ", " + escapeHtml(props.result.address.address2) + " "
                        : "") +
                    (escapeHtml(props.result.address.address3) || "")
            )
        })
        const city = computed(() => {
            return addStrong(
                highlightString.value,
                escapeHtml(props.result.address.zipcode) +
                    " " +
                    escapeHtml(props.result.address.city) +
                    ", " +
                    props.result.address.country
            )
        })
        const email = computed(() =>
            addStrong(highlightString.value, escapeHtml(props.result.email))
        )
        const phone = computed(() =>
            addStrong(highlightString.value, escapeHtml(props.result.phone))
        )

        const html = computed(() => {
            return `<div class="address"> ${name.value}, ${address.value}, ${city.value}</div> `
        })

        return {
            html,
            email,
            phone,
        }
    },
})
</script>
<style lang="scss" scoped>
.result {
    border-bottom: $normal-border;
    font-size: $font-size-s;
    padding: $margin 0;
    cursor: pointer;

    & > div {
        display: flex;
        align-items: center;
    }

    .icon-base {
        margin-right: $margin-s;
    }

    .address,
    .email {
        margin-bottom: $margin-xs;
    }
}

.result:hover {
    color: $primary-color;
}
</style>
