import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "label-component" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.labelIcon)
      ? (_openBlock(), _createBlock(_component_IconBase, {
          key: 0,
          icon: _ctx.labelIcon,
          class: _normalizeClass(["label-icon", _ctx.labelIconClass])
        }, null, 8, ["icon", "class"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createTextVNode(_toDisplayString(_ctx.label), 1)
    ])
  ]))
}