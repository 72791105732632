import { CustomerAddress } from "@/interface/Customers/CustomerAddress"
import { FormErrors } from "@/utils/useFormErrors"
import { useZipcodesStore } from "@/store/Zipcodes"
import { computed, ComputedRef, Ref, ref, watch } from "vue"
import axios, { Canceler } from "axios"
import { useCartStore } from "@/store/cart/Cart"
import { useCustomerModalStore } from "@/store/CustomerModal"
import { dateAfterToday } from "@/pages/pos/modal/customer/data/useUtils"
import { useDebounce } from "@/utils/useDebounce"
import i18n from "@/i18n"
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification"
import { RiceCooker } from "@/riceCooker/riceCooker"
import form from "@/pages/settings/form/Form.vue"
import { cloneDeep } from "lodash"
import { offlineModeStore } from "@/store/offlineMode"
export const requiredFields = [
    "street",
    "street_number",
    "phone",
    "zipcode",
    "city",
] as Array<keyof CustomerAddress>
export const getInitialErrors = (): FormErrors => {
    return {
        validationErrors: {} as Record<keyof CustomerAddress, string[]>,
        processingError: "",
    }
}
export const isEmailValid = (email: string) => {
    return !!email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
}

type AdressErrors = {
    out_of_delivery_range: boolean
    invalid_address: boolean
}

type AutoCompleteErrors = Record<string, Array<string>>
export const defaultAddressErrors = {
    out_of_delivery_range: false,
    invalid_address: false,
}
export const addressKeys = ["zipcode", "street_number"]
const getValidationErrors = (addressErrors: Ref<AdressErrors>): FormErrors => {
    const { t: translate } = i18n.global
    const cartStore = useCartStore()
    const errors = getInitialErrors()

    if (
        cartStore.customer.address.email &&
        !isEmailValid(cartStore.customer.address.email)
    ) {
        errors.validationErrors.email = [translate("email_is_not_valid")]
    }
    if (
        dateAfterToday(cartStore.currentCart.cart.schedule.date) &&
        !cartStore.currentCart.cart.schedule.time
    ) {
        errors.validationErrors.time = [translate("field_is_required")]
    }

    if (cartStore.currentCart.cart.type !== "deliver") {
        return errors
    }
    requiredFields.forEach((key) => {
        if (!cartStore.customer.address[key]) {
            errors.validationErrors[key] = [translate("field_is_required")]
        }
    })

    addressKeys.forEach((addresKey) => {
        if (addressErrors.value.out_of_delivery_range) {
            const out_of_range = translate("zipcode_out_of_range")
            if (!errors.validationErrors[addresKey]) {
                errors.validationErrors[addresKey] = [out_of_range]
            } else {
                errors.validationErrors[addresKey].push(out_of_range)
            }
        }

        if (addressErrors.value.invalid_address) {
            const invalid = translate("address_is_invalid")
            if (!errors.validationErrors[addresKey]) {
                errors.validationErrors[addresKey] = [invalid]
            } else {
                errors.validationErrors[addresKey].push(invalid)
            }
        }
    })

    return errors
}

export const useCustomerValidation = () => {
    const cartStore = useCartStore()
    const modalStore = useCustomerModalStore()
    const addressErrors = ref(defaultAddressErrors)
    const phoneError = ref("")
    const autoCompleteErrors: Ref<AutoCompleteErrors> = ref({})
    const formErrors: ComputedRef<FormErrors> = computed(() => {
        if (!modalStore.doValidation) {
            return getInitialErrors()
        }
        return getValidationErrors(addressErrors)
    })
    const isAutoCompletingAddress = ref(false)
    let cancelRequest: Canceler | undefined
    const autocompleteAddress = async () => {
        isAutoCompletingAddress.value = true

        if (offlineModeStore().isOffline) {
            isAutoCompletingAddress.value = false
            return
        }

        if (cancelRequest) {
            cancelRequest()
        }

        addressErrors.value.out_of_delivery_range = false
        addressErrors.value.invalid_address = false
        autoCompleteErrors.value = {}

        axios
            .get("/utils/address/autocomplete", {
                params: {
                    streetnumber: cartStore.customer.address.street_number,
                    zipcode: cartStore.customer.address.zipcode,
                    country: cartStore.customer.address.country,
                    city: cartStore.customer.address.city,
                    street:
                        cartStore.customer.address.country.toUpperCase() ===
                        "nl"
                            ? null
                            : cartStore.customer.address.street,
                },
                cancelToken: new axios.CancelToken((c) => {
                    cancelRequest = c
                }),
            })
            .then((response) => {
                if (!response.data?.data?.address) {
                    return
                }
                if (
                    !Number(response.data.meta.inDeliveryRange) ||
                    !Number(response.data.meta.inDeliveryZipArea) ||
                    !Number(response.data.meta.inDeliveryDuration) ||
                    !Number(response.data.meta.inDeliveryDistance)
                ) {
                    if (!Number(response.data.meta.inDeliveryZipArea)) {
                        addressErrors.value.out_of_delivery_range = true
                    } else {
                        addressErrors.value.invalid_address = true
                    }
                } else {
                    addressErrors.value.out_of_delivery_range = false
                    addressErrors.value.invalid_address = false
                }

                cartStore.customer.address = {
                    ...cartStore.customer.address,
                    street: response.data.data.address.streetname,
                    city: response.data.data.address.city,
                }
            })
            .catch((response) => {
                if (!axios.isCancel(response)) {
                    if (response?.response?.status === 422) {
                        const data = response.response.data.data
                        if (data) {
                            Object.keys(data).forEach((field: string) => {
                                autoCompleteErrors.value[field] = data[field]
                            })
                        }
                    }

                    console.warn(
                        "Wrong data!",
                        response?.response?.data?.message
                    )
                    addressErrors.value.out_of_delivery_range = false
                    addressErrors.value.invalid_address = true
                }
            })
            .finally(() => {
                isAutoCompletingAddress.value = false
            })
    }
    watch(
        requiredFields
            .map((field) => () => cartStore.customer.address[field])
            .concat([
                () => cartStore.customer.address.email,
                () => cartStore.customer.address.phone,
                () => cartStore.currentCart.cart.schedule.date,
                () => cartStore.currentCart.cart.type,
            ]),
        () => {
            modalStore.doValidation = true
        }
    )
    watch(
        [
            () => cartStore.customer.address.zipcode,
            () => cartStore.customer.address.street_number,
            () => cartStore.customer.address.country,
            () => cartStore.customer.address.street,
        ],
        useDebounce(() => {
            if (
                cartStore.customer.address.zipcode &&
                cartStore.customer.address.street_number &&
                (String(
                    cartStore.customer.address.country ?? "NL"
                ).toUpperCase() !== "NL"
                    ? cartStore.customer.address.street
                    : !cartStore.customer.address.street)
            ) {
                autocompleteAddress()
            }
        }, 500)
    )
    const showValidationNotification = (skipErrors: boolean = false) => {
        if (phoneError.value) {
            showInvalidPhone()
            return true
        }
        if (!modalStore.hasValidAddress) {
            showMissingDataNotification()
            return true
        }
        if (
            cartStore.customer.address.email &&
            !isEmailValid(cartStore.customer.address.email)
        ) {
            showInvalidEmail()
            return true
        }
        if (
            dateAfterToday(cartStore.currentCart.cart.schedule.date) &&
            !cartStore.currentCart.cart.schedule.time
        ) {
            showTimeRequired()
            return true
        }
        if (!skipErrors) {
            if (addressErrors.value.out_of_delivery_range) {
                showZipcodeOutOfRange()
                return true
            }
            if (addressErrors.value.invalid_address) {
                showInvalidAddressNotification()
                return true
            }
        }

        return false
    }

    return {
        showValidationNotification,
        formErrors,
        phoneError,
        isAutoCompletingAddress,
        autoCompleteErrors,
    }
}
const showInvalidPhone = () => {
    const { t: translate } = i18n.global
    setToastNotification(
        translate("please_try_again"),
        translate("app_phoneNotValid"),
        "danger"
    )
}
const showInvalidEmail = () => {
    const { t: translate } = i18n.global
    setToastNotification(
        translate("please_try_again"),
        translate("email_is_not_valid"),
        "danger"
    )
}
const showTimeRequired = () => {
    const { t: translate } = i18n.global
    setToastNotification(
        translate("missing_data"),
        "Desired time is required!",
        "danger"
    )
}
const showZipcodeOutOfRange = () => {
    const { t: translate } = i18n.global
    setToastNotification(
        translate("zipcode_out_of_range"),
        translate("save_again_to_overrule"),
        "danger"
    )
}
const showInvalidAddressNotification = () => {
    const { t: translate } = i18n.global
    setToastNotification(
        translate("address_is_invalid"),
        translate("save_again_to_overrule"),
        "danger"
    )
}
const showMissingDataNotification = () => {
    const { t: translate } = i18n.global

    setToastNotification(
        translate("missing_data"),
        translate("components_brake_additional_field"),
        "danger"
    )
}
