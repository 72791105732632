import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_InputField = _resolveComponent("InputField")!

  return (_openBlock(), _createBlock(_component_InputField, {
    class: "check-input-wrapper",
    disabled: _ctx.disabled,
    error: _ctx.error,
    success: _ctx.success,
    label: _ctx.label,
    name: _ctx.name,
    "label-icon": _ctx.labelIcon,
    "label-icon-class": _ctx.labelIconClass
  }, {
    input: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["check-content", { checked: _ctx.checked, disabled: _ctx.disabled }]),
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('update:checked', !_ctx.checked)), ["prevent"]))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["check-box", {
                        error: _ctx.error,
                        indeterminate: _ctx.indeterminate,
                    }])
        }, [
          _createVNode(_component_IconBase, {
            class: "checkAnimation",
            icon: "check"
          })
        ], 2),
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.description), 1)
        ])
      ], 2)
    ]),
    _: 3
  }, 8, ["disabled", "error", "success", "label", "name", "label-icon", "label-icon-class"]))
}