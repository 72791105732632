import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableCell = _resolveComponent("TableCell")!
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_TableRow = _resolveComponent("TableRow")!
  const _component_BaseTable = _resolveComponent("BaseTable")!

  return (_openBlock(), _createBlock(_component_BaseTable, {
    selectable: false,
    deletable: false,
    class: "mb-4"
  }, {
    "list-header": _withCtx(() => [
      _createVNode(_component_TableCell, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate("number")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_TableCell, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate("status")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_TableCell, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate("total")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_TableCell, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate("customers_invoices_days")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_TableCell, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.translate("actions")), 1)
        ]),
        _: 1
      })
    ]),
    list: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoices, (invoice) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: invoice.id
        }, [
          _createVNode(_component_TableRow, {
            selectable: false,
            deletable: false,
            onClick: ($event: any) => (_ctx.openInvoiceInOrderBuddy(invoice.id))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TableCell, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(invoice.nr), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_TableCell, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(invoice.status_description), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_TableCell, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(invoice.total), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_TableCell, {
                class: _normalizeClass([
                            invoice.is_long_overdue
                                ? 'longOverdue'
                                : invoice.is_overdue
                                ? 'overdue'
                                : '',
                        ])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(invoice.days_open), 1)
                ]),
                _: 2
              }, 1032, ["class"]),
              _createVNode(_component_TableCell, null, {
                default: _withCtx(() => [
                  _createVNode(_component_IconBase, { icon: "link" })
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1032, ["onClick"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(invoice.lines, (line, index) => {
            return (_openBlock(), _createBlock(_component_TableRow, {
              key: index,
              selectable: false,
              deletable: false,
              onClick: ($event: any) => (_ctx.openInvoiceInOrderBuddy(invoice.id))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_TableCell),
                _createVNode(_component_TableCell, { colspan: 4 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(line), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ], 64))
      }), 128))
    ]),
    _: 1
  }))
}