import { useSettingsStore } from "@/store/Settings"
import { DateTime } from "luxon"

const getTimeShift = (shift: string) => {
    const sign = shift[0]
    const [hour, minutes] = shift
        .slice(1)
        .split(":")
        .map((component) => Number(component))
    return {
        sign,
        hour,
        minutes,
    }
}
const shiftTime = (time: string, direction: string) => {
    if (!time) {
        return ""
    }
    const settings = useSettingsStore().settings
    const correctFormat =
        settings.ob_timezone_offset.search(/^[+,-]\d{1,2}:\d{2}$/)
    if (correctFormat !== 0) {
        console.warn("ob_timezone_offset format is not correct")
        return ""
    }
    const { sign, ...transformOptions } = getTimeShift(
        settings.ob_timezone_offset
    )
    const dateTime = DateTime.fromFormat(time, "HH:mm")
    const shiftedDateTime =
        sign === direction
            ? dateTime.plus(transformOptions)
            : dateTime.minus(transformOptions)
    return shiftedDateTime.toFormat("HH:mm")
}
export const toUTC = (time: string) => {
    return shiftTime(time, "-")
}
export const fromUTC = (time: string) => {
    return shiftTime(time, "+")
}
