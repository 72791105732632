import { useRouter } from "vue-router"

export const checkPath = (route: string) => {
    const router = useRouter()
    const regex = new RegExp(route)
    return (
        (router?.currentRoute.value.matched.length &&
            router?.currentRoute.value.matched[0].path === route) ||
        regex.test(router?.currentRoute.value.path)
    )
}
