import { onBeforeUnmount, Ref } from "vue"

export function closest(
    el: HTMLElement,
    fn: (el: HTMLElement) => boolean
): boolean {
    const parent = el.parentElement
    return !!(el && parent && (fn(el) ? el : closest(parent, fn)))
} // recursively check from el up the element tree
// if any parent satisfies the condition in fn

export const checkIfClickedAway = (
    event: MouseEvent,
    awayFrom: HTMLElement,
    callback: Function
) => {
    const element = event.target as HTMLElement
    if (!closest(element, (el: HTMLElement) => el === awayFrom)) {
        callback()
    }
}

export const useClickAway = (
    awayFrom: Ref<HTMLElement>,
    callback: Function
) => {
    const check = (event: MouseEvent) =>
        checkIfClickedAway(event, awayFrom.value, callback)
    document.addEventListener("click", check)
    onBeforeUnmount(() => {
        document.removeEventListener("click", check)
    })
}
