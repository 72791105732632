<template>
    <div class="browser-header grid grid-cols-2">
        <div class="overview">
            <h1 class="title">
                {{ translate("pictures") }}
            </h1>
            <div class="results">
                {{
                    translate("app_result", {
                        count: number,
                    })
                }}
            </div>
        </div>
        <div class="filters">
            <!--            should remain here for when we add multiple disk functionality-->
            <!--            <SelectComponent-->
            <!--                :options="diskOptions"-->
            <!--                :value="disk"-->
            <!--                @update:value="$emit('update:disk', $event)"-->
            <!--                name="disk"-->
            <!--            ></SelectComponent>-->
            <TextField
                :text="search"
                @update:text="$emit('update:search', $event)"
                icon="search"
            ></TextField>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import { useI18n } from "vue-i18n"
import { SelectOption } from "@/ui-elements/input/select/SelectComponent.vue"
import TextField from "@/ui-elements/input/text-field/TextField.vue"

export default defineComponent({
    name: "BrowserHeader",
    components: { TextField },
    props: {
        number: Number,
        diskOptions: {
            type: Array as PropType<SelectOption[]>,
            required: true,
        },
        disk: String,
        search: String,
    },
    setup(props) {
        const { t: translate } = useI18n()

        return {
            translate,
        }
    },
    emits: ["update:disk", "update:string"],
})
</script>

<style lang="scss">
.browser-header {
    position: sticky;
    top: 0;
    box-shadow: 0 6px 17px rgba(0, 0, 0, 0.03);
    height: 4.75rem;
    padding: $padding;
    .overview {
        display: flex;
        align-items: baseline;
    }
    .results {
        color: $darker-gray;
        margin-left: $margin;
    }
    .filters {
        display: flex;
        & > div {
            flex: 1 1;
        }
        .text-field {
            margin-left: $margin;
        }
    }
}
</style>
