import {
    CustomerAddress,
    CustomerBillingAddress,
    getCustomerAddress,
    getCustomerBillingAddress,
} from "@/interface/Customers/CustomerAddress"
import i18n from "@/i18n"

export interface Customer {
    company: string
    first_name: string
    last_name: string
    address: CustomerAddress
    age_range: string
    alt_phone: string
    alt_phone_type: CustomerPhoneType
    avg_total_orders: number
    billing_address: CustomerBillingAddress
    birthdate: string
    client_id: number
    coordinates: string
    count_orders: number
    created_at: string
    email: string
    gender: CustomerGender
    has_mailing_bounced: boolean
    has_sms_bounced: boolean
    id: number
    invoice?: CustomerInvoice
    is_billing_address_same: boolean
    is_blocked: boolean
    is_deleted: boolean
    language: string
    last_order_at?: string | null
    mailing: boolean
    mailing_preferences: CustomerMailingPreference[]
    notes: string | null
    payment_methods: string | null
    payment_methods_request: null | {
        period: number | string | null
        limit: number | null
        type: string | null
    }
    phone: string
    phone_type: CustomerPhoneType
    points: number
    prev_orders: number
    type: CustomerType
    updated_at: string
    has_zipcode_check: boolean
    send_login_details: boolean
    total_orders: number
    discount: number
}

export enum CustomerType {
    Consumer = "consumer",
    Business = "business",
}

export enum CustomerPhoneType {
    Fixed = "fixed",
    Mobile = "mobile",
    Unknown = "?",
}

export type InvoiceReminderType =
    | "inv_reminder1_days"
    | "inv_reminder2_days"
    | "inv_reminder3_days"
    | "inv_reminder4_days"

interface CustomerInvoice {
    enabled: boolean
    limit: number
    period: string
    payment_term: number
    reminders: Record<InvoiceReminderType, number | string>
    reference: {
        is_required: boolean
        value: string | null
    }
}

export enum CustomerGender {
    Nothing = "",
    Other = "other",
    Male = "male",
    Female = "female",
}

export enum CustomerMailingPreference {
    Email = "email",
    SMS = "sms",
    Push = "push",
}

export const getDefaultCustomer = (): Customer => {
    const { locale } = i18n.global
    return {
        company: "",
        first_name: "",
        has_zipcode_check: false,
        send_login_details: false,
        last_name: "",
        total_orders: 0,
        address: getCustomerAddress(),
        alt_phone_type: CustomerPhoneType.Unknown,
        billing_address: getCustomerBillingAddress(),
        gender: CustomerGender.Nothing,
        notes: "",
        payment_methods: "",
        payment_methods_request: null,
        phone_type: CustomerPhoneType.Unknown,
        type: CustomerType.Consumer,
        age_range: "",
        alt_phone: "",
        avg_total_orders: 0,
        birthdate: "",
        client_id: 0,
        coordinates: "",
        count_orders: 0,
        created_at: "",
        email: "",
        has_mailing_bounced: false,
        has_sms_bounced: false,
        id: 0,
        invoice: {
            enabled: false,
            limit: 0,
            period: "",
            payment_term: 0,
            reminders: {
                inv_reminder1_days: "",
                inv_reminder2_days: "",
                inv_reminder3_days: "",
                inv_reminder4_days: "",
            },
            reference: {
                is_required: false,
                value: "",
            },
        },
        is_billing_address_same: false,
        is_blocked: false,
        is_deleted: false,
        language: locale.value,
        mailing: false,
        mailing_preferences: [],
        phone: "",
        points: 0,
        prev_orders: 0,
        updated_at: "",
        discount: 0,
    }
}
