import {
    financialName,
    financialStructure,
} from "@/pageStructure/FinancialStructure"
import { RouteRecordRaw } from "vue-router"
import { useSettingsStore } from "@/store/Settings"
import { useUserStore } from "@/store/User"

export const FinancialRoutes: RouteRecordRaw = {
    path: "/financial",
    meta: { name: financialName },
    component: () =>
        import(
            /* webpackPrefetch: true */ "@/pages/financial/FinancialLayout.vue"
        ),
    children: [
        {
            path: "",
            name: financialName,
            redirect: { name: financialStructure.turnover.name },
        },
        {
            path: financialStructure.turnover.path,
            name: financialStructure.turnover.name,
            meta: { subName: financialStructure.turnover.name },
            component: () =>
                import(
                    "@/pages/financial/turnover/list/components/TurnoverIndex.vue"
                ),
        },
        {
            path: financialStructure.turnover.path + "/edit/:date",
            meta: { subName: financialStructure.turnover.name },
            component: () =>
                import("@/pages/financial/turnover/edit/TurnoverEdit.vue"),
            props: true,
            beforeEnter: (to, from, next) => {
                const date = to.params.date as string
                const isDate = date
                    .split("-")
                    .reduce((acc, _) => acc && !isNaN(parseInt(_)), true)
                if (isDate) {
                    next()
                } else {
                    next(false)
                }
            },
        },
        {
            path: "turnover-charts",
            name: "turnoverCharts",
            meta: { subName: "turnoverCharts" },
            component: () =>
                import("@/pages/financial/turnover-charts/TurnoverCharts.vue"),
            beforeEnter: (to, from, next) => {
                const settings = useSettingsStore()
                if (
                    useUserStore().user.is_admin ||
                    Number(settings.settings.dunkin)
                ) {
                    next()
                } else {
                    next(from)
                }
            },
        },
        {
            path: financialStructure.payouts.path,
            name: financialStructure.payouts.name,
            meta: { subName: financialStructure.payouts.name },
            component: () => import("@/pages/financial/payouts/PayoutList.vue"),
        },
        {
            path: financialStructure.payouts.path + "/edit/:id",
            meta: { subName: financialStructure.payouts.name },
            component: () =>
                import("@/pages/financial/payouts/edit/PayoutEdit.vue"),
            props: true,
        },
        {
            path: financialStructure.invoices.path,
            name: financialStructure.invoices.name,
            meta: { subName: financialStructure.invoices.name },
            component: () =>
                import("@/pages/financial/invoices/list/InvoiceIndex.vue"),
        },
        {
            path: financialStructure.invoices.path + "/edit/:id",
            name: "financialInvoiceEdit",
            meta: { subName: financialStructure.invoices.name },
            component: () =>
                import("@/pages/financial/invoices/edit/InvoiceEditLayout.vue"),
            props: true,
        },
        {
            path: financialStructure.vat_extern.path,
            name: financialStructure.vat_extern.name,
            component: () =>
                import("@/pages/settings/connections/vat-extern/VatIndex.vue"),
        },
    ],
}
