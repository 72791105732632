<template>
    <h3 class="page-title">
        <IconBase
            v-if="titleIcon"
            :icon="titleIcon"
            :class="titleIconClass"
        ></IconBase>
        <slot></slot>
        {{ text }}
    </h3>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import IconBase from "@/ui-elements/IconBase.vue"
export default defineComponent({
    name: "PageTitle",
    components: {
        IconBase,
    },
    props: {
        text: String,
        titleIcon: {
            type: String,
        },
        titleIconClass: {
            type: String,
        },
    },
})
</script>

<style lang="scss">
.page-title {
    width: 100%;
    margin-bottom: $margin-l;
    margin-top: $margin-s;
    display: flex;
    align-items: flex-end;

    .icon-base {
        align-self: center;
        margin-right: $margin-m;
        font-size: 1.3rem;
    }
    &::after {
        content: " ";
        height: $margin-m;
        flex: 1 1;
        border-top: $normal-border;
        margin-left: $margin-s;
    }
}
</style>
