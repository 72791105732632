import {
    CalculatedCart,
    CartDiscount,
    CartItem,
    CartProduct,
    DiscountType,
    DispatchType,
} from "@/interface/Cart"
import { Customer, getDefaultCustomer } from "@/interface/Customers/Customer"
import { Category } from "@/interface/menu/Category"
import { Extra, ExtraItem } from "@/interface/menu/Extra"
import { Product } from "@/interface/menu/Product"
import { Order } from "@/interface/orders/Order"
import { User } from "@/interface/user/User"
import { PaymentMethod } from "@/pages/pos/ticket/PaymentMethod"
import { RiceCooker } from "@/riceCooker/riceCooker"
import { dataHydration } from "@/services/DataHydrationService"
import { saveOrder, submitCartOrder } from "@/store/cart/cartOrderUtils"
import { getDefaultPaymentMethod, getInitialCart } from "@/store/cart/cartUtils"
import { useCartDiscountsStore } from "@/store/CartDiscount"
import { useCustomerModalStore } from "@/store/CustomerModal"
import { multiLocationStore } from "@/store/MultiLocation"
import { useOrdersStore } from "@/store/Orders"
import { usePinPaymentStore } from "@/store/PinPayment"
import { usePOSStore } from "@/store/POS"
import { DEFAULT_MENU_ID, usePosMenusStore } from "@/store/PosMenus"
import type { Settings } from "@/store/Settings"
import { useSettingsStore } from "@/store/Settings"
import { useUserStore } from "@/store/User"
import { useZipcodesStore, Zipcode } from "@/store/Zipcodes"
import { paramsSerializer } from "@/utils/api/paramsSerializer"
import { getDefaultDispatchType } from "@/utils/settings/dispatchType"
import axios, { AxiosResponse } from "axios"
import { cloneDeep } from "lodash"
import { Calculate } from "orderbuddy-calculations/src/Calculate"
import { DispatchType as CalculatorDispatchType } from "orderbuddy-calculations/src/input/types/Cart"
import { SourceType } from "orderbuddy-calculations/src/input/types/Input"
import { defineStore } from "pinia"
import { UnwrapRef } from "vue"

export type CartState = {
    loadingCartOrder: boolean
    currentCart: CalculatedCart
    customer: Customer
    onlinePaymentLinkVia: string | null
    cashAmount: number
    pinAmount: number
    selectedChoiceProducts: number[]
    submittingOrder: boolean
    orderPlaced: boolean
    existingOrder: boolean
    existingOrderWasPaid: boolean
    orderSeen: boolean
    mainLocationId: number | null
}

interface ProductParams {
    product: Product
    items: CartItem[]
    remarks: string
    quantity?: number
    discountId: number
}

// @ts-ignore
export const useCartStore = defineStore({
    id: "cart",
    state: () =>
        ({
            loadingCartOrder: false,
            currentCart: getInitialCart(),
            customer: getDefaultCustomer(),
            onlinePaymentLinkVia: "email",
            cashAmount: 0,
            pinAmount: 0,
            selectedChoiceProducts: [],
            submittingOrder: false,
            orderPlaced: false,
            existingOrder: false,
            existingOrderWasPaid: false,
            orderSeen: false,
            mainLocationId: multiLocationStore().isActive
                ? multiLocationStore().main_location_id
                : null,
        } as CartState),
    getters: {
        products: (store): CartProduct[] => store.currentCart.cart.products,
        freeProducts: (store): CartProduct[] =>
            store.currentCart.cart.free_products,
        hasProducts: (store) => {
            return !!(
                store.currentCart.cart.products.length ||
                store.currentCart.cart.free_products.length
            )
        },
        deliveryCost: (store) => store.currentCart.costs.delivery,
        transactionCost: (store) => store.currentCart.costs.payment,
        deposit: (store) => store.currentCart.costs.deposit,
        tip: (store) => store.currentCart.costs.tip,
        defaultDispatchType: () => getDefaultDispatchType(),
        defaultPaymentMethod: () => getDefaultPaymentMethod(),
        isPinPayment: (store) => {
            return (
                store.currentCart.cart.payment_method === PaymentMethod.PIN ||
                store.currentCart.cart.payment_method === PaymentMethod.PIN_CASH
            )
        },
    },
    actions: {
        async selectTable(tableId: number): Promise<void> {
            if (this.currentCart.table_id) {
                await this.clearCurrentCart()
            }
            this.currentCart.table_id = tableId
            if (this.currentCart.cart.type !== DispatchType.EatIn) {
                this.currentCart.cart.type = DispatchType.EatIn
                this.updateCart()
            }
        },
        async loadCart(orderId: number): Promise<void> {
            this.loadingCartOrder = true

            await dataHydration.waitForHydration([
                "orders",
                "posMenu",
                "discounts",
                "zipcodes",
            ])

            const ordersStore = useOrdersStore()
            const loadOrder = async (order: Order): Promise<void> => {
                await this.clearCurrentCart()
                const cartOrder = ordersStore.toCartOrder(order)
                this.currentCart = cartOrder.currentCart
                this.customer = cartOrder.customer
                this.existingOrder = true
                this.orderSeen = false
                this.existingOrderWasPaid = cartOrder.existingOrderWasPaid
                await this.updateCart()
                usePosMenusStore().resetSelection(
                    order.products[0].product?.delivery ??
                        Number(useSettingsStore().settings.counter_menu) ??
                        DEFAULT_MENU_ID,
                    order.client_id
                )
            }

            // @todo, which attributes do we really need?
            await axios
                .get(
                    useOrdersStore().fetchOrderUrl(
                        orderId,
                        [
                            "customer_id",
                            "created_at",
                            "label",
                            "company",
                            "first_name",
                            "last_name",
                            "printer_address",
                            "billing_address",
                            "language",
                            "coordinates",
                            "email",
                            "phone",
                            "total_netto",
                            "delivery_costs",
                            "deliverer_id",
                            "remarks",
                            "is_delivery_asap",
                            "deleted",
                            "mailing",
                            "discount",
                            "discounts",
                            "time_offset",
                            "init_at",
                            "pending_at",
                            "en_route_at",
                            "children_ids",
                            "subtotal",
                            "parent_id",
                            "updated_at",
                            "reason",
                            "tip",
                            "deposit",
                            "via_client_id",
                            "payout_id",
                            "joeyco",
                            "set_at",
                            "table_id",
                            "points",
                            "is_in_future",
                            "is_pin_final",
                            "products_can_be_updated",
                            "is_refundable",
                            "delivered_at",
                            "is_test",
                            "is_awaiting_payment",
                            "aggregator_data",
                            "has_split_payment",
                            "expected_delivery_at",
                            "refunded_at",
                            "packaging_costs",
                            "has_custom_packaging_costs",
                        ],
                        ["products", "customer"]
                    ),
                    {
                        params: {
                            main_location_id: multiLocationStore().isActive
                                ? multiLocationStore().main_location_id
                                : 0,
                        },
                        paramsSerializer: paramsSerializer,
                    }
                )
                .then(
                    async (response: AxiosResponse<any>): Promise<void> =>
                        await loadOrder(response.data.data)
                )
                .finally((): boolean => (this.loadingCartOrder = false))
        },
        updateCartDiscount(
            type: any,
            discount: number,
            description: string
        ): void {
            const index: number = (
                this.currentCart.cart.discounts || []
            ).findIndex(
                (discount: CartDiscount): boolean =>
                    discount.type === DiscountType.CUSTOM
            )
            if (index >= 0) {
                this.currentCart.cart.discounts[index] = {
                    type: "custom",
                    custom: {
                        type: type,
                        description: description,
                        amount: discount,
                    },
                }
            } else {
                if (!this.currentCart.cart.discounts) {
                    this.currentCart.cart.discounts = []
                }

                this.currentCart.cart.discounts.push({
                    type: "custom",
                    custom: {
                        type: type,
                        description: description,
                        amount: discount,
                    },
                })
            }
            this.updateCart()
        },
        setCustomer(customer: Customer): void {
            this.customer = customer
        },
        setProductQuantity(index: number, quantity: number): void {
            this.currentCart.cart.products[index].quantity = quantity
            this.updateCart()
        },
        deleteProduct(index: number): void {
            this.currentCart.cart.products.splice(index, 1)
            this.updateCart()
        },
        // This function is called when clicking in product in POS menu, params.quantity = 1
        updateExistingProduct(index: number, params: ProductParams): void {
            const oldQuantity: number =
                this.currentCart.cart.products[index].quantity || 1
            const addedQuantity: number = params.quantity || 1

            this.currentCart.cart.products[index] = {
                ...cloneDeep(this.currentCart.cart.products[index]),
                items: params.items,
                remarks: params.remarks,
                quantity: oldQuantity + addedQuantity,
                discount_id: params.discountId,
            }
        },
        addNewProductLine(productParams: ProductParams): void {
            const cartProduct: CartProduct = {
                id: productParams.product.id,
                client_id: productParams.product.client_id,
                order_product_id: 0,
                quantity: productParams.quantity || 1,
                remarks: productParams.remarks,
                total: 0,
                vat: { amount: 0, rate: 0 },
                items: productParams.items,
                discount_id: productParams.discountId,
                packaging_costs: productParams.product.packaging_costs,
                category_packaging_costs:
                    productParams.product?.category?.packaging_costs,
                is_free: false,
            }
            const index: number =
                this.currentCart.cart.products.push(cartProduct)
            if (
                useSettingsStore().settings.pos_edit_buttons_auto === "1" ||
                Number(useSettingsStore().settings.pos_edit_buttons_auto)
            ) {
                const posStore = usePOSStore()
                posStore.openedTicketLine.type = "product"
                posStore.openedTicketLine.index = index - 1
            }
        },
        addProduct(productParams: ProductParams): void {
            let index: number = -1
            // We don't merge products if pos_keepinput = true
            // So no need to find the same product in cart
            if (
                !productParams.items.length &&
                !Number(useSettingsStore().settings.pos_keepinput)
            ) {
                index = this.currentCart.cart.products.findIndex(
                    (cartProduct: UnwrapRef<CartProduct>) =>
                        cartProduct.id === productParams.product.id &&
                        (!cartProduct.items || !cartProduct.items.length) &&
                        !cartProduct.remarks &&
                        !cartProduct.order_product_id &&
                        cartProduct.discount_id === productParams.discountId
                )
            }

            if (index >= 0) {
                // This product was already added to cart
                this.updateExistingProduct(index, productParams)
            } else {
                this.addNewProductLine(productParams)
            }
            // To prevent cart recalculation when free product was chosen,
            // also, if the product already exists, then increasing the quantity already invokes the calculator
            if (!productParams.discountId && index == -1) {
                this.updateCart()
            }

            const cartStore = useCartStore()
            // If product came from discount popup, then we need to remove one
            cartStore.selectedChoiceProducts.shift()
        },
        addCustomProduct(product: CartProduct): void {
            this.currentCart.cart.products.push(product)
            this.updateCart()
        },
        updateCustomProduct(customProduct: CartProduct): void {
            const oldCustomProduct: number =
                this.currentCart.cart.products.findIndex(
                    (product: UnwrapRef<CartProduct>) =>
                        product.customId &&
                        product.customId === customProduct.customId
                )
            this.currentCart.cart.products[oldCustomProduct] = customProduct
            this.updateCart()
        },
        async updateCart(
            triggerTipRoundUp: boolean = true,
            cart: CartState | null = null
        ): Promise<void> {
            const { user } = useUserStore() as { user: UnwrapRef<User> }
            const settings: UnwrapRef<Settings> = useSettingsStore().settings

            usePinPaymentStore().errorMessage = ""

            if (!cart) {
                cart = this
            }

            if (usePOSStore().tipRoundUp && triggerTipRoundUp) {
                cart.currentCart.cart.tip = 0
            }

            const categories: Map<number, Category> = multiLocationStore()
                .isActive
                ? multiLocationStore().flattenedCategories
                : usePosMenusStore().categories

            const products: Map<number, Product> = multiLocationStore().isActive
                ? multiLocationStore().flattenedProducts
                : usePosMenusStore().products

            const extras: Map<number, Extra> = multiLocationStore().isActive
                ? multiLocationStore().flattenedExtras
                : usePosMenusStore().extras

            const items: Map<number, ExtraItem> = multiLocationStore().isActive
                ? multiLocationStore().flattenedItems
                : usePosMenusStore().items

            const updatedCart: CalculatedCart = (await Calculate({
                language: localStorage.getItem("localLocale") ?? "nl",
                cart: {
                    ...cart.currentCart.cart,
                    products: cart.currentCart.cart.products.filter(
                        (product: CartProduct) => !product.is_free
                    ),
                    free_products: cart.currentCart.cart.products.filter(
                        // This is not handy, filtering on just discount_id. Now we can't assign discount id to
                        // discounted products. They are not free, but if the calculator would assign the discount id,
                        // they would here be added to free_products. The consequence is that when storing the
                        // products we don't know which products were discounted in which action
                        (product: CartProduct) => product.is_free
                    ),
                    zip_code: cart.customer.address.zipcode || undefined,
                    packaging_costs_override: usePOSStore().packaging_costs,
                } as any,
                source: SourceType.COUNTER,
                customer: cart.customer,
                discounts: useCartDiscountsStore().discounts,
                categories: [...(categories ?? []).values()].map(
                    (category: Category) => ({
                        id: category.id,
                        delivery_costs: category.delivery_costs || 0,
                        parent_id: category.parent_id || 0,
                        packaging_costs: category.packaging_costs,
                    })
                ),
                products: [...(products ?? []).values()].map(
                    (product: Product) => ({
                        id: product.id,
                        price: product.price,
                        deposit: product.deposit || 0,
                        vat_rate: user.vat_rates[(product.vat || 0) - 1] || 0,
                        vat_rate_alt:
                            user.vat_rates[(product.vat_alt || 0) - 1] || 0,
                        extra_ids: product.extra_ids || [],
                        main_category_id: product.main_category_id || 0,
                        packaging_costs: product.packaging_costs,
                    })
                ),
                //@ts-ignore
                items: [...(items ?? []).values()].map((item: ExtraItem) => ({
                    id: item.id,
                    price: item.price,
                    packaging_costs: item.packaging_costs,
                })),
                extras: [...(extras ?? []).values()].map((extra: Extra) => ({
                    id: extra.id,
                    item_ids: extra.item_ids,
                })),
                zip_codes: useZipcodesStore().zipcodes.map(
                    (zipcode: UnwrapRef<Zipcode>) => ({
                        id: zipcode.id,
                        from: zipcode.start,
                        to: zipcode.end,
                        free_from: zipcode.free_from || null,
                        costs: zipcode.costs,
                        minimum: zipcode.min_order_amount,
                    })
                ),
                settings: Object.keys(settings).map(
                    (setting: string): { setting: string; value: string } => ({
                        setting,
                        value: settings[setting as keyof Settings] as string,
                    })
                ),
                client: useUserStore().user,
                vat_rates: useUserStore().user.vat_rates,
                cash_amount: cart.currentCart.cash_amount,
                table_id: cart.currentCart.table_id,
                is_loaded_from_order: cart.currentCart.is_loaded_from_order,
            })) as unknown as CalculatedCart

            cart.currentCart = {
                ...updatedCart,
                cart: {
                    ...updatedCart.cart,
                    products: [
                        ...updatedCart.cart.products,
                        ...updatedCart.cart.free_products,
                    ],
                    free_products: [],
                },
                choices: updatedCart.choices.filter(
                    (choice) => choice.product_ids.length
                ),
            }

            if (usePOSStore().tipRoundUp && triggerTipRoundUp) {
                cart.currentCart.cart.tip =
                    Math.ceil(cart.currentCart.total) - cart.currentCart.total
                await this.updateCart(false, cart)
            }

            // Update cash and pin amounts if payment method = pin_cash
            if (
                cart.currentCart.cart.payment_method === PaymentMethod.PIN_CASH
            ) {
                cart.pinAmount = cart.currentCart.total - cart.cashAmount
            }

            RiceCooker.updateCustomerScreen({
                cart: cloneDeep(cart.currentCart),
                products: [...(usePosMenusStore().products ?? []).values()],
                extraItems: [...(usePosMenusStore().items ?? []).values()],
            })
        },
        async clearCurrentCart() {
            this.existingOrder = false
            this.existingOrderWasPaid = false
            this.orderSeen = false
            this.currentCart = getInitialCart()
            usePOSStore().openedTicketLine.type = null
            usePOSStore().openedTicketLine.index = null
            this.selectedChoiceProducts = []
            await this.resetCustomer()
            usePinPaymentStore().errorMessage = ""
            usePOSStore().packaging_costs = null
        },
        async resetCustomer() {
            this.customer = getDefaultCustomer()
            this.currentCart.cart.zip_code = ""
            await this.updateCart()
            // reset to false so when customer's modal is opened again it will
            // change dispatch type to the default one
            useCustomerModalStore().dispatchTypeSet = false
        },
        saveCustomer(customer: Customer) {
            this.customer = customer
            this.currentCart.cart.zip_code = customer.address.zipcode
            this.updateCart()
        },
        savePinAndCashAmount(cashAmount: number, pinAmount: number) {
            this.cashAmount = cashAmount
            this.pinAmount = pinAmount
            this.currentCart.cart.payment2!.amount = pinAmount
        },
        submitOrder() {
            // Submit and pay
            return submitCartOrder()
        },
        saveOrder() {
            // Just save order without payment
            return saveOrder()
        },
        // @ts-ignore
        // Calculator only had deliver, pickup and dine in
        getCalculatorDispatchType(): CalculatorDispatchType {
            let cartDispatch = this.currentCart.cart.type
            if (
                cartDispatch === undefined ||
                cartDispatch === DispatchType.None
            ) {
                cartDispatch = getDefaultDispatchType()
            }

            if (
                [DispatchType.PickUp, DispatchType.PickUpOnly].includes(
                    cartDispatch
                )
            ) {
                return CalculatorDispatchType.PICK_UP
            }

            if (
                [DispatchType.Deliver, DispatchType.DeliverOnly].includes(
                    cartDispatch
                )
            ) {
                return CalculatorDispatchType.DELIVER
            }

            if (DispatchType.EatIn === cartDispatch) {
                return CalculatorDispatchType.DINE_IN
            }
        },
    },
})
