<template>
    <InputField
        class="text-area"
        :disabled="disabled"
        :focus="focus"
        :error="error"
        :success="success"
        :label="label"
        :name="name"
        :class="name"
        :label-icon="labelIcon"
        :label-icon-class="labelIconClass"
    >
        <template v-slot:input>
            <textarea
                ref="textarea"
                v-model="content"
                v-bind="{ ...bind, ...$attrs }"
                @focusin="focus = true"
                @focusout="focus = false"
                @input="run()"
                :class="{ resizable: resizable }"
            ></textarea>
        </template>
    </InputField>
</template>

<script lang="ts">
import {
    computed,
    defineComponent,
    nextTick,
    onMounted,
    PropType,
    ref,
    watch,
} from "vue"
import InputField from "@/ui-elements/input/InputField.vue"
export default defineComponent({
    name: "TextArea",
    components: { InputField },
    props: {
        text: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        autocomplete: {
            type: String,
            default: "on",
        },
        placeholder: {
            type: String,
            default: "",
        },
        error: {
            type: Boolean,
            default: false,
        },
        success: {
            type: Boolean,
            default: false,
        },
        name: {
            type: [Array, String] as PropType<string | string[]>,
            default: () => [],
        },
        label: {
            type: String,
        },
        resizable: {
            type: Boolean,
            default: true,
        },
        labelIcon: {
            type: String,
        },
        labelIconClass: {
            type: String,
        },
    },
    setup: function (props, context) {
        const content = computed({
            get: () => props.text,
            set: (_) => context.emit("update:text", _),
        })
        const focus = ref(false)
        const bind = ref({
            disabled: props.disabled,
            autocomplete: props.autocomplete,
            placeholder: props.placeholder,
        })
        const textarea = ref()

        const run = () => {
            nextTick(() => {
                textarea.value.setAttribute(
                    "style",
                    "height:" + textarea.value.scrollHeight + "px"
                )
            })
        }
        onMounted(() => {
            run()
        })

        return {
            content,
            focus,
            bind,
            textarea,
            run,
        }
    },
    emits: ["update:text"],
    inheritAttrs: false,
})
</script>

<style lang="scss">
.text-area {
    width: 100%;

    .input-field {
        height: 100%;
    }

    .border {
        height: unset;
        overflow: hidden;
    }

    textarea {
        border: none;
        border-radius: $radius;
        display: block;
        outline: none;
        width: 100%;
        min-height: 2.75rem;
        padding: $padding-s;
        margin: 0;
        overflow: hidden;
        resize: none;

        &.resizable {
            resize: vertical;
        }
    }

    &.disabled textarea {
        color: $dark-gray;
        cursor: not-allowed;
    }
}
</style>
