import { SelectOption } from "@/ui-elements/input/select/SelectComponent.vue"
import { DateTime } from "luxon"
import { useFormatCurrency } from "@/utils/useCurrencySymbol"

type CustomTimeSlot = {
    startTime: string
    endTime: string
    timeRange: string
    cost: number
    days: string
    remark: string
}

export type AdditionalDeliveryCosts = Record<string, number>

export const getCustomTimeSlots = (
    timeSlotSetting: string,
    dateTime: DateTime
) => {
    const timeSlots: SelectOption[] = []
    const additionalCosts: AdditionalDeliveryCosts = {}

    const timeSlotLines = timeSlotSetting.split("\n")
    timeSlotLines.forEach((timeSlotLine) => {
        const timeSlot = getTimeSlotFromSetting(timeSlotLine)
        let isTimeAllowed = true

        const isDayAllowed = isTimeSlotForWeekDayAllowed(
            timeSlot,
            dateTime.weekday
        )
        const isToday = dateTime.hasSame(DateTime.now(), "day")

        if (isToday) {
            const intervalEndDateTime = DateTime.fromISO(timeSlot.endTime)
            isTimeAllowed = DateTime.now() < intervalEndDateTime
        }

        if (isDayAllowed && isTimeAllowed) {
            timeSlots.push(getTimeSlotOption(timeSlot))
            additionalCosts[timeSlot.timeRange] = timeSlot.cost
        }
    })

    return { timeSlots, additionalCosts }
}

const getTimeSlotFromSetting = (timeSlotLine: string) => {
    const timeSlot: CustomTimeSlot = {
        timeRange: "",
        startTime: "",
        endTime: "",
        cost: 0,
        days: "",
        remark: "",
    }

    const lineValues = timeSlotLine.split(" ")
    lineValues.forEach((value) => {
        const timeRegExp = new RegExp(/\d\d:\d\d-\d\d:\d\d/)
        const costsRegExp = new RegExp(/\+[0-9,]*/)
        const daysRegExp = new RegExp(/\d-\d|\d/)

        if (value.match(timeRegExp)) {
            const times = value.split("-")
            timeSlot.timeRange = value
            timeSlot.startTime = times[0]
            timeSlot.endTime = times[1]
        } else if (value.match(costsRegExp)) {
            timeSlot.cost = Number(value.replace(/\+/, "").replace(/,/, "."))
        } else if (value.match(daysRegExp)) {
            timeSlot.days = value
        } else {
            timeSlot.remark = value.replace(/"/, "")
        }
    })
    return timeSlot
}

const isTimeSlotForWeekDayAllowed = (
    timeSlot: CustomTimeSlot,
    weekDay: number
) => {
    if (!timeSlot.days) {
        return true
    }

    const days = timeSlot.days.split("-")
    if (days.length === 2) {
        return weekDay >= Number(days[0]) && weekDay <= Number(days[1])
    } else {
        return weekDay === Number(days[0])
    }
}

const getTimeSlotOption = (timeSlot: CustomTimeSlot): SelectOption => {
    const timeValue = timeSlot.startTime + " - " + timeSlot.endTime
    const cost = timeSlot.cost ? "+" + useFormatCurrency(timeSlot.cost) : ""

    return {
        value: timeSlot.timeRange,
        label: [timeValue, cost, timeSlot.remark].join(" "),
    }
}
