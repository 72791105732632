import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "default"
}
const _hoisted_2 = { class: "color-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_InputField = _resolveComponent("InputField")!

  return (_openBlock(), _createBlock(_component_InputField, {
    class: "color-select",
    onMousedown: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.click($event)), ["prevent"])),
    focus: _ctx.opened || _ctx.textFocussed,
    disabled: _ctx.disabled,
    error: _ctx.error,
    success: _ctx.success,
    label: _ctx.label,
    name: _ctx.name,
    "label-icon": _ctx.labelIcon,
    "label-icon-class": _ctx.labelIconClass
  }, {
    visual: _withCtx(() => [
      _createElementVNode("div", {
        class: "square",
        style: _normalizeStyle({ background: _ctx.color, 'border-color': _ctx.darkerColor })
      }, [
        (_ctx.colorValue === undefined)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1))
          : _createCommentVNode("", true)
      ], 4)
    ]),
    input: _withCtx(() => [
      _withDirectives(_createElementVNode("input", {
        class: "color-hidden",
        type: "color",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.colorValue) = $event)),
        ref: "select",
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.opened = false)),
        onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.opened = true))
      }, null, 544), [
        [_vModelText, _ctx.colorValue]
      ]),
      _createVNode(_component_IconBase, {
        class: _normalizeClass(["arrow-icon", { open: _ctx.opened }]),
        icon: "chevron-down"
      }, null, 8, ["class"]),
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.colorValue) = $event)),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.focusText($event))),
          onFocus: _cache[5] || (_cache[5] = ($event: any) => (_ctx.textFocussed = true)),
          onBlur: _cache[6] || (_cache[6] = ($event: any) => (_ctx.textFocussed = false)),
          ref: "textInput",
          placeholder: "Default"
        }, null, 544), [
          [_vModelText, _ctx.colorValue]
        ])
      ]),
      _createElementVNode("div", {
        class: "clear",
        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.colorValue = undefined)),
        onMousedown: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["stop"]))
      }, [
        _createVNode(_component_IconBase, {
          icon: "delete",
          class: "clear-icon"
        })
      ], 32)
    ]),
    _: 1
  }, 8, ["focus", "disabled", "error", "success", "label", "name", "label-icon", "label-icon-class"]))
}