import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["password-strength", { active: _ctx.active }])
  }, [
    _createElementVNode("span", null, _toDisplayString(_ctx.translate("use_at_least")) + ":", 1),
    _createElementVNode("span", {
      class: _normalizeClass(["lower-case", { check: _ctx.passwordTests.lower_case }])
    }, _toDisplayString(_ctx.translate("1_lowercase_letter")), 3),
    _createElementVNode("span", {
      class: _normalizeClass(["upper-case", { check: _ctx.passwordTests.upper_case }])
    }, _toDisplayString(_ctx.translate("1_capital_letter")), 3),
    _createElementVNode("span", {
      class: _normalizeClass(["digit", { check: _ctx.passwordTests.digit }])
    }, _toDisplayString(_ctx.translate("1_digit")), 3),
    _createElementVNode("span", {
      class: _normalizeClass(["8-characters", { check: _ctx.passwordTests.number }])
    }, _toDisplayString(_ctx.translate("8_characters")), 3)
  ], 2))
}