import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputField = _resolveComponent("InputField")!

  return (_openBlock(), _createBlock(_component_InputField, {
    class: _normalizeClass(["option-list-wrapper", { disabled: _ctx.disabled }]),
    name: _ctx.name,
    "label-icon": _ctx.labelIcon,
    "label-icon-class": _ctx.labelIconClass,
    label: _ctx.label
  }, {
    input: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["option-list", _ctx.contentClass])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["option", { selected: _ctx.isChecked(option.value) }]),
            key: option.value
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), {
              disabled: _ctx.disabled,
              checked: _ctx.isChecked(option.value),
              "onUpdate:checked": ($event: any) => (_ctx.onSelect(option.value, $event)),
              text: 
                            _ctx.type === 'radio' && option.label ? option.label : ''
                        ,
              description: 
                            _ctx.type === 'check' && option.label ? option.label : ''
                        
            }, null, 40, ["disabled", "checked", "onUpdate:checked", "text", "description"])),
            (_ctx.type === 'switch')
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(option.label), 1))
              : _createCommentVNode("", true)
          ], 2))
        }), 128))
      ], 2)
    ]),
    _: 1
  }, 8, ["name", "label-icon", "label-icon-class", "label", "class"]))
}