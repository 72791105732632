<template>
    <ModalWindow
        show
        @close="$emit('close')"
        class="messagebox-modal"
        :class="
            messagesList[selectedIndex]
                ? 'min-width-loaded'
                : 'min-width-loading'
        "
    >
        <div class="messagebox-header">
            <ButtonBase
                icon="delete"
                class="messagebox-close"
                v-on:click="$emit('close')"
            ></ButtonBase>
            <h2 class="modal-title">
                {{ translate("message_box") }}
            </h2>
            <TextField
                class="seach-input"
                v-model:text="filterOptions.search"
                icon="search"
                :placeholder="translate('search') + '...'"
            >
            </TextField>
        </div>
        <div class="messagebox-content">
            <div class="list-content">
                <div class="messages-list" v-if="messagesList" ref="scrollArea">
                    <div v-if="loading">
                        <LoadingIndicator cover />
                    </div>
                    <div v-for="(message, i) in messagesList" :key="i">
                        <MessageSingle
                            :message="message"
                            @click="selectedIndex = i"
                            :selected="selectedIndex === i"
                            :is-new="
                                obMessagesStore.unreadMessageIds.includes(
                                    message.id
                                )
                            "
                        />
                        <HorizontalBar v-if="i < messagesList.length - 1" />
                    </div>
                </div>
                <div v-else class="no-messages">
                    <IconBase icon="search-rows" class="no-rows"></IconBase>
                    <div>{{ translate("no_messages") }}</div>
                </div>
                <PageFooter
                    :scrollArea="scrollArea"
                    v-model:pagination="pagination"
                    hide-per-page
                />
            </div>
            <div class="message-preview">
                <template v-if="loading">
                    <LoadingIndicator cover />
                </template>
                <img
                    v-if="selectedIndex < 0"
                    src="/assets/images/mailbox.png"
                    class="mailbox-image"
                />
                <div v-if="selectedIndex < 0" class="zero-unread-msgs">
                    <IconBase
                        icon="party-popper"
                        class="party-popper"
                    ></IconBase>
                    <span>{{
                        translate("message_box_zero_unread_messages")
                    }}</span>
                </div>

                <MessageView
                    v-on:toPreviousMessage="showPreviousMessage"
                    v-on:toNextMessage="showNextMessage"
                    v-if="messagesList[selectedIndex]"
                    :message="messagesList[selectedIndex]"
                    :disablePreviousButton="disablePrevious"
                    :disableNextButton="disableNext"
                ></MessageView>
            </div>
        </div>
    </ModalWindow>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue"
import { ModalWindow } from "@/ui-elements"
import { useI18n } from "vue-i18n"
import { ButtonBase, TextField } from "@/ui-elements"
import MessageSingle from "@/ui-elements/message-box/MessageSingle.vue"
import PageFooter from "@/ui-elements/layout/footer/PageFooter.vue"
import IconBase from "@/ui-elements/IconBase.vue"
import MessageView from "@/ui-elements/message-box/MessageView.vue"
import HorizontalBar from "@/ui-elements/layout/HorizontalBar.vue"
import { usePaginatedRequest } from "@/utils/api/usePaginatedRequest"
import { MessageObject } from "@/interface/message-box/MessageObject"
import { useOBMessagesStore } from "@/store/OBMessage"
import axios from "axios"
import { RiceCooker } from "@/riceCooker/riceCooker"
import LoadingIndicator from "@/ui-elements/loaders/LoadingIndicator.vue"
import { offlineModeStore } from "@/store/offlineMode"
export default defineComponent({
    name: "MessageModal",
    components: {
        HorizontalBar,
        MessageView,
        IconBase,
        ButtonBase,
        ModalWindow,
        TextField,
        MessageSingle,
        PageFooter,
        LoadingIndicator,
    },
    setup() {
        const { t: translate } = useI18n()
        const scrollArea = ref()
        const obMessagesStore = useOBMessagesStore()

        const initializeFilters = () => {
            return {
                search: "",
            }
        }
        const {
            list: messagesList,
            filterOptions,
            pagination,
            loading,
        } = usePaginatedRequest<MessageObject>(
            "/client/ob_messages",
            initializeFilters,
            [],
            ["search", "page", "perPage"],
            10
        )
        const selectedIndex = ref(0)

        const disablePrevious = computed(() => selectedIndex.value <= 0)
        const disableNext = computed(
            () => selectedIndex.value >= messagesList.value.length - 1
        )

        const showPreviousMessage = () => {
            if (selectedIndex.value - 1 >= 0) {
                selectedIndex.value--
            }
        }

        const showNextMessage = () => {
            if (selectedIndex.value + 1 <= messagesList.value.length - 1) {
                selectedIndex.value++
            }
        }

        const markMessageAsRead = () => {
            // skip if offline
            if (offlineModeStore().isOffline) {
                return
            }

            const message = messagesList.value[selectedIndex.value]
            if (!message) {
                return
            }
            const wasRead = !obMessagesStore.unreadMessageIds.includes(
                message.id
            )
            if (wasRead) {
                return
            }
            // mark message as read
            const url = "client/ob_messages/" + message.id + "/mark-as-read"
            axios
                .post(url)
                .then(() => {
                    const messageIndex =
                        obMessagesStore.unreadMessageIds.indexOf(message.id)
                    obMessagesStore.unreadMessageIds.splice(messageIndex, 1)
                })
                .catch((e) => {
                    console.error(e)
                })
        }

        watch(
            [selectedIndex, messagesList],
            () => {
                markMessageAsRead()
            },
            { immediate: true }
        )

        return {
            translate,
            filterOptions,
            pagination,
            messagesList,
            scrollArea,
            selectedIndex,
            showPreviousMessage,
            disablePrevious,
            disableNext,
            obMessagesStore,
            showNextMessage,
            markMessageAsRead,
            loading,
        }
    },
})
</script>

<style lang="scss">
.messagebox-modal {
    .modal-window {
        width: 70vw;
        height: 90vh;
    }

    .messagebox-header {
        background: url("/public/assets/images/messagebox-background.png")
            no-repeat;
        width: 100%;
        background-position: right;
        background-size: cover;
        position: relative;
        background-origin: padding-box;
        padding: $padding-xl;
        border-radius: $radius $radius 0 0;

        span,
        div {
            color: $white;
        }

        .messagebox-close {
            position: absolute;
            right: $margin-m;
            top: $margin-m;
            margin: 0;
        }

        .modal-title {
            color: $white;
        }

        .text-field {
            padding-top: $padding-l;
            width: 30%;

            span,
            div {
                color: $secondary-color;
            }
        }
    }

    .messagebox-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: minmax(0, 1fr);
        overflow: scroll;

        .list-content {
            position: relative;
            box-shadow: $shadow-m;
            display: flex;
            flex-direction: column;
            overflow: auto;

            .messages-list {
                flex: 1 1;
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            .no-messages {
                min-height: 100%;
                display: flex;
                align-items: center;
                row-gap: $margin-m;
                flex-direction: column;
                padding-top: 4rem;

                .no-rows {
                    font-size: $font-size-large;
                }

                span {
                    color: $dark-gray;
                }

                div {
                    color: $dark-gray;
                    font-size: $font-size-h4;
                }
            }
        }

        .message-preview {
            position: relative;

            .mailbox-image {
                position: absolute;
                bottom: 0;
                width: 12rem;
                right: 1.1rem;
            }

            .zero-unread-msgs {
                display: flex;
                justify-content: center;
                padding-top: 5rem;
                align-items: center;

                .party-popper {
                    font-size: 1.5rem;
                    padding-right: $padding-s;
                }

                span {
                    color: $dark-gray;
                    font-size: $font-size-h4;
                }
            }

            .message-view {
                height: 100%;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .messagebox-modal {
        .modal-window {
            width: 95vw;
            height: 80vh;
        }

        .messagebox-content {
            grid-template-columns: 1fr;
            overflow: hidden;
        }
    }

    .messagebox-modal {
        .list-content {
            display: none !important;
        }

        .message {
            min-height: 100vh;

            table {
                display: contents;
            }
        }

        .seach-input {
            display: none !important;
        }
    }
}
</style>
