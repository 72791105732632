<template>
    <div class="sub-menu">
        <router-link
            v-for="link in menuOptions"
            :key="link.text"
            class="sub-menu-link"
            :to="{ name: link.name }"
            :class="{ current: currentPage === link.name }"
        >
            <IconBase
                v-if="link.icon"
                :icon="link.icon"
                :class="link.iconClass"
            ></IconBase>
            {{ translations ? translate(link.text) : link.text }}
        </router-link>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue"
import { useRoute } from "vue-router"
import { useI18n } from "vue-i18n"
import { MenuStructureItem } from "@/interface/MenuStructure"
import IconBase from "@/ui-elements/IconBase.vue"
export default defineComponent({
    name: "SubMenu",
    components: { IconBase },
    props: {
        menuOptions: {
            type: Object as PropType<{ [subItem: string]: MenuStructureItem }>,
            default: () => {},
        },
        translations: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        const { t: translate } = useI18n()
        const currentPage = computed(() => useRoute().meta.subSubName)
        return {
            translate,
            currentPage,
        }
    },
})
</script>

<style lang="scss">
.sub-menu {
    margin: 0 $margin;
    padding-left: $padding;
    display: flex;
    flex-flow: column nowrap;
    max-height: 40rem;
    border-left: $normal-border;
    background: $white;

    .sub-menu-link {
        text-decoration: none;
        color: $darker-gray;
        margin: $margin-m 0;
        transition: $quick-transition;
        .icon-base {
            font-size: 0.8rem;
        }
        &.current {
            font-weight: $font-weight-medium;
            color: $primary-color;
        }
    }

    &.open-submenu-enter-from,
    &.open-submenu-leave-to {
        max-height: 0;
        opacity: 0;
        padding: 0;

        .sub-menu-link {
            margin: 0;
        }
    }

    &.open-submenu-enter-active,
    &.open-submenu-leave-active {
        transition: $quick-transition;

        .sub-menu-link {
            transition: $quick-transition;
        }
    }
}
</style>
