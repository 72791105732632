<template>
    <InputField
        class="phone-input"
        :disabled="disabled"
        :error="error || !!validationError"
        :success="success"
        :focus="opened"
        :label="label"
        :name="name"
        :label-icon="labelIcon"
        :label-icon-class="labelIconClass"
    >
        <template v-slot:input>
            <VueTelInput
                mode="international"
                :modelValue="value"
                @update:modelValue="update($event)"
                @validate="onValidate"
                @focus="onFocus"
                @blur="onBlur"
                @open="onFocus"
                @close="onBlur"
                :inputOptions="inputOptions"
                :disabled="disabled"
                :defaultCountry="defaultCountry"
            >
                <template v-slot:arrow-icon>
                    <IconBase class="arrow-icon" icon="chevron-down"></IconBase>
                </template>
            </VueTelInput>
        </template>
        <template #message v-if="validationError">
            {{ validationError }}
        </template>
    </InputField>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue"
import InputField from "@/ui-elements/input/InputField.vue"
import { IconBase } from "@/ui-elements"
import { VueTelInput } from "vue-tel-input"
import "vue-tel-input/dist/vue-tel-input.css"
import { useUserStore } from "@/store/User"
import { useI18n } from "vue-i18n"
export default defineComponent({
    name: "PhoneInput",
    components: {
        InputField,
        VueTelInput,
        IconBase,
    },
    props: {
        value: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        success: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "",
        },
        autocomplete: {
            type: String,
            default: "on",
        },
        label: {
            type: String,
        },
        name: {
            type: String,
        },
        labelIcon: {
            type: String,
        },
        labelIconClass: {
            type: String,
        },
        validate: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, context) {
        const { t: translate } = useI18n()
        const defaultCountry = ref(useUserStore().user.address.country)
        const opened = ref(false)
        const validationError = ref("")

        const onValidate = (phoneObject: any) => {
            if (!props.validate) {
                validationError.value = ""
                return
            }
            validationError.value = phoneObject.valid
                ? ""
                : translate("app_phoneNotValid")

            // ugly trick to make parent know that there is an error
            context.emit("validation", validationError.value)
        }

        const inputOptions = ref({
            placeholder: props.placeholder,
            autocomplete: props.autocomplete,
            //showDialCode: true,
        })

        const onBlur = () => {
            opened.value = false
            context.emit("blur")
        }

        const onFocus = () => {
            opened.value = true
            context.emit("focus")
        }

        const update = (newValue: string) => {
            if (newValue === "") {
                validationError.value = ""
            }
            context.emit("update:value", newValue)
        }

        return {
            defaultCountry,
            opened,
            inputOptions,
            validationError,
            onValidate,
            onBlur,
            onFocus,
            update,
        }
    },
    emits: ["update:value", "focus", "blur", "validation"],
})
</script>

<style lang="scss">
.phone-input {
    .vue-tel-input {
        padding-left: $padding-m;
        flex: 1 1;
        border: none;
        box-shadow: none;

        .vti__dropdown {
            padding: 0;
        }

        .vti__selection {
            width: 3rem;
            height: 1rem;

            .vti__flag {
                margin: 0;
            }
        }

        .vti__flag {
            margin: 0 $margin-s 0 0;
            transform: scale(1.5);
            transform-origin: left 50%;
        }

        .arrow-icon {
            margin-left: $margin;
            color: $secondary-color;
        }

        .vti__dropdown-item strong {
            margin-left: $margin-s;
        }
    }
}
</style>
