<template>
    <div class="delivery-details">
        <IconBase
            v-if="deliveryType.icon"
            :icon="deliveryType.icon"
            class="delivery-icon"
        ></IconBase>
        <div>{{ translate(deliveryType.label) }}</div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import { DeliveryType } from "@/pages/orders/order/utils/getDeliveryType"
import { useI18n } from "vue-i18n"
import IconBase from "@/ui-elements/IconBase.vue"
export default defineComponent({
    name: "DeliveryDetails",
    components: {
        IconBase,
    },
    props: {
        deliveryType: { type: Object as PropType<DeliveryType> },
    },
    setup() {
        const { t: translate } = useI18n()
        return { translate }
    },
})
</script>
<style lang="scss">
.delivery-details {
    display: flex;
    align-items: center;

    div,
    span {
        color: $darker-gray;
    }

    .delivery-icon {
        padding-right: $padding-xs;
    }
}
</style>
