<template>
    <ButtonBase
        ref="downButton"
        :disabled="!scrollableDown"
        variant="filled"
        type="regular"
        class="page-down"
        @mousedown="scroll(1)"
        @mouseup="stopConstantScroll()"
        icon="arrow-down"
    >
    </ButtonBase>
    <ButtonBase
        ref="upButton"
        :disabled="!scrollableUp"
        variant="filled"
        type="regular"
        class="page-up"
        @mousedown="scroll(-1)"
        @mouseup="stopConstantScroll()"
        icon="arrow-up"
    ></ButtonBase>

    <ButtonBase
        v-if="scrolledDown"
        variant="filled"
        type="regular"
        class="to-top"
        icon="chevron-up"
        @click="scrollToTop"
    ></ButtonBase>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, toRef } from "vue"
import { useScroll } from "@/utils/useScroll"
import { onLongPress } from "@vueuse/core"
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue"
export default defineComponent({
    name: "ScrollButtons",
    components: {
        ButtonBase,
    },
    props: {
        scrollArea: {
            type: Object as PropType<HTMLElement>,
        },
    },
    setup(props) {
        const {
            scrollableDown,
            scrollableUp,
            scrolledDown,
            scroll,
            scrollToTop,
            stopConstantScroll,
            startConstantScroll,
        } = useScroll(toRef(props, "scrollArea"))
        const downButton = ref()
        const upButton = ref()
        onLongPress(downButton, () => startConstantScroll(1), { delay: 200 })
        onLongPress(upButton, () => startConstantScroll(-1), { delay: 200 })
        return {
            scrollableDown,
            scrollableUp,
            scrollToTop,
            scrolledDown,
            scroll,
            downButton,
            upButton,
            stopConstantScroll,
        }
    },
})
</script>

<style lang="scss">
.page-down,
.page-up,
.to-top {
    width: 2.75rem;
    padding: 0;
    margin-right: $margin-s;
    transition: background 0.1s;
}

.to-top {
    position: absolute;
    right: 0;
    bottom: 150%;
    box-shadow: $shadow;
}
</style>
