<template>
    <ModalWindow
        :title="translate('components_brake_title')"
        show
        @close="brakeStore.closeModal()"
        class="emergency-brake"
    >
        <PanelComponent :ready="true">
            <template #default>
                <div class="header">
                    <IconBase icon="warning"></IconBase>
                    <h1 class="title">
                        {{ translate("components_brake_title") }}
                    </h1>
                    <ButtonBase
                        @click="brakeStore.closeModal()"
                        icon="delete"
                        type="regular"
                        variant="filled"
                        class="close"
                    >
                    </ButtonBase>
                </div>
                <div class="body" ref="">
                    <TabOptions :options="tabOptions" v-model:value="tab" />
                    <BrakeTab
                        v-if="tab === 'brake'"
                        v-model:settings="brakeSettings"
                    />
                    <WebshopTab v-else v-model:settings="brakeSettings" />
                </div>
            </template>
            <template #footer>
                <ButtonBase type="secondary" variant="outlined" @click="cancel">
                    {{ translate("cancel") }}
                </ButtonBase>
                <ButtonBase class="save" @click="save">
                    {{ translate("save") }}
                </ButtonBase>
            </template>
        </PanelComponent>
    </ModalWindow>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue"
import ModalWindow from "@/ui-elements/layout/modals/ModalWindow.vue"
import { useBrakeStore } from "@/store/Brake"
import PanelComponent from "@/ui-elements/layout/PanelComponent.vue"
import ButtonBase from "@/ui-elements/button-base/ButtonBase.vue"
import { useI18n } from "vue-i18n"
import TabOptions from "@/ui-elements/input/TabOptions.vue"
import BrakeTab from "@/pages/orders/modals/brake/components/BrakeTab.vue"
import WebshopTab from "@/pages/orders/modals/brake/components/WebshopTab.vue"
import IconBase from "@/ui-elements/IconBase.vue"
import { useSettingsStore } from "@/store/Settings"
import type { Settings } from "@/store/Settings"
import axios from "axios"
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification"
import { dataHydration } from "@/services/DataHydrationService"
export default defineComponent({
    name: "EmergencyBrakeModal",
    components: {
        ModalWindow,
        PanelComponent,
        ButtonBase,
        TabOptions,
        BrakeTab,
        WebshopTab,
        IconBase,
    },
    setup() {
        const { t: translate } = useI18n()
        const brakeStore = useBrakeStore()
        const settingsStore = useSettingsStore()
        const getBrakeSettings = (settings: Settings) => {
            return {
                closed_tmp: !!Number(settings.closed_tmp),
                pickup_closed_tmp: !!Number(settings.pickup_closed_tmp),
                pickup_min_time_tmp: settings.pickup_min_time_tmp,
                delivery_closed_tmp: !!Number(settings.delivery_closed_tmp),
                delivery_min_time_tmp: Number(settings.delivery_min_time_tmp),
            }
        }
        const brakeSettings = ref(getBrakeSettings(settingsStore.settings))

        const cancel = () => {
            brakeSettings.value = getBrakeSettings(settingsStore.settings)
            brakeStore.closeModal()
        }
        const saveRequest = async () =>
            axios
                .post("/client/emergency_brake", {
                    delivery_min_time_tmp:
                        brakeSettings.value.delivery_min_time_tmp,
                    delivery_closed_tmp: brakeSettings.value.delivery_closed_tmp
                        ? 1
                        : 0,
                    pickup_min_time_tmp:
                        brakeSettings.value.pickup_min_time_tmp,
                    pickup_closed_tmp: brakeSettings.value.pickup_closed_tmp
                        ? 1
                        : 0,
                    closed_tmp: brakeSettings.value.closed_tmp,
                    tmp_set: true,
                })
                .then(() => {
                    setToastNotification(
                        translate("saved"),
                        translate("app_toast_success_content"),
                        "success"
                    )
                    dataHydration.hydrateModuleIfNoWebsocket("settings")
                })
                .catch((response) =>
                    setToastNotification(
                        translate("something_went_wrong"),
                        response.message,
                        "danger"
                    )
                )
        const save = () => {
            saveRequest()
            brakeStore.closeModal()
        }
        const tab = ref(brakeSettings.value.closed_tmp ? "webshop" : "brake")
        const tabOptions = computed(() => [
            {
                value: "brake",
                label: translate("emergency_brake"),
            },
            {
                value: "webshop",
                label: translate("close_webshop"),
            },
        ])

        return {
            brakeStore,
            cancel,
            translate,
            save,
            tabOptions,
            tab,
            brakeSettings,
        }
    },
})
</script>

<style lang="scss">
.emergency-brake {
    &.modal .modal-window {
        width: 60vw;
        height: 90vh;
        display: grid;
        grid-template-rows: minmax(0, 1fr);
    }

    .panel {
        display: grid;
        grid-template-rows: minmax(0, 1fr) 4.75rem;
        max-height: 100%;
    }

    .panel-content {
        display: grid;
        grid-template-rows: 5.75rem minmax(0, 1fr);
    }

    .header {
        display: flex;
        align-items: center;
        padding: $padding-l $padding $padding-l $padding-xl;
        background: $primary-gradient;
        border-radius: $radius $radius 0 0;

        h1 {
            color: $white;
        }

        .icon-warning {
            color: $white;
            font-size: 1.5rem;
            margin-right: $margin;
        }

        .button.close {
            margin-left: auto;
            align-self: flex-start;
        }
    }

    .body {
        padding: $padding $padding-xl;
        overflow-y: scroll;
    }

    .tab-options {
        width: 100%;
    }

    .emergency-brake-tab {
        display: flex;
        flex-direction: column;
        row-gap: $margin;
        padding: $padding 0;

        .notifications {
            margin-bottom: $margin;
        }
    }
}

@media only screen and (max-width: 768px) {
    .emergency-brake {
        &.modal .modal-window {
            width: 95vw;
            height: 80vh;
        }
    }
}
</style>
