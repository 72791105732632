<template>
    <div
        class="order-details grid grid-cols-3"
        :class="{ 'divider-margin': showRemarks || showDeliverer }"
        v-if="!showButtons"
    >
        <div class="delivery col-span-2 justify-self-stretch">
            <div class="street-name">{{ order.label }}</div>
            <div class="delivery-info">
                <DeliveryDetails :delivery-type="deliveryType" />
                <div
                    class="distance-details"
                    v-if="order.delivery === 'deliver'"
                >
                    <IconBase icon="circle-filled" class="circle-icon" />
                    <div v-if="showDuration">
                        {{ convertSecondsToHMS(order.duration) + " min" }}
                    </div>
                    <div v-else>{{ order.distance + " km" }}</div>
                </div>
            </div>
        </div>
        <div class="payment-details justify-self-end">
            <div class="grid cols-1 gap-4 items-center">
                <div
                    class="reference-number text-center"
                    v-if="(showRefNr && order.refnr) || isForMap"
                >
                    {{ order.refnr || orderLetter }}
                </div>
                <OrderPrice :order="order" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue"
import IconBase from "@/ui-elements/IconBase.vue"
import { DeliveryType } from "@/pages/orders/order/utils/getDeliveryType"
import { useI18n } from "vue-i18n"
import OrderPrice from "@/pages/orders/order/components/OrderPrice.vue"
import { Order } from "@/interface/orders/Order"
import { useSettingsStore } from "@/store/Settings"
import DeliveryDetails from "@/pages/orders/order/components/DeliveryDetails.vue"
import { convertSecondsToHMS } from "@/utils/useRefactorDate"
import { useOrdersStore } from "@/store/Orders"
export default defineComponent({
    name: "OrderDetails",
    components: {
        OrderPrice,
        IconBase,
        DeliveryDetails,
    },
    props: {
        order: { type: Object as () => Order, required: true },
        deliveryType: { type: Object as PropType<DeliveryType> },
        showButtons: {
            type: Boolean,
            default: false,
        },
        showRemarks: Boolean,
        showDeliverer: Boolean,
        isForMap: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { t: translate } = useI18n()
        const settings = useSettingsStore().settings
        const showDuration = settings.order_show_disdur === "duration"
        const showRefNr = computed(() => settings.ob_show_refnr === "1")
        const orderLetter = useOrdersStore().getOrderLetter(props.order.id)

        return {
            translate,
            showDuration,
            showRefNr,
            orderLetter,
            convertSecondsToHMS,
        }
    },
})
</script>

<style lang="scss">
.order-details {
    .street-name {
        font-weight: $font-weight-medium;
    }
    .delivery-info {
        display: flex;

        .distance-details {
            display: flex;
            align-items: center;
            padding-left: $padding-s;

            div,
            span {
                color: $darker-gray;
            }

            .circle-icon {
                font-size: 0.3rem;
                padding-right: $padding-s;
            }
        }
    }
    .payment-details {
        display: flex;
        align-items: center;
    }
}
</style>
