import { websocket } from "@/services/WebsocketService"
import { ref } from "vue"
import { useVoipStore } from "@/store/Voip"

export const useCallerId = () => {
    const _log = (
        message: string,
        isError: boolean = false,
        stackTrace: any = null
    ) => {
        if (window.ob.hasDebugModule("CallerId")) {
            if (!isError) {
                return stackTrace
                    ? console.log(`[CallerId] ${message}`, stackTrace)
                    : console.log(`[CallerId] ${message}`)
            }

            return console.error(`[CallerId] ${message}`, stackTrace)
        }
    }

    const store = useVoipStore()

    const callerIdCallback: Function = (data: any) => {
        store.callerIdActive = true
        store.callerId = data.phoneNumber
    }
    const callerIdTerminatedCallback: Function = (data: any) => {
        store.callerId = null
        store.callerIdActive = false
    }
    const tries = ref(0)
    const subscribeToCallerIdEvents = async (force: boolean = false) => {
        _log("subscribeToCallerIdEvents")
        if (force) {
            _log(
                "Forcing (re) subscribe to CallerIdEvents. UbSubscribing first."
            )
            await unSubscribeToCallerIdEvents()
        }

        // If already subscribed, return
        if (store.subscribed) {
            _log("Already subscribed to CallerIdEvents")
            return true
        }

        if (!websocket.isConnected()) {
            _log("Websocket not connected, retrying in 1 second", false, {
                tries: tries.value,
            })

            if (tries.value < 20) {
                tries.value++
                window.setTimeout(subscribeToCallerIdEvents, 1000)
            } else {
                _log(
                    "Websocket not connected, tried 20 times, giving up. DataHydration should retry."
                )
                tries.value = 0
            }
        } else {
            const websocketChannel = websocket.websocketChannel

            try {
                _log("Subscribing to CallerIdEvents")
                await websocketChannel
                    ?.listen(".CallerIdEvent", callerIdCallback)
                    .listen(
                        ".CallerIdTerminatedEvent",
                        callerIdTerminatedCallback
                    )

                _log("Subscribed to CallerIdEvents")
                store.subscribed = true
                return true
            } catch (error) {
                _log("Error subscribing to CallerIdEvents", true, error)
                return false
            }
        }

        return false
    }
    const unSubscribeToCallerIdEvents = async () => {
        _log("unSubscribeToCallerIdEvents")
        const websocketChannel = websocket.websocketChannel
        tries.value = 0

        await websocketChannel?.stopListening(
            ".CallerIdEvent",
            callerIdCallback
        )
        await websocketChannel?.stopListening(
            ".CallerIdTerminatedEvent",
            callerIdTerminatedCallback
        )

        store.subscribed = false
    }

    return {
        VoipStore: store,
        subscribeToCallerIdEvents,
        unSubscribeToCallerIdEvents,
    }
}
