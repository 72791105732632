<template>
    <div class="status-icon">
        <IconBase
            :icon="orderStatusIcon"
            class="status"
            :class="[orderStatus, { assigned: Boolean(delivererId) }]"
            :style="{ color: delivererColor }"
        ></IconBase>
        <IconBase
            icon="check"
            v-if="orderStatus === 'pending-ready'"
            class="check-icon"
        ></IconBase>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue"
import IconBase from "@/ui-elements/IconBase.vue"
import { OrderStatusType } from "@/interface/orders/Order"
import { useDelivererStore } from "@/store/Deliverer"
export default defineComponent({
    name: "StatusIcon",
    components: {
        IconBase,
    },
    props: {
        orderStatus: String as PropType<OrderStatusType>,
        delivererId: Number,
    },
    setup(props) {
        const delivererStore = useDelivererStore()
        const orderStatusIcon = computed(() =>
            props.delivererId ? "circle-half-filled" : "circle-open"
        )
        const delivererColor = computed(() => {
            if (props.delivererId) {
                const orderDeliverer = delivererStore.getDeliverer(
                    props.delivererId
                )
                return orderDeliverer ? orderDeliverer.orders_color : ""
            } else {
                return ""
            }
        })
        return { orderStatusIcon, delivererColor }
    },
})
</script>

<style lang="scss">
.status-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .status {
        font-size: 0.9rem;
        position: relative;
        line-height: 0.85rem;
        margin: 0 0.175rem;
        border-radius: 50%;
        &:before {
            margin: 0 -0.5px 0 -0.5px;
        }
        &.assigned {
            color: $info-color;
        }
        &.init {
            background: $white;
        }
        &.pending,
        &.pending-ready {
            background: $primary-color;
        }
        &.enroute {
            background: $dark-gray;
        }
        &.delivered {
            background: $success-color;
        }
    }
    .check-icon {
        position: absolute;
        font-size: 0.55rem;
        font-weight: 600;
    }
}
</style>
