import { RouteRecordRaw } from "vue-router"
import { kitchenMenu, kitchenName } from "@/pageStructure/KitchenStructure"

export const KitchenRoutes: RouteRecordRaw = {
    path: "/" + kitchenMenu.kitchen.path,
    name: kitchenName,
    meta: { name: kitchenName },
    component: () =>
        import(/* webpackPrefetch: true */ "@/pages/kitchen/KitchenLayout.vue"),
}
