<template>
    <teleport to="#modals" v-if="show">
        <div
            class="modal cursor-not-allowed"
            :class="[
                $attrs.class,
                type === 'alert' ? 'alert-overlay' : 'overlay',
            ]"
            ref="overlay"
        >
            <!-- @click="close($event)" -->
            <div class="modal-window cursor-default">
                <slot></slot>
            </div>
        </div>
    </teleport>
</template>

<script lang="ts">
import { ref, defineComponent } from "vue"
export default defineComponent({
    name: "ModalWindow",
    props: {
        show: Boolean,
        title: String,
        type: String,
    },
    inheritAttrs: false,
    setup(props, context) {
        const overlay = ref()
        function close(event: MouseEvent) {
            if (overlay.value === event.target) {
                context.emit("close")
            }
        }
        return {
            overlay,
            close,
        }
    },
    emits: ["close"],
})
</script>

<style lang="scss" scoped>
.modal {
    position: fixed;
    top: 0;
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-rows: 1rem minmax(0, 1fr) 1rem;
    z-index: 3000;
    -webkit-app-region: no-drag;

    &.overlay {
        background: $transparent-black;
    }

    &.alert-overlay {
        background: $alert-background;
    }

    .modal-window {
        background: $white;
        border-radius: $radius;
        display: flex;
        flex-flow: column nowrap;
        grid-row-start: 2;
        box-shadow: $shadow-dark;
        overflow: hidden;
    }
}
</style>
