<template>
    <div class="password-strength" :class="{ active: active }">
        <span>{{ translate("use_at_least") }}:</span>
        <span class="lower-case" :class="{ check: passwordTests.lower_case }">
            {{ translate("1_lowercase_letter") }}
        </span>
        <span class="upper-case" :class="{ check: passwordTests.upper_case }">
            {{ translate("1_capital_letter") }}
        </span>
        <span class="digit" :class="{ check: passwordTests.digit }">
            {{ translate("1_digit") }}
        </span>
        <span class="8-characters" :class="{ check: passwordTests.number }">
            {{ translate("8_characters") }}
        </span>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue"
import { useI18n } from "vue-i18n"

export default defineComponent({
    name: "PasswordStrength",
    props: {
        password: {
            type: String,
            default: "",
        },
    },
    setup(props) {
        const { t: translate } = useI18n()
        const active = computed(() => props.password !== "")
        const passwordTests = computed(() => {
            let lowerCase = false
            let upperCase = false
            let digit = false
            let password = props.password || ""
            password.split("").forEach((c: string) => {
                if (!isNaN(parseInt(c))) {
                    digit = true
                } else {
                    if (c === c.toUpperCase()) {
                        upperCase = true
                    }
                    if (c === c.toLowerCase()) {
                        lowerCase = true
                    }
                }
            })
            return {
                lower_case: lowerCase,
                upper_case: upperCase,
                digit: digit,
                number: password.length >= 8,
            }
        })
        return {
            translate,
            passwordTests,
            active,
        }
    },
})
</script>

<style lang="scss" scoped>
.password-strength {
    color: $dark-gray;

    &.active {
        color: $secondary-color;
    }

    span {
        margin-right: $margin-s;
    }

    .check {
        color: $dark-green;
    }
}
</style>
