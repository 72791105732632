import { archiveMenu, archiveName } from "@/pageStructure/ArchiveStructure"
import { RouteRecordRaw } from "vue-router"

export const ArchiveRoutes: RouteRecordRaw = {
    path: "/archive",
    meta: { name: archiveName },
    component: () =>
        import(/* webpackPrefetch: true */ "@/pages/archive/ArchiveLayout.vue"),
    children: [
        {
            path: archiveMenu.archive.path,
            name: archiveMenu.archive.name,
            meta: {
                name: archiveMenu.archive.name,
                subName: archiveMenu.archive.name,
            },
            component: () => import("@/pages/archive/list/ArchiveList.vue"),
        },
        {
            path: "order/:id",
            meta: {
                name: archiveMenu.archive.name,
                subName: archiveMenu.archive.name,
            },
            component: () => import("@/pages/archive/edit/ArchiveEdit.vue"),
            props: true,
        },
    ],
}
