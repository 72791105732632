<template>
    <div class="horizontal-bar" />
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
    name: "HorizontalBar",
})
</script>

<style lang="scss">
.horizontal-bar {
    width: 100%;
    border-top: $normal-border;
    margin: $margin-s 0;
}
</style>
