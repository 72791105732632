<template>
    <div class="orders-sidebar">
        <div class="header">
            <h1 class="title">
                {{ translate("all_orders") }}
            </h1>
            <ButtonBase
                name="close"
                icon="delete"
                @click="$emit('close')"
            ></ButtonBase>
        </div>
        <div class="body" ref="scrollArea">
            <OrdersFilter
                :statusFilter="statusFilter"
                @update:statusFilter="updateMapModalFilters($event)"
            ></OrdersFilter>
            <div class="orders-list" v-if="ready">
                <template v-if="!orders.length">
                    {{ translate("orders_map_noOrders") }}
                </template>
                <OrderTile
                    v-for="order of orders"
                    :key="order.id"
                    :order="order"
                    :is-for-map="true"
                    @mouseover="$emit('hover', order.id)"
                    @mouseleave="$emit('hover', 0)"
                    :highlight="highlight === order.id"
                />
                <DelivererTile
                    v-for="deliverer in deliverers"
                    :key="deliverer.id"
                    :deliverer="deliverer"
                    :more-data="true"
                    @mouseover="$emit('hover', deliverer.id)"
                    @mouseleave="$emit('hover', 0)"
                    :class="{ highlight: highlight === deliverer.id }"
                ></DelivererTile>
            </div>
        </div>
        <PageFooter :scroll-area="scrollArea">
            <ButtonBase name="save" @click="$emit('close')">
                {{ translate("close") }}
            </ButtonBase>
        </PageFooter>
    </div>
</template>

<script lang="ts">
import { useOrderModalStore } from "@/store/OrderModal"
import { defineComponent, PropType, ref } from "vue"
import { ButtonBase } from "@/ui-elements"
import { useI18n } from "vue-i18n"
import { Order, OrderStatusType } from "@/interface/orders/Order"
import OrdersFilter from "@/pages/orders/modals/map/OrdersFilter.vue"
import OrderTile from "@/pages/orders/order/components/OrderTile.vue"
import PageFooter from "@/ui-elements/layout/footer/PageFooter.vue"
import DelivererTile from "@/pages/orders/modals/order/tabs/DelivererTile.vue"
import { Deliverer } from "@/interface/deliverer/Deliverer"

export default defineComponent({
    name: "MapSideBar",
    components: {
        OrdersFilter,
        ButtonBase,
        OrderTile,
        PageFooter,
        DelivererTile,
    },
    props: {
        statusFilter: {
            type: Array as PropType<(OrderStatusType | "deliverers")[]>,
            required: true,
        },
        orders: {
            type: Array as PropType<Order[]>,
            required: true,
        },
        highlight: {
            type: Number,
            required: true,
        },
        deliverers: {
            type: Array as PropType<Deliverer[]>,
            required: true,
        },
        ready: Boolean,
    },
    setup() {
        const orderModalStore = useOrderModalStore()
        const { t: translate } = useI18n()
        const scrollArea = ref()

        function updateMapModalFilters(
            statusFilter: (OrderStatusType | "deliverers")[]
        ) {
            orderModalStore.updateStatusFilter(statusFilter)
        }

        return {
            translate,
            scrollArea,
            updateMapModalFilters,
        }
    },
    emits: ["close", "update:statusFilter", "hover"],
})
</script>

<style lang="scss">
.orders-sidebar {
    height: 100%;
    background: $white;
    display: grid;
    grid-template-rows: 4.75rem minmax(0, 1fr) 4.75rem;
    grid-template-columns: 100%;
    border-radius: 0 $radius $radius 0;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $padding $padding $padding 0;

        .title {
            padding-left: $padding-m;
            color: $secondary-color;
        }
    }

    .body {
        display: grid;
        grid-template-rows: 3.4rem minmax(0, 1fr);
        grid-template-columns: 100%;

        .orders-list {
            padding: $padding-m;
            overflow-y: scroll;
        }
        .order-tile {
            width: unset;
            margin: 0 0 $margin-s 0;
            transition: $quick-transition;
        }
        .deliverer-tile {
            width: unset;
            margin-bottom: $margin-s;
        }
    }
}
</style>
