import { MenuStructure } from "@/interface/MenuStructure"

export const financialName = "financial.title"
export const financialStructure: MenuStructure = {
    turnover: {
        text: "turnover",
        name: "turnover",
        path: "turnover",
        subMenu: {},
    },
    payouts: {
        text: "payouts",
        name: "payouts",
        path: "payouts",
        subMenu: {},
    },
    invoices: {
        text: "invoices",
        name: "financial/invoices",
        path: "invoices",
        subMenu: {},
    },
    vat_extern: {
        text: "vat_external",
        name: "financial/vat-extern",
        path: "vat-extern",
        subMenu: {},
    },
}
