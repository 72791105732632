<template>
    <div class="order-status-options">
        <div
            class="order-status"
            v-for="order_status in statusOptions"
            :key="order_status.key"
            :class="{ selected: status === order_status.value }"
            @click="$emit('update:status', order_status.value)"
        >
            <StatusIcon :order-status="order_status.value"></StatusIcon>
            <div class="status-label">{{ order_status.label }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue"
import { useI18n } from "vue-i18n"
import { OrderDeliveryType, OrderStatusType } from "@/interface/orders/Order"
import StatusIcon from "@/pages/orders/StatusIcon.vue"
export default defineComponent({
    name: "ModalStatus",
    components: { StatusIcon },
    props: {
        status: {
            type: String as PropType<OrderStatusType>,
        },
        delivery: {
            type: String as PropType<OrderDeliveryType>,
        },
    },
    setup(props) {
        const { t: translate } = useI18n()
        const statusOptions = computed(() => [
            {
                label: translate("new"),
                value: "init",
            },
            {
                label: translate("kitchen"),
                value: "pending",
            },
            {
                label: translate("ready"),
                value: "pending-ready",
            },
            {
                label: translate("on_the_way"),
                value: "enroute",
            },
            {
                label: translate(
                    props.delivery === "deliver" ? "delivered" : "picked_up"
                ),
                value: "delivered",
            },
        ])
        return {
            statusOptions,
        }
    },
    emits: ["update:status"],
})
</script>

<style lang="scss" scoped>
.order-status-options {
    display: flex;
    flex-direction: row;
    column-gap: $padding;
    justify-content: center;
    width: 100%;
    flex-wrap: nowrap;
    margin-bottom: $margin;

    .order-status {
        background: $lighter-gray;
        border-radius: $radius-s;
        margin: 0;
        display: flex;
        align-items: center;
        flex-grow: 1;
        flex-basis: 0;
        padding: $padding-m;
        position: relative;
        cursor: pointer;

        &.selected {
            background: $secondary-color;

            div,
            :deep(span) {
                color: $white;
            }
        }
        .status-icon {
            font-size: 0.9rem;
            position: relative;
            line-height: 0.85rem;
            margin: 0 0.175rem;
            border-radius: 50%;

            &:before {
                margin: 0 -1px 0 -1px;
            }
            &.init {
                background: $white;
            }
            &.pending,
            &.pending-ready {
                background: $primary-color;
            }
            &.enroute {
                background: $dark-gray;
            }
            &.delivered {
                background: $success-color;
            }
        }
        .check-icon {
            position: absolute;
            font-size: 0.6rem;
            left: 0.75rem;
            font-weight: 600;
        }
        .status-label {
            margin-left: $margin;
            font-weight: $font-weight-medium;
        }
    }
}

@media only screen and (max-width: 768px) {
    .order-status-options {
        display: block;

        .order-status {
            padding: 0.4rem;
        }
    }
}
</style>
