<template>
    <div class="map-control">
        <div class="zoom-in" @click="zoomIn">+</div>
        <div class="zoom-out" @click="zoomOut">-</div>
        <div class="home" @click="home">🚚</div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import L, { LatLngBounds } from "leaflet"

export default defineComponent({
    name: "MapControls",
    props: {
        map: {
            type: Object as PropType<L.Map>,
            default: () => {},
        },
        bounds: {
            type: Object as PropType<LatLngBounds>,
            default: () => {},
        },
        padding: {
            type: Array as PropType<number[]>,
            default: () => [20, 20],
        },
    },
    setup(props) {
        const zoomIn = () => props.map.zoomIn()
        const zoomOut = () => props.map.zoomOut()
        const home = () =>
            props.map.flyToBounds(props.bounds, {
                animate: true,
                duration: 0.5,
                padding: props.padding as [number, number],
            })
        return {
            zoomIn,
            zoomOut,
            home,
        }
    },
})
</script>
