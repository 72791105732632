<template>
    <PageTitle :text="data.title"></PageTitle>
    <div class="data">
        <div class="header">
            <div v-for="(value, name) in data.header" :class="name" :key="name">
                {{ value }}
            </div>
        </div>
        <div
            v-for="(line, lineName) in data.data"
            :class="lineName"
            :key="lineName"
        >
            <div v-for="(value, name) in line" :class="name" :key="name">
                {{ value }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import PageTitle from "@/ui-elements/PageTitle.vue"
import { SaldoData } from "@/interface/components/StatisticsTile"
export default defineComponent({
    name: "SaldoDisplay",
    components: {
        PageTitle,
    },
    props: {
        data: Object as PropType<SaldoData>,
    },
})
</script>

<style lang="scss">
.statistics-tile {
    .page-title {
        color: $white;

        &::after {
            border-color: $white;
        }
    }
    .data {
        display: flex;
        flex-flow: column nowrap;

        .header {
            div {
                font-weight: $font-weight-medium;
                font-size: $font-size-h5;
            }
        }

        & > div {
            display: flex;
            margin-bottom: $margin;
            & > div {
                flex: 1 1;
                color: $white;
            }
        }
    }

    .balance,
    .amount {
    }
}
</style>
