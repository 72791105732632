<template>
    <TextField
        :label="translate('reference')"
        v-model:text="cartStore.customer.invoice.reference.value"
    ></TextField>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import TextField from "@/ui-elements/input/text-field/TextField.vue"
import { useI18n } from "vue-i18n"
import { useCartStore } from "@/store/cart/Cart"
export default defineComponent({
    name: "ReferenceField",
    components: {
        TextField,
    },
    setup() {
        const { t: translate } = useI18n()
        const cartStore = useCartStore()

        return {
            translate,
            cartStore,
        }
    },
})
</script>
