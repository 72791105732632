import { DateTime } from "luxon"

export const dateAfterToday = (date: string) => {
    if (!date) {
        return false
    } else {
        const today = DateTime.now()
        const selectedDate = DateTime.fromJSDate(new Date(date))
        return selectedDate > today
    }
}
