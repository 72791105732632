import { defineStore } from "pinia"

export const useOrderWarningStore = defineStore({
    id: "order-warning",
    state: () => ({
        showWarning: false,
        warningTitle: "",
        warningDescription: "",
        earlyWarningDone: false,
    }),
    actions: {
        setWarning(title: string, description: string) {
            this.showWarning = true
            this.warningTitle = title
            this.warningDescription = description
        },
    },
})
