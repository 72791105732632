import { RiceCooker } from "@/riceCooker/riceCooker"
import axios from "axios"
import { useOrdersStore } from "@/store/Orders"
import { useCartStore } from "@/store/cart/Cart"
import { useMasterSlave } from "@/utils/useMasterSlave"
import { devGetRiceCookerVersion } from "@/utils/devUtils"
import { offlineModeStore } from "@/store/offlineMode"

class SyncOrdersService {
    private _interval: NodeJS.Timer | undefined = undefined

    start() {
        if (this.isActive() || !RiceCooker.isPresent()) {
            return
        }

        SyncOrdersService._log("Start")

        this._interval = setInterval(() => {
            this.syncOrders()
        }, 30000) // sync orders every 30s
        this.syncOrders()
    }

    async syncOrders() {
        // we're offline or not master
        if (offlineModeStore().isOffline || useMasterSlave().isSlave.value) {
            return
        }

        const ordersStore = useOrdersStore()
        const cartStore = useCartStore()
        const rcVersion = await devGetRiceCookerVersion()
        const orders = await RiceCooker.dbFetchAll("order_offline")

        if (!orders.length) {
            return
        }

        SyncOrdersService._log("Unsycned orders: " + orders.length)

        for (let i = 0; i < orders.length; i++) {
            const row = orders[i]

            SyncOrdersService._log("Syncing order #" + row.id + "...")

            try {
                if (
                    typeof row.id === "string" &&
                    row.id.indexOf("new") !== -1
                ) {
                    const cart = ordersStore.toCartOrder(row)
                    await cartStore.updateCart(false, cart)
                    cart.currentCart.cart.order_id = null
                    cart.currentCart.rc_version = rcVersion
                    await ordersStore.storeOrder(cart)
                } else {
                    await axios.patch("client/archive/" + row.id, {
                        deliverer_id: row.deliverer_id,
                        time_set: row.time_set,
                        status: row.status,
                        has_seen: row.has_seen,
                        payment: row.payment,
                        payment2: row.payment2,
                    })
                }

                await RiceCooker.syncRemove({ id: row.id })

                SyncOrdersService._log("SUCCESS syncing order #" + row.id)
            } catch (e) {
                SyncOrdersService._log("FAILED syncing order #" + row.id)
                console.error(e)
            }
        }
    }

    stop() {
        // stop isn't called anywhere
        if (this._interval) {
            SyncOrdersService._log("Stop")
            //@ts-ignore
            clearInterval(this._interval)
            this._interval = undefined
        }
    }

    isActive(): boolean {
        return this._interval !== undefined
    }

    private static _log(message: string) {
        if (RiceCooker.isPresent()) {
            console.log(`[SyncOrders] ${message}`)
        }
    }
}

export const syncOrders = new SyncOrdersService()
