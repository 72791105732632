import { defineStore } from "pinia"
import axios from "axios"
import { RiceCooker } from "@/riceCooker/riceCooker"
import { offlineModeStore } from "@/store/offlineMode"

export type OBMessageStore = {
    showMessagesModal: boolean
    unreadMessageIds: number[]
}

export const useOBMessagesStore = defineStore({
    id: "obMessage",
    state: () =>
        ({
            showMessagesModal: false,
            unreadMessageIds: [],
        } as OBMessageStore),
    actions: {
        async fetchObMessages(): Promise<boolean> {
            // skip if offline
            if (offlineModeStore().isOffline) {
                return true
            }

            try {
                const response = await axios.get("client/ob_messages/unread")
                this.unreadMessageIds = response.data.data.ids || []
                if (this.unreadMessageIds.length) {
                    // temp solution to not irritate our customers
                    // this.showMessagesModal = true
                }
                return true
            } catch (e) {
                return false
            }
        },
    },
})
