<template>
    <div class="label-component">
        <IconBase
            v-if="labelIcon"
            :icon="labelIcon"
            class="label-icon"
            :class="labelIconClass"
        />
        <slot>
            {{ label }}
        </slot>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import IconBase from "@/ui-elements/IconBase.vue"

export default defineComponent({
    name: "LabelComponent",
    components: {
        IconBase,
    },
    props: {
        label: {
            type: String,
        },
        labelIcon: {
            type: String,
        },
        labelIconClass: {
            type: String,
        },
    },
})
</script>

<style lang="scss">
.label-component {
    width: 100%;
    color: $black;
    font-weight: $font-weight-medium;
    letter-spacing: 0;
    line-height: 1.15rem;
    margin-bottom: $margin-s;
    column-gap: $margin-s;
    display: flex;
    align-items: center;

    .label-icon {
        &.primary {
            color: $primary-color;
        }
        &.success {
            color: $success-color;
        }
        &.warning {
            color: $warning-color;
        }
    }
}
</style>
