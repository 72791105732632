<template>
    <slot />
</template>

<script lang="ts">
import { computed, defineComponent, provide } from "vue"
import { FormErrors } from "@/utils/useFormErrors"

export default defineComponent({
    name: "FormErrorsProvider",
    props: {
        errors: {
            type: Object as () => FormErrors,
            default: () => {},
        },
    },
    setup(props) {
        provide(
            "errors",
            computed(() => props.errors)
        )
    },
})
</script>

<style scoped></style>
