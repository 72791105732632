export enum ApiError {
    CSRF = "csrf",
    GENERIC = "generic",
    INVALID_CREDENTIALS = "invalid_credentials",
    UNAUTHORIZED = "unauthorized",
    VALIDATION = "validation",
    NOT_FOUND = "not_found",
    PINCODE_REQUIRED = "pincode_required",
    READONLY = "client_is_readonly",
}

export type ApiResponse<DataType> = {
    success: boolean
    message: string
    data: DataType
}
