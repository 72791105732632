import { MenuStructure } from "@/interface/MenuStructure"

export const archiveName = "archive"
export const archiveMenu: MenuStructure = {
    archive: {
        text: "archive_overview",
        name: "archive",
        path: "",
        subMenu: {},
    },
}
