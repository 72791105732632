import { multiLocationStore } from "@/store/MultiLocation"
import { Ref, ref, watch } from "vue"
import axios, { AxiosError, Canceler } from "axios"
import { useDebounce } from "@/utils/useDebounce"
import { getPagination } from "@/utils/api/usePagination"
import { ResponseMeta } from "@/interface/ResponseMeta"
import { paramsSerializer } from "@/utils/api/paramsSerializer"
import { removeProperties, useFiltering } from "@/utils/useFiltering"
import { useSelectedItems } from "@/utils/useSelectedItems"
import { ObjectWithId } from "@/interface/components/SelectedItems"
import { useBulkDelete } from "@/utils/useBulkDelete"
import { isEqual } from "lodash"
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification"
import { useI18n } from "vue-i18n"

export type FailureCallback = (error: AxiosError) => void

export const getDefaultPerPageValue = () => {
    const defaultValue = 10
    if (localStorage.getItem("perPage")) {
        return Number(localStorage.getItem("perPage")) || defaultValue
    }
    return defaultValue
}

export const usePaginatedRequest = <
    ItemType extends ObjectWithId,
    OptionsType = {},
    ParamsType = {},
    MetaType = ResponseMeta
>(
    url: string,
    initializeOptions: (queryParams: ParamsType) => OptionsType = () =>
        ({} as OptionsType),
    frontendOnly = [] as string[],
    backendOnly = [] as string[],
    perPageDefault = getDefaultPerPageValue(),
    failureCallback: FailureCallback | undefined = undefined,
    includes: string[] = [],
    mainLocationId?: number
) => {
    const { t: translate } = useI18n()
    const { filterOptions, filterParameters } = useFiltering<
        OptionsType,
        ParamsType
    >(initializeOptions, frontendOnly, backendOnly)

    let cancelRequest: Canceler | undefined

    const loading = ref(false)
    const pagination = ref({
        current_page: 1,
        last_page: 1,
        total: 0,
        per_page: perPageDefault,
    })
    const dataReady = ref(false)
    const list = ref([]) as Ref<ItemType[]>
    const meta = ref({}) as Ref<MetaType>

    const { selectedItems, selection } = useSelectedItems(list)

    const makeRequest = async () => {
        loading.value = true
        if (cancelRequest) {
            cancelRequest()
        }
        const response = await axios.get(url, {
            params: {
                per_page: pagination.value.per_page,
                page: pagination.value.current_page,
                filter: filterParameters.value,
                include: includes,
                main_location_id: mainLocationId ?? 0,
                doAggregate: multiLocationStore().aggregateParameter(true),
            },
            paramsSerializer: paramsSerializer,
            cancelToken: new axios.CancelToken((c) => {
                cancelRequest = c
            }),
        })
        list.value = response.data.data
        meta.value = response.data.meta
        pagination.value = getPagination(
            response.data.meta?.count || 0,
            pagination.value
        )
        dataReady.value = true
    }
    const fetchData = () =>
        makeRequest()
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    defaultFailureCallback(error)
                }
            })
            .finally(() => (loading.value = false))

    const defaultFailureCallback = (e: any) => {
        if (failureCallback) {
            failureCallback(e)
            return
        }
        setToastNotification(
            translate("something_went_wrong"),
            translate("please_try_again"),
            "danger"
        )
        list.value = []
    }

    const { onDelete, undoDelete, isDeleting } = useBulkDelete(
        selectedItems,
        url,
        fetchData,
        () => selection.value.setAllItems(false)
    )

    watch(
        () => pagination.value.per_page,
        () => {
            pagination.value.current_page = 1
            fetchData()
            localStorage.setItem("perPage", String(pagination.value.per_page))
        }
    )
    watch(
        () => pagination.value.current_page,
        () => fetchData()
    )
    watch(
        filterParameters,
        useDebounce((newParams: any, oldParams: any) => {
            pagination.value.current_page = 1
            const newFiltered = removeProperties(newParams, frontendOnly)
            const oldFiltered = removeProperties(oldParams, frontendOnly)
            const equal = isEqual(newFiltered, oldFiltered)
            if (!equal) {
                fetchData()
            }
        }, 300),
        { deep: true }
    )
    fetchData()

    return {
        dataReady,
        pagination,
        refetchData: fetchData,
        list,
        meta,
        loading,
        filterOptions,
        filterParameters,
        onDelete,
        undoDelete,
        isDeleting,
        selectedItems,
        selection,
    }
}
