import { formatLeadingZero } from "@/utils/useLeadingZero"
import { DateTime, Duration } from "luxon"
import { useSettingsStore } from "@/store/Settings"
type StringDate = string | Date

export const useDateFormatters = () => {
    // makes sense to use formatters only if dateTime string, not date only or time only
    const settingsStore = useSettingsStore()

    const formatDateTime = (dateString: StringDate) => {
        return toLongDateFormat(dateString, settingsStore.getTimeZone)
    }

    const formatDate = (dateString: StringDate) => {
        return toShortDateFormat(dateString, settingsStore.getTimeZone)
    }

    const formatTime = (
        dateString: StringDate,
        withSeconds: boolean = false
    ) => {
        return toTimeFormat(dateString, withSeconds, settingsStore.getTimeZone)
    }

    return {
        formatDateTime,
        formatDate,
        formatTime,
    }
}

//replace with toshortdate
export function refactorDateOnly_MonthName(dateOrString: StringDate) {
    const newDate = new Date(dateOrString)
    return (
        formatLeadingZero(newDate.getDate()) +
        " " +
        refactorMonthName(dateOrString)
    )
}

export function refactorMonthName(dateOrString: StringDate) {
    const newDate =
        dateOrString instanceof Date
            ? DateTime.fromJSDate(dateOrString)
            : DateTime.fromISO(dateOrString)
    return newDate.toFormat("MMMM")
}
export function refactorShortMonthName(dateOrString: StringDate) {
    const newDate =
        dateOrString instanceof Date
            ? DateTime.fromJSDate(dateOrString)
            : DateTime.fromISO(dateOrString)
    return newDate.toFormat("MMM")
}
export function refactorShortDayName(dateOrString: StringDate) {
    const newDate =
        dateOrString instanceof Date
            ? DateTime.fromJSDate(dateOrString)
            : DateTime.fromISO(dateOrString)
    return newDate.toFormat("ccc dd")
}
export function refactorYearOnly(dateOrString: StringDate) {
    const newDate = new Date(dateOrString)
    return newDate.getFullYear()
}

export function toShortDateFormat(dateString: StringDate, locale: string = "") {
    const options = locale ? { zone: locale } : {}

    const dateTime =
        dateString instanceof Date
            ? DateTime.fromJSDate(dateString, options)
            : DateTime.fromISO(dateString, options)

    return dateTime.toFormat("yyyy-MM-dd")
}

export function toTimeFormat(
    dateString: StringDate,
    withSeconds: boolean = false,
    locale: string = ""
) {
    const options = locale ? { zone: locale } : {}

    const dateTime =
        dateString instanceof Date
            ? DateTime.fromJSDate(dateString, options)
            : DateTime.fromISO(dateString, options)

    return dateTime.toFormat("HH:mm" + (withSeconds ? ":ss" : ""))
}

export function toLongDateFormat(dateString: StringDate, locale: string = "") {
    const options = locale ? { zone: locale } : {}

    const dateTime =
        dateString instanceof Date
            ? DateTime.fromJSDate(dateString, options)
            : DateTime.fromISO(dateString, options)

    return dateTime.toFormat("yyyy-MM-dd HH:mm")
}

export function getDayName(dateString: string) {
    return DateTime.fromISO(dateString).toFormat("cccc")
}

export function toDateMonthFormat(dateString: string) {
    return DateTime.fromISO(dateString).toFormat("dd MMM.")
}

export const convertSecondsToHMS = (seconds: number) => {
    const duration = Duration.fromObject({ seconds })
    return duration.toFormat("mm:ss")
}
