<template>
    <div class="hamburger" :class="{ settingsHeader: isSettings }">
        <MenuLogo :class="{ settings: isSettings }" />
        <UserData />
        <button class="hamburger-menu-button" @click="showNav = !showNav">
            &#9776;
        </button>
    </div>

    <template v-if="showNav">
        <div
            class="page-header"
            :class="{ settings: isSettings, draggable: RiceCookerPresent }"
        >
            <IconBase icon="icon-burger"></IconBase>
            <SettingsMenu v-if="isSettings" />
            <MobileMainMenu v-else />
        </div>
        <CallerId />

        <div
            class="offline top-bar"
            v-if="
                Boolean(Number(settings.tb_api_active)) &&
                settings.tb_api_version !== 'pos_api'
            "
        >
            {{ translate("tb_external_order_api_deprecated_body") }}
        </div>
        <div
            class="top-bar offline"
            v-if="
                offlineModeStore().isOffline ||
                networkIssuesStore().hasNetworkIssues ||
                archive_mode
            "
        >
            <span class="text">
                <IconBase icon="warning" class="mr-2" />
                <b>{{ translate("warning") }}: </b>
                <template v-if="offlineModeStore().isOffline">
                    {{ translate("app_offlineMode") }}
                </template>
                <template v-else-if="networkIssuesStore().hasNetworkIssues">
                    {{ translate("app_network_issues") }}
                </template>
                <template v-else-if="archive_mode">
                    {{ translate("warning_archive_mode") }}
                </template>
            </span>
        </div>
        <div v-if="showFlyerImage" class="shop-background">
            <div class="overlay">
                <h1>{{ translate("order_flyers") }}</h1>
            </div>
        </div>
    </template>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue"
import { useRouter } from "vue-router"
import SettingsMenu from "@/ui-elements/menu/SettingsMenu.vue"
import { useI18n } from "vue-i18n"
import MenuLogo from "@/ui-elements/page-header/MenuLogo.vue"
import { RiceCooker } from "@/riceCooker/riceCooker"
import IconBase from "@/ui-elements/IconBase.vue"
import CallerId from "@/ui-elements/page-header/CallerId.vue"
import { useSettingsStore } from "@/store/Settings"
import { useUserStore } from "@/store/User"
import MobileMainMenu from "@/ui-elements/responsive/mobile/menu/MobileMainMenu.vue"
import UserData from "@/ui-elements/page-header/UserData.vue"
import { offlineModeStore } from "@/store/offlineMode"
import { networkIssuesStore } from "@/store/networkIssues"

export default defineComponent({
    name: "MobilePageHeader",
    methods: { networkIssuesStore, offlineModeStore },
    components: {
        IconBase,
        MenuLogo,
        SettingsMenu,
        CallerId,
        MobileMainMenu,
        UserData,
    },
    setup() {
        const { t: translate } = useI18n()
        const router = useRouter()
        const userStore = useUserStore()
        const settingStore = useSettingsStore()
        const settings = settingStore.settings
        const showNav = ref(false)

        const isSettings = computed(() => {
            const regex = new RegExp("/settings")
            return (
                (router.currentRoute.value.matched.length &&
                    router.currentRoute.value.matched[0].path ===
                        "/settings") ||
                regex.test(router.currentRoute.value.path)
            )
        })

        const showFlyerImage = computed(
            () => router.currentRoute.value.name === "newFlyer"
        )
        const RiceCookerPresent = RiceCooker.isPresent()

        const archive_mode = computed(
            () => !!Number(userStore.user.is_read_only)
        )

        router.afterEach(() => {
            showNav.value = false
        })

        return {
            showFlyerImage,
            translate,
            open,
            isSettings,
            RiceCooker,
            RiceCookerPresent,
            archive_mode,
            settings,
            showNav,
        }
    },
})
</script>

<style lang="scss">
@media only screen and (max-width: $screen-mobile) {
    .page-header {
        align-items: flex-start;
        display: flex;
        padding: 0rem;
        position: fixed;
        z-index: 999;
        background: $white;
        height: 100vh;
        width: 100vw;
        flex-direction: column;
        flex-wrap: nowrap;
        position: sticky;

        .menu-list {
            list-style: none;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            height: auto;
            padding: 1rem 0.5rem;

            .menu-item {
                justify-content: flex-start;

                padding: 1rem 1rem;
                flex-flow: nowrap;
                align-items: baseline;

                .statistic {
                    border-radius: $radius;
                    color: $primary-color;
                    text-align: center;
                    font-weight: $font-weight-bold;
                    position: absolute;
                    right: 2rem;
                    top: 13px;
                }

                .text {
                    font-size: 1.5rem !important;
                }

                .icon-base {
                    font-size: 1.5rem !important;
                }
            }

            .menu-item::after {
                content: none;
            }

            .icon-base {
                padding: 5px 0 0 0;
                margin: 0;
            }

            .text {
                font-size: 1.2rem;
            }
        }

        .menu-list-right {
            list-style: none;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            height: auto;
            padding: 0 0.5rem;

            .messages {
                padding: 0 0 0.5rem 1rem;

                .messages-label {
                    font-size: 1.5rem;
                }
            }
        }

        .dropdown-menu {
            left: 1rem;
            top: calc(100% - 16rem);
        }

        .footer-items {
            display: flex;
            padding: 3rem 0 1rem 0;
            flex-direction: row;
            width: 100%;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: nowrap;
            width: 94vw;
            margin-left: 1rem;
            border-bottom: 1px solid #dfdfdf;

            .button {
                min-height: 3rem;
                width: auto;
                padding: 1rem;
                border: none !important;
                background: white;
                display: flex;
                flex-direction: column;
                align-items: stretch;
                justify-content: center;

                .icon-base {
                    font-size: 2rem;
                    color: $primary-color;
                }

                .button-text {
                    color: #31344a;
                    font-size: 1rem;
                    padding: 1rem;
                }
            }

            .animate-pulse-object {
                height: auto;
                background: #ffaf21;
                margin-bottom: 1rem;
                padding: 0;

                .icon-base {
                    color: white;
                    margin-top: 1rem;
                }

                .button-text {
                    color: white;
                }
            }
        }

        .language-selector {
            width: 100%;

            .select {
                height: 4rem;

                .selected {
                    height: 4rem;
                    width: 88vw;
                }

                .option-label {
                    font-size: 1.4rem;
                }
            }

            .icon-base {
                font-size: 1.2rem;
            }

            .selected-text {
                font-size: 1.5rem;
            }
        }

        .child-text {
            width: 100%;
            color: rgb(0, 0, 0);
            text-decoration: none;
            transition: all 0.18s ease-out;
            font-size: 1.4rem;
        }
    }

    .logo {
        width: 2.5rem;
        height: 2.5rem;
        margin: 10px;
    }

    .font {
        margin: 0 !important;
        padding: 10px;
    }

    .font-size-modifier {
        top: 68vh;
        left: 10px;
    }

    .hamburger {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $white;
        position: sticky;
        gap: 0.5rem;
    }

    .hamburger-menu-button {
        padding: 17px 17px;
        font-size: 30px;
        border: 1px solid $darker-gray;
        border-radius: 5px;
        background-color: white;
        color: #e06633;
        margin: 0.5rem 1rem;
    }

    .language-selector {
        margin-left: 0;
        padding: 8px;
        margin-top: 2rem;
    }

    .menu-list-right {
        display: inline-flex;
        align-items: center;
        margin-left: 0;
    }

    .logout-div {
        display: flex;
        justify-content: flex-start;
        padding: 1rem 1.5rem;
        flex-flow: nowrap;
        align-items: baseline;
        gap: 0.5rem;
    }

    .logout-text {
        font-size: 1.5rem;
    }

    .logout-icon {
        font-size: 1.5rem;
    }

    .unread-messages {
        position: relative;
        font-size: 0.75rem;
        color: #e06633;
        top: -0.3rem;
        right: -0.35rem;
        border-radius: 2.5rem;
        background: white;
        height: 1rem;
    }

    .settingsHeader {
        background: linear-gradient(90deg, #e10606 0%, #e06633 100%);

        .user-data {
            .name {
                color: $white;
            }

            .id {
                color: $gray;
            }
        }
    }

    .settings {
        .logo {
            background: transparent;
        }

        .menu-list {
            list-style: none;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            height: auto;
            padding: 1rem 0.5rem;

            .menu-item {
                justify-content: flex-start;
                padding: 1rem 1rem;
                flex-flow: nowrap;
                align-items: baseline;

                .text {
                    font-size: 1.5rem !important;
                    color: rgb(0, 0, 0);
                    font-size: 0.9rem;
                }

                .icon-base {
                    font-size: 1.5rem !important;

                    &::before {
                        content: none;
                    }
                }
            }
        }
    }

    .back {
        margin-left: 1rem !important;
        padding: 1rem 0.5rem;
    }
}
</style>
