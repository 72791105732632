import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "selected" }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "options-list",
  ref: "optionsList"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  key: 1,
  class: "options-list",
  ref: "optionsList"
}
const _hoisted_8 = { class: "option" }
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBase = _resolveComponent("IconBase")!
  const _component_ScrollButtons = _resolveComponent("ScrollButtons")!
  const _component_TextField = _resolveComponent("TextField")!
  const _component_InputField = _resolveComponent("InputField")!

  return (_openBlock(), _createBlock(_component_InputField, {
    class: _normalizeClass(["select-component", _ctx.name]),
    disabled: _ctx.disabled,
    error: _ctx.error,
    success: _ctx.success,
    transparent: _ctx.transparent,
    focus: _ctx.opened,
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.componentClickHandler())),
    label: _ctx.label,
    name: _ctx.name,
    "label-icon": _ctx.labelIcon,
    "label-icon-class": _ctx.labelIconClass,
    ref: "root"
  }, {
    input: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["select", [{ open: _ctx.opened }, _ctx.name]]),
        style: _normalizeStyle(_ctx.styleObject),
        ref: "select"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _renderSlot(_ctx.$slots, "icon"),
            _createElementVNode("span", {
              class: "selected-text",
              innerHTML: _ctx.selectedText
            }, null, 8, _hoisted_3)
          ]),
          _createVNode(_component_IconBase, { icon: "chevron-down" })
        ]),
        (_ctx.filteredOptions.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass(["option", [
                            { 'selected-item': item.value === _ctx.value },
                            'option-' + item.value,
                            { disabled: item.disabled },
                            { 'group-title': item.optGroup },
                        ]]),
                  onClick: _withModifiers(($event: any) => (_ctx.optionClickHandler(item)), ["stop"]),
                  style: _normalizeStyle({ top: _ctx.optionTops[index] })
                }, [
                  (item.icon)
                    ? (_openBlock(), _createBlock(_component_IconBase, {
                        key: 0,
                        icon: item.icon,
                        class: "option-icon"
                      }, null, 8, ["icon"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", {
                    class: "option-label",
                    innerHTML: item.label
                  }, null, 8, _hoisted_6),
                  (item.value === _ctx.value)
                    ? (_openBlock(), _createBlock(_component_IconBase, {
                        key: 1,
                        icon: "check"
                      }))
                    : _createCommentVNode("", true)
                ], 14, _hoisted_5))
              }), 128))
            ], 512))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", {
                  class: "option-label no-results",
                  innerHTML: _ctx.translate('no_results_were_found')
                }, null, 8, _hoisted_9)
              ])
            ], 512)),
        _createElementVNode("div", {
          class: "footer",
          onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createVNode(_component_ScrollButtons, { "scroll-area": _ctx.optionsList }, null, 8, ["scroll-area"]),
          (_ctx.canSearch)
            ? (_openBlock(), _createBlock(_component_TextField, {
                key: 0,
                appendIcon: "delete",
                onAppendIconClick: _ctx.resetFilteredOptions,
                text: _ctx.searchQuery,
                "onUpdate:text": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
                icon: "search",
                class: "w-full",
                placeholder: `${_ctx.translate('search')}...`
              }, null, 8, ["onAppendIconClick", "text", "placeholder"]))
            : _createCommentVNode("", true)
        ])
      ], 6)
    ]),
    _: 3
  }, 8, ["disabled", "error", "success", "transparent", "focus", "class", "label", "name", "label-icon", "label-icon-class"]))
}